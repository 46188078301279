import styled from 'styled-components'
import { RESERVATION_BOOKING_MODAL_MOBILE_BREAKPOINT } from '../../../styles/constants'

export const Form = styled.form``

export const PromoBannerLink = styled.a`
	text-decoration: none;
	display: block;
`

export const Picture = styled.picture`
	display: block;
	width: 100%;
	aspect-ratio: 327 / 128;
	margin-top: 20px;

	img {
		width: 100%;
		height: 100%;
	}

	@media (min-width: ${RESERVATION_BOOKING_MODAL_MOBILE_BREAKPOINT}) {
		aspect-ratio: 452 / 128;
	}
`
