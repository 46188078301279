import React, { useContext } from 'react'
import { Outlet } from 'react-router-dom'
import { Redirect } from '@notino/react-toolkit/renderer/fragment/contexts/HttpContext'

// utils
import { AppContext } from '../utils/appProvider'

const AuthRoute = () => {
	const { isAuthorized } = useContext(AppContext)

	if (!isAuthorized) {
		return <Redirect to={'/'} />
	}

	return <Outlet />
}

export default AuthRoute
