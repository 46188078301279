import styled, { css } from 'styled-components'
import { Button, IconRegularClose as NotinoCloseIcon, theme } from '@notino/react-styleguide'

// utils
import { NotinoLabelRegular500, NotinoTitleMedium, NotinoTitleSmall } from '../../styles/helpers'
import { BREAKPOINTS, SALONS_PAGE_MOBILE_BREAKPOINT } from '../../styles/constants'

export const ContentWrapper = styled.div``

export const DialogBody = styled.main<{ $centerMode?: boolean }>`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-height: 100%;
	overflow: hidden;

	${({ $centerMode }) =>
		$centerMode &&
		css`
			text-align: center;
			align-items: center;
		`}

	&:not(:last-child) {
		padding: 0 24px;
	}

	&:last-child {
		padding: 0 24px 24px 24px;
	}

	@media (max-width: ${SALONS_PAGE_MOBILE_BREAKPOINT}) {
		&:not(:last-child) {
			padding: 0 16px;
		}

		&:last-child {
			padding: 0 16px 16px 16px;
		}
	}
`

export const DialogHeader = styled.header`
	width: 100%;
	display: flex;
	justify-content: space-between;
	padding: 24px;
	gap: 16px;

	@media (max-width: ${SALONS_PAGE_MOBILE_BREAKPOINT}) {
		padding: 16px;
	}
`

export const CloseButton = styled.button`
	width: 20px;
	height: 20px;
	display: flex;
	border: none;
	background: transparent;
	transition: 0.3s ease;
	cursor: pointer;
	align-items: center;
	justify-content: center;
`

export const Title = styled.h3<{ $centerMode?: boolean }>`
	${NotinoTitleMedium};
	margin: 0 !important; // NOTE: !important overrides Notino main app heading global styles
	flex: 1;

	${({ $centerMode }) =>
		$centerMode &&
		css`
			text-align: center;
			margin: 0 0 0 20px !important; // NOTE: !important overrides Notino main app heading global styles
		`};

	@media (max-width: ${BREAKPOINTS.sm}) {
		${NotinoTitleSmall};
	}
`

export const CloseIcon = styled(NotinoCloseIcon)<{ $isSelected?: boolean }>`
	color: ${theme.color.text.primary};
	width: 16px;
	height: 16px;
`

export const DialogFooter = styled.footer`
	padding: 24px;

	@media (max-width: ${SALONS_PAGE_MOBILE_BREAKPOINT}) {
		padding: 16px;
	}
`

export const FooterCtaButton = styled(Button)`
	border-radius: 2px;
	${NotinoLabelRegular500};
`
