import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'

// utils
import { FORM, SALONS_FILTER_TYPE } from '../../../../utils/enums'
import { SALONS_FILTER_ITEMS_CONFIG } from '../../../../utils/helper'

// hooks
import useMessages from '../../../../hooks/useMessages'

// components
import Drawer from '../../../../components/Drawer/Drawer'
import SidebarFooter from '../FilterSidebarFooter/FilterSidebarFooter'
import Tag from '../../../../atoms/Tag/Tag'
import HookFormField from '../../../../formFields/HookFormField'
import PriceRangeFormField from '../../../../formFields/PriceRangeFormField/PriceRangeFormField'

// types
import { FilterPriceValuesType, FilterValuesType, LoadSalonsFromFiltersParams } from '../../../../types/types'

// assets
import ChevronDownLeft from '../../../../assets/icons/ChevronLeftIcon'

// styles
import * as SC from './FilterSidebarStyles'

// types
type FilterSidebarPriceProps = {
	filterSidebarDetailOpen: boolean
	setFilterSidebarDetailOpen: (open: boolean) => void
	filterDetailOpenedDirectly: boolean
	loadSalonsFromFilters: (params: LoadSalonsFromFiltersParams) => void
	setFilterValues: (newFilterValues: FilterPriceValuesType) => void
	currencySymbol: string | undefined
	filterItemsConfig: ReturnType<typeof SALONS_FILTER_ITEMS_CONFIG>
	submittedFilterValues: FilterValuesType
}

// component
const FilterSidebarPrice = (props: FilterSidebarPriceProps) => {
	const {
		filterSidebarDetailOpen,
		setFilterSidebarDetailOpen,
		filterDetailOpenedDirectly,
		loadSalonsFromFilters,
		setFilterValues,
		currencySymbol,
		filterItemsConfig,
		submittedFilterValues
	} = props
	const { messages } = useMessages()

	// default filter values
	const serviceTotalPriceFromDefaultValue = filterItemsConfig[SALONS_FILTER_TYPE.PRICE].defaultValue[0]
	const serviceTotalPriceToDefaultValue = filterItemsConfig[SALONS_FILTER_TYPE.PRICE].defaultValue[1]

	// submitted filter values
	const submittedServiceTotalPriceFrom = submittedFilterValues.serviceTotalPriceRange.priceFrom ?? serviceTotalPriceFromDefaultValue
	const submittedServiceTotalPriceTo = submittedFilterValues.serviceTotalPriceRange.priceTo ?? serviceTotalPriceToDefaultValue

	const {
		control,
		handleSubmit,
		reset,
		setValue,
		watch,
		formState: { isDirty }
	} = useForm<FilterPriceValuesType>({
		defaultValues: {
			serviceTotalPriceRange: {
				priceFrom: submittedServiceTotalPriceFrom,
				priceTo: submittedServiceTotalPriceTo
			}
		}
	})

	const { priceFrom: serviceTotalPriceFrom, priceTo: serviceTotalPriceTo } = watch('serviceTotalPriceRange')

	// check if current internal filter values are equal to default values
	// we will show "reset" buttons based on the check
	const isPriceFilterEqualToDefault = serviceTotalPriceFrom === serviceTotalPriceFromDefaultValue && serviceTotalPriceTo === serviceTotalPriceToDefaultValue

	useEffect(() => {
		// reinitialize internal filter values when submitted values changes to keep them in sync
		reset({
			serviceTotalPriceRange: {
				priceFrom: submittedServiceTotalPriceFrom,
				priceTo: submittedServiceTotalPriceTo
			}
		})
	}, [submittedServiceTotalPriceFrom, submittedServiceTotalPriceTo, reset])

	const handleSubmitFilterDetail = (formSubmitValues: FilterPriceValuesType) => {
		const {
			serviceTotalPriceRange: { priceFrom: priceFromSubmitValue, priceTo: priceToSubmitValue }
		} = formSubmitValues

		if (filterDetailOpenedDirectly) {
			const params: LoadSalonsFromFiltersParams = {
				serviceTotalPriceFrom: priceFromSubmitValue === serviceTotalPriceFromDefaultValue ? null : priceFromSubmitValue,
				serviceTotalPriceTo: priceToSubmitValue === serviceTotalPriceToDefaultValue ? null : priceToSubmitValue
			}

			loadSalonsFromFilters(params)
		} else {
			setFilterValues({ serviceTotalPriceRange: { priceFrom: priceFromSubmitValue, priceTo: priceToSubmitValue } })
		}

		setFilterSidebarDetailOpen(false)
	}

	const resetPriceFilterToDefault = () => {
		setValue(
			'serviceTotalPriceRange',
			{
				priceFrom: serviceTotalPriceFromDefaultValue,
				priceTo: serviceTotalPriceToDefaultValue
			},
			{ shouldDirty: true }
		)
	}

	const closeSidebarDetail = () => {
		setFilterSidebarDetailOpen(false)
		reset()
	}

	const getFooterSliderItems = () => {
		const selectedPriceTitle = filterItemsConfig[SALONS_FILTER_TYPE.PRICE].getSelectedTitle(
			currencySymbol,
			serviceTotalPriceFromDefaultValue,
			serviceTotalPriceToDefaultValue,
			serviceTotalPriceFrom,
			serviceTotalPriceTo
		)

		return selectedPriceTitle ? <Tag key={SALONS_FILTER_TYPE.PRICE} label={selectedPriceTitle} onCloseBtnClick={resetPriceFilterToDefault} /> : null
	}

	return (
		<Drawer
			open={filterSidebarDetailOpen}
			mask
			maskClosable
			// if the detail is open from the main filter sidebar, it already has a mask, so we need to set opacity of detail sidebar mask to zero, so they don't overlap visually
			maskStyle={!filterDetailOpenedDirectly ? { opacity: 0 } : undefined}
			closeIcon={!filterDetailOpenedDirectly ? <ChevronDownLeft /> : undefined}
			title={filterItemsConfig[SALONS_FILTER_TYPE.PRICE].title}
			onClose={closeSidebarDetail}
			headerExtra={
				!isPriceFilterEqualToDefault ? <SC.HeaderResetButton onClick={resetPriceFilterToDefault}>{messages.Reset}</SC.HeaderResetButton> : undefined
			}
			footer={<SidebarFooter isVisible={isDirty} extraContent={getFooterSliderItems()} formId={FORM.FILTER_SIDEBAR_PRICE} />}
			destroyOnClose
		>
			<SC.SidebarDetailContent>
				<SC.SidebarForm onSubmit={handleSubmit(handleSubmitFilterDetail)} id={FORM.FILTER_SIDEBAR_PRICE}>
					<HookFormField
						control={control}
						name={'serviceTotalPriceRange'}
						component={PriceRangeFormField}
						priceFromDefaultValue={serviceTotalPriceFromDefaultValue}
						priceToDefaultValue={serviceTotalPriceToDefaultValue}
						currencySymbol={currencySymbol}
					/>
				</SC.SidebarForm>
			</SC.SidebarDetailContent>
		</Drawer>
	)
}

export default FilterSidebarPrice
