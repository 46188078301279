/* eslint-disable global-require */
import React, { useContext, useEffect, useRef, useState, useCallback } from 'react'

// types
import { GetSalonsDataParams, Salon } from '../../../../types/types'

// utils
import { AppContext } from '../../../../utils/appProvider'

// styles
import useMessages from '../../../../hooks/useMessages'
import { MyLocationContext } from '../../../../utils/myLocationProvider'
import { HomePageContainer } from '../../HomePageStyles'
import SalonsSlider from '../SalonsSlider/SalonsSlider'
import { RECOMMENDED_SALONS_PARAMS } from '../../utils'

type Props = {
	recommendedSalonsData: Salon[] | undefined
}

// component
const RecommendedSalonsSection = (props: Props) => {
	const { recommendedSalonsData } = props

	const { apiBrowser } = useContext(AppContext)
	const { myLocation, isLoadingMyLocation } = useContext(MyLocationContext)

	const { messages } = useMessages()

	// NOTE: undefined means loading error
	const [recommendedSalons, setRecommendedSalons] = useState<Salon[] | undefined>(recommendedSalonsData)

	const [isLoadingRecommendedSalons, setIsLoadingRecommendedSalons] = useState(isLoadingMyLocation)

	const isRecommendedSalonsMountFetchRef = useRef(true)
	const isMyPositionKnownOnMount = useRef(!!myLocation)

	const fetchRecommendedSalons = useCallback(async () => {
		try {
			const salonsDataParams: GetSalonsDataParams = {
				...RECOMMENDED_SALONS_PARAMS,
				latMy: myLocation?.latMy,
				lonMy: myLocation?.lonMy
			}
			const newSalonsData = await apiBrowser.b2c.getSalonsData({ ...salonsDataParams })
			setRecommendedSalons(newSalonsData.salons)
		} catch {
			setRecommendedSalons(undefined)
		}
	}, [myLocation?.latMy, myLocation?.lonMy, apiBrowser])

	useEffect(() => {
		/**
		 * Salons are already fetched on the server side, so client-side fetching is not always necessary on page load.
		 * However, since we are working with `myLocation`, which is not available on the server, we need to handle location-based fetching.
		 * If the user grants permission to access their location, we want to refetch the data using the user's location.
		 * During this process, we show an initial loading state until the location is resolved, and if needed, fetch new data.
		 * If the user's location is already known from query parameters at page initialization, there is no need to show the initial loading state.
		 * The loading state is displayed over the salons fetched from the server, ensuring that the data is available in the HTML for search engine bots, while preventing a content flash for users.
		 */
		;(async () => {
			// NOTE: uncomment if you want to refetch salons based on user's location when user allow his position in Booking form
			/* if (myLocation) {
				isRecommendedSalonsMountFetchRef.current = true
			} */

			if (isLoadingMyLocation || !isRecommendedSalonsMountFetchRef.current) {
				return
			}

			if (myLocation && !isMyPositionKnownOnMount.current) {
				setIsLoadingRecommendedSalons(true)
				await fetchRecommendedSalons()
			}

			setIsLoadingRecommendedSalons(false)
			isRecommendedSalonsMountFetchRef.current = false
		})()
	}, [isLoadingMyLocation, myLocation, fetchRecommendedSalons])

	const onTryAgain = async () => {
		setIsLoadingRecommendedSalons(true)
		await fetchRecommendedSalons()
		setIsLoadingRecommendedSalons(false)
	}

	return (
		<HomePageContainer>
			<SalonsSlider
				title={messages['recommended-salons-section-title']}
				salons={recommendedSalons || []}
				isLoadingData={isLoadingRecommendedSalons}
				isLoadingError={recommendedSalons === undefined}
				imageLoading={'lazy'}
				errorScreen={{
					button: {
						onClick: onTryAgain
					}
				}}
			/>
		</HomePageContainer>
	)
}

export default RecommendedSalonsSection
