import styled from 'styled-components'
import { BREAKPOINTS } from '../../../../styles/constants'
import { HomePageSectionDescription, HomePageSectionTitle } from '../../HomePageStyles'

export const InfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-bottom: 24px;

	@media (min-width: ${BREAKPOINTS.lg}) {
		margin-bottom: 32px;
	}
`

export const SectionTitle = styled(HomePageSectionTitle)`
	max-width: 400px;
`

export const SectionDescriptionDesktop = styled(HomePageSectionDescription)`
	max-width: 800px;
	display: none;

	@media (min-width: ${BREAKPOINTS.lg}) {
		display: block;
	}
`

export const SectionDescriptionMobile = styled(HomePageSectionDescription)`
	@media (min-width: ${BREAKPOINTS.lg}) {
		display: none;
	}
`

export const DownloadsSectionWrapper = styled.div`
	display: flex;
	gap: 20px;
`

export const StoreDownloadButtonsWrapper = styled.div`
	display: flex;
	gap: 8px;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;

	@media (min-width: ${BREAKPOINTS.lg}) {
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: space-between;
		align-items: flex-start;
	}
`

export const QRCodeImageWrapper = styled.div`
	width: 86px;
	height: 86px;
	flex: 0 0 auto;
	display: none;

	@media (min-width: ${BREAKPOINTS.lg}) {
		display: block;
	}
`

export const ImageLink = styled.a`
	display: block;
	width: 100%;
	height: 100%;
	text-decoration: none;
`

export const ImageContainer = styled.div`
	aspect-ratio: 544 / 330;

	&:nth-child(5) {
		aspect-ratio: 544 / 700;
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: fill;
	}
`

export const Layout = styled.div`
	display: grid;
	gap: 32px;
	overflow: hidden;

	@media (min-width: ${BREAKPOINTS.sm}) {
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(3, 1fr);

		${ImageContainer}:nth-child(5) {
			grid-column: 2 / 3;
			grid-row: 1 / 3;
		}
	}

	@media (min-width: ${BREAKPOINTS.lg}) {
		grid-template-columns: repeat(3, 1fr);
		grid-template-rows: repeat(2, 1fr);

		${ImageContainer}:nth-child(5) {
			grid-column: 3 / 4;
			grid-row: 1 / 3;
		}
	}
`
