import styled from 'styled-components'
import { theme } from '@notino/react-styleguide'
import { NotinoBodyLarge, resetButtonStyles } from '../../../../styles/helpers'
import { BREAKPOINTS } from '../../../../styles/constants'
import { HomePageSectionDescription, HomePageSectionTitle } from '../../HomePageStyles'

export const AppDownloadSection = styled.section<{ $mobileBg: string; $desktopBg: string }>`
	width: 100%;
	background-size: cover;
	background-position: center;
	background-image: ${({ $mobileBg }) => `url("${$mobileBg}")`};
	display: flex;
	align-items: center;

	@media (min-width: ${BREAKPOINTS.lg}) {
		background-image: ${({ $desktopBg }) => `url("${$desktopBg}")`};
	}
`

export const Layout = styled.div`
	display: grid;
	gap: 24px;
	padding: 40px 0;

	@media (min-width: ${BREAKPOINTS.sm}) {
		gap: 40px;
	}

	@media (min-width: ${BREAKPOINTS.lg}) {
		gap: 0;
		grid-template-columns: repeat(2, 1fr);
	}
`

export const InfoCol = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;

	@media (min-width: ${BREAKPOINTS.lg}) {
		padding: 40px 0;
		margin-right: 80px;
	}
`

export const ImageCol = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
`

export const SectionTitle = styled(HomePageSectionTitle)`
	max-width: 400px;

	@media (min-width: ${BREAKPOINTS.lg}) {
		max-width: 600px;
	}
`

export const SectionDescription = styled(HomePageSectionDescription)`
	max-width: 600px;
`

export const PlayIcon = styled.div`
	width: 64px;
	height: 64px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	transform: scale(1);
	border-radius: 50%;
	overflow: hidden;
	transition: transform 0.2s ease;

	svg {
		position: relative;
	}

	&::before {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background: ${theme.color.background.inverse};
		opacity: 60%;
	}
`

export const ImageContainer = styled.div`
	display: block;
	width: 100%;
	aspect-ratio: 1.6;
`

export const ImageLink = styled.a`
	display: block;
	width: 100%;
	height: 100%;
	text-decoration: none;
`

export const VideoPreviewButton = styled.button`
	${resetButtonStyles};
	display: block;
	width: 100%;
	height: 100%;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;

	&:hover ${PlayIcon} {
		transform: scale(1.1);
	}
`

export const PictureContainer = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
`

export const Picture = styled.picture`
	width: 100%;
	height: 100%;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
	}
`

export const DownloadsSectionWrapper = styled.div`
	display: flex;
	gap: 20px;
`

export const StoreDownloadButtonsWrapper = styled.div`
	display: flex;
	gap: 8px;
	flex-wrap: wrap;

	@media (min-width: ${BREAKPOINTS.lg}) {
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: space-between;
	}
`

export const QRCodeImageWrapper = styled.div`
	width: 86px;
	height: 86px;
	flex: 0 0 auto;
	display: none;

	@media (min-width: ${BREAKPOINTS.lg}) {
		display: block;
	}
`

export const InfoIconButton = styled.button`
	${resetButtonStyles};
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${theme.color.background.primary};
	border-radius: 100%;
	height: 1rem;
	width: 1rem;
	margin-left: auto;
	align-self: flex-end;
`

export const InfoModalText = styled.p`
	${NotinoBodyLarge};
	overflow: hidden;
	white-space: pre-wrap;
	margin-top: 24px;
`
