import styled from 'styled-components'

// components
import DefaultContainer from '../../components/DefaultContainer/DefaultContainer'

// styles
import { screenReaderOnly } from '../../styles/helpers'

export const SeoTitle = styled.h1`
	${screenReaderOnly};
`

export const PageContainer = styled(DefaultContainer)`
	overflow: hidden;
	position: relative;
`
