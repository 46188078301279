import React, { useContext } from 'react'
import { Breadcrumb as NotinoBreadcrumb, IBreadcrumb } from '@notino/react-styleguide'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'

// utils
import { pushToDataLayer } from '../../utils/dataLayer'
import { getBreadcrumbClickEvent } from '../../utils/dataLayerEvents'
import { PAGE_LINKS } from '../../utils/helper'
import { MyLocationContext } from '../../utils/myLocationProvider'

// hooks
import useIsMobile from '../../hooks/useIsMobile'
import useMessages from '../../hooks/useMessages'

// styles
import { SALONS_PAGE_MOBILE_BREAKPOINT_INT } from '../../styles/constants'
import * as SC from './BreadcrumbStyles'

type BreadcrumbProps = {
	hasSalonsBreadcrumb?: boolean
	breadcrumbItems: IBreadcrumb[]
	handleNavigation?: (item: IBreadcrumb) => void
	className?: string
}

const Breadcrumb = (props: BreadcrumbProps) => {
	const location = useLocation()

	const { breadcrumbItems, handleNavigation, hasSalonsBreadcrumb = true, className } = props

	const { locale } = useIntl()
	const { messages } = useMessages()

	const { myLocation } = useContext(MyLocationContext)

	const isMobile = useIsMobile(SALONS_PAGE_MOBILE_BREAKPOINT_INT)

	const handleItemClick = (params: { index: number } & IBreadcrumb) => {
		// push to dataLayer
		const event = getBreadcrumbClickEvent({
			isMobile,
			name: params.name,
			pathQuery: `${location.pathname}/${location.search}`,
			levelIndex: params.index,
			text: breadcrumbItems.map((item) => item.name)
		})
		pushToDataLayer(event)
	}

	const items = hasSalonsBreadcrumb
		? [
				{
					link: PAGE_LINKS['/salons/homepage'](locale, myLocation ? { lonMy: myLocation.lonMy, latMy: myLocation.latMy } : undefined),
					name: messages.Salons
				},
				...breadcrumbItems
			]
		: breadcrumbItems

	return (
		<SC.BreadcrumbWrapper className={className}>
			<NotinoBreadcrumb homeLinkTitle='Home page' items={items} onItemClick={handleItemClick} handleNavigation={handleNavigation} />
		</SC.BreadcrumbWrapper>
	)
}

export default Breadcrumb
