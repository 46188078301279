import React from 'react'
import { FormattedMessage } from 'react-intl'

// styles
import * as SC from './StatisticsSectionStyles'
import { HomePageContainer } from '../../HomePageStyles'

// types
import { CmsHomepageData } from '../../../../types/types'

// hooks
import useMessages from '../../../../hooks/useMessages'

type Props = {
	cmsData: CmsHomepageData['npStatisticsData'] | undefined
}

// component
const StatisticsSection = (props: Props) => {
	const { cmsData } = props

	const { messages } = useMessages()

	const salonsCount = cmsData?.salonsCount || messages['statistics-section-item-1-fallback-count']
	const professionalsCount = cmsData?.professionalsCount || messages['statistics-section-item-2-fallback-count']
	const reservationsCount = cmsData?.reservationsCount || messages['statistics-section-item-3-fallback-count']

	return (
		<section>
			<HomePageContainer>
				<SC.Layout>
					<SC.StatisticsItem>
						<SC.Text>
							<FormattedMessage
								id={'statistics-section-item-1'}
								defaultMessage={'Choose from the list of over <span>{ salonsCount }</span> salons'}
								values={{
									salonsCount,
									span: (chunks) => <SC.Highlight>{chunks}</SC.Highlight>
								}}
							/>
						</SC.Text>
					</SC.StatisticsItem>
					<SC.Divider />
					<SC.StatisticsItem>
						<SC.Text>
							<FormattedMessage
								id={'statistics-section-item-2'}
								defaultMessage={'<span>{ professionalsCount }</span> beauty professionals using Notino Partner'}
								values={{
									professionalsCount,
									span: (chunks) => <SC.Highlight>{chunks}</SC.Highlight>
								}}
							/>
						</SC.Text>
					</SC.StatisticsItem>
					<SC.Divider />
					<SC.StatisticsItem>
						<SC.Text>
							<FormattedMessage
								id={'statistics-section-item-3'}
								defaultMessage={'More than <span>{ reservationsCount }/span> reservations made through Notino Partner'}
								values={{
									reservationsCount,
									span: (chunks) => <SC.Highlight>{chunks}</SC.Highlight>
								}}
							/>
						</SC.Text>
					</SC.StatisticsItem>
				</SC.Layout>
			</HomePageContainer>
		</section>
	)
}

export default StatisticsSection
