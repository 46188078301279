import React, { useContext } from 'react'
import { useIntl } from 'react-intl'

// utils
import { PAGE_LINKS } from '../../../../utils/helper'
import { MyLocationContext } from '../../../../utils/myLocationProvider'

// types
import { CategoriesResponse, CmsHomepageData } from '../../../../types/types'

// styles
import * as SC from './ServicesSectionStyles'
import { HomePageContainer, HomePageSectionLink } from '../../HomePageStyles'

// hooks
import useMessages from '../../../../hooks/useMessages'

type Props = {
	categoriesData: CategoriesResponse
	cmsData: CmsHomepageData['discoverServiceData'] | undefined
}

// component
const ServicesSection = (props: Props) => {
	const { locale } = useIntl()
	const { messages } = useMessages()

	const { categoriesData, cmsData } = props

	const { myLocation } = useContext(MyLocationContext)

	const title = cmsData?.title || messages['services-section-title']
	const description = cmsData?.subtitle || messages['services-section-description']
	const sectionLink = PAGE_LINKS['/salons'](locale, { ...myLocation })

	return (
		<section>
			<HomePageContainer>
				<SC.Layout>
					<SC.InfoCol>
						<SC.SectionTitle $marginBottom={'16px'}>{title}</SC.SectionTitle>
						<SC.SectionDescription $marginBottom={'16px'}>{description}</SC.SectionDescription>
						<HomePageSectionLink href={sectionLink}>{messages['services-section-link']}</HomePageSectionLink>
					</SC.InfoCol>
					<SC.ServicesCol>
						<SC.ServicesGrid>
							{categoriesData.categories.map((category) => (
								<SC.TopLevelCategoryButton
									type='button'
									href={PAGE_LINKS['/salons/services/:categorySlug'](locale, category.nameSlug ?? '', { ...myLocation })}
									key={category.id}
								>
									<SC.TopLevelCategoryImgWrapper>
										<SC.TopLevelCategoryImg
											src={category.image?.resizedImages.thumbnail}
											alt={category.name || ''}
											loading='lazy'
											width={80}
											height={80}
										/>
									</SC.TopLevelCategoryImgWrapper>
									<SC.TopLevelCategoryTitle>{category.name}</SC.TopLevelCategoryTitle>
								</SC.TopLevelCategoryButton>
							))}
						</SC.ServicesGrid>
					</SC.ServicesCol>
				</SC.Layout>
			</HomePageContainer>
		</section>
	)
}

export default ServicesSection
