import React, { PropsWithChildren, useContext, useState } from 'react'
import { Button, ButtonModel, IconRegularClose, Modal, ModalModel, Spinner } from '@notino/react-styleguide'
import { useForm } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'

// types
import { AddReviewForm, PostSalonReviewRequestBody } from '../../../../types/types'
import { INFO_LINE_TYPE } from '../../../../atoms/InfoLine/types'

// utils
import { PARTIAL_RATING, VALIDATION_MAX_LENGTH } from '../../../../utils/enums'
import { AppContext } from '../../../../utils/appProvider'
import { POST_REVIEW_ERROR_KEYS_MAP } from '../../../../utils/clientErrorValidations'

// components
import HookFormField from '../../../../formFields/HookFormField'
import TextAreaFormField from '../../../../formFields/TextAreaFormField/TextAreaFormField'
import InfoLine from '../../../../atoms/InfoLine/InfoLine'
import InputFormField from '../../../../formFields/InputFormField/InputFormField'
import SalonPartialRatingsFormField from '../../../../formFields/SalonPartialRatingsFormField/SalonPartialRatingsFormField'
import RatingFormField from '../../../../formFields/RatingFormField/RatingFormField'

// hooks
import useMessages from '../../../../hooks/useMessages'

// styles
import * as SC from './AddReviewModalStyles'

type Props = {
	isOpen: boolean
	onClose: () => void
	salonID: string
	t?: string
	calendarEventID?: string
	onSubmitSuccess: () => void
	onSubmitError?: (error: unknown) => void
}

type AgreementLinkProps = PropsWithChildren<{ link: string }>

const AgreementLink = (props: AgreementLinkProps) => {
	const { children, link } = props

	return (
		<SC.AgreementLink href={link} onClick={(e) => e.stopPropagation()} target='_blank' rel={'noopener noreferrer'}>
			{children}
		</SC.AgreementLink>
	)
}

const AddReviewModal = (props: Props) => {
	const { messages } = useMessages()
	const { isOpen, onClose, salonID, calendarEventID, t, onSubmitSuccess, onSubmitError } = props

	const { apiBrowser } = useContext(AppContext)

	const [postReviewGeneralError, setPostReviewGeneralError] = useState('')

	const { dataProcessingLink } = messages

	const {
		control,
		handleSubmit,
		watch,
		setError,
		formState: { isSubmitting }
	} = useForm<AddReviewForm>({
		defaultValues: {
			reviewerName: '',
			reviewMessage: '',
			rating: 0,
			partialRatings: {
				[PARTIAL_RATING.SERVICE]: 0,
				[PARTIAL_RATING.PLACE]: 0,
				[PARTIAL_RATING.COMMUNICATION]: 0,
				[PARTIAL_RATING.GENERAL_IMPRESSION]: 0
			}
		}
	})

	const { reviewerName, reviewMessage, rating, partialRatings } = watch()

	const disabledSubmitButton =
		isSubmitting || !reviewerName || !reviewMessage || !rating || Object.values(partialRatings).some((partialRating) => !partialRating)

	const handleSubmitAddReviewForm = async (values: AddReviewForm) => {
		setPostReviewGeneralError('')

		try {
			const requestBody: PostSalonReviewRequestBody = {
				reviewerName: values.reviewerName,
				reviewMessage: values.reviewMessage,
				rating: values.rating,
				partialRatings: values.partialRatings,
				calendarEventID,
				t
			}

			await apiBrowser.b2c.postSalonReview(salonID, requestBody, { formErrors: { setError, errorsMap: POST_REVIEW_ERROR_KEYS_MAP } })
			onSubmitSuccess()
		} catch (error) {
			if (onSubmitError) {
				onSubmitError(error)
			}
		}
	}

	return (
		<SC.ModalWrapper>
			<Modal show={isOpen} fullscreenOnMobile onClose={onClose} size={ModalModel.Sizes.default} disableOnBlurClose noBorders>
				<SC.ModalContent>
					<SC.Form onSubmit={handleSubmit(handleSubmitAddReviewForm)}>
						<SC.Header>
							<SC.HeaderTitle>{messages['Write a review']}</SC.HeaderTitle>
							<SC.CloseButton type={'button'} onClick={onClose}>
								<IconRegularClose color={'icon.primary'} />
							</SC.CloseButton>
						</SC.Header>
						{postReviewGeneralError && <InfoLine type={INFO_LINE_TYPE.ERROR} message={postReviewGeneralError} />}
						<SC.Main>
							<HookFormField
								control={control}
								name={'reviewerName'}
								component={InputFormField}
								label={messages.Name}
								placeholder={messages['Your name']}
								maxLength={VALIDATION_MAX_LENGTH.LENGTH_255}
								required
							/>
							<HookFormField
								control={control}
								name={'reviewMessage'}
								component={TextAreaFormField}
								label={messages['Your review']}
								placeholder={messages['Write your rating here']}
								maxLength={VALIDATION_MAX_LENGTH.LENGTH_500}
								autoSize={{
									minRows: 3
								}}
								required
							/>
							<HookFormField control={control} name={'rating'} component={RatingFormField} label={messages['Overall rating']} required />
							<SC.PartialRatingsWrapper>
								<HookFormField
									control={control}
									name={'partialRatings'}
									component={SalonPartialRatingsFormField}
									label={messages['Parameter rating']}
									ratingsGap={'20px'}
									required
								/>
							</SC.PartialRatingsWrapper>
						</SC.Main>
						<SC.AgreementDescription>
							<FormattedMessage
								id='By submitting, you agree to the processing of personal data for the purpose of displaying product reviews by Notino s.r.o. on their website, social networks or in commercial communications. You have the right to withdraw your consent. For more information, please see the personal <aDataProcessingLink>data processing policy</aDataProcessingLink>.'
								defaultMessage='By submitting, you agree to the processing of personal data for the purpose of displaying product reviews by Notino s.r.o. on their website, social networks or in commercial communications. You have the right to withdraw your consent. For more information, please see the personal <aDataProcessingLink>data processing policy</aDataProcessingLink>.'
								values={{
									aDataProcessingLink: (chunks) => <AgreementLink link={dataProcessingLink}>{chunks}</AgreementLink>
								}}
							/>
						</SC.AgreementDescription>
						<SC.Footer>
							<Button fullWidth type={'submit'} buttonStyle={ButtonModel.Styles.primary} disabled={disabledSubmitButton}>
								{isSubmitting ? <Spinner size={16} color={'icon.disabled'} /> : messages['Send review']}
							</Button>
						</SC.Footer>
					</SC.Form>
				</SC.ModalContent>
			</Modal>
		</SC.ModalWrapper>
	)
}

export default AddReviewModal
