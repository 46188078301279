import styled from 'styled-components'
import { Button, theme } from '@notino/react-styleguide'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'

import { BREAKPOINTS } from '../../../styles/constants'
import { NotinoBodyMedium, NotinoBodyRegular, NotinoBodySmall, NotinoLabelRegular, NotinoLabelSmall, NotinoTitleLarge } from '../../../styles/helpers'

export const SummaryLayoutWrapper = styled.div`
	display: flex;
	overflow: hidden;
	height: 100%;
	flex-direction: column;

	@media (min-width: ${BREAKPOINTS.lg}) {
		flex-direction: row;
	}
`

export const ScrollableContent = styled(OverlayScrollbarsComponent)`
	@media (min-width: ${BREAKPOINTS.lg}) {
		flex: 1;
		min-width: 0;
		min-height: 0;
		overflow: auto;
		position: relative;
		z-index: 1;
		transition: height 0.3s ease;
	}
`

export const SummaryWrapper = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
`

export const SummaryGrid = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;

	@media (min-width: ${BREAKPOINTS.lg}) {
		flex-direction: row;
		flex: 1;
	}
`

export const CloseIconWrapper = styled.div`
	position: absolute;
	right: 20px;
	top: 20px;
	cursor: pointer;
	width: 24px;
	height: 24px;
`

export const PromoBanner = styled.div`
	order: 2;

	@media (min-width: ${BREAKPOINTS.lg}) {
		width: 400px;
		order: 1;
		padding: 0;
		border-radius: 0;
	}
`

export const PromoBannerContent = styled.div`
	@media (min-width: ${BREAKPOINTS.lg}) {
		position: sticky;
		top: 0;
	}
`

export const Picture = styled.picture`
	display: block;
	width: 100%;
	aspect-ratio: 500 / 960;

	img {
		width: 100%;
		height: 100%;
	}

	@media (min-width: ${BREAKPOINTS.lg}) {
		aspect-ratio: 400 / 720;
	}
`

export const PromoBannerLink = styled.a`
	text-decoration: none;
	display: block;
`

export const ReservationContent = styled.div`
	width: 100%;
	padding: 20px;
	order: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 20px;

	@media (min-width: ${BREAKPOINTS.lg}) {
		width: 520px;
		order: 2;
	}
`

export const CheckIcon = styled.div`
	width: 60px;
	height: 60px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 16px;
	background: ${theme.color.background.highlight};
`

export const ReservationTitleWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`

export const ReservationTitle = styled.h1`
	${NotinoTitleLarge};
	margin-bottom: 16px;
	text-align: center;
`

export const ReservationWrapper = styled.div`
	padding: 10px;
	border: 1px solid rgba(225, 225, 225, 1);
	border-radius: 4px;
	display: flex;
	position: relative;
	flex-direction: column;
	gap: 10px;
	@media (min-width: ${BREAKPOINTS.lg}) {
		padding: 20px;
		gap: 20px;
	}
`

export const SalonInfo = styled.div`
	display: flex;
	gap: 20px;
`
export const SalonCover = styled.div`
	width: 82px;
	height: 82px;
	img {
		width: 82px;
		height: 82px;
		object-fit: cover;
		border-radius: 4px;
	}
`

export const Rating = styled.div`
	display: flex;
	${NotinoLabelSmall};
	align-items: center;
	gap: 4px;
	color: ${theme.color.text.highlight};
	margin-bottom: 2px;
`

export const SalonTitle = styled.h2`
	font-size: ${theme.typography.bodyLarge};
	font-weight: 500;
	margin-top: 0; // resetting global notino margin on heading
	margin-bottom: 4px;
	line-height: 20px; // overriding value from theme.typography.bodyLarge to match Figma
`

export const LineWithIcon = styled.span`
	display: flex;
	gap: 4px;
	margin-bottom: 4px;
	${NotinoBodyMedium};

	& > svg {
		flex-shrink: 0;
		margin-top: 3px;
	}
`

export const OpeningHoursStatus = styled.span<{ $isOpen: boolean }>`
	color: ${(props) => (props.$isOpen ? theme.color.text.positive : theme.color.text.negative)};
`

export const OpeningHours = styled(LineWithIcon)<{ $isOpen: boolean }>`
	color: ${theme.color.text.tertiary};

	& svg {
		color: ${(props) => (props.$isOpen ? theme.color.icon.positive : theme.color.icon.negative)};
		width: 12px;
		height: 12px;
	}
`

export const Divider = styled.div`
	border-top: 1px solid rgba(225, 225, 225, 1);
	width: 100%;
`

export const CustomerWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;

	@media (min-width: ${BREAKPOINTS.lg}) {
		gap: 20px;
	}
`

export const Customer = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 8px;
`

export const CustomerLabel = styled.span`
	${NotinoBodyRegular};
`

export const CustomerValue = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
`

export const LabelRegular = styled.span`
	${NotinoLabelRegular};
	word-break: break-word;
`

export const Price = styled.span`
	${NotinoLabelRegular};
	white-space: nowrap;
`

export const CustomerEmail = styled.span`
	${NotinoBodySmall};
	color: rgba(71, 71, 71, 1);
`

export const CustomerPhone = styled.span`
	${NotinoBodySmall};
	color: rgba(71, 71, 71, 1);
`

export const Service = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 16px;
`
export const ServiceValues = styled.div`
	display: flex;
	gap: 8px;
	flex-direction: column;
`
export const ServiceValue = styled.div`
	display: flex;
	gap: 8px;

	svg {
		width: 20px;
		height: 20px;
		flex-shrink: 0;
	}
`

export const Note = styled.div`
	padding: 16px 12px;
	${NotinoBodySmall};
	background: rgba(241, 241, 241, 1);
`

export const Footer = styled.div`
	display: none;
	@media (min-width: ${BREAKPOINTS.lg}) {
		display: flex;
		gap: 8px;
		position: sticky;
		bottom: 20px;
	}
`

export const MobileFooter = styled.div`
	flex-shrink: 0;
	border-top: 1px solid rgba(5, 5, 5, 0.06);
	box-shadow: 0px -4px 16px 0px rgba(0, 0, 0, 0.08);
	z-index: 2;
	padding: 24px;
	display: flex;
	width: 100%;
	gap: 8px;
	background: ${theme.color.background.primary};

	@media (min-width: ${BREAKPOINTS.lg}) {
		display: none;
	}
`

export const EmployeeWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
	word-break: break-word;
`

export const EmployeeAvatar = styled.img`
	width: 20px !important;
	height: 20px;
	border-radius: 50%;
`

export const SalonLogo = styled.div`
	position: absolute;
	top: 10px;
	right: 10px;
	width: 24px;
	height: 24px;
	@media (min-width: ${BREAKPOINTS.lg}) {
		width: 40px;
		height: 40px;
		top: 20px;
		right: 20px;
	}
	img {
		border-radius: 4px;
	}
`

export const LinkButton = styled(Button)`
	// fix for notino button when using href
	display: flex;
	align-items: center;

	justify-content: center;
	gap: 8px;
`
