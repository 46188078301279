import { useContext, useState } from 'react'
import { SalonReviewsResponse } from '../types/types'
import { AppContext } from '../utils/appProvider'

const useSalonReviews = (salonId: string, reviewsData: SalonReviewsResponse | undefined) => {
	const { apiBrowser } = useContext(AppContext)

	const initialReviews = reviewsData?.reviews && reviewsData?.reviews.length > 0 ? reviewsData?.reviews : null
	const [reviews, setReviews] = useState(initialReviews)
	const [reviewsPage, setReviewsPage] = useState(1)

	const fetchMoreReviews = async (args: { resetPage?: boolean } = {}) => {
		const { resetPage = false } = args

		const newReviewsData = await apiBrowser.b2c.getSalonReviewsData(salonId, { page: resetPage ? 1 : reviewsPage + 1 })
		if (newReviewsData) {
			setReviews((prev) => {
				if (prev && !resetPage) return [...prev, ...newReviewsData.reviews]
				return newReviewsData.reviews
			})
			setReviewsPage(reviewsPage + 1)
		}
	}

	return { reviews, reviewsPage, fetchMoreReviews }
}

export default useSalonReviews
