import { useContext, useState, useEffect } from 'react'
import { useIntl } from 'react-intl'

// utils
import { AppContext } from '../utils/appProvider'
import { SCREEN_STATE_KEY } from '../utils/enums'
import { PAGE_LINKS } from '../utils/helper'

// types
import { ReservationBookingModalInitData, RequiredSnapshotData } from '../types/types'

// hooks
import useLocalStorage from './useLocalStorage'

type SnapshotData = {
	[SCREEN_STATE_KEY.RESERVATION_SUMMARY_MODAL_SALON_CATEGORY_DETAIL]: ReservationBookingModalInitData
}

type SnapshotType<T extends SCREEN_STATE_KEY> = SnapshotData[T]

const getBaseUrl = () => {
	if (!window?.location) {
		const { origin, pathname } = window.location
		if (!origin || !pathname) {
			// eslint-disable-next-line no-console
			console.warn('Base URL will be empty because window.location is available only on client side')
			return ''
		}
	}

	return window.location.origin + window.location.pathname
}

const createIdpLink = (type: 'login' | 'register', redirectUrl: string, shopId: string) => {
	if (!window?.location?.origin) {
		// eslint-disable-next-line no-console
		console.warn('IDP link cannot be created because window.location.origin is not defined')
		return ''
	}

	return `${window.location.origin}/login?returnUrl=${encodeURIComponent(redirectUrl)}&shopId=${shopId}&prompt=salon_${type}`
}

/**
 * Hook which helps to handle authorization process. It stores data in local storage so that
 * it can be restored after authorization process is completed. It also helps to redirect to
 * identity provider.
 * Keep in mind that this hook should be used only on client side (due localStorage).
 *
 * @param persistentStoreKey - key which is used to store data in local storage.
 * 							Same key used in different parts of application will grant access to the same data.
 * @param dataToPersist - data which is persisted in local storage. This data is restored
 *                        after authorization process is completed.
 *
 * @returns object with following properties:
 *   - isAuthorized - boolean which indicates if user is authorized or not
 *   - onLogin - function which redirects user to identity provider for login
 *   - onRegister - function which redirects user to identity provider for registration
 *   - snapshot - data which was persisted in local storage
 *   - clearSnapshot - function which deletes data from local storage
 */
const useAuthRedirect = (persistentStoreKey: SCREEN_STATE_KEY, dataToPersist?: SnapshotType<typeof persistentStoreKey>) => {
	const { isAuthorized, shopId } = useContext(AppContext)

	const { locale } = useIntl()

	const { setValue, storedValue, deleteStoredValue } = useLocalStorage<SnapshotType<typeof persistentStoreKey> & RequiredSnapshotData>(persistentStoreKey)

	const [snapshot, setSnapshot] = useState<SnapshotType<typeof persistentStoreKey> | null>(null)

	useEffect(() => {
		// restore snapshot from local storage if it matches current url
		if (storedValue?.url === getBaseUrl()) {
			setSnapshot(storedValue)
		}
	}, [storedValue])

	const persistValue = (snapshotData?: SnapshotType<typeof persistentStoreKey>) => {
		const toPersist = snapshotData || dataToPersist

		const localStorageValue = { ...toPersist, url: getBaseUrl(), authorized: false }
		setValue(localStorageValue)
	}

	const redirectToIdentityProvider = (type: 'login' | 'register') => {
		const idpLink = createIdpLink(type, PAGE_LINKS['/salons/auth-complete'](locale), shopId)

		window.location.href = idpLink
	}

	const onRegister = () => {
		persistValue()
		redirectToIdentityProvider('register')
	}

	const onLogin = () => {
		persistValue()
		redirectToIdentityProvider('login')
	}

	return { isAuthorized, onLogin, onRegister, snapshot, clearSnapshot: deleteStoredValue }
}

export default useAuthRedirect
