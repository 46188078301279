import React from 'react'
import Modal from 'react-modal'
import { ButtonModel, Spinner } from '@notino/react-styleguide'

// types
import { DialogProps } from './types'

// hooks
import useMessages from '../../hooks/useMessages'

// styles
import * as SC from './DialogStyles'

const Dialog = (props: DialogProps) => {
	const { children, title, onConfirm, confirmButtonLabel, className, overlayClassName, loading, centerMode, onRequestClose } = props

	const { messages } = useMessages()

	return (
		<Modal
			{...props}
			ariaHideApp={false}
			className={`noti-dialog-content ${className || ''}`}
			onRequestClose={onRequestClose}
			overlayClassName={`noti-dialog-overlay ${overlayClassName || ''}`}
		>
			<SC.ContentWrapper>
				<SC.DialogHeader>
					<SC.Title $centerMode={centerMode}>{title}</SC.Title>
					<SC.CloseButton onClick={onRequestClose}>
						<SC.CloseIcon />
					</SC.CloseButton>
				</SC.DialogHeader>
				<SC.DialogBody $centerMode={centerMode}>{children}</SC.DialogBody>
				<SC.DialogFooter>
					<SC.FooterCtaButton fullWidth buttonStyle={ButtonModel.Styles.primary} onClick={onConfirm}>
						{loading ? <Spinner size={16} color={'icon.disabled'} /> : confirmButtonLabel || messages['I understand']}
					</SC.FooterCtaButton>
				</SC.DialogFooter>
			</SC.ContentWrapper>
		</Modal>
	)
}
export default Dialog
