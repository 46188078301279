import styled, { css } from 'styled-components'
import { theme } from '@notino/react-styleguide'
import { HomePageSectionTitle } from '../../HomePageStyles'
import { BREAKPOINTS, CONTAINER_X_PADDING } from '../../../../styles/constants'

export const SectionTitle = styled(HomePageSectionTitle)`
	max-width: 400px;
	margin-left: auto;
	margin-right: auto;
`

export const Layout = styled.div<{ $hasMoreThanThreeReviews: boolean }>`
	display: grid;
	grid-auto-flow: column;
	grid-template-rows: 1fr;
	grid-auto-columns: 90%;
	overflow-x: auto;
	gap: 16px;
	margin-left: -${CONTAINER_X_PADDING}px;
	margin-right: -${CONTAINER_X_PADDING}px;
	padding-left: ${CONTAINER_X_PADDING}px;
	padding-right: ${CONTAINER_X_PADDING}px;

	@media (min-width: ${BREAKPOINTS.md}) {
		grid-auto-columns: 45%;
	}

	@media (min-width: ${BREAKPOINTS.lg}) {
		gap: 20px;
		margin-left: 0;
		margin-right: 0;
		padding-left: 0;
		padding-right: 0;

		${({ $hasMoreThanThreeReviews }) =>
			$hasMoreThanThreeReviews
				? css`
						grid-auto-columns: 30%;
					`
				: css`
						grid-auto-flow: initial;
						grid-template-columns: repeat(3, 1fr);
					`}
	}

	@media (pointer: coarse) {
		scroll-snap-type: x mandatory;
		scroll-behavior: smooth;

		/* hide scrollbar */
		&::-webkit-scrollbar {
			display: none; // for Chrome, Safari and Opera
		}
		& {
			-ms-overflow-style: none; // for IE and Edge
			scrollbar-width: none; // for for Firefox
		}
	}
`

export const ReviewCard = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	padding: 20px;
	background: ${theme.color.background.secondary};
	height: fit-content;

	@media (pointer: coarse) {
		scroll-snap-align: start;
		scroll-snap-stop: always;
	}
`

export const ReviewHeading = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 4px;
`

export const ReviewerName = styled.div`
	font-size: 0.75rem;
	line-height: 1rem;
	font-weight: 500;
`

export const ReviewTitle = styled.div`
	font-size: 1.125rem;
	line-height: 1.5rem;
	font-weight: 500;
`

export const ReviewText = styled.div`
	font-size: 0.875rem;
	line-height: 1.25rem;
`
