import styled from 'styled-components'
import { SALONS_PAGE_MOBILE_BREAKPOINT } from '../../../../styles/constants'

// eslint-disable-next-line import/prefer-default-export
export const SalonsSearchWrapper = styled.div`
	width: 100%;
	max-width: 100%;

	@media (min-width: ${SALONS_PAGE_MOBILE_BREAKPOINT}) {
		max-width: 382px;
	}
`
