import React, { useContext, useEffect, useRef, useState, useMemo, useCallback } from 'react'
import { useInitialData } from '@notino/react-toolkit/renderer/fragment/useInitialData'
import { useIntl } from 'react-intl'
import axios from 'axios'

// components
import SalonsCategoryPageHead from './components/SalonsCategoryPageHead/SalonsCategoryPageHead'
import SalonsSeoSchema from '../../components/SalonsSeoSchema/SalonsSeoSchema'
import SalonsView from '../../components/SalonsView/SalonsView'
import Filters from './components/Filters/Filters'
import SalonsAndCitiesSearchWrap from './components/SalonsAndCitiesSearchWrap/SalonsAndCitiesSearchWrap'
import ElementsSlider from '../../components/ElementsSlider/ElementsSlider'
import SalonsPageBanner from './components/SalonsPageBanner/SalonsPageBanner'

// types
import {
	Currency,
	GetSalonsDataParams,
	CategoryResponse,
	ConfigResponse,
	ContextProps,
	IGetInitialDataProps,
	GetSalonsFilterCountResponse,
	SalonsResponse,
	LoadSalonsFromFiltersParams,
	SalonsCategoryPageServerQueryType,
	SubcategoryChangeEventInteraction,
	SalonsCategoryPageQueryType,
	SalonsCategoryPageSetQueryType,
	CmsSalonsCategoryPageData,
	Cosmetic,
	Language
} from '../../types/types'

// styles
import * as SC from './SalonsCategoryPageStyles'

// utils
import { AppContext } from '../../utils/appProvider'
import { MyLocationContext } from '../../utils/myLocationProvider'
import { areReservationsAllowed, findSmallestIndexMatch, getCategoryIDs, getValidPriceRangeQueryParams, isNil, PAGE_LINKS } from '../../utils/helper'
import { pushToDataLayer } from '../../utils/dataLayer'
import { getPageViewEvent, getSubcategoryChangeEvent, getViewItemListEvent } from '../../utils/dataLayerEvents'
import { shopsConfig } from '../../appDefaults'
import { GET_MY_LOCATION_OPTION } from '../../components/SalonsAndCitiesSearch/SalonsAndCitiesSearch'
import { ABORT_KEYS, SESSION_STORAGE_KEYS } from '../../utils/enums'
import { getCategorySeoData } from '../../utils/categoriesSeoData'

// hooks
import useMessages from '../../hooks/useMessages'

// types
type ExtendedContextProps = {
	query: SalonsCategoryPageServerQueryType
	setQuery: SalonsCategoryPageSetQueryType
	categorySlug: string
} & ContextProps

type InitialData = {
	salonsData: SalonsResponse
	categoryData: CategoryResponse
	configData: ConfigResponse
	currency: Currency
	filterCountsData: GetSalonsFilterCountResponse | undefined
	allowedReservations: boolean
	cmsData: CmsSalonsCategoryPageData | undefined
}

type SalonsCategoryPageProps = InitialData &
	Omit<ExtendedContextProps, 'query'> & {
		query: SalonsCategoryPageQueryType
		setSalonsData: React.Dispatch<React.SetStateAction<SalonsResponse | undefined>>
		setFilterCountsData: React.Dispatch<React.SetStateAction<GetSalonsFilterCountResponse | undefined>>
	}

// page
const SalonsCategoryPage = (props: SalonsCategoryPageProps) => {
	const {
		query,
		setQuery,
		categorySlug,
		salonsData,
		categoryData,
		configData,
		cmsData,
		currency,
		filterCountsData,
		allowedReservations,
		setSalonsData,
		setFilterCountsData
	} = props

	const { salons, pagination } = salonsData

	const { messages } = useMessages()
	const { locale } = useIntl()
	const { apiBrowser } = useContext(AppContext)
	const { myLocation, isLoadingMyLocation } = useContext(MyLocationContext)

	const [cosmetics, setCosmetics] = useState<Cosmetic[]>([])
	const [languages, setLanguages] = useState<Language[]>([])

	const [initialLocationBasedLoading, setInitialLocationBasedLoading] = useState(isLoadingMyLocation)
	const [areSalonsLoading, setAreSalonsLoading] = useState<boolean>(false)
	const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false)
	const [initialClientDataLoading, setInitialClientDataLoading] = useState(true)

	const isMountFetchRef = useRef(true)
	const isMyPositionKnownOnMount = useRef(!!myLocation)
	const mergeWithPreviousSalons = useRef(false)

	const defaultCity = configData.rolloutCountries.find((country) => country.languageCode === locale)?.capitalCity

	const MY_LOCATION_OPTION = GET_MY_LOCATION_OPTION()

	const refetchSalonsAndFilterCountsFromLoadMoreBtn = () => {
		setIsButtonLoading(true)

		// set mergeWithPreviousSalons flag to true so the currently showed salons can be merged with newly loaded salons
		mergeWithPreviousSalons.current = true

		setQuery({
			...query,
			page: query.page + 1
		})
	}

	const getLocationParams = (city: LoadSalonsFromFiltersParams['city']) => {
		const isUsingMyPosition = city?.placeID === MY_LOCATION_OPTION.value

		let lat: number | undefined
		let lon: number | undefined
		let latMy: number | undefined
		let lonMy: number | undefined
		let googlePlaceID: string | undefined

		if (myLocation || isUsingMyPosition) {
			latMy = myLocation?.latMy || city?.latitude
			lonMy = myLocation?.lonMy || city?.longitude
		}

		if (!isUsingMyPosition && city !== null) {
			lat = city?.latitude ?? query.lat
			lon = city?.longitude ?? query.lon
			googlePlaceID = city?.placeID ?? query.googlePlaceID
		}

		return { lat, lon, latMy, lonMy, googlePlaceID, isUsingMyPosition }
	}

	const updateQueryFromFilters = async (params: LoadSalonsFromFiltersParams) => {
		const {
			openingHoursStatus: openingHoursStatusParam,
			city,
			categoryIDs: categoryIDsParam,
			orderBy: orderByParam,
			exactRating: exactRatingParam,
			languageIDs: languageIDsParam,
			cosmeticIDs: cosmeticIDsParam,
			serviceTotalPriceFrom: serviceTotalPriceFromParam,
			serviceTotalPriceTo: serviceTotalPriceToParam,
			hasAvailableReservationSystem: hasAvailableReservationSystemParam,
			avResTimeSlotDayPart: avResTimeSlotDateParam,
			avResTimeSlotDateFrom: avResTimeSlotDateFromParam,
			avResTimeSlotDateTo: avResTimeSlotDateToParam
		} = params

		const openingHoursStatus = openingHoursStatusParam || query.openingHoursStatus
		const categoryIDs = categoryIDsParam || query.categoryIDs
		const orderBy = orderByParam || query.orderBy
		const exactRating = exactRatingParam || query.exactRating
		const languageIDs = languageIDsParam || query.languageIDs
		const cosmeticIDs = cosmeticIDsParam || query.cosmeticIDs
		const avResTimeSlotDayPart = avResTimeSlotDateParam || query.avResTimeSlotDayPart

		// using null to purposely reset value in following params
		const serviceTotalPriceFrom = serviceTotalPriceFromParam === null ? undefined : (serviceTotalPriceFromParam ?? query.serviceTotalPriceFrom)
		const serviceTotalPriceTo = serviceTotalPriceToParam === null ? undefined : (serviceTotalPriceToParam ?? query.serviceTotalPriceTo)
		const avResTimeSlotDateFrom = avResTimeSlotDateFromParam === null ? undefined : avResTimeSlotDateFromParam || query.avResTimeSlotDateFrom
		const avResTimeSlotDateTo = avResTimeSlotDateToParam === null ? undefined : avResTimeSlotDateToParam || query.avResTimeSlotDateTo
		const hasAvailableReservationSystem =
			hasAvailableReservationSystemParam !== undefined ? hasAvailableReservationSystemParam : query.hasAvailableReservationSystem

		const { lat, lon, googlePlaceID } = getLocationParams(city)

		// update query
		setQuery({
			openingHoursStatus,
			page: 1,
			lat,
			lon,
			categoryIDs,
			googlePlaceID,
			orderBy,
			exactRating,
			languageIDs,
			cosmeticIDs,
			serviceTotalPriceFrom,
			serviceTotalPriceTo,
			hasAvailableReservationSystem,
			avResTimeSlotDayPart,
			avResTimeSlotDateFrom,
			avResTimeSlotDateTo
		})
	}

	const fetchSalonsAndFilterCounts = useCallback(
		async (mergeWithPrevious = false) => {
			const categoryIDs = getCategoryIDs(query.categoryIDs, categoryData.category.id)

			let commonParams: GetSalonsDataParams = {
				openingHoursStatus: query.openingHoursStatus,
				latMy: myLocation?.latMy,
				lonMy: myLocation?.lonMy,
				lat: query.lat,
				lon: query.lon,
				categoryIDs,
				exactRating: query.exactRating,
				hasAvailableReservationSystem: query.hasAvailableReservationSystem,
				languageIDs: query.languageIDs,
				cosmeticIDs: query.cosmeticIDs,
				orderBy: query.orderBy,
				avResTimeSlotDayPart: query.avResTimeSlotDayPart,
				avResTimeSlotDateFrom: query.avResTimeSlotDateFrom,
				avResTimeSlotDateTo: query.avResTimeSlotDateTo
			}

			if (!isNil(query.serviceTotalPriceFrom) || !isNil(query.serviceTotalPriceTo)) {
				commonParams = {
					...commonParams,
					serviceTotalPriceFrom: query.serviceTotalPriceFrom,
					serviceTotalPriceTo: query.serviceTotalPriceTo,
					serviceTotalPriceCurrencyCode: currency.code
				}
			}

			try {
				const newSalonsData = await apiBrowser.b2c.getSalonsData(
					{ ...commonParams, page: query.page },
					{ allowAbort: true, abortSignalKey: ABORT_KEYS.SALONS_ABORT_KEY }
				)

				setSalonsData((prevSalons) => {
					if (!prevSalons) {
						return undefined
					}

					if (mergeWithPrevious) {
						return {
							...prevSalons,
							pagination: newSalonsData.pagination,
							salons: [...prevSalons.salons, ...newSalonsData.salons]
						}
					}

					return newSalonsData
				})

				// push to dataLayer
				const event = getViewItemListEvent({
					currentPage: query.page || 1,
					currentSortingOptionName: query.orderBy,
					salons: newSalonsData.salons
				})
				pushToDataLayer(event)
			} catch (error) {
				// set error, but only if it is not caused by cancel token
				if (axios.isAxiosError(error) && error.code !== 'ERR_CANCELED') {
					setSalonsData(undefined)
				}
			}

			try {
				const newSalonsFilterCounts = await apiBrowser.b2c.getSalonsFilterCounts(
					{ ...commonParams, serviceTotalPriceCurrencyCode: currency.code },
					{ allowAbort: true, abortSignalKey: ABORT_KEYS.SALONS_FILTER_COUNT_ABORT_KEY }
				)
				setFilterCountsData(newSalonsFilterCounts)
			} catch (error) {
				if (axios.isAxiosError(error) && error.code !== 'ERR_CANCELED') {
					setFilterCountsData(undefined)
				}
			}
		},
		[
			apiBrowser,
			query.page,
			query.openingHoursStatus,
			query.orderBy,
			query.exactRating,
			query.categoryIDs,
			query.lat,
			query.lon,
			query.languageIDs,
			query.cosmeticIDs,
			query.serviceTotalPriceFrom,
			query.serviceTotalPriceTo,
			query.hasAvailableReservationSystem,
			query.avResTimeSlotDayPart,
			query.avResTimeSlotDateFrom,
			query.avResTimeSlotDateTo,
			currency.code,
			categoryData.category.id,
			myLocation?.latMy,
			myLocation?.lonMy,
			setSalonsData,
			setFilterCountsData
		]
	)

	useEffect(() => {
		/**
		 * Fetch new data whenever some of the dependencies changes after the first data load
		 */
		;(async () => {
			if (!isMountFetchRef.current && !query.isMapView) {
				setAreSalonsLoading(true)
				await fetchSalonsAndFilterCounts(mergeWithPreviousSalons.current)
				setAreSalonsLoading(false)
				setIsButtonLoading(false)
				mergeWithPreviousSalons.current = false
			}
		})()
	}, [fetchSalonsAndFilterCounts, query.isMapView])

	useEffect(() => {
		/**
		 * Salons are already fetched on the server side, so client-side fetching is not always necessary on page load.
		 * However, since we are working with `myLocation`, which is not available on the server, we need to handle location-based fetching.
		 * If the user grants permission to access their location, we want to refetch the data using the user's location.
		 * During this process, we show an initial loading state until the location is resolved, and if needed, fetch new data.
		 * If the user's location is already known from query parameters at page initialization, there is no need to show the initial loading state.
		 * The loading state is displayed over the salons fetched from the server, ensuring that the data is available in the HTML for search engine bots, while preventing a content flash for users.
		 */
		;(async () => {
			if (isLoadingMyLocation || !isMountFetchRef.current || query.isMapView) {
				return
			}

			if (myLocation && !isMyPositionKnownOnMount.current) {
				await fetchSalonsAndFilterCounts()
			}

			setInitialLocationBasedLoading(false)
			isMountFetchRef.current = false
		})()
	}, [isLoadingMyLocation, myLocation, fetchSalonsAndFilterCounts, query.isMapView])

	useEffect(() => {
		;(async () => {
			try {
				const languagesData = await apiBrowser.b2c.getLanguagesData()
				const cosmeticsData = await apiBrowser.b2c.getCosmeticsData({})

				setLanguages(languagesData.languages)
				setCosmetics(cosmeticsData.cosmetics)
			} catch (e) {
				// eslint-disable-next-line no-console
				console.error(e)
			} finally {
				setInitialClientDataLoading(false)
			}
		})()
	}, [apiBrowser])

	useEffect(() => {
		// GA page view event
		const pageViewEvent = getPageViewEvent('salons')
		pushToDataLayer(pageViewEvent)
	}, [])

	useEffect(() => {
		// remove helper data for GA event getViewItemListEvent from session storage when page unmounts or is refreshed
		// check getViewItemListEvent function for more info
		const onPageLeave = () => sessionStorage.setItem(SESSION_STORAGE_KEYS.IS_SALONS_SEARCH, '')

		window.addEventListener('beforeunload', onPageLeave)

		return () => {
			window.removeEventListener('beforeunload', onPageLeave)
			onPageLeave()
		}
	}, [])

	const handleSubCategoryButtonClick = (categoryID: string) => {
		const queryCategoryIDs = query.categoryIDs
		let newCategoryIDs: string[]
		let eventInteraction: SubcategoryChangeEventInteraction

		if (queryCategoryIDs.includes(categoryID)) {
			newCategoryIDs = queryCategoryIDs.filter((cID) => cID !== categoryID)
			eventInteraction = 'click_remove'
		} else {
			newCategoryIDs = [...queryCategoryIDs, categoryID]
			eventInteraction = 'click_add'
		}

		// push to dataLayer
		const event = getSubcategoryChangeEvent({
			eventInteraction,
			clickedCategoryID: categoryID,
			clickedCategorySlug: categorySlug
		})
		pushToDataLayer(event)

		updateQueryFromFilters({ categoryIDs: newCategoryIDs })
	}

	const sliderCategories = categoryData.category.children || []

	const firstSelectedSubcategoryID = findSmallestIndexMatch(
		query.categoryIDs,
		sliderCategories.map((category) => category.id)
	)

	const categorySeoData = getCategorySeoData(categoryData.category.id, categoryData.category.name ?? '')

	const breadcrumbItems = [{ link: PAGE_LINKS['/salons'](locale, { ...myLocation }), name: messages['List of salons'] }]

	if (categoryData.category.name) {
		breadcrumbItems.push({ link: '', name: categoryData.category.name })
	}

	return (
		<>
			<SalonsSeoSchema breadcrumbItems={[{ link: PAGE_LINKS['/salons/homepage'](locale), name: messages.Salons }, ...breadcrumbItems]} />
			<SalonsCategoryPageHead pagination={pagination} categoryLocalizations={categoryData.category.nameLocalizations} categorySeoData={categorySeoData} />
			<SC.Container>
				<SC.SalonsPageWrapper>
					<SC.Grid>
						<SC.SalonsPageBreadcrumbs breadcrumbItems={breadcrumbItems} hasSalonsBreadcrumb />

						<SalonsPageBanner cmsData={cmsData?.bannersData} />

						<SC.TitleWrapper>
							<SC.MainTitle>{categoryData.category.name}</SC.MainTitle>
						</SC.TitleWrapper>

						<SalonsAndCitiesSearchWrap query={query} loadSalonsFromFilters={updateQueryFromFilters} currentCategoryID={categoryData.category.id} />

						<SC.SubcategoriesSliderWrapper>
							<ElementsSlider initScrolledElementID={firstSelectedSubcategoryID} gap={'8px'}>
								{sliderCategories.map((category) => {
									return (
										<SC.SubcategoryButton
											id={category.id}
											type='button'
											onClick={() => handleSubCategoryButtonClick(category.id)}
											$isSelected={query.categoryIDs.includes(category.id)}
											key={category.id}
										>
											<span>{category.name}</span>
										</SC.SubcategoryButton>
									)
								})}
							</ElementsSlider>
						</SC.SubcategoriesSliderWrapper>

						<Filters
							query={query}
							filterCounts={filterCountsData}
							currencySymbol={currency?.symbol}
							currencyCode={currency?.code}
							cosmetics={cosmetics}
							languages={languages}
							loadSalonsFromFilters={updateQueryFromFilters}
							initialClientDataLoading={initialClientDataLoading}
							topLevelCategoryID={categoryData.category.id}
							maxAvResTimeSlotDateRange={configData?.maxAvResTimeSlotDateRange}
						/>

						<SalonsView
							salons={salons}
							pagination={pagination}
							categoryData={categoryData}
							query={query}
							setQuery={setQuery}
							loadSalonsFromLoadMoreButton={refetchSalonsAndFilterCountsFromLoadMoreBtn}
							loadSalonsFromFilters={updateQueryFromFilters}
							initialLoading={initialLocationBasedLoading}
							salonsLoading={areSalonsLoading}
							buttonLoading={isButtonLoading}
							currencyCode={currency.code}
							defaultCity={defaultCity}
							allowedReservations={allowedReservations}
							cmsAppPromoData={cmsData?.appPromoData}
						/>
						{categorySeoData.categoryDescription && !query.isMapView && (
							<SC.CategoryDescriptionContainer>
								{categorySeoData.categoryDescriptionTitle && (
									<SC.CategoryDescriptionTitle>{categorySeoData.categoryDescriptionTitle}</SC.CategoryDescriptionTitle>
								)}
								<SC.CategoryDescription>{categorySeoData.categoryDescription}</SC.CategoryDescription>
							</SC.CategoryDescriptionContainer>
						)}
					</SC.Grid>
				</SC.SalonsPageWrapper>
			</SC.Container>
		</>
	)
}

// page container
const SalonsCategoryPageContainer: IGetInitialDataProps<InitialData | null, ExtendedContextProps> = (props) => {
	const data = useInitialData(SalonsCategoryPageContainer, props)
	const { query: serverQuery, ...restProps } = props

	// save data to state to allow client-side refetching
	const [salonsData, setSalonsData] = useState<SalonsResponse | undefined>(data?.salonsData)
	const [filterCountsData, setFilterCountsData] = useState<GetSalonsFilterCountResponse | undefined>(data?.filterCountsData)

	// When working with the user's location, always use values from `MyLocationContext`, not from the query.
	// While the query does include `latMy` and `lonMy`, this is only to ensure the server can fetch the correct data when the user's detected location is passed via query parameters in the URL.
	// Therefore, throughout the application, this typed `query` should be used, where user-related location parameters are omitted to avoid unintentional usage.
	const query: SalonsCategoryPageQueryType = useMemo(() => {
		const resolvedQuery = {
			...props.query,
			...getValidPriceRangeQueryParams(props.query.serviceTotalPriceFrom, props.query.serviceTotalPriceTo, filterCountsData?.priceRange)
		}

		delete resolvedQuery.latMy
		delete resolvedQuery.lonMy

		return resolvedQuery
	}, [props.query, filterCountsData?.priceRange])

	// NOTE: needed for react-toolkit SSR
	if (!data || !salonsData) {
		return null
	}

	return (
		<SalonsCategoryPage
			{...restProps}
			{...data}
			query={query}
			currency={data.currency}
			salonsData={salonsData}
			filterCountsData={filterCountsData}
			setSalonsData={setSalonsData}
			setFilterCountsData={setFilterCountsData}
		/>
	)
}

// ssr setup
SalonsCategoryPageContainer.initDefaultData = async ({ api, query, categorySlug, locale, shopId }) => {
	try {
		const [categoriesData, configData, cmsData] = await Promise.all([
			api.b2c.getCategoriesData(),
			api.b2c.getConfigData(),
			api.cms.getSalonsCategoryPageCmsData()
		])

		const localeCode = shopsConfig.find((config) => config.lang === locale)?.locale
		const currencyCode = configData.rolloutCountries.find((country) => country.languageCode === localeCode)?.currencyCode
		const currency = configData.systemCurrencies.find((c) => c.code === currencyCode)

		const foundCategory = categoriesData.categories.find((category) => category.nameSlug === categorySlug)

		if (!currency || !foundCategory) {
			return null
		}

		const allowedReservations = areReservationsAllowed(configData.allowReservations, shopId)

		const categoryIDs = query.categoryIDs ? getCategoryIDs(query.categoryIDs, foundCategory.id) : undefined

		let getSalonsDataParams: GetSalonsDataParams = {
			openingHoursStatus: query.openingHoursStatus,
			latMy: query.latMy,
			lonMy: query.lonMy,
			lat: query.lat,
			lon: query.lon,
			categoryIDs,
			exactRating: query.exactRating,
			languageIDs: query.languageIDs,
			cosmeticIDs: query.categoryIDs,
			hasAvailableReservationSystem: query.hasAvailableReservationSystem,
			orderBy: query.orderBy,
			avResTimeSlotDayPart: query.avResTimeSlotDayPart,
			avResTimeSlotDateFrom: query.avResTimeSlotDateFrom,
			avResTimeSlotDateTo: query.avResTimeSlotDateTo
		}

		if (!isNil(query.serviceTotalPriceFrom) || !isNil(query.serviceTotalPriceTo)) {
			getSalonsDataParams = {
				...getSalonsDataParams,
				serviceTotalPriceFrom: query.serviceTotalPriceFrom,
				serviceTotalPriceTo: query.serviceTotalPriceTo,
				serviceTotalPriceCurrencyCode: currency.code
			}
		}

		let filterCountsData: GetSalonsFilterCountResponse | undefined

		try {
			filterCountsData = await api.b2c.getSalonsFilterCounts({ ...getSalonsDataParams, serviceTotalPriceCurrencyCode: currency.code })
		} catch {
			filterCountsData = undefined
		}

		const queryWithValidPriceRange = {
			...getSalonsDataParams,
			...getValidPriceRangeQueryParams(query.serviceTotalPriceFrom, query.serviceTotalPriceTo, filterCountsData?.priceRange)
		}

		const [categoryData, salonsData] = await Promise.all([
			await api.b2c.getCategoryData(foundCategory.id),
			api.b2c.getSalonsData({
				...queryWithValidPriceRange,
				page: query.page
			})
		])

		return { salonsData, categoryData, configData, currency, filterCountsData, cmsData, allowedReservations }
	} catch {
		return null
	}
}

SalonsCategoryPageContainer.identifier = 'SalonsCategoryPage'

export default SalonsCategoryPageContainer
