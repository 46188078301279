import styled, { css } from 'styled-components'
import { theme } from '@notino/react-styleguide'

// utils
import { NotinoLabelRegular400, truncate } from '../../styles/helpers'

// components
import Input from '../Input/Input'
import FetchResult from '../../components/FetchResult/FetchResult'

export const SelectWrapper = styled.div`
	position: relative;
`

export const PrefixIconWrapper = styled.div<{ $isDisabled?: boolean }>`
	position: absolute;
	z-index: 1;
	left: 16px;
	width: 20px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	pointer-events: none;
	color: ${({ $isDisabled }) => ($isDisabled ? theme.color.text.disabled : theme.color.text.primary)};

	svg {
		width: 20px;
		height: 20px;
	}
`

// Mobile
export const MobileListWrapper = styled.div`
	width: 100%;
	height: 100%;
`

export const MobileSearchWrapper = styled.div``

export const MobileSearchInput = styled(Input)`
	&.rc-input-affix-wrapper {
		background-color: ${theme.color.background.primary};
		padding: 0.75rem 0;
		margin-left: -10px;
		outline: none;
		border: none;

		input {
			background-color: ${theme.color.background.primary};
		}
	}
`

export const StyledFetchResults = styled(FetchResult)``

export const MobileListBox = styled.div<{ $isDisabled?: boolean }>`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	outline: none;
`

export const MobileListBoxItem = styled.div<{ $isSelected: boolean; $isHighlighted: boolean }>`
	padding: 10px 20px;
	min-height: 44px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	overflow: hidden;
	gap: 8px;
	cursor: pointer;
	${NotinoLabelRegular400};
	flex-shrink: 0;

	&:hover {
		background-color: #f5f5f5;
	}

	${({ $isSelected }) =>
		$isSelected &&
		css`
			background-color: #f5f5f5;
		`}

	${({ $isHighlighted }) =>
		$isHighlighted &&
		css`
			background-color: #f5f5f5;
		`}
`

export const MobileMenuItemText = styled.div`
	${truncate};
`

export const MobileMenuItemIconWrapper = styled.div`
	flex-shrink: 0;

	svg {
		width: 20px;
		height: 20px;
	}
`
