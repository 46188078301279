import React, { ComponentProps, useEffect, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import dayjs, { Dayjs } from 'dayjs'

// types
import { DateTimeSlotPickerBookingFormFieldProps } from './types'

// styles
import * as SC from './DateTimeSlotPickerBookingFormFieldStyles'
import { BREAKPOINTS_INTS } from '../../styles/constants'

// components
import { RadioButtonOption } from '../RadioButtonsFormField/types'
import Button from '../../atoms/Button/Button'
import DateTimeSlotPicker from '../../atoms/DateTimeSlotPicker/DateTimeSlotPicker'
import { DateTimeSlotPickerValueType } from '../../atoms/DateTimeSlotPicker/types'
import Drawer from '../../components/Drawer/Drawer'

// assets
import CalendarIcon from '../../assets/icons/CalendarIcon'

// hooks
import useOnClickOutside from '../../hooks/useClickOutside'
import useMessages from '../../hooks/useMessages'
import useIsMobile from '../../hooks/useIsMobile'

// utils
import { RESERVATIONS_TIME_SLOTS, SALONS_FILTER_TYPE } from '../../utils/enums'
import { RESERVATIONS_TIME_SLOTS_START_TIME, SALONS_FILTER_ITEMS_CONFIG, TIME_SLOTS_TRANSLATIONS } from '../../utils/helper'
import { getIntl } from '../../utils/intl'

type ValueType = DateTimeSlotPickerValueType<RESERVATIONS_TIME_SLOTS>

const formatSubmittedValue = (
	now: Dayjs,
	dayPeriodTranslations: Record<RESERVATIONS_TIME_SLOTS, string | undefined>,
	value: DateTimeSlotPickerBookingFormFieldProps<RESERVATIONS_TIME_SLOTS>['input']['value']
) => {
	const { dateFrom, dateTo, timeSlot } = value
	const { formatMessage, formatDateTimeRange, formatDate } = getIntl()

	let formattedDate
	let formattedDayPeriod
	const isToday = dateTo === undefined && dateFrom && dateFrom?.isSame(now, 'date')
	const isTomorrow = dateTo === undefined && dateFrom && dateFrom?.isSame(now.add(1, 'day'), 'date')

	if (isToday) {
		formattedDate = formatMessage({ id: 'Today', defaultMessage: 'Today' })
	} else if (isTomorrow) {
		formattedDate = formatMessage({ id: 'Tomorrow', defaultMessage: 'Tomorrow' })
	} else if (dateFrom && dateTo === undefined) {
		formattedDate = formatDate(dateFrom.toDate(), { month: 'long', day: 'numeric', weekday: 'long' })
	} else if (dateFrom && dateTo) {
		formattedDate = formatDateTimeRange(dateFrom.toDate(), dateTo.toDate(), { day: 'numeric', month: 'numeric' })
	}

	if (timeSlot !== RESERVATIONS_TIME_SLOTS.ANY) {
		formattedDayPeriod = dayPeriodTranslations[timeSlot]
	}

	if (!formattedDate && !formattedDayPeriod) {
		return dayPeriodTranslations[RESERVATIONS_TIME_SLOTS.ANY]
	}

	return formattedDate ? `${formattedDate}${formattedDayPeriod ? `, ${formattedDayPeriod}` : ''}` : formattedDayPeriod
}

const DateTimeSlotPickerBookingFormField = (props: DateTimeSlotPickerBookingFormFieldProps<RESERVATIONS_TIME_SLOTS>) => {
	const {
		input: { value, onChange },
		maxRange,
		defaultValue: defaultValueProps,
		onFocus,
		...restProps
	} = props

	const { messages } = useMessages()
	const intl = useIntl()
	const { formatMessage } = intl
	const isMobile = useIsMobile(BREAKPOINTS_INTS.sm)

	// save current date into a state, so we get consistent results when working with this variable
	const [now, setNow] = useState(dayjs())

	const [internalValue, setInternalValue] = useState<ValueType>({
		dateFrom: value.dateFrom,
		dateTo: value.dateTo,
		timeSlot: value.timeSlot
	})

	const [isMenuOpen, setIsMenuOpen] = useState(false)

	const defaultValue = useMemo(() => {
		return defaultValueProps === 'now'
			? {
					timeSlot: RESERVATIONS_TIME_SLOTS.ANY,
					dateFrom: now,
					dateTo: undefined
				}
			: defaultValueProps
	}, [defaultValueProps, now])

	const dropdownRef = useRef<HTMLDivElement | null>(null)

	const isToday = internalValue.dateFrom?.isSame(now, 'date') && internalValue.dateTo === undefined
	const afternoon = dayjs(new Date(now.year(), now.month(), now.date(), RESERVATIONS_TIME_SLOTS_START_TIME.AFTERNOON, 0))
	const evening = dayjs(new Date(now.year(), now.month(), now.date(), RESERVATIONS_TIME_SLOTS_START_TIME.EVENING, 0))

	const hasSubmittedValue = value.dateFrom !== undefined || value.dateTo !== undefined || value.timeSlot !== RESERVATIONS_TIME_SLOTS.ANY

	const isRangeError =
		internalValue.dateFrom && internalValue.dateTo && maxRange !== undefined && !(internalValue.dateTo.diff(internalValue.dateFrom, 'day') <= maxRange)

	useOnClickOutside([dropdownRef], () => {
		if (!isMobile) {
			setIsMenuOpen(false)
		}
	})

	useEffect(() => {
		// synchronize submitted values with internal values
		let newValue: ValueType = {
			dateFrom: value.dateFrom,
			dateTo: value.dateTo,
			timeSlot: value.timeSlot
		}

		if (defaultValue && !value.dateFrom && !value.dateTo) {
			newValue = defaultValue
		}

		setInternalValue(newValue)
	}, [value.dateFrom, value.dateTo, value.timeSlot, defaultValue?.dateFrom, defaultValue?.dateTo, defaultValue?.timeSlot, defaultValue, now])

	useEffect(() => {
		// update "now" whenever menu is opened
		if (isMenuOpen) {
			setNow(dayjs())
		}
	}, [isMenuOpen])

	useEffect(() => {
		// close menu using "Escape" key
		const handleKeyDownOnMobile = (event: KeyboardEvent) => {
			if (event.key === 'Escape') {
				setIsMenuOpen(false)
			}
		}

		if (isMenuOpen) {
			document.addEventListener('keydown', handleKeyDownOnMobile)
		} else {
			document.removeEventListener('keydown', handleKeyDownOnMobile)
		}

		return () => {
			document.removeEventListener('keydown', handleKeyDownOnMobile)
		}
	}, [isMenuOpen])

	const dayPeriodTranslations = TIME_SLOTS_TRANSLATIONS()

	const getOptions = () => {
		const result: RadioButtonOption<RESERVATIONS_TIME_SLOTS>[] = [
			{
				id: RESERVATIONS_TIME_SLOTS.ANY,
				label: dayPeriodTranslations[RESERVATIONS_TIME_SLOTS.ANY]
			}
		]

		if ((isToday && now.isBefore(afternoon)) || !isToday) {
			result.push({
				id: RESERVATIONS_TIME_SLOTS.MORNING,
				label: dayPeriodTranslations[RESERVATIONS_TIME_SLOTS.MORNING],
				description: formatMessage({ id: 'Until { time }', defaultMessage: 'Until { time }' }, { time: afternoon.format('LT') })
			})
		}

		if ((isToday && now.isBefore(evening)) || !isToday) {
			result.push({
				id: RESERVATIONS_TIME_SLOTS.AFTERNOON,
				label: dayPeriodTranslations[RESERVATIONS_TIME_SLOTS.AFTERNOON],
				description: formatMessage({ id: 'Until { time }', defaultMessage: 'Until { time }' }, { time: evening.format('LT') })
			})
		}

		result.push({
			id: RESERVATIONS_TIME_SLOTS.EVENING,
			label: dayPeriodTranslations[RESERVATIONS_TIME_SLOTS.EVENING],
			description: formatMessage({ id: 'After { time }', defaultMessage: 'After { time }' }, { time: evening.format('LT') })
		})

		return result
	}

	const timeSlotOptions = getOptions()

	const onConfirm = () => {
		onChange(internalValue)
		setIsMenuOpen(false)
	}

	const onDateTimeSlotPickerChange: ComponentProps<typeof DateTimeSlotPicker<RESERVATIONS_TIME_SLOTS>>['onChange'] = (newValue) => {
		let validValue = {
			...newValue
		}

		if (isToday) {
			if (
				(internalValue.timeSlot === RESERVATIONS_TIME_SLOTS.AFTERNOON && now.isAfter(evening)) ||
				(internalValue.timeSlot === RESERVATIONS_TIME_SLOTS.MORNING && now.isAfter(afternoon))
			) {
				validValue = {
					timeSlot: RESERVATIONS_TIME_SLOTS.ANY,
					dateFrom: internalValue.dateFrom,
					dateTo: internalValue.dateTo
				}
			}
		}

		setInternalValue(validValue)
	}

	const onCloseMenu = () => {
		setIsMenuOpen(!isMenuOpen)
	}

	const onOpenMenu = () => {
		setIsMenuOpen(!isMenuOpen)
	}

	const handleClearSubmittedValue: React.MouseEventHandler<HTMLButtonElement> = (e) => {
		e.stopPropagation()
		onChange({ dateFrom: undefined, dateTo: undefined, timeSlot: RESERVATIONS_TIME_SLOTS.ANY })
	}

	const getFooterExtraContent = () => {
		const selectedDateTitle = SALONS_FILTER_ITEMS_CONFIG(undefined, undefined)[SALONS_FILTER_TYPE.DATE].getSelectedTitle(
			internalValue.timeSlot,
			internalValue.dateFrom,
			internalValue.dateTo
		)

		return selectedDateTitle ? <SC.DateFilterFooterLabel>{selectedDateTitle}</SC.DateFilterFooterLabel> : null
	}

	const extraContent = getFooterExtraContent()

	const datePicker = (
		<DateTimeSlotPicker
			value={internalValue}
			onChange={onDateTimeSlotPickerChange}
			layout={'row'}
			size={'small'}
			timeSlotOptions={timeSlotOptions}
			calendarErrorMsg={
				isRangeError
					? formatMessage(
							{ id: 'The selected range exceeds { daysCount }', defaultMessage: 'The selected range exceeds { daysCount }' },
							{ daysCount: maxRange }
						)
					: undefined
			}
			{...restProps}
		/>
	)

	const confirmButton = (
		<Button fullWidth onClick={onConfirm} disabled={isRangeError || !internalValue.dateFrom} type={'button'}>
			{messages.Confirm}
		</Button>
	)

	return (
		<SC.Wrapper ref={dropdownRef}>
			<SC.Trigger
				$isActive={isMenuOpen}
				role={'button'}
				tabIndex={0}
				onClick={(e) => {
					e.stopPropagation()
					onOpenMenu()
				}}
				onKeyDown={(e) => {
					e.stopPropagation()
					if (e.key === 'Enter') {
						onOpenMenu()
					}
				}}
				onFocus={onFocus}
			>
				<CalendarIcon />
				<SC.TriggerText>{formatSubmittedValue(now, dayPeriodTranslations, value)}</SC.TriggerText>
				{hasSubmittedValue && (
					<SC.ClearButton type={'button'} onClick={handleClearSubmittedValue}>
						<SC.CloseIcon />
					</SC.ClearButton>
				)}
			</SC.Trigger>
			{isMenuOpen && !isMobile && (
				<SC.Dropdown>
					{datePicker}
					{confirmButton}
				</SC.Dropdown>
			)}
			{isMobile && (
				<Drawer
					open={isMenuOpen}
					mask
					maskClosable
					title={messages.Date}
					onClose={onCloseMenu}
					footer={
						<SC.DrawerFooterWrapper>
							{extraContent && <SC.FooterExtraContent>{extraContent}</SC.FooterExtraContent>}
							<SC.FooterCtaButtonWrapper>{confirmButton}</SC.FooterCtaButtonWrapper>
						</SC.DrawerFooterWrapper>
					}
					destroyOnClose
				>
					<SC.DrawerContentWrapper>{datePicker}</SC.DrawerContentWrapper>
				</Drawer>
			)}
		</SC.Wrapper>
	)
}

export default DateTimeSlotPickerBookingFormField
