import styled, { createGlobalStyle, css, keyframes } from 'styled-components'
// NOTE - Fix for the bug https://goodrequest.atlassian.net/browse/NOT-11551
// Notino seems not have a global box-sizing set up to border-box,
// but rc-select library that we use in our project sets it globally to border-box, which causes Notino main web styles to break
// 'rc-select/assets/index.css' - so we can't import the whole css to the project from that library, but just the necessary parts
import './rc-select.css'
import 'rc-tooltip/assets/bootstrap.css'

import { theme } from '@notino/react-styleguide'
import { BREAKPOINTS, RC_DRAWER_IS_OPEN_CLASS_NAME, SALONS_PAGE_MOBILE_BREAKPOINT } from './constants'
import { NotinoLabelLarge400, NotinoLabelRegular400, NotinoLabelSmall, truncate } from './helpers'
import { SELECT_OPTION_CLASS_NAME } from '../utils/enums'

export const cssReset = css`
	letter-spacing: 0;
	font-family: ${theme.fonts.primary};
	backface-visibility: hidden;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: subpixel-antialiased;
`

export const AppContainer = styled.div`
	${cssReset};
	font-size: ${theme.fontBaseSize}px;
	font-weight: 300;

	*,
	*:before,
	*:after {
		box-sizing: border-box;
	}

	img {
		width: 100%;
	}
`

const dialogMoveIn = keyframes`
	0% {
		opacity: 0;
		transform: translateY(-200px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
`

const dialogFadeIn = keyframes`
	0% {
	  opacity: 0;
	}
	100% {
	  opacity: 1;
	}
	`

const GlobalStyles = createGlobalStyle`
	*, *:before, *:after {
		margin: 0;
		padding: 0;
	}

	body {
		// NOTE: explanation for the override is in Drawer compoment, which handles adding and removing RC_DRAWER_IS_OPEN_CLASS_NAME classname from the body
		&.${RC_DRAWER_IS_OPEN_CLASS_NAME} {
		 	margin: 0 !important;
		}
	}

	button.ril-next-button.ril__navButtons.ril__navButtonNext {
		background: rgba(0, 0, 0, 0.2) url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAxOCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPG1hc2sgaWQ9Im1hc2swXzE4NzhfMTA2MDciIHN0eWxlPSJtYXNrLXR5cGU6YWxwaGEiIG1hc2tVbml0cz0idXNlclNwYWNlT25Vc2UiIHg9IjAiIHk9IjAiIHdpZHRoPSIxOCIgaGVpZ2h0PSIyMCI+CjxwYXRoIGQ9Ik04Ljk1ODcxIDAuOTg0OTg1TDcuOTAxMjEgMi4wNDYyNEwxNS44NTUgOS45OTk5OUw3LjkwMTIxIDE3Ljk1MzdMOC45NTg3MSAxOS4wMTVMMTcuOTc3NSA5Ljk5OTk5TDguOTU4NzEgMC45ODQ5ODVaTTEwLjEwMjUgOS45OTk5OUwxLjA4MzcxIDAuOTg0OTg1TDAuMDIyNDYwOSAyLjA0NjI0TDcuOTc5OTYgOS45OTk5OUwwLjAyMjQ2MDkgMTcuOTUzN0wxLjA4MzcxIDE5LjAxNUwxMC4xMDI1IDkuOTk5OTlaIiBmaWxsPSJ3aGl0ZSIvPgo8L21hc2s+CjxnIG1hc2s9InVybCgjbWFzazBfMTg3OF8xMDYwNykiPgo8cGF0aCBkPSJNMjEgLTJILTNWMjJIMjFWLTJaIiBmaWxsPSJ3aGl0ZSIvPgo8L2c+Cjwvc3ZnPg==') no-repeat center;
	}

	button.ril-prev-button.ril__navButtons.ril__navButtonPrev {
		background: rgba(0, 0, 0, 0.2) url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPG1hc2sgaWQ9Im1hc2swXzE4NzhfMTA1OTkiIHN0eWxlPSJtYXNrLXR5cGU6YWxwaGEiIG1hc2tVbml0cz0idXNlclNwYWNlT25Vc2UiIHg9IjMiIHk9IjIiIHdpZHRoPSIxOCIgaGVpZ2h0PSIyMCI+CjxwYXRoIGQ9Ik0xMy4xMDI1IDQuMDQ2MjRMMTIuMDQxMiAyLjk4NDk5TDMuMDIyNDYgMTJMMTIuMDQxMiAyMS4wMTVMMTMuMTAyNSAxOS45NTM3TDUuMTQ0OTYgMTJMMTMuMTAyNSA0LjA0NjI0Wk0yMC45Nzc1IDQuMDQ2MjRMMTkuOTE2MiAyLjk4NDk5TDEwLjkwMTIgMTJMMTkuOTE2MiAyMS4wMTVMMjAuOTc3NSAxOS45NTM3TDEzLjAyIDEyTDIwLjk3NzUgNC4wNDYyNFoiIGZpbGw9IndoaXRlIi8+CjwvbWFzaz4KPGcgbWFzaz0idXJsKCNtYXNrMF8xODc4XzEwNTk5KSI+CjxwYXRoIGQ9Ik0yNCAwSDBWMjRIMjRWMFoiIGZpbGw9IndoaXRlIi8+CjwvZz4KPC9zdmc+') no-repeat center;
	}

	button.ril-close.ril-toolbar__item__child.ril__toolbarItemChild.ril__builtinButton.ril__closeButton {
		background: rgba(0, 0, 0, 0.2) url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPG1hc2sgaWQ9Im1hc2swXzE4NzhfMTA2NDYiIHN0eWxlPSJtYXNrLXR5cGU6YWxwaGEiIG1hc2tVbml0cz0idXNlclNwYWNlT25Vc2UiIHg9IjAiIHk9IjAiIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCI+CjxwYXRoIGQ9Ik0xOCAxLjQ2NTI5TDE2LjUzODQgMEw5IDcuNTM0NzFMMS40NjUyOSAwTDAgMS40NjUyOUw3LjUzODM3IDlMMCAxNi41MzQ3TDEuNDY1MjkgMThMOSAxMC40NjUzTDE2LjUzODQgMThMMTggMTYuNTM0N0wxMC40NjUzIDlMMTggMS40NjUyOVoiIGZpbGw9IndoaXRlIi8+CjwvbWFzaz4KPGcgbWFzaz0idXJsKCNtYXNrMF8xODc4XzEwNjQ2KSI+CjxwYXRoIGQ9Ik0xOCAxLjQ2NTI5TDE2LjUzODQgMEw5IDcuNTM0NzFMMS40NjUyOSAwTDAgMS40NjUyOUw3LjUzODM3IDlMMCAxNi41MzQ3TDEuNDY1MjkgMThMOSAxMC40NjUzTDE2LjUzODQgMThMMTggMTYuNTM0N0wxMC40NjUzIDlMMTggMS40NjUyOVoiIGZpbGw9ImJsYWNrIi8+CjxwYXRoIGQ9Ik0yMSAtM0gtM1YyMUgyMVYtM1oiIGZpbGw9IndoaXRlIi8+CjwvZz4KPC9zdmc+') no-repeat center;
	}

	.ReactModalPortal .ril-toolbar__item::before {
		background-image: none !important; // reseting global notino style
	}

	// Drawer styles
	.rc-drawer,
	.rc-drawer *, .rc-drawer *::before, .rc-drawer *::after,
	.rc-drawer::after,
	.rc-drawer::before {
		${cssReset};
		box-sizing: border-box;
	}

	.rc-drawer {
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		position: fixed;
		z-index: 1050;
		pointer-events: none;
	}

	.rc-drawer-inline {
		position: absolute;
	}

	.rc-drawer-mask {
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		position: absolute;
		z-index: 1050;
		background: rgba(0, 0, 0, 0.5);
		pointer-events: auto;
	}

	.rc-drawer-content-wrapper {
		position: absolute;
		z-index: 1050;
		overflow: hidden;
		transition: transform 0.3s;

		@media (max-width: ${BREAKPOINTS.sm}) {
			width: 100% !important;
		}

	}

	.rc-drawer-content-wrapper-hidden {
		display: none;
	}

	.rc-drawer-section {
		width: 100%;
		height: 100%;
		overflow: auto;
		background-color: ${theme.color.background.primary};
		pointer-events: auto;
	}

	.rc-drawer-left .rc-drawer-content-wrapper {
		top: 0;
		bottom: 0;
		left: 0;
	}

	.rc-drawer-right .rc-drawer-content-wrapper {
		top: 0;
		right: 0;
		bottom: 0;

		.rc-drawer-section {
			border-top-left-radius: 4px;
			border-bottom-left-radius: 4px;

			@media (max-width: ${BREAKPOINTS.sm}) {
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
			}
		}
	}

	.rc-drawer-bottom .rc-drawer-content-wrapper {
		bottom: 0;
		right: 0;
		left: 0;

		.rc-drawer-section {
			border-top-left-radius: 4px;
			border-top-right-radius: 4px;
		}
	}

	// dialog styles
	.ReactModalPortal,
	.ReactModalPortal *, .ReactModalPortal *::before, .ReactModalPortal *::after,
	.ReactModalPortal::after,
	.ReactModalPortal::before {
		${cssReset};
		box-sizing: border-box;
	}

	.ReactModalPortal {
		z-index: 9999;

		.noti-dialog-overlay {
			position: fixed;
			inset: 0;
			background: rgba(0, 0, 0, 0.4);
			padding: 80px 24px;
			opacity: 0;

			@media (max-width: ${SALONS_PAGE_MOBILE_BREAKPOINT}) {
				padding: 16px;
			}
		}

		.noti-dialog-content {
			width: 100%;
			max-width: 500px;
			height: auto;
			margin: auto;
			outline: none;
			background-color: ${theme.color.background.primary};
			opacity: 0;
			transform: translateY(-200px);

		}
	}

	body.ReactModal__Body--open {
		overflow: hidden;

		.ReactModalPortal {
			position: fixed;
			z-index: 9999;

			.noti-dialog-overlay {
				animation: ${dialogFadeIn} 0.3s ease forwards;
			}

			.noti-dialog-content {
				animation: ${dialogMoveIn} 0.3s ease forwards;
			}
		}
	}

	// Select styles
	.rc-select,
	.rc-select-single:not(.rc-select-customize-input) {
		${cssReset};
		height: 44px;
		display: block;
		width: 100%;

		.rc-select-selector {
			height: 100%;
			background-color: ${theme.color.background.primary};
			border-radius: 2px;
			padding: 0 16px;
			display: flex;
			align-items: center;
			cursor: pointer;
			transition: border 0.3s ease,
			outline 0.3s ease;
		}

		.rc-select-selection-placeholder {
			left: 0;
			color: ${theme.color.text.tertiary};
			${truncate};
			${NotinoLabelRegular400};
			min-width: 0;
			right: 16px;
			opacity: 1;
		}

		.rc-select-selection-item {
			left: 0;
			top: 50%;
			transform: translateY(-50%);
			color: ${theme.color.text.primary};
			${NotinoLabelRegular400};
			${truncate};
			min-width: 0;
			right: 16px;
		}

		.rc-select-selection-search {
			color: ${theme.color.text.primary};
			${NotinoLabelLarge400};
			height: 20px;
			display: inline-block;

			.rc-select-selection-search-input {
				background-color: ${theme.color.background.primary};
				height: 20px;
				${NotinoLabelLarge400};
				cursor: pointer;
			}
		}

		&.rc-select-show-search {
			.rc-select-selector {
				cursor: text;
			}

			.rc-select-selection-search {
				.rc-select-selection-search-input {
					cursor: text;
				}
			}
		}

		&.rc-select-show-arrow,
		&.rc-select-allow-clear {
			.rc-select-selector {
				padding-right: 44px;
			}

			.rc-select-selection-item {
				right: 0;
			}

			.rc-select-selection-placeholder {
				right: 0;
			}

			.rc-select-arrow {
				right: 16px;
				top: 50%;
				transform: translateY(-50%);
				width: 20px;
				height: 20px;
				color: ${theme.color.text.tertiary};

				svg {
					color: ${theme.color.text.tertiary};
					width: 100%;
					height: 100%;
				}
			}

			.rc-select-clear {
				right: 16px;
				top: 50%;
				transform: translateY(-50%);
				width: 20px;
				height: 20px;
				cursor: pointer;
				background-color: ${theme.color.background.primary};
				color: ${theme.color.text.tertiary};

				svg {
					width: 100%;
					height: 100%;
				}

				&:hover,
				&:focus-visible {
					color: ${theme.color.text.primary};
				}
			}
		}

		&.rc-select-prefix-icon {
			.rc-select-selection-placeholder {
				left: 28px;
			}

			.rc-select-selection-item {
				left: 28px;
			}

			.rc-select-selection-search {
				padding-left: 28px;
			}
		}

		&:not(.rc-select-no-borders) {
			.rc-select-selector {
				outline-offset: -2px;
				outline: 2px solid transparent;
				border: 1px solid ${theme.color.border.default};
			}
		}

		&.rc-select-no-borders {
			.rc-select-selector {
				outline: none;
				border: 1px solid transparent;
			}
		}

		&:not(.rc-select-disabled) {
			&.rc-select-focused {
				&.rc-select-no-borders {
					.rc-select-selector {
						border-color: ${theme.color.common.black} !important;
					}
				}

				&:not(.rc-select-no-borders) {
					.rc-select-selector {
						border-color: ${theme.color.common.black};
						outline-color: ${theme.color.common.black};
					}
				}
			}

			&:hover {
				&.rc-select-no-borders {
					.rc-select-selector {
						border-color: ${theme.color.common.black};
					}
				}

				&:not(.rc-select-no-borders) {
					.rc-select-selector {
						border-color: ${theme.color.common.black};
						outline-color: ${theme.color.common.black};
					}
				}
			}
		}

		&.rc-select-disabled {
			opacity: 1;
			cursor: default;

			.rc-select-selector {
				cursor: default;
				opacity: 1;
				background: ${theme.color.background.secondary};

				.rc-select-selection-item {
					color: ${theme.color.text.disabled};
				}

				.rc-select-selection-placeholder {
					color: ${theme.color.text.disabled};
				}

				.rc-select-selection-search {
					.rc-select-selection-search-input {
						cursor: default;
						background: ${theme.color.background.secondary};
					}
				}
			}

			.rc-select-clear {
				color: ${theme.color.text.disabled};
			}

			.rc-select-arrow {
				color: ${theme.color.text.disabled};
				svg {
					color: ${theme.color.text.disabled};
				}
			}
		}

		&.rc-select-invalid {
			&.rc-select-disabled {
				.rc-select-selector {
					border-color: ${theme.color.border.negative};
				}
			}

			&:not(.rc-select-disabled) {
				.rc-select-selector {
					border-color: ${theme.color.border.negative};
				}

				&:hover {
					.rc-select-selector {
						border-color: ${theme.color.border.negative};
					}

					&:not(.rc-select-no-borders) {
						.rc-select-selector {
							outline-color: ${theme.color.common.red50};
						}
					}
				}

				&.rc-select-focused {
					.rc-select-selector {
						border-color: ${theme.color.border.negative} !important;
					}

					&:not(.rc-select-no-borders) {
						.rc-select-selector {
							outline-color: ${theme.color.common.red50};
						}
					}
				}
			}
		}
	}

	.rc-select-dropdown {
		${cssReset};
		z-index: 2;
		background-color: ${theme.color.background.primary};
		border: 1px solid ${theme.color.border.divider};
		border-radius: 2px;
		box-shadow: 0px 4px 6px -1px rgba(17, 24, 39, 0.06);

		.rc-virtual-list-scrollbar {
			.rc-virtual-list-scrollbar-thumb {
				background-color: ${theme.color.common.gray90} !important;
			}
		}

		.rc-select-item {
			padding: 10px;
			min-height: 44px;
			display: flex;
			height: 100%;
			align-items: center;
			justify-content: space-between;
			overflow: hidden;
			gap: 8px;
			cursor: pointer;

			.rc-select-item-option-content {
				${truncate};
				min-width: 0;
				flex: 1;
			}

			.rc-select-item-option-state {
				position: relative;
				top: 0;
				right: 0;
				width: 0;
				height: 100%;
				display: flex;
				align-items: center;
				flex-shrink: 0;

				&:not(:empty) {
					width: 20px;
				}
			}

			&.rc-select-item-option-selected {
				background: #F5F5F5;
				font-weight: 500;
			}

			&.rc-select-item-option-active {
				background: #F5F5F5;
			}

			&.${SELECT_OPTION_CLASS_NAME.BOTTOM_DIVIDER} {
				border-bottom: 1px solid ${theme.color.border.divider};
			}
		}
	}

	// Tooltip styles
	.rc-tooltip {
		${cssReset};
		opacity: 1;

		.rc-tooltip-inner {
			padding: 12px;
			box-shadow: 0px 4px 6px -1px rgba(17, 24, 39, 0.1), 0px 2px 4px -1px rgba(17, 24, 39, 0.06);
			${NotinoLabelSmall};
			color: #667085;
			background-color: ${theme.color.background.primary};
		}

		&.rc-tooltip-placement-top,
		&.rc-tooltip-placement-topLeft,
		&.rc-tooltip-placement-topRight {
			padding-bottom: 5px;

			.rc-tooltip-arrow {
				border-top-color: ${theme.color.common.white};

				/* shadow for the arrow */

				&::before {
					content: '';
					position: absolute;
					bottom: -1px; /* Position slightly below the arrow */
					left: -5px; /* Align with the arrow */
					width: 0;
					height: 0;
					border-width: 5px 5px 0;
					border-style: solid;
					border-color: transparent;
					border-top-color: rgba(0, 0, 0, 0.2); /* The shadow color */
					z-index: -1; /* Place behind the actual arrow */
					filter: blur(2px); /* Optional: blur for a softer shadow */
				}
			}
		}
	}
`

export default GlobalStyles
