import { IconRegularChevronLeft, IconRegularChevronRight } from '@notino/react-styleguide'
import React, { useEffect, useRef, useState } from 'react'
import useScrolledElement from '../../../../hooks/useScrolledElement'
import { SalonServicesResponse, ServiceCardCallbackData, ServiceDetailData } from '../../../../types/types'
import { isElementOverflownX } from '../../../../utils/helper'
import * as SC from './SalonServicesTabsStyles'
import SalonServicesCards from '../SalonServicesCards/SalonServicesCards'
import { getServiceTabClickEvent } from '../../../../utils/dataLayerEvents'
import { pushToDataLayer } from '../../../../utils/dataLayer'

type Props = {
	services: SalonServicesResponse
	onServiceCardButtonClick: (data: ServiceCardCallbackData) => void
	setServiceDetail: (data: ServiceDetailData) => void
	selectedTabKey: string | undefined
	phoneNumber: string
}

const SalonServicesTabs = ({ services, onServiceCardButtonClick, setServiceDetail, selectedTabKey, phoneNumber }: Props) => {
	const scrollElement = useRef<HTMLUListElement | null>(null)

	const { isScrolledLeft, isScrolledRight, scrollLeft, scrollRight } = useScrolledElement(scrollElement.current)
	const [isOverflown, setIsOverflown] = useState(false)

	useEffect(() => {
		const checkOverflow = () => {
			if (scrollElement.current) {
				setIsOverflown(isElementOverflownX(scrollElement.current))
			}
		}

		checkOverflow()
		window.addEventListener('resize', checkOverflow)

		return () => {
			window.removeEventListener('resize', checkOverflow)
		}
	}, [scrollElement])

	const [tabKey, setTabKey] = useState<string | undefined>(() => {
		if (services.groupedServicesByCategory.some((firstLevel) => firstLevel.category?.id === selectedTabKey)) {
			return selectedTabKey
		}

		return services.groupedServicesByCategory[0].category?.id || undefined
	})

	const handleTabButtonClick = (key: string) => {
		setTabKey(key)
		const event = getServiceTabClickEvent({ categoryId: key })
		pushToDataLayer(event)
	}

	return (
		<SC.ServicesTabsWrapper>
			<SC.TabsContainer>
				{isOverflown && isScrolledLeft && (
					<SC.ScrollLeftButton type='button' onClick={scrollLeft} style={{ zIndex: 10 }}>
						<IconRegularChevronLeft color={'icon.primary'} />
					</SC.ScrollLeftButton>
				)}
				<SC.Tabs role={'tablist'} ref={scrollElement}>
					{services?.groupedServicesByCategory?.map((industry) => {
						if (!industry.category) {
							return null
						}
						const key = industry.category?.id
						const isActive = tabKey === key

						return (
							<SC.Tab
								key={key}
								onKeyDown={(e) => {
									if (e.key === 'Enter') {
										setTabKey(key)
									}
								}}
							>
								<SC.TabButton $isActive={isActive} tabIndex={0} role={'tab'} aria-selected={isActive} onClick={() => handleTabButtonClick(key)}>
									{industry.category?.name}
								</SC.TabButton>
							</SC.Tab>
						)
					})}
				</SC.Tabs>
				{isOverflown && isScrolledRight && (
					<SC.ScrollRightButton type='button' onClick={scrollRight} style={{ zIndex: 10 }}>
						<IconRegularChevronRight color={'icon.primary'} />
					</SC.ScrollRightButton>
				)}
			</SC.TabsContainer>
			<SC.TabsContent>
				{services?.groupedServicesByCategory.map((industry) => {
					if (!industry.category) {
						return null
					}
					const key = industry.category.id
					const isActive = tabKey === key

					return (
						<SC.TabContent key={key} $isActive={isActive}>
							<SalonServicesCards
								phoneNumber={phoneNumber}
								industryData={industry.category}
								onServiceCardButtonClick={onServiceCardButtonClick}
								setServiceDetail={setServiceDetail}
							/>
						</SC.TabContent>
					)
				})}
			</SC.TabsContent>
		</SC.ServicesTabsWrapper>
	)
}

export default SalonServicesTabs
