import { theme } from '@notino/react-styleguide'
import styled, { css } from 'styled-components'
import { SALON_PAGE_MOBILE_BREAKPOINT } from '../../../../styles/constants'
import { NotinoBodyLarge, NotinoBodyMedium, NotinoTitleExtraLarge, NotinoTitleMedium } from '../../../../styles/helpers'

const ORDER_LAST_ITEM = 999

export const Container = styled.div`
	margin-bottom: 32px;
	margin-top: 24px;

	@media (min-width: ${SALON_PAGE_MOBILE_BREAKPOINT}) {
		margin-bottom: 40px;
		margin-top: 0;
	}
`

export const NameAndLogoContainer = styled.div`
	display: flex;
	gap: 8px;
	align-items: center;
`

export const LogoWrapper = styled.div`
	width: 40px;
	height: 40px;
`

export const Logo = styled.img`
	width: 100%;
	height: 100%;
	object-fit: contain;
`

export const Name = styled.h2`
	/* resetting global notino margin on heading */
	margin: 0;

	${NotinoTitleMedium};

	@media (min-width: ${SALON_PAGE_MOBILE_BREAKPOINT}) {
		${NotinoTitleExtraLarge};
	}
`

export const AdditionalInfoContainer = styled.div`
	${NotinoBodyMedium};
	display: flex;
	flex-direction: column;
	margin-top: 4px;
	gap: 4px;

	@media (min-width: ${SALON_PAGE_MOBILE_BREAKPOINT}) {
		${NotinoBodyLarge};
		flex-direction: row;
		align-items: flex-start;
		flex-wrap: wrap;
		margin-top: 16px;
		gap: 8px;
	}
`

const divider = css`
	@media (min-width: ${SALON_PAGE_MOBILE_BREAKPOINT}) {
		&:not(:last-child) {
			position: relative;
			margin-right: 12px;

			&::after {
				${NotinoBodyLarge};
				color: ${theme.color.text.tertiary};
				content: '・';
				padding: 0;
				top: 50%;
				right: -20px;
				width: 20px;
				display: flex;
				justify-content: center;
				transform: translateY(-50%);
				position: absolute;
			}
		}
	}
`

export const Address = styled.div`
	${NotinoBodyMedium};

	@media (min-width: ${SALON_PAGE_MOBILE_BREAKPOINT}) {
		${NotinoBodyLarge};
	}

	${divider};
`

export const ShowOnMapLink = styled.a`
	${NotinoBodyMedium};
	color: ${theme.color.text.tertiary};
	text-decoration: underline;
	padding-left: 4px;

	@media (min-width: ${SALON_PAGE_MOBILE_BREAKPOINT}) {
		${NotinoBodyLarge};
	}
`

export const RatingsContainer = styled.div`
	${divider};
	order: ${ORDER_LAST_ITEM};

	svg {
		display: block;
	}

	@media (min-width: ${SALON_PAGE_MOBILE_BREAKPOINT}) {
		order: unset;
	}
`

export const OpeningStatus = styled.div`
	${NotinoBodyMedium};

	@media (min-width: ${SALON_PAGE_MOBILE_BREAKPOINT}) {
		${NotinoBodyLarge};
	}

	${divider};
`

export const OpeningStatusValue = styled.span<{ $isOpen: boolean }>`
	color: ${(p) => (p.$isOpen ? theme.color.text.positive : theme.color.text.negative)};
`
