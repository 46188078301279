/* eslint-disable global-require */
import React, { useContext } from 'react'
import * as SC from './SalonDetailPlaceholderStyles'
import { AppContext } from '../../utils/appProvider'
import { SALON_DETAIL } from '../../styles/constants'

type Props = {
	initialLoading?: boolean
}

const SalonDetailPlaceholder = (props: Props) => {
	const { initialLoading } = props
	const { assetsPath } = useContext(AppContext)

	const placeholderImageJpg = `${assetsPath}/${require('../../assets/images/salons-view-loading.jpg')}`
	const placeholderImageWebp = `${assetsPath}/${require('../../assets/images/salons-view-loading.webp')}`

	return (
		<SC.PlaceholderWrapper>
			{initialLoading ? (
				<picture>
					<source srcSet={placeholderImageWebp} type='image/webp' />
					<source srcSet={placeholderImageJpg} type='image/jpeg' />
					<SC.PlaceholderImage src={placeholderImageJpg} alt='' loading='eager' width={SALON_DETAIL.IMAGE_WIDTH} height={SALON_DETAIL.IMAGE_HEIGHT} />
				</picture>
			) : (
				<SC.PlaceholderImage as='div' />
			)}
			<SC.PlaceholderContent>
				<SC.PlaceholderText />
				<SC.PlaceholderText />
				<SC.PlaceholderText />
			</SC.PlaceholderContent>
		</SC.PlaceholderWrapper>
	)
}

export default SalonDetailPlaceholder
