import { FieldValues, UseFormSetError } from 'react-hook-form'
import {
	AddReviewForm,
	CancelReservationForm,
	PatchCancelCalendarEventsReservationBody,
	PostCalendarEventsReservationBody,
	PostSalonReviewRequestBody,
	ReservationBookingConfirmationForm
} from '../types/types'

type Primitive = string | number | boolean | bigint | symbol | null | undefined

type DefaultPrefix = 'body'

/**
 * The `Path` type is a utility type that generates a string literal union of all possible property paths
 * within a nested object, including arrays. It is useful for accessing deeply nested properties in a
 * type-safe manner, such as when working with dynamic object structures, form libraries, or path-based data access.
 *
 * @template T - The type of the object whose paths are being generated.
 * @template Prefix - An optional string prefix to track the current path during recursion.
 *
 * How it works:
 * - If the type `T` is a primitive (string, number, boolean, etc.), the path is the current `Prefix`.
 * - If `T` is an array, the path adds `[number]` to represent any index, and recursively applies `Path` to the array's element type.
 * - If `T` is an object, the path recursively computes paths for each key in the object, adding `.` as a separator.
 * - The resulting type is a union of all possible paths in the object.
 *
 * Example Usage:
 * ```typescript
 * type NestedObject = {
 *   user: {
 *     name: string;
 *     address: {
 *       street: string;
 *       city: string;
 *     };
 *   };
 *   tags: string[];
 * };
 *
 * type Paths = Path<NestedObject>;
 * // Result: "user" | "user.name" | "user.address" | "user.address.street" | "user.address.city" | "tags" | "tags[number]"
 * ```
 */
type Path<T, Prefix extends string = DefaultPrefix> = T extends Primitive
	? Prefix // If the type is a primitive, return the current prefix
	: T extends ReadonlyArray<infer V>
		? Prefix | Path<V, `${Prefix}[number]`> // Handle arrays by adding `[number]` for indices
		: T extends object
			? {
					[K in keyof T]: K extends string ? Path<T[K], `${Prefix}${Prefix extends '' ? '' : '.'}${K}`> : never
				}[keyof T]
			: never

type FieldPath<T> = Path<T>

/**
 * Maps the source object paths to target form field names for error handling.
 * @template TSource - The source data structure (e.g., API request body).
 * @template TTarget - The target form structure for mapping (e.g., React Hook Form field names).
 */
type ErrorsMaps<TSource, TTarget extends FieldValues> = Partial<Record<NonNullable<FieldPath<TSource>>, Parameters<UseFormSetError<TTarget>>[0]>>

/**
 * POST: /api/b2c/web/salons/{salonID}/reviews/
 */
export const POST_REVIEW_ERROR_KEYS_MAP: ErrorsMaps<PostSalonReviewRequestBody, AddReviewForm> = {
	'body.reviewerName': 'reviewerName',
	'body.reviewMessage': 'reviewMessage',
	'body.rating': 'rating',
	'body.partialRatings.SERVICE': 'partialRatings',
	'body.partialRatings.COMMUNICATION': 'partialRatings',
	'body.partialRatings.GENERAL_IMPRESSION': 'partialRatings',
	'body.partialRatings.PLACE': 'partialRatings'
}

/**
 * POST: /api/b2c/web/salons/{salonID}/calendar-events/reservations/
 */
export const POST_RESERVATION_ERROR_KEYS_MAP: ErrorsMaps<PostCalendarEventsReservationBody, ReservationBookingConfirmationForm> = {
	'body.customer.firstName': 'firstName',
	'body.customer.lastName': 'lastName',
	'body.customer.phone': 'phone',
	'body.customer.phonePrefixCountryCode': 'phone',
	'body.customer.email': 'email',
	'body.customerNote': 'note',
	'body.visitor.firstName': 'visitor.firstName',
	'body.visitor.lastName': 'visitor.lastName'
}

/**
 * PATCH: /api/b2c/web/calendar-events/reservations/{calendarEventID}/cancel
 */
export const PATCH_RESERVATION_CANCEL_ERROR_KEYS_MAP: ErrorsMaps<PatchCancelCalendarEventsReservationBody, CancelReservationForm> = {
	'body.reservationCancellation.quickFeedbackAnswers': 'quickFeedbackAnswer',
	'body.reservationCancellation.quickFeedbackAnswers[number]': 'quickFeedbackAnswer',
	'body.reservationCancellation.text': 'text'
}
