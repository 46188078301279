import React, { PropsWithChildren } from 'react'
import { SkeletonElement } from '@notino/react-styleguide'

// styles
import * as SC from './ReservationBookingModalSkeletonStyles'

// components
import ReservationBookingModalLayout from '../ReservationBookingModalLayout/ReservationBookingModalLayout'

type Props = PropsWithChildren<{
	onClose: () => void
	onBackButtonClick?: () => void
	headerTitleSkeleton?: React.ReactNode
	headerImage?: string
	headerContentSkeleton?: React.ReactNode
	footerContentSkeleton?: React.ReactNode
	children?: React.ReactNode
}>

const ReservationBookingModalSkeleton = (props: Props) => {
	const {
		onClose,
		onBackButtonClick,
		headerImage,
		headerTitleSkeleton = <SkeletonElement animated width={10} height={2} />,
		headerContentSkeleton = <SkeletonElement animated width={10} height={1.5} />,
		footerContentSkeleton,
		children = (
			<SC.ContentSkeletonsWrap>
				<SkeletonElement animated width={'100%'} height={'80px'} />
				<SkeletonElement animated width={'100%'} height={'80px'} />
				<SkeletonElement animated width={'100%'} height={'80px'} />
			</SC.ContentSkeletonsWrap>
		)
	} = props

	return (
		<ReservationBookingModalLayout
			title={headerTitleSkeleton}
			headerContent={headerContentSkeleton}
			headerImage={headerImage}
			footerContent={footerContentSkeleton}
			onBackButtonClick={onBackButtonClick}
			onClose={() => onClose()}
		>
			{children}
		</ReservationBookingModalLayout>
	)
}

export default ReservationBookingModalSkeleton
