/* eslint-disable import/no-dynamic-require, global-require */
import { ActionButton, ButtonModel, IDropdownOption, IconRegularSearch, Pager } from '@notino/react-styleguide'
import React, { PropsWithChildren, useContext, useEffect } from 'react'
import { useIntl } from 'react-intl'

// hooks
import useMessages from '../../hooks/useMessages'
import useSortingOptions from '../../hooks/useSortingOptions'

// styles
import * as SC from './SalonsViewStyles'
import { DesktopOnly, MobileOnly } from '../../styles/helpers'

// types
import {
	AvailableLanguagesType,
	Salon,
	SalonsResponse,
	LoadSalonsFromFiltersParams,
	SalonsListPageQueryType,
	SalonsListPageSetQueryType,
	ServiceTotalPriceCurrencyCode,
	SalonsCategoryPageQueryType,
	DefaultCityType,
	CategoryResponse,
	SalonsCategoryPageSetQueryType,
	CmsAppPromoData
} from '../../types/types'

// components
import SalonDetail from '../SalonDetail/SalonDetail'
import SalonDetailPlaceholder from '../SalonDetailPlaceholder/SalonDetailPlaceholder'
import SalonsMap from '../SalonsMap/SalonsMap'
import AppDownloadSection from '../../pages/HomePage/components/AppDownloadSection/AppDownloadSection'

// assets
import ListIcon from '../../assets/icons/ListIcon'
import MapIcon from '../../assets/icons/MapIcon'

// utils
import { pushToDataLayer } from '../../utils/dataLayer'
import { getListPaginatingEvent, getSortingSetEvent, getToggleMapEvent, getViewItemListEvent } from '../../utils/dataLayerEvents'
import { GA_EVENTS_DELAY, SALONS_GRID_ELEMENTS_PER_PAGE, SALONS_PER_PAGE, SORTING_OPTION } from '../../utils/enums'
import { AppContext } from '../../utils/appProvider'

const BANNER_INDEX = 3

// props
type SalonsViewProps = {
	salons: Salon[]
	pagination: SalonsResponse['pagination']
	loadSalonsFromLoadMoreButton: () => void
	loadSalonsFromFilters: (params: LoadSalonsFromFiltersParams) => void
	query: SalonsListPageQueryType | SalonsCategoryPageQueryType
	setQuery: SalonsListPageSetQueryType | SalonsCategoryPageSetQueryType
	initialLoading: boolean
	salonsLoading: boolean
	buttonLoading: boolean
	defaultCity: DefaultCityType | undefined
	allowedReservations: boolean
	categoryData?: CategoryResponse
	currencyCode?: ServiceTotalPriceCurrencyCode
	cmsAppPromoData: CmsAppPromoData | undefined
}

// component
const SalonsView = (props: PropsWithChildren<SalonsViewProps>) => {
	const {
		salons,
		loadSalonsFromFilters,
		pagination,
		loadSalonsFromLoadMoreButton,
		query,
		setQuery,
		salonsLoading,
		buttonLoading,
		initialLoading,
		defaultCity,
		allowedReservations,
		categoryData,
		currencyCode,
		cmsAppPromoData
	} = props
	const intl = useIntl()
	const locale = intl.locale as AvailableLanguagesType
	const { messages } = useMessages()
	// const { formatMessage } = useIntl()
	const { assetsPath } = useContext(AppContext)

	// sorting
	const [currentSortingOption, sortingOptions] = useSortingOptions(query)

	// map view
	const { isMapView } = query
	const toggleMapView = () => {
		setQuery({ isMapView: isMapView ? undefined : true })

		// push to dataLayer
		const event = getToggleMapEvent(isMapView)
		pushToDataLayer(event)
	}

	// filters
	const handleOptionSelect = (option: IDropdownOption) => {
		loadSalonsFromFilters({ orderBy: option.id as SORTING_OPTION })

		// push to dataLayer
		const event = getSortingSetEvent({ option })
		pushToDataLayer(event)
	}

	// ga event
	useEffect(() => {
		// timeout so the dataLayer is updated after the page_view event is sent (in AppLayout)
		setTimeout(() => {
			const event = getViewItemListEvent({ currentPage: query.page, currentSortingOptionName: `${currentSortingOption.id}`, salons })
			pushToDataLayer(event)
		}, GA_EVENTS_DELAY)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// pagination
	const handlePageChange = (page: number) => {
		setQuery({ ...query, page })
		// push to dataLayer
		const event = getListPaginatingEvent({ page })
		pushToDataLayer(event)
		window.scrollTo({ top: 0, behavior: 'smooth' })
	}

	/**
	 * Temporary hide reaming salons count in the button
	 * https://goodrequest.atlassian.net/browse/NOT-11611
	 */
	// const remainingSalonsCount = pagination.totalCount - pagination.page * pagination.limit
	// const showMoreButtonText = formatMessage(
	// 	{ id: 'Show more salons ({remainingSalonsCount})', defaultMessage: 'Show more salons ({remainingSalonsCount})' },
	// 	{ remainingSalonsCount }
	// )
	const showMoreButtonText = messages['Show more salons']

	const showLoadingSkeletons = salonsLoading && !buttonLoading

	// salons
	const salonsToRender = salons.map((salon, index) => {
		// NOTE: list position is index of salon in the list of ALL available salons
		const pageOffset = (query.page - 1) * SALONS_PER_PAGE
		const listPosition = index + 1 + pageOffset
		return (
			<SalonDetail
				salon={salon}
				key={salon.id}
				query={query}
				isLoading={showLoadingSkeletons}
				currentCategoryID={categoryData?.category.id}
				listPosition={listPosition}
				imageLoading={index < 4 ? undefined : 'lazy'}
				logoLoading={index < 4 ? undefined : 'lazy'}
			/>
		)
	})

	// banner src
	let bannerSrc: string
	let bannerLink: string

	switch (locale) {
		case 'ro':
			bannerSrc = `${assetsPath}/${require(`../../assets/images/salons-view/banner-ro.jpg`)}`
			bannerLink = 'https://bit.ly/43gEzC3'
			break
		case 'bg':
			bannerSrc = `${assetsPath}/${require(`../../assets/images/salons-view/banner-bg.jpg`)}`
			bannerLink = 'https://bit.ly/4i6Ivty'
			break
		case 'hu':
			bannerSrc = `${assetsPath}/${require(`../../assets/images/salons-view/banner-hu.jpg`)}`
			bannerLink = 'https://bit.ly/43cTtsZ'
			break
		case 'sk':
			bannerSrc = `${assetsPath}/${require(`../../assets/images/salons-view/banner-sk.jpg`)}`
			bannerLink = 'https://bit.ly/4hSvfZr'
			break
		case 'cs':
		default:
			bannerSrc = `${assetsPath}/${require(`../../assets/images/salons-view/banner-cs.jpg`)}`
			bannerLink = 'https://bit.ly/3EY9oBt'
			break
	}

	// add banners to salons
	const bannersIndexes: number[] = [] // banner is always on 4th places of "page", so [3, 15, 27, 39, ...]
	for (let index = 0; index < (query.page || 1); index += 1) {
		const itemsPerPage = SALONS_GRID_ELEMENTS_PER_PAGE
		bannersIndexes.push(itemsPerPage * index + BANNER_INDEX)
	}

	bannersIndexes.forEach((index) => {
		// prepare banner or placeholder
		const banner = showLoadingSkeletons ? (
			<SalonDetailPlaceholder key={`banner-placeholder-${index}`} />
		) : (
			<a key={`banner-${index}`} href={bannerLink} target='_blank' rel={'noopener noreferrer'}>
				<SC.PromoBannerWrapper>
					<img src={bannerSrc} width={652} height={504} alt={'banner'} loading='lazy' />
				</SC.PromoBannerWrapper>
			</a>
		)

		// add banner to salons
		if (salonsToRender.length > index) {
			salonsToRender.splice(index, 0, banner)
		}
	})

	const loadMore = () => {
		loadSalonsFromLoadMoreButton()
	}

	const salonsInitialLoadingSkeletons = Array.from({ length: SALONS_GRID_ELEMENTS_PER_PAGE }, (_, i) => {
		return <SalonDetailPlaceholder key={i} initialLoading />
	})

	return !isMapView ? (
		<>
			{/* sorting and pagination desktop */}
			<SC.SortingAndPaginationDesktop>
				<SC.ToggleMapButton onClick={toggleMapView}>
					<MapIcon />
					<span>{messages['Show on map']}</span>
				</SC.ToggleMapButton>
				<SC.SortingSelect
					$assetsPath={assetsPath}
					currentOption={currentSortingOption}
					handleOptionSelect={handleOptionSelect}
					options={sortingOptions}
					borderless
				/>
			</SC.SortingAndPaginationDesktop>
			{/* sorting mobile */}
			<SC.SortingMobile>
				<SC.SortingSelect
					$assetsPath={assetsPath}
					currentOption={currentSortingOption}
					handleOptionSelect={handleOptionSelect}
					options={sortingOptions}
					borderless
				/>
			</SC.SortingMobile>

			<SC.SalonsGridWrapper>
				<SC.SalonsGridOverflow>
					{initialLoading && <SC.SalonsGridView>{salonsInitialLoadingSkeletons}</SC.SalonsGridView>}
					<SC.SalonsGridPositionWrapper $initialLoading={initialLoading}>
						{/* salons grid and map toggle button */}
						{salons.length > 0 ? (
							<SC.SalonsGridView>{salonsToRender}</SC.SalonsGridView>
						) : (
							<SC.NoSalonsMessageWrapper>
								<IconRegularSearch color={'icon.disabled'} height='50' width='50' />
								<SC.NoSalonsMessage>{messages['No results found']}</SC.NoSalonsMessage>
							</SC.NoSalonsMessageWrapper>
						)}
					</SC.SalonsGridPositionWrapper>
				</SC.SalonsGridOverflow>

				{/**
				 * App promo desktop
				 * Display only when reservations are allowed
				 */}
				{allowedReservations && (
					<DesktopOnly $breakpoint={'lg'}>
						<SC.AppDownloadSectionWrapper>
							<AppDownloadSection cmsData={cmsAppPromoData} />
						</SC.AppDownloadSectionWrapper>
					</DesktopOnly>
				)}

				{/* load more salons button */}
				{!initialLoading && salons.length > 0 && pagination.totalPages > 1 && (
					<SC.BottomNavigation>
						{salons.length > 0 && query.page !== pagination.totalPages && (
							<ActionButton loading={buttonLoading} buttonStyle={ButtonModel.Styles.secondary} onClick={loadMore}>
								{showMoreButtonText}
							</ActionButton>
						)}

						<SC.PaginationWrapper>
							<Pager currentPage={query.page} lastPage={pagination.totalPages} onPageChange={handlePageChange} />
						</SC.PaginationWrapper>
					</SC.BottomNavigation>
				)}

				{/**
				 * App promo mobile
				 * The mobile version needs to have a different banner position than the desktop version to maintain scroll position and ensure the user sees the newly loaded salons
				 * Display only when reservations are allowed
				 */}
				{allowedReservations && (
					<MobileOnly $breakpoint={'lg'}>
						<SC.AppDownloadSectionWrapper>
							<AppDownloadSection cmsData={cmsAppPromoData} />
						</SC.AppDownloadSectionWrapper>
					</MobileOnly>
				)}

				{/** this button must be separated from <SC.SalonsGridOverflow> container, because when parent has set overflown: hidden; the sticky position is not working */}
				{salons.length > 0 && (
					<SC.ToggleMapContainerMobile>
						<SC.ToggleMapButtonMobile onClick={toggleMapView}>
							<MapIcon />
							{messages['Show on map']}
						</SC.ToggleMapButtonMobile>
					</SC.ToggleMapContainerMobile>
				)}
			</SC.SalonsGridWrapper>
		</>
	) : (
		<SC.SalonsMapView>
			<SC.ToggleListContainerDesktop>
				<SC.ToggleMapButton onClick={toggleMapView}>
					<ListIcon />
					<span>{messages['Show as list']}</span>
				</SC.ToggleMapButton>
			</SC.ToggleListContainerDesktop>
			<SalonsMap
				query={query}
				toggleMapView={toggleMapView}
				currencyCode={currencyCode}
				currentCategoryID={categoryData?.category.id}
				defaultCity={defaultCity}
			/>
		</SC.SalonsMapView>
	)
}

export default SalonsView
