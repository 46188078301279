import { styled, theme } from '@notino/react-styleguide'
import { CONTAINER_X_PADDING, SALON_MOBILE_PROMO_BANNER_CLASSNAME } from '../../../../styles/constants'

export const GalleryMobileContainer = styled.div`
	margin-left: -${CONTAINER_X_PADDING}px;
	margin-right: -${CONTAINER_X_PADDING}px;

	/*
    NOTE: following classes come from react-image-gallery library
    */

	.image-gallery-content .image-gallery-slide {
		.image-gallery-image {
			width: 100%;
			aspect-ratio: 1.25;
			object-fit: cover;
			max-height: 400px;
			display: block;
		}

		&.${SALON_MOBILE_PROMO_BANNER_CLASSNAME} {
			cursor: pointer;
		}
	}

	.image-gallery-bullets {
		.image-gallery-bullet {
			padding: 3px;
			&:hover {
				background: ${theme.color.background.inverse};
				border-color: ${theme.color.border.selected};

				.active {
					background: ${theme.color.background.inverse};
					border-color: ${theme.color.border.selected};
				}
			}
		}
	}
`

export const SingleImage = styled.img`
	width: 100%;
	aspect-ratio: 1.25;
	object-fit: cover;
	max-height: 400px;
	display: block;
`
