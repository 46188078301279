import dayjs, { Dayjs } from 'dayjs'
import {
	AllowedReservationTimeSlot,
	BaseCheckboxGroupCustomOption,
	GetCalendarEventsReservationAvailableResponse,
	PriceAndDurationData,
	ReservationBookingEmployeeAndDateSelectionForm,
	ReservationBookingEmployeeAndDateSelectionSubmittedForm,
	SalonServiceResponse,
	ServiceCategoryParameterValueData,
	TimeSlotOption,
	TimeSlotOptionsByDayPeriod,
	TimeSlotsSubset
} from '../../types/types'
import { DEFAULT_DATE_FORMAT, RESERVATION_BOOKING_MODAL_FIXED_HEADER_HEIGHT, RESERVATIONS_TIME_SLOTS } from '../../utils/enums'
import { TIME_SLOTS_TRANSLATIONS } from '../../utils/helper'
import { getIntl } from '../../utils/intl'

export type SelectedIndustryData = { industryID: string; image?: string }

export type AvailableDatesStateData = Record<string, GetCalendarEventsReservationAvailableResponse['availableReservations'] | null>

export type TimeSlotPeriods = {
	label: string
	value: AllowedReservationTimeSlot
	formName: keyof TimeSlotsSubset
}

export type EmployeeOption = BaseCheckboxGroupCustomOption<{ avatar?: string; priceAndDurationData: PriceAndDurationData | undefined }>

export const ANYONE_AVAILABLE_OPTION_VALUE = 'ANYONE_AVAILABLE_OPTION_VALUE'

export const EMPTY_TIME_SLOT_OPTIONS: TimeSlotOptionsByDayPeriod = {
	[RESERVATIONS_TIME_SLOTS.MORNING]: [],
	[RESERVATIONS_TIME_SLOTS.AFTERNOON]: [],
	[RESERVATIONS_TIME_SLOTS.EVENING]: []
}

export const getTimeSlotFormName = (dayPeriod: AllowedReservationTimeSlot): keyof TimeSlotsSubset => {
	return `timeSlot-${dayPeriod}`
}

export const TIME_SLOT_PERIODS = (timeSlotTranslations: ReturnType<typeof TIME_SLOTS_TRANSLATIONS>): TimeSlotPeriods[] => [
	{
		label: timeSlotTranslations[RESERVATIONS_TIME_SLOTS.MORNING],
		value: RESERVATIONS_TIME_SLOTS.MORNING,
		formName: getTimeSlotFormName(RESERVATIONS_TIME_SLOTS.MORNING)
	},
	{
		label: timeSlotTranslations[RESERVATIONS_TIME_SLOTS.AFTERNOON],
		value: RESERVATIONS_TIME_SLOTS.AFTERNOON,
		formName: getTimeSlotFormName(RESERVATIONS_TIME_SLOTS.AFTERNOON)
	},
	{
		label: timeSlotTranslations[RESERVATIONS_TIME_SLOTS.EVENING],
		value: RESERVATIONS_TIME_SLOTS.EVENING,
		formName: getTimeSlotFormName(RESERVATIONS_TIME_SLOTS.EVENING)
	}
]

type TimeSlotDataMapItem = { timeFrom: string; timeTo: string; employeeID: string; dayPeriod: AllowedReservationTimeSlot }

type TimeSlotDataMap = Record<string, TimeSlotDataMapItem[]>

type TimeSlotData = {
	timeSlotOptions: TimeSlotOptionsByDayPeriod
	timeSlotDataMap: TimeSlotDataMap
}

export const getSelectedTimeSlotValue = (
	selectedTimeSlotData: TimeSlotDataMapItem[] | null,
	timeSlotEmployee: ReservationBookingEmployeeAndDateSelectionForm['timeSlotEmployee']
): ReservationBookingEmployeeAndDateSelectionSubmittedForm['timeSlot'] | null => {
	if (!selectedTimeSlotData?.length) {
		return null
	}

	if (selectedTimeSlotData.length === 1) {
		return selectedTimeSlotData[0]
	}

	if (!timeSlotEmployee.length) {
		return null
	}

	const employeeID = timeSlotEmployee[0]
	return selectedTimeSlotData.find((data) => data.employeeID === employeeID) || null
}

export const getSelectedTimeSlotOption = (timeSlotPeriods: TimeSlotOption[][]): TimeSlotOption | null => {
	const selectedTimeSlot = timeSlotPeriods.find((period) => period.length > 0)
	return selectedTimeSlot ? selectedTimeSlot[0] : null
}

export const getTimeSlotData = (availableData: NonNullable<AvailableDatesStateData[number]>, date: Dayjs): TimeSlotData => {
	const timeSlotDataMap = availableData.reduce<TimeSlotDataMap>((acc, item) => {
		if (!dayjs(item.date).isSame(dayjs(date), 'date')) {
			return acc
		}

		return item.items.reduce<TimeSlotDataMap>((itemsAcc, currentItem) => {
			const newValue: TimeSlotDataMapItem = {
				timeFrom: currentItem.from,
				timeTo: currentItem.to,
				employeeID: currentItem.employeeID,
				dayPeriod: currentItem.dayPart as AllowedReservationTimeSlot
			}

			if (itemsAcc[currentItem.from]) {
				return {
					...itemsAcc,
					[currentItem.from]: [...itemsAcc[currentItem.from], newValue]
				}
				return itemsAcc
			}
			return {
				...itemsAcc,
				[currentItem.from]: [newValue]
			}
		}, {})
	}, {})

	const timeSlotOptions = Object.entries({ ...timeSlotDataMap }).reduce<TimeSlotOptionsByDayPeriod>((acc, [timeFrom, timeItems]) => {
		const item = { ...timeItems[0] }

		return {
			...acc,
			[item.dayPeriod]: [...acc[item.dayPeriod], { key: timeFrom, value: timeFrom, label: timeFrom, extra: { dayPeriod: item.dayPeriod } }]
		}
	}, EMPTY_TIME_SLOT_OPTIONS)

	return {
		timeSlotOptions,
		timeSlotDataMap
	}
}

const KEY_SEPARATOR = '___'

export const getCalendarGridKey = (startDate: dayjs.Dayjs, endDate: dayjs.Dayjs, employeeID: string) =>
	`${startDate.format(DEFAULT_DATE_FORMAT)}${KEY_SEPARATOR}${endDate.format(DEFAULT_DATE_FORMAT)}${KEY_SEPARATOR}${employeeID}`

export const parseCalendarGridKey = (key: string) => {
	const split = key.split(KEY_SEPARATOR)
	return { startDate: dayjs(split[0]), endDate: dayjs(split[1]), employeeID: split[2] }
}

export const getAnyoneAvailableOption = (
	selectedServiceData: SalonServiceResponse['service'],
	selectedServiceCategoryParameterValueData: ServiceCategoryParameterValueData | undefined
): EmployeeOption => {
	const intl = getIntl()

	return {
		key: ANYONE_AVAILABLE_OPTION_VALUE,
		value: ANYONE_AVAILABLE_OPTION_VALUE,
		label: intl.formatMessage({ id: 'Anyone available', defaultMessage: 'Anyone available' }),
		extra: {
			priceAndDurationData: selectedServiceCategoryParameterValueData
				? selectedServiceData.serviceCategoryParameter?.values.find(
						(value) => selectedServiceCategoryParameterValueData.categoryParameterValueID === value.categoryParameterValueID
					)?.priceAndDurationData
				: selectedServiceData.rangePriceAndDurationData
		}
	}
}

export const getEmployeeOption = (
	employee: SalonServiceResponse['service']['employees'][number],
	selectedServiceCategoryParameterValueData: ServiceCategoryParameterValueData | undefined
): EmployeeOption => {
	return {
		key: employee.id,
		value: employee.id,
		label: employee.fullName || employee.email || employee.inviteEmail || employee.id,
		extra: {
			avatar: employee.image.resizedImages.thumbnail,
			priceAndDurationData: selectedServiceCategoryParameterValueData
				? employee.serviceCategoryParameter?.values.find(
						(value) => selectedServiceCategoryParameterValueData.categoryParameterValueID === value.categoryParameterValueID
					)?.priceAndDurationData
				: employee.rangePriceAndDurationData
		}
	}
}

export const scrollElementIntoView = <T extends HTMLElement>(element: T) => {
	// element.scrollIntoView is used only as fallback, because we need to offset the scroll distance with the fixed header height
	// fallback is needed, because we are targeting data attribute from external library (overlayscrollbars), which could be changed with any release
	// unfortunately, the library doesn't provide a way to set custom class or id to this attribute

	const scrollWrapper = element?.closest<HTMLDivElement>('[data-overlayscrollbars-viewport]')

	if (scrollWrapper) {
		const headerOffset = RESERVATION_BOOKING_MODAL_FIXED_HEADER_HEIGHT + 10

		// Get the bounding rectangle of both the element and the scroll wrapper
		const elementRect = element.getBoundingClientRect()
		const scrollWrapperRect = scrollWrapper.getBoundingClientRect()

		// Calculate the element's position relative to the scrollWrapper
		const relativeTop = elementRect.top - scrollWrapperRect.top

		// Get the current scroll position of the scrollWrapper
		const currentScrollTop = scrollWrapper.scrollTop

		// Calculate the final scroll position with the offset
		const offsetPosition = currentScrollTop + relativeTop - headerOffset

		scrollWrapper.scrollTo({
			top: offsetPosition,
			behavior: 'smooth'
		})
	} else {
		element.scrollIntoView({ behavior: 'smooth' })
	}
}

export const RESERVATION_BOOKING_MODAL_Z_INDEXES = {
	MODAL_WRAP: 900,
	SELECT_DROPDOWN: 901
}

export const getEmployeeAndDateSelectionInitFormData = (
	submittedData: ReservationBookingEmployeeAndDateSelectionSubmittedForm
): ReservationBookingEmployeeAndDateSelectionForm => {
	let initData: ReservationBookingEmployeeAndDateSelectionForm = {
		employeeID: [submittedData.employeeID || ANYONE_AVAILABLE_OPTION_VALUE],
		date: dayjs(submittedData.date),
		'timeSlot-MORNING': [],
		'timeSlot-EVENING': [],
		'timeSlot-AFTERNOON': [],
		timeSlotEmployee: []
	}

	if (submittedData.timeSlot.timeFrom && submittedData.timeSlot.timeTo) {
		if (submittedData.timeSlot.employeeID && submittedData.employeeID !== submittedData.timeSlot.employeeID) {
			initData = {
				...initData,
				timeSlotEmployee: [submittedData.timeSlot.employeeID]
			}
		}

		const timeSlotValue: TimeSlotOption = {
			key: submittedData.timeSlot.timeFrom,
			value: submittedData.timeSlot.timeFrom,
			label: submittedData.timeSlot.timeFrom,
			extra: {
				dayPeriod: submittedData.timeSlot.dayPeriod
			}
		}
		initData[`timeSlot-${submittedData.timeSlot.dayPeriod}`].push(timeSlotValue)
	}

	return initData
}
