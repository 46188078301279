import React, { forwardRef } from 'react'
import { InputRef } from 'rc-input'
import { IconRegularSearch } from '@notino/react-styleguide'

// types
import { InputProps } from './types'

// styles
import * as SC from './InputStyles'

// TODO: dorobit potvrdenie na 'Enter'
const Input = forwardRef<InputRef, InputProps>((props, ref) => {
	const { inputMode, isPristine, suffix, onChange, allowClear, value, invalid, ...restProps } = props

	const isSearchMode = inputMode === 'search'

	const getSuffix = () => {
		if (isSearchMode && !value) {
			return suffix || <IconRegularSearch />
		}

		return suffix
	}

	const isAllowClear = allowClear || !!(isSearchMode && value)

	return (
		<SC.Input
			ref={ref}
			$isInvalid={invalid}
			$isPristine={isPristine}
			$isSearch={isSearchMode}
			value={value}
			inputMode={inputMode}
			suffix={getSuffix()}
			onChange={onChange}
			allowClear={isAllowClear}
			{...restProps}
		/>
	)
})

export default Input
