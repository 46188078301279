import React, { useContext, PropsWithChildren, useState } from 'react'
import { Button, ButtonModel, IconRegularClose } from '@notino/react-styleguide'

// utils
import { AppContext } from '../../utils/appProvider'

// hooks
import useMessages from '../../hooks/useMessages'

// styles
import * as SC from './LoginScreenStyles'

type Props = PropsWithChildren<{
	onLogin: () => void
	onRegister: () => void
	onContinueAsGuest: () => void
	onClose?: () => void
}>

const LoginScreen = (props: Props) => {
	const { messages } = useMessages()
	const { assetsPath, isAuthorized } = useContext(AppContext)

	const { onLogin, onRegister, onContinueAsGuest, onClose, children } = props

	const [canContinue, setCanContinue] = useState(isAuthorized)

	// eslint-disable-next-line global-require
	const appTeaserImage = `${assetsPath}/${require(`../../assets/images/notino-b2c-app-teaser.png`)}`

	if (!canContinue) {
		return (
			<SC.LoginScreenWrapper>
				{onClose && (
					<SC.CloseButton>
						<IconRegularClose color={'icon.primary'} width={'1.5rem'} height={'1.5rem'} onClick={() => onClose()} />
					</SC.CloseButton>
				)}
				<SC.MainContent>
					{/* only decorative image with no meaningful content for the user, so it's better to have an empty alt tag */}
					<SC.ImageWrapper>
						<img src={appTeaserImage} alt={''} width={295} height={295} />
					</SC.ImageWrapper>
					<SC.Title>{messages['With the account, you will be in control of all bookings']}</SC.Title>
					<SC.Description>{messages['Simple and in one place. Including bookings in a few clicks.']}</SC.Description>
				</SC.MainContent>
				<SC.Footer>
					<Button buttonStyle={ButtonModel.Styles.primary} onClick={onLogin}>
						{messages['Sign in']}
					</Button>
					<Button buttonStyle={ButtonModel.Styles.secondary} onClick={onRegister}>
						{messages.Register}
					</Button>
					<SC.LinkButton
						buttonStyle={ButtonModel.Styles.neutral}
						onClick={() => {
							setCanContinue(true)
							onContinueAsGuest()
						}}
					>
						{messages['Continue as guest']}
					</SC.LinkButton>
				</SC.Footer>
			</SC.LoginScreenWrapper>
		)
	}

	// eslint-disable-next-line react/jsx-no-useless-fragment
	return <>{children}</>
}

export default LoginScreen
