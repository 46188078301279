import React, { useMemo, createContext, PropsWithChildren, useEffect, useState, useCallback } from 'react'
import { NumberParam, useQueryParams } from 'use-query-params'
import * as Sentry from '@sentry/react'
import { MyLocationType } from '../types/types'
import MyLocationProviderModal from '../components/MyLocationProviderModal/MyLocationProviderModal'

type Props = PropsWithChildren

type MyLocationModel = {
	myLocation: MyLocationType | null
	isLoadingMyLocation: boolean
	askForGeolocationPermissions: (updateMyLocationContext?: boolean) => Promise<MyLocationType | null>
}

export const MyLocationContext = createContext<MyLocationModel>({
	myLocation: null,
	isLoadingMyLocation: false,
	askForGeolocationPermissions: () => Promise.resolve(null)
})

const MyLocationProvider = ({ children }: Props) => {
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

	const [query] = useQueryParams({
		lonMy: NumberParam,
		latMy: NumberParam
	})

	// Determine if position is already provided via query parameters
	const hasPositionFromQueryParams = !!(query.latMy && query.lonMy)

	// Check if the Geolocation API is supported by the browser
	let isGeolocationAvailable = false
	try {
		isGeolocationAvailable = typeof window !== 'undefined' && !!navigator.geolocation
	} catch (e) {
		// eslint-disable-next-line no-console
		console.error(e)
	}

	const [myLocation, setMyLocation] = useState<MyLocationType | null>(query.lonMy && query.latMy ? { lonMy: query.lonMy, latMy: query.latMy } : null)
	const [isLoadingMyLocation, setIsLoadingMyLocation] = useState(!hasPositionFromQueryParams)

	// Get the geolocation permission status from the browser
	const getGeolocationStatus = useCallback(async () => {
		try {
			const status = await navigator.permissions.query({ name: 'geolocation' })
			return status.state
		} catch {
			return undefined
		}
	}, [])

	// Get the current geographical position from the browser
	const getCurrentPosition = useCallback(
		(): Promise<MyLocationType | null> =>
			new Promise((resolve, reject) => {
				if (!isGeolocationAvailable) {
					reject(new Error('Geolocation service in not available'))
				} else {
					navigator.geolocation.getCurrentPosition(
						// success callback
						(position) => {
							resolve({ latMy: position.coords.latitude, lonMy: position.coords.longitude })
						},
						// error callback
						(error) => {
							reject(error)
						},
						{
							enableHighAccuracy: false, // disable high accuracy to speed up geolocation search
							maximumAge: 30 * 60 * 1_000, // cache time location for 30 minutes
							timeout: 15 * 1_000 // timeout searching after 15 seconds
						}
					)
				}
			}),
		[isGeolocationAvailable]
	)

	/**
	 * Prompts the user for geolocation permissions.
	 * - If permissions are granted, fetches the user's coordinates.
	 * - If permissions are denied, shows a modal informing the user.
	 * - Should be invoked via a user action. Try to avoid automatic prompts on page load.
	 * @param updateMyLocationContext Whether to update the `myLocation` state
	 * @returns The user's location or null if permission is denied
	 */
	const askForGeolocationPermissions = useCallback(
		async (updateMyLocationContext = true) => {
			if (isLoadingMyLocation) {
				return null
			}

			let result = null

			try {
				result = await getCurrentPosition()
				if (updateMyLocationContext) {
					setMyLocation(result)
				}
			} catch (error: unknown) {
				const typeError = error as GeolocationPositionError | Error

				// error code === 1 means permissions are denied
				// modal is shown for the user with message informing him to enable geolocation in his browser to use this feature
				if ('code' in typeError && typeError.code === 1) {
					setIsModalOpen(true)
				} else {
					// Sent other errors to sentry
					Sentry.captureException(error)
				}
				if (myLocation !== null) {
					setMyLocation(null)
				}
			}

			return result
		},
		[getCurrentPosition, myLocation, isLoadingMyLocation]
	)

	useEffect(() => {
		;(async () => {
			if (!isGeolocationAvailable) {
				return
			}

			// get current position on page load when user previously granted the permission
			// skip the initialization if user's position is already known from the query params
			if (!hasPositionFromQueryParams) {
				const status = await getGeolocationStatus()
				if (status === 'granted') {
					const position = await getCurrentPosition()
					setMyLocation(position)
				}
			}

			setIsLoadingMyLocation(false)
		})()
	}, [getCurrentPosition, getGeolocationStatus, hasPositionFromQueryParams, isGeolocationAvailable])

	useEffect(() => {
		// Every user could have different location, so we don't want expose it in the address bar
		const searchParams = new URLSearchParams(window.location.search)
		searchParams.delete('lonMy')
		searchParams.delete('latMy')
		// get the current base URL (without the query parameters)
		const baseUrl = window.location.origin + window.location.pathname
		// replace the current URL without reloading the page
		window.history.replaceState(null, '', `${baseUrl}${searchParams.toString() ? `?${searchParams.toString()}` : ''}`)
	}, [])

	const value = useMemo<MyLocationModel>(
		() => ({
			myLocation: myLocation?.latMy
				? {
						latMy: myLocation.latMy,
						lonMy: myLocation.lonMy
					}
				: null,
			askForGeolocationPermissions,
			isLoadingMyLocation
		}),
		[myLocation?.latMy, myLocation?.lonMy, isLoadingMyLocation, askForGeolocationPermissions]
	)

	return (
		<>
			<MyLocationContext.Provider value={value}>{children}</MyLocationContext.Provider>
			{isModalOpen && <MyLocationProviderModal show onOk={() => setIsModalOpen(false)} />}
		</>
	)
}

export default MyLocationProvider
