import React, { useEffect } from 'react'
import { IDropdownOption } from '@notino/react-styleguide'

// hooks
import { useForm } from 'react-hook-form'
import useMessages from '../../../../hooks/useMessages'

// components
import Drawer from '../../../../components/Drawer/Drawer'
import SidebarFooter from '../FilterSidebarFooter/FilterSidebarFooter'
import Tag from '../../../../atoms/Tag/Tag'
import HookFormField from '../../../../formFields/HookFormField'
import RadioButtonsFormField from '../../../../formFields/RadioButtonsFormField/RadioButtonsFormField'

// utils
import { SALONS_FILTER_ITEMS_CONFIG } from '../../../../utils/helper'
import { FORM, SALONS_FILTER_TYPE, SORTING_OPTION } from '../../../../utils/enums'

// assets
import ChevronDownLeft from '../../../../assets/icons/ChevronLeftIcon'

// types
import { FilterOrderByValuesType, FilterValuesType, LoadSalonsFromFiltersParams } from '../../../../types/types'

// styles
import * as SC from './FilterSidebarStyles'

// types
type FilterSidebarSortingProps = {
	filterSidebarDetailOpen: boolean
	setFilterSidebarDetailOpen: (open: boolean) => void
	filterDetailOpenedDirectly: boolean
	loadSalonsFromFilters: (params: LoadSalonsFromFiltersParams) => void
	submittedFilterValues: FilterValuesType
	setFilterValues: (newFilterValues: FilterOrderByValuesType) => void
	sortingOptions: IDropdownOption[]
	filterItemsConfig: ReturnType<typeof SALONS_FILTER_ITEMS_CONFIG>
}

// component
const FilterSidebarOrderBy = (props: FilterSidebarSortingProps) => {
	const {
		filterSidebarDetailOpen,
		setFilterSidebarDetailOpen,
		filterDetailOpenedDirectly,
		loadSalonsFromFilters,
		submittedFilterValues,
		setFilterValues,
		sortingOptions,
		filterItemsConfig
	} = props
	const { messages } = useMessages()

	// default filter values
	const orderByDefaultValue = filterItemsConfig[SALONS_FILTER_TYPE.SORTING].defaultValue

	// submitted filter values
	const submittedOrderBy = submittedFilterValues.orderBy || orderByDefaultValue

	// internal filter values - no submitted - just for components state
	const {
		control,
		handleSubmit,
		reset,
		setValue,
		watch,
		formState: { isDirty }
	} = useForm<FilterOrderByValuesType>({
		defaultValues: {
			orderBy: submittedOrderBy
		}
	})

	const orderBy = watch('orderBy')

	// check if current internal filter values are equal to default values
	// we will show "reset" buttons based on the check
	const isOrderByFilterEqualToDefault = orderBy === orderByDefaultValue

	useEffect(() => {
		// reinitialize internal filter values when submitted values changes to keep them in sync
		reset({ orderBy: submittedOrderBy })
	}, [submittedOrderBy, reset])

	const handleSubmitFilterDetail = () => {
		if (filterDetailOpenedDirectly) {
			const params: LoadSalonsFromFiltersParams = {
				orderBy
			}

			loadSalonsFromFilters(params)
		} else {
			setFilterValues({ orderBy })
		}

		setFilterSidebarDetailOpen(false)
	}

	const resetSortingFilterToDefault = () => {
		setValue('orderBy', orderByDefaultValue, { shouldDirty: true })
	}

	const closeSidebarDetail = () => {
		setFilterSidebarDetailOpen(false)
		reset()
	}

	const getFooterSliderItems = () => {
		let sortingSliderContent = null
		const selectedSortingTitle = filterItemsConfig[SALONS_FILTER_TYPE.SORTING].getSelectedTitle(sortingOptions, orderBy, SORTING_OPTION.RECOMMENDED)
		if (selectedSortingTitle) {
			sortingSliderContent = <Tag label={selectedSortingTitle} onCloseBtnClick={resetSortingFilterToDefault} />
		}

		return sortingSliderContent
	}

	return (
		<Drawer
			open={filterSidebarDetailOpen}
			mask
			maskClosable
			// if the detail is open from the main filter sidebar, it already has a mask, so we need to set opacity of detail sidebar mask to zero, so they don't overlap visually
			maskStyle={!filterDetailOpenedDirectly ? { opacity: 0 } : undefined}
			closeIcon={!filterDetailOpenedDirectly ? <ChevronDownLeft /> : undefined}
			title={filterItemsConfig[SALONS_FILTER_TYPE.PRICE].title}
			onClose={closeSidebarDetail}
			headerExtra={
				!isOrderByFilterEqualToDefault ? <SC.HeaderResetButton onClick={resetSortingFilterToDefault}>{messages.Reset}</SC.HeaderResetButton> : undefined
			}
			footer={<SidebarFooter formId={FORM.FILTER_SIDEBAR_ORDER_BY} isVisible={isDirty} extraContent={getFooterSliderItems()} />}
			destroyOnClose
		>
			<SC.SidebarDetailContent>
				<SC.SidebarForm id={FORM.FILTER_SIDEBAR_ORDER_BY} onSubmit={handleSubmit(handleSubmitFilterDetail)}>
					<HookFormField control={control} name={'orderBy'} component={RadioButtonsFormField} options={sortingOptions} />
				</SC.SidebarForm>
			</SC.SidebarDetailContent>
		</Drawer>
	)
}

export default FilterSidebarOrderBy
