import React from 'react'
import { cssTransition } from 'react-toastify'
import * as SC from './ToastContainerStyles'

const ToastAnimation = cssTransition({
	enter: 'enter-animation',
	exit: 'exit-animation',
	appendPosition: false,
	collapse: true,
	collapseDuration: 300
})

const ToastContainer = () => {
	return <SC.StyledToastContainer transition={ToastAnimation} hideProgressBar closeButton={false} />
}

export default ToastContainer
