import React from 'react'

// styles
import * as SC from './SalonSliderItemPlaceholderStyles'

const SalonSliderItemPlaceholder = () => {
	return (
		<SC.PlaceholderWrapper>
			<SC.PlaceholderImage />
			<SC.PlaceholderContent>
				<SC.PlaceholderReview />
				<SC.PlaceholderTitle />
				<SC.PlaceholderAddress />
			</SC.PlaceholderContent>
		</SC.PlaceholderWrapper>
	)
}

export default SalonSliderItemPlaceholder
