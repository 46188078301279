import React from 'react'

// styles
import * as SC from './RadioButtonsFormFieldStyles'

// types
import { RadioButtonsFormFieldProps } from './types'

// components
import FormItem from '../../atoms/FormItem/FormItem'

// component
const RadioButtonsFormField = <V extends string | number>(props: RadioButtonsFormFieldProps<V>) => {
	const {
		options,
		disabled,
		input: { value, onChange, name },
		meta: { invalid, error },
		onAfterChange,
		optionRender,
		inputStyle = 'default',
		htmlFor,
		label,
		required
	} = props

	const invalidStatus = !!(error || invalid)

	const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>, option: V) => {
		if (e.target.checked) {
			onChange(option)
			if (onAfterChange) {
				onAfterChange(option)
			}
		}
	}

	return (
		<FormItem htmlFor={htmlFor} label={label} required={required} error={invalidStatus && error ? error : undefined}>
			<SC.RadioButtonsWrapper $inputStyle={inputStyle}>
				{options.map((option) => {
					const checked = option.id === value
					const isOptionDisabled = !!(disabled || option.disabled)

					const optionNode = (
						<SC.RadioButton
							$checked={checked}
							key={option.id}
							$inputStyle={inputStyle}
							checked={checked}
							name={name}
							disabled={isOptionDisabled}
							onChange={(e) => handleChangeValue(e, option.id)}
						>
							<SC.RadioButtonLabel>
								<SC.RadioButtonLabelTitle $checked={checked} $inputStyle={inputStyle}>
									{option.label}
								</SC.RadioButtonLabelTitle>
								{option.description && <SC.RadioButtonLabelDescription>{option.description}</SC.RadioButtonLabelDescription>}
							</SC.RadioButtonLabel>
						</SC.RadioButton>
					)

					return optionRender ? optionRender(optionNode, { option, checked, disabled: isOptionDisabled }) : optionNode
				})}
			</SC.RadioButtonsWrapper>
		</FormItem>
	)
}

export default RadioButtonsFormField
