import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button, ButtonModel, IconRegularCheckmark, Ratings } from '@notino/react-styleguide'
import { FormattedMessage, useIntl } from 'react-intl'
import axios from 'axios'

// types
import { SalonDetail, SalonPageQueryType, SalonPageSetQueryType, SalonReviewsResponse } from '../../../../types/types'

// utils
import { AppContext } from '../../../../utils/appProvider'

// components
import SalonRatings from '../SalonRatings/SalonRatings'
import SalonPartialRatings from '../../../../components/SalonPartialRatings/SalonPartialRatings'
import AddReviewsModal from '../AddReviewModal/AddReviewModal'
import Tooltip from '../../../../components/Tooltip/Tooltip'

// hooks
import useMessages from '../../../../hooks/useMessages'
import useSalonReviews from '../../../../hooks/useSalonReviews'

// styles
import * as SC from './SalonPageReviewsStyles'

type Props = {
	salonData: SalonDetail
	reviewsData: SalonReviewsResponse
	fetchSalonData: () => Promise<void>
	query: SalonPageQueryType
	setQuery: SalonPageSetQueryType
}

const SalonPageReviews = (props: Props) => {
	const { formatDate } = useIntl()
	const { messages } = useMessages()
	const { isAuthorized } = useContext(AppContext)

	const { salonData, reviewsData, query, setQuery, fetchSalonData } = props
	const { reviews, reviewsPage, fetchMoreReviews } = useSalonReviews(salonData.id, reviewsData)

	const [isAddReviewModalOpen, setIsAddReviewModalOpen] = useState(false)

	const salonReviewsContainerRef = useRef<HTMLDivElement>(null)

	const { totalPages } = reviewsData.pagination
	const hasMoreReviews = totalPages ? reviewsPage < totalPages : false

	/** review is allowed to add
	 * - logged in user
	 * - user with a valid token in the URL (when guest user completes the reservation in the salon, an email with URL for providing a review for the reservation is send to him. Shape of the URL should be: "salony/{seoSlugName}/?calendarEventID={calendarEventID}&t={token}")
	 */
	const isUserAllowedToSeeReviews = isAuthorized || query.t

	useEffect(() => {
		if (query.calendarEventID && isUserAllowedToSeeReviews) {
			salonReviewsContainerRef.current?.scrollIntoView()
			setIsAddReviewModalOpen(true)
		}
	}, [query.calendarEventID, isUserAllowedToSeeReviews])

	const onSubmitReviewSuccess = () => {
		setIsAddReviewModalOpen(false)
		fetchSalonData()
		fetchMoreReviews({ resetPage: true })
		setQuery({ ...query, calendarEventID: undefined, t: undefined })
	}

	const onSubmitError = (error: unknown) => {
		// if calendarEventID query parameter exists and user gets 409, it means, he has already posted a review for this salon with this calendarEventId
		if (query.calendarEventID && axios.isAxiosError(error) && error.response?.status === 409) {
			setQuery({ ...query, calendarEventID: undefined })
		}
	}

	const getReviewButton = (buttonProps: React.ComponentProps<typeof Button> = {}) => {
		return (
			<Button fullWidth buttonStyle={ButtonModel.Styles.primary} onClick={() => setIsAddReviewModalOpen(true)} {...buttonProps}>
				{messages['Add review']}
			</Button>
		)
	}

	const totalRatingsCount = (
		<SC.TotalRatingsCount>
			<FormattedMessage
				id={'{ totalCount } <span>reviews</span>'}
				defaultMessage={'{ totalCount } <span>reviews</span>'}
				values={{
					totalCount: salonData.ratingsData.ratesCount,
					span: (chunks) => <span>{chunks}</span>
				}}
			/>
		</SC.TotalRatingsCount>
	)

	return (
		<>
			<SC.SalonReviewsContainer ref={salonReviewsContainerRef}>
				<SC.Heading>{messages.Reviews}</SC.Heading>
				<SC.ReviewsHeader>
					<SC.HeaderLeftColumn>
						<SC.AverageRatingContainer>
							<SC.AverageRatingValue>{salonData.ratingsData.rating}</SC.AverageRatingValue>
							<SC.AverageRatingStars>
								<Ratings
									ratingId='salon-average-rating'
									foreground={'text.highlight'}
									background={'background.tertiary'}
									rating={salonData.ratingsData.rating}
									size={20}
								/>
							</SC.AverageRatingStars>
						</SC.AverageRatingContainer>
						{isUserAllowedToSeeReviews && <SC.AddReviewsButtonDesktop>{getReviewButton()}</SC.AddReviewsButtonDesktop>}
						<SC.TotalRatingsCountContainerMobile>{totalRatingsCount}</SC.TotalRatingsCountContainerMobile>
					</SC.HeaderLeftColumn>
					<SC.HeaderRightColumn>
						<SC.TotalRatingsCountContainerDesktop>{totalRatingsCount}</SC.TotalRatingsCountContainerDesktop>
						<SC.RatingsContainer>
							<SC.GroupedRatingsContainer>
								<SalonRatings ratingsData={salonData.ratingsData} />
							</SC.GroupedRatingsContainer>
							<SC.PartialRatingsContainer>
								<SalonPartialRatings value={salonData.ratingsData.partialRatings} readOnly roundReadOnlyValues />
							</SC.PartialRatingsContainer>
						</SC.RatingsContainer>
					</SC.HeaderRightColumn>
					{isUserAllowedToSeeReviews && <SC.AddReviewsButtonMobile>{getReviewButton({ fullWidth: true })}</SC.AddReviewsButtonMobile>}
				</SC.ReviewsHeader>
				{reviews && (
					<>
						<SC.Divider />
						<SC.ReviewsContainer>
							{reviews.map((review) => {
								return (
									<SC.ReviewContainer key={review.id}>
										<SC.Review>
											<SC.ReviewInfo>
												<SC.ReviewInfoLeftContent>
													<SC.StyledRatings
														ratingId={`salon-rating-${review.id}`}
														foreground={'text.highlight'}
														background={'background.tertiary'}
														rating={review.rating}
														size={12}
													/>
													<SC.ReviewerName>{review.reviewerName}</SC.ReviewerName>
													{review.verified && (
														<Tooltip
															content={
																<SC.VerifiedReviewTooltipContent>
																	{messages['The customer wrote the review after completing a service in the salon.']}
																</SC.VerifiedReviewTooltipContent>
															}
															styles={{ body: { maxWidth: 250 } }}
														>
															<SC.VerifiedReview>
																<IconRegularCheckmark color={'text.positive'} width={'0.75rem'} height={'0.75rem'} />
																<span>{messages['Verified review']}</span>
															</SC.VerifiedReview>
														</Tooltip>
													)}
												</SC.ReviewInfoLeftContent>
												<SC.ReviewDate>{formatDate(review.createdAt, { dateStyle: 'medium' })}</SC.ReviewDate>
											</SC.ReviewInfo>
											<SC.ReviewMessage>{review.reviewMessage}</SC.ReviewMessage>
										</SC.Review>
										{review.salonResponse?.message && (
											<SC.ReviewResponse>
												<SC.ReviewResponseInfo>
													<SC.SalonName>
														{salonData.logo && (
															<SC.SalonLogo
																src={salonData.logo.resizedImages.thumbnail}
																width={24}
																height={24}
																alt={messages['Salon logo']}
															/>
														)}
														{salonData.name || salonData.id}
													</SC.SalonName>
													<SC.ReviewResponseDate>
														{formatDate(review.salonResponse.updatedAt, { dateStyle: 'medium' })}
													</SC.ReviewResponseDate>
												</SC.ReviewResponseInfo>
												<SC.ResponseMessage>{review.salonResponse.message}</SC.ResponseMessage>
											</SC.ReviewResponse>
										)}
									</SC.ReviewContainer>
								)
							})}
						</SC.ReviewsContainer>
						{hasMoreReviews && (
							<SC.ReviewsButtonContainer>
								<Button onClick={() => fetchMoreReviews()} buttonStyle={ButtonModel.Styles.secondary}>
									{messages['Show more']}
								</Button>
							</SC.ReviewsButtonContainer>
						)}
					</>
				)}
			</SC.SalonReviewsContainer>
			{isAddReviewModalOpen && isUserAllowedToSeeReviews && (
				<AddReviewsModal
					isOpen
					onClose={() => setIsAddReviewModalOpen(false)}
					salonID={salonData.id}
					onSubmitSuccess={onSubmitReviewSuccess}
					onSubmitError={onSubmitError}
					calendarEventID={query.calendarEventID || undefined}
					t={query.t || undefined}
				/>
			)}
		</>
	)
}

export default SalonPageReviews
