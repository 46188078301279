import React, { ReactNode } from 'react'
import * as SC from './ResourceCardStyles'

type Props = {
	title: string
	infoContent: string | ReactNode
	customIcon?: ReactNode
	avatarUrl?: string
	extraContent?: ReactNode
}

const ResourceCard = ({ title, avatarUrl, infoContent, extraContent, customIcon }: Props) => {
	return (
		<SC.ResourceCardWrapper>
			<SC.Avatar>{avatarUrl ? <SC.AvatarImg src={avatarUrl} /> : customIcon}</SC.Avatar>
			<div>
				<SC.Title>{title}</SC.Title>
				<SC.Description>{infoContent}</SC.Description>
			</div>
			<SC.ExtraContentWrapper>{extraContent}</SC.ExtraContentWrapper>
		</SC.ResourceCardWrapper>
	)
}

export default ResourceCard
