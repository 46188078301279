import React, { useEffect } from 'react'
import { Head } from '@notino/react-toolkit/exports/Head'
import { useIntl } from 'react-intl'

// utils
import { shopsConfig } from '../../../../appDefaults'
import { formatUrlSlug, PAGE_LINKS } from '../../../../utils/helper'
import { CategorySeoData } from '../../../../utils/categoriesSeoData'
import { ENVIRONMENTS } from '../../../../utils/enums'

// types
import { CategoryNameLocalization, SalonsPagination } from '../../../../types/types'

type Props = {
	pagination: SalonsPagination
	categoryLocalizations: CategoryNameLocalization[]
	categorySeoData: CategorySeoData
}

const SalonsCategoryPageHead = (props: Props) => {
	const { locale } = useIntl()

	const { pagination, categoryLocalizations, categorySeoData } = props

	const defaultShop = shopsConfig[0]

	const currentShop = shopsConfig.find((shop) => shop.locale === locale)
	const currentCategoryLocalizations = categoryLocalizations.find((localization) => localization.language === locale)
	const currentCategoryDefaultLocalizations = categoryLocalizations.find((localization) => localization.language === shopsConfig[0].locale)
	const currentCategoryLocalizationsSlug = formatUrlSlug(currentCategoryLocalizations?.valueSlug)

	const currentPath = currentShop ? PAGE_LINKS['/salons/services'](currentShop.locale) : null

	const pageTitle = categorySeoData.metaTitle

	// use effect needed to see title in local development
	useEffect(() => {
		if (process.env.NODE_ENV === ENVIRONMENTS.development) {
			document.title = pageTitle
		}
	}, [pageTitle])

	// canonical href
	const canonicalHref = currentPath && currentCategoryLocalizationsSlug ? `${currentPath}${currentCategoryLocalizationsSlug}` : undefined

	// alternate hrefs
	const alternateLinks = shopsConfig.reduce<Array<{ href: string; lang: string }>>((acc, shop) => {
		const categoryLocalization = categoryLocalizations.find((localization) => localization.language === shop.locale)
		const categorySlug = formatUrlSlug(categoryLocalization?.valueSlug)

		if (!categorySlug) {
			return acc
		}

		return [
			...acc,
			{
				href: `${shop.url}${PAGE_LINKS['/salons/services/:categorySlug'](shop.locale, categorySlug)}`,
				lang: shop.lang
			}
		]
	}, [])

	// x-default href
	const xDefaultHref = currentCategoryDefaultLocalizations?.valueSlug
		? `${defaultShop.url}${PAGE_LINKS['/salons/services/:categorySlug'](defaultShop.locale, currentCategoryDefaultLocalizations.valueSlug)}}`
		: undefined

	// prev link
	const hasLessPages = pagination && pagination.page !== 1

	let prevLink: string | undefined
	if (hasLessPages) {
		prevLink = currentPath && currentCategoryLocalizationsSlug ? `${currentPath}${currentCategoryLocalizationsSlug}?page=${pagination.page - 1}` : undefined
	}

	// next link
	const hasMorePages = pagination && pagination.page !== pagination.totalPages

	let nextLink: string | undefined
	if (hasMorePages) {
		nextLink = currentPath && currentCategoryLocalizationsSlug ? `${currentPath}${currentCategoryLocalizationsSlug}?page=${pagination.page + 1}` : undefined
	}

	// NOTE: defining children as array and using .flat() on it otherwise <Head> doesn't work properly
	// SIDENOTE: each item has to have unique 'key' because <Head/> iterates over the array
	const children = [
		// charset
		<meta key='charset' charSet='utf-8' />,

		// title
		<title key='title'>{pageTitle}</title>,

		// description
		<meta key='description' name='description' content={categorySeoData.metaDescription} />,

		// keywords
		<meta key='keywords' name='keywords' content={categorySeoData.metaKeywords} />,

		// canonical
		canonicalHref && <link key='canonical' rel='canonical' href={canonicalHref} />,
		// alternates
		alternateLinks.map((link, id) => {
			return <link key={`alternate-${id}`} rel='alternate' href={link.href} hrefLang={link.lang} />
		}),
		// x-default
		xDefaultHref && <link key='x-default' rel='alternate' href={xDefaultHref} hrefLang='x-default' />,

		// pagination SEO links
		prevLink && <link key='prev' rel='prev' href={prevLink} />,
		nextLink && <link key='next' rel='next' href={nextLink} />
	]
		.flat()
		.filter(Boolean)

	return <Head>{children}</Head>
}

export default SalonsCategoryPageHead
