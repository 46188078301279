import React, { useContext } from 'react'
import { useIntl } from 'react-intl'
import { AVAILABLE_LANGUAGES } from '../../appDefaults'
import useMessages from '../../hooks/useMessages'
import { AvailableLanguagesType, AppStoreType } from '../../types/types'
import { getMobileAppClickEvent } from '../../utils/dataLayerEvents'
import { pushToDataLayer } from '../../utils/dataLayer'
import { AppContext } from '../../utils/appProvider'
import * as SC from './StoreDownloadButtonsStyles'

type Props = {
	imageLoading?: React.ImgHTMLAttributes<HTMLImageElement>['loading']
}

const StoreDownloadButtons = (props: Props) => {
	const { imageLoading = 'lazy' } = props
	const { locale } = useIntl() as { locale: AvailableLanguagesType }
	const { messages } = useMessages()
	const { assetsPath } = useContext(AppContext)

	// images
	let appStoreImageWebp
	let appStoreImagePng
	let googlePlayImageWebp
	let googlePlayImagePng

	if (AVAILABLE_LANGUAGES.includes(locale)) {
		// WebP paths
		// eslint-disable-next-line global-require,import/no-dynamic-require
		appStoreImageWebp = `${assetsPath}/${require(`../../assets/images/store-badges/badge-app-store-${locale}.webp`)}`
		// eslint-disable-next-line global-require,import/no-dynamic-require
		googlePlayImageWebp = `${assetsPath}/${require(`../../assets/images/store-badges/badge-google-play-${locale}.webp`)}`

		// PNG fallback
		// eslint-disable-next-line global-require,import/no-dynamic-require
		appStoreImagePng = `${assetsPath}/${require(`../../assets/images/store-badges/badge-app-store-${locale}.png`)}`
		// eslint-disable-next-line global-require,import/no-dynamic-require
		googlePlayImagePng = `${assetsPath}/${require(`../../assets/images/store-badges/badge-google-play-${locale}.png`)}`
	} else {
		// Default to Czech version
		// eslint-disable-next-line global-require,import/no-dynamic-require
		appStoreImageWebp = `${assetsPath}/${require(`../../assets/images/store-badges/badge-app-store-cs.webp`)}`
		// eslint-disable-next-line global-require,import/no-dynamic-require
		googlePlayImageWebp = `${assetsPath}/${require(`../../assets/images/store-badges/badge-google-play-cs.webp`)}`
		// eslint-disable-next-line global-require,import/no-dynamic-require
		appStoreImagePng = `${assetsPath}/${require(`../../assets/images/store-badges/badge-app-store-cs.png`)}`
		// eslint-disable-next-line global-require,import/no-dynamic-require
		googlePlayImagePng = `${assetsPath}/${require(`../../assets/images/store-badges/badge-google-play-cs.png`)}`
	}

	// links
	const appStoreLink = messages['link app store']
	const googlePlayLink = messages['link google play']

	const handleDownloadClick = (storeType: AppStoreType) => {
		const event = getMobileAppClickEvent({ storeType })
		pushToDataLayer(event)
	}

	return (
		<>
			{/* App Store Button */}
			<a href={appStoreLink} target='_blank' rel='noopener noreferrer' onClick={() => handleDownloadClick('app_store')}>
				<picture>
					<source srcSet={appStoreImageWebp} type='image/webp' />
					<SC.StoreIcon height={40} width={120} src={appStoreImagePng} alt='app store logo' loading={imageLoading} />
				</picture>
			</a>
			{/* Google Play Button */}
			<a href={googlePlayLink} target='_blank' rel='noopener noreferrer' onClick={() => handleDownloadClick('google_play')}>
				<picture>
					<source srcSet={googlePlayImageWebp} type='image/webp' />
					<SC.StoreIcon height={40} width={134} src={googlePlayImagePng} alt='google play store logo' loading={imageLoading} />
				</picture>
			</a>
		</>
	)
}

export default StoreDownloadButtons
