import styled from 'styled-components'
import { theme } from '@notino/react-styleguide'
import { BREAKPOINTS } from '../../../../styles/constants'

export const Layout = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 32px;
	padding: 0 40px;

	@media (min-width: ${BREAKPOINTS.lg}) {
		flex-direction: row;
		justify-content: space-between;
		align-items: stretch;
		gap: 24px;
	}
`

export const StatisticsItem = styled.div`
	width: 100%;
	max-width: 250px;

	@media (min-width: ${BREAKPOINTS.lg}) {
		display: flex;
		align-items: center;
		padding: 16px 0;
	}
`

export const Text = styled.p`
	margin: 0 auto;
	text-align: center;
	width: 100%;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 500;
	overflow: hidden;

	@media (min-width: ${BREAKPOINTS.lg}) {
		margin: 0;
	}
`

export const Highlight = styled.span`
	color: ${theme.color.text.highlight};
	word-break: break-word;
`

export const Divider = styled.div`
	width: 100%;
	height: 1px;
	background: ${theme.color.border.divider};
	flex-shrink: 0;
	max-width: 400px;

	@media (min-width: ${BREAKPOINTS.lg}) {
		width: 1px;
		height: auto;
		max-width: 100%;
	}
`
