import React, { useContext, useEffect, useState } from 'react'
import { useInitialData } from '@notino/react-toolkit/renderer/fragment/useInitialData'
import { useIntl } from 'react-intl'

// components
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'
import SalonSeoSchema from './components/SalonSeoSchema/SalonSeoSchema'
import BasicSalon from './components/BasicSalon/BasicSalon'
import ProfiSalon from './components/ProfiSalon/ProfiSalon'
import ReservationBookingModal from '../../components/ReservationBookingModal/ReservationBookingModal'
import ReservationBookingPromoModal from '../../components/ReservationBookingPromoModal/ReservationBookingPromoModal'
import SalonHead from './components/SalonHead/SalonHead'

// types
import {
	// BloomreachPostSalonDetailBody,
	ConfigResponse,
	ContextProps,
	IGetInitialDataProps,
	ReservationBookingModalInitData,
	SalonPageQueryType,
	SalonPageSetQueryType,
	SalonResponse,
	SalonReviewsResponse,
	SalonServicesResponse,
	ServiceCardCallbackData
} from '../../types/types'

// utils
import { pushToDataLayer } from '../../utils/dataLayer'
import { getAddToCartEvent, getPageViewEvent, getReservationBeginEvent, getViewItemEvent } from '../../utils/dataLayerEvents'
import { GA_EVENTS_DELAY, SALON_TYPE, SCREEN_STATE_KEY } from '../../utils/enums'
import { areReservationsAllowed, getFirstLevelCategoriesNames, PAGE_LINKS } from '../../utils/helper'
import { AppContext } from '../../utils/appProvider'

// hooks
import useMessages from '../../hooks/useMessages'
import useAuthRedirect from '../../hooks/useAuthRedirect'

// styles
import * as SC from './SalonPageStyles'

type ExtendedContextProps = ContextProps & {
	salonSlug: string
	query: SalonPageQueryType
	setQuery: SalonPageSetQueryType
}

type InitialData = {
	salonData: SalonResponse
	servicesData: SalonServicesResponse
	configData: ConfigResponse
	reviewsData: SalonReviewsResponse
	allowedReservations: boolean
}

type SalonPageProps = InitialData &
	ExtendedContextProps & {
		setSalonData: React.Dispatch<React.SetStateAction<SalonResponse | undefined>>
	}

const SalonPage = (props: SalonPageProps) => {
	const { query, setQuery, salonData, servicesData, configData, reviewsData, salonSlug, allowedReservations, setSalonData } = props
	const { salon } = salonData

	const { locale } = useIntl()
	const { messages } = useMessages()
	const {
		apiBrowser,
		userInfo: { firstName, lastName, email },
		isAuthorized
	} = useContext(AppContext)

	const { snapshot } = useAuthRedirect(SCREEN_STATE_KEY.RESERVATION_SUMMARY_MODAL_SALON_CATEGORY_DETAIL)

	const [isReservationBookingPromoModalOpen, setIsReservationBookingPromoModalOpen] = useState(false)
	const [reservationBookingModalInitData, setReservationBookingModalInitData] = useState<ReservationBookingModalInitData | null>(null)

	const salonServices = getFirstLevelCategoriesNames(servicesData)
	const isBasic = salon.createType === SALON_TYPE.BASIC

	useEffect(() => {
		if (snapshot) {
			setReservationBookingModalInitData((prevState) => {
				if (!prevState) {
					return {
						...snapshot,
						bookingConfirmationData: isAuthorized
							? {
									firstName,
									lastName,
									email
								}
							: snapshot.bookingConfirmationData
					}
				}

				return null
			})
		}
	}, [snapshot, isAuthorized, firstName, lastName, email])

	// GA events
	useEffect(() => {
		const pageViewEvent = getPageViewEvent('salon')
		pushToDataLayer(pageViewEvent)

		// timeout so the dataLayer is updated after the page_view event is sent
		setTimeout(() => {
			const viewItemEvent = getViewItemEvent({ salon })
			pushToDataLayer(viewItemEvent)
		}, GA_EVENTS_DELAY)
	}, [salon])

	// Bloomreach tracking
	// TODO: temporarily commented out for the 2.3 release, uncomment in 2.4 release!
	/* useEffect(() => {
		;(async () => {
			try {
				const { salon: salonDetail } = await apiBrowser.b2c.getSalonData(salonSlug, { headers: { 'Accept-Language': 'en' } })
				const reqBody: BloomreachPostSalonDetailBody = {
					id: salonDetail.id,
					name: salonDetail.name ?? '',
					industryIds: salonDetail.categories.map((category) => category.id),
					industryNames: salonDetail.categories.map((category) => category.name ?? category.id),
					cosmeticIds: salonDetail.cosmetics.map((cosmetic) => cosmetic.id),
					cosmeticNames: salonDetail.cosmetics.map((cosmetic) => cosmetic.name ?? cosmetic.id),
					city: salonDetail.address?.city ?? '',
					address: salonDetail.formattedAddress ?? '',
					country: salonDetail.address?.countryCode ?? ''
				}
				apiBrowser.bloomreach.postSalonDetail(reqBody)
			} catch (error) {
				// eslint-disable-next-line no-console
				console.error(error)
			}
		})()
	}, [apiBrowser.bloomreach, apiBrowser.b2c, salonSlug]) */

	const fetchSalonData = async () => {
		const response = await apiBrowser.b2c.getSalonData(salonSlug)
		setSalonData(response)
	}

	const onOpenBookingModal = (serviceData?: ServiceCardCallbackData) => {
		/* Display the reservation promo modal instead of the booking modal when reservations are not allowed */
		if (!allowedReservations) {
			setIsReservationBookingPromoModalOpen(true)

			const event = getAddToCartEvent(salon)
			pushToDataLayer(event)

			return
		}

		const initialData: ReservationBookingModalInitData = {
			initIndustryID: serviceData?.industryID ?? query.selectedIndustryID ?? null,
			initServiceID: serviceData?.serviceID ?? null,
			initAvResTimeSlotDayPart: query.selectedAvResTimeSlotDayPart || null,
			initServiceCategoryParameterID: serviceData?.serviceCategoryParameterID || null,
			parameterSelectionData: null,
			employeeAndDateSelectionData: {
				date: query.selectedAvResTimeSlotDateFrom
			},
			bookingConfirmationData: isAuthorized
				? {
						firstName,
						lastName,
						email
					}
				: null
		}

		setReservationBookingModalInitData(initialData)

		// If no service data is provided, the user opened the modal from the general button, not from a specific service card.
		if (!serviceData) {
			const event = getAddToCartEvent(salon)
			pushToDataLayer(event)
		}

		const event = getReservationBeginEvent({
			event: 'reservation_begin',
			mode: serviceData ? 'with_service' : 'blank',
			category: serviceData?.industryID,
			subcategory: serviceData?.gaSpecificData.secondLevelCategoryID,
			type: serviceData?.gaSpecificData.serviceCategoryID
		})
		pushToDataLayer(event)
	}

	// breadcrumbs
	const breadcrumbItems = [{ link: '', name: salon.name || '' }]

	return (
		<>
			<SalonHead salonSlug={salonSlug} salon={salon} salonServices={salonServices} />
			<SalonSeoSchema
				salon={salon}
				breadcrumbItems={[{ link: PAGE_LINKS['/salons/homepage'](locale), name: messages.Salons }, ...breadcrumbItems]}
				countriesData={configData.rolloutCountries}
				salonSlug={salonSlug}
			/>
			<SC.SeoTitle>{salon.name}</SC.SeoTitle>

			<SC.PageContainer>
				<Breadcrumb breadcrumbItems={breadcrumbItems} hasSalonsBreadcrumb />

				{isBasic ? (
					<BasicSalon salonData={salon} countriesData={configData.rolloutCountries} />
				) : (
					<ProfiSalon
						salonData={salon}
						countriesData={configData.rolloutCountries}
						salonServicesData={servicesData}
						salonReviewsData={reviewsData}
						onOpenBookingModal={onOpenBookingModal}
						fetchSalonData={fetchSalonData}
						query={query}
						setQuery={setQuery}
						allowedReservations={allowedReservations}
					/>
				)}
			</SC.PageContainer>

			{allowedReservations && reservationBookingModalInitData && (
				<ReservationBookingModal
					salonData={{
						id: salon.id,
						availableReservationSystem: salon.availableReservationSystem,
						images: salon.images,
						phones: salon.phones
					}}
					isOpen
					initData={reservationBookingModalInitData}
					onClose={() => setReservationBookingModalInitData(null)}
					salonServicesData={servicesData}
					configData={configData}
				/>
			)}

			{/* Display the reservation promo modal instead of the booking modal when reservations are not allowed */}
			{!allowedReservations && isReservationBookingPromoModalOpen && (
				<ReservationBookingPromoModal
					isOpen
					onClose={() => setIsReservationBookingPromoModalOpen(false)}
					availableReservation={salon.availableReservationSystem}
				/>
			)}
		</>
	)
}

// page container
const SalonPageContainer: IGetInitialDataProps<InitialData | null, ExtendedContextProps> = (props) => {
	const data = useInitialData(SalonPageContainer, props)

	//  save data to state to allow client-side refetching
	const [salonData, setSalonData] = useState<SalonResponse | undefined>(data?.salonData)

	// NOTE: needed for react-toolkit SSR
	if (!data || !salonData || !props.salonSlug) {
		return null
	}

	return <SalonPage {...props} {...data} salonData={salonData} setSalonData={setSalonData} />
}

// ssr setup
SalonPageContainer.initDefaultData = async ({ api, salonSlug, shopId }) => {
	try {
		const salonData = await api.b2c.getSalonData(salonSlug)

		const [configData, servicesData, reviewsData] = await Promise.all([
			api.b2c.getConfigData(),
			api.b2c.getSalonServicesData(salonData.salon.id),
			api.b2c.getSalonReviewsData(salonData.salon.id, { page: 1 })
		])

		const allowedReservations = areReservationsAllowed(configData.allowReservations, shopId)

		return { salonData, servicesData, configData, reviewsData, allowedReservations }
	} catch {
		return null
	}
}

SalonPageContainer.identifier = 'SalonPage'

export default SalonPageContainer
