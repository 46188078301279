/* eslint-disable global-require */
import React, { useContext, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

// types
import { CategoriesResponse, HeroSectionData } from '../../../../types/types'

// utils
import { PAGE_LINKS } from '../../../../utils/helper'
import { AppContext } from '../../../../utils/appProvider'
import { MyLocationContext } from '../../../../utils/myLocationProvider'
import { HERO_CATEGORIES_COUNT_DESKTOP, HERO_CATEGORIES_COUNT_MOBILE } from '../../utils'

// styles
import * as SC from './HeroSectionStyles'

// hooks
import useMessages from '../../../../hooks/useMessages'

type Props = {
	categoriesData: CategoriesResponse
	heroSectionData: HeroSectionData
}

// component
const HeroSection = (props: Props) => {
	const { locale } = useIntl()

	const { categoriesData, heroSectionData } = props
	const { desktopBg, mobileBg, title, subtitle } = heroSectionData

	const { assetsPath } = useContext(AppContext)
	const { myLocation } = useContext(MyLocationContext)

	const { messages } = useMessages()

	const moreCategoriesImage = `${assetsPath}/${require('../../../../assets/images/homepage/hero-more-categories.png')}`
	const loadingCategoryPlaceholder = `${assetsPath}/${require('../../../../assets/images/hero-categories-loading.jpg')}`

	const categoriesToShow = categoriesData.categories.slice(0, HERO_CATEGORIES_COUNT_DESKTOP)
	const showMoreCategoriesMobile = categoriesData.categories.length > HERO_CATEGORIES_COUNT_MOBILE
	const showMoreCategoriesDesktop = categoriesData.categories.length > HERO_CATEGORIES_COUNT_DESKTOP

	const [loading, setLoading] = useState(true)

	useEffect(() => {
		const imagesToCheck = categoriesData.categories.slice(0, HERO_CATEGORIES_COUNT_MOBILE).map((category) => category.image.resizedImages.thumbnail)

		const checkImagesLoaded = async () => {
			const promises = imagesToCheck.map((src) => {
				return new Promise((resolve) => {
					const img = new Image()
					img.src = src
					img.onload = () => resolve(true)
					img.onerror = () => resolve(false)
				})
			})

			const results = await Promise.all(promises)

			if (results.every(Boolean)) {
				setLoading(false)
			}
		}

		checkImagesLoaded()
	}, [categoriesData.categories])

	return (
		<SC.HeroSection $mobileBg={mobileBg} $desktopBg={desktopBg}>
			<SC.HeroContainer>
				<SC.HeroMainHeading>{title}</SC.HeroMainHeading>
				<SC.HeroDescription>{subtitle}</SC.HeroDescription>
				<SC.HeroCategoriesContainer>
					{categoriesToShow.map((category) => {
						return (
							<SC.CategoryCard
								key={category.id}
								href={PAGE_LINKS['/salons/services/:categorySlug'](locale, category.nameSlug ?? '', { ...myLocation })}
							>
								{loading ? (
									<SC.CategoryImage src={loadingCategoryPlaceholder} width={20} height={20} alt={''} />
								) : (
									<SC.CategoryImage
										loading='lazy'
										src={category.image.resizedImages.thumbnail}
										alt={category.name || ''}
										width={20}
										height={20}
									/>
								)}
								<SC.CategoryTitle>{category.name}</SC.CategoryTitle>
							</SC.CategoryCard>
						)
					})}
					<SC.CategoryCard
						href={PAGE_LINKS['/salons'](locale, { ...myLocation })}
						$hideOnMobile={!showMoreCategoriesMobile}
						$hideOnDesktop={!showMoreCategoriesDesktop}
					>
						{loading ? (
							<SC.CategoryImage src={loadingCategoryPlaceholder} alt={''} />
						) : (
							<SC.CategoryImage src={moreCategoriesImage} alt={''} width={20} height={20} />
						)}
						<SC.CategoryTitle>{messages['&  more...']}</SC.CategoryTitle>
					</SC.CategoryCard>
				</SC.HeroCategoriesContainer>
			</SC.HeroContainer>
		</SC.HeroSection>
	)
}

export default HeroSection
