import React from 'react'

const CategoryIcon = () => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' viewBox='0 0 20 20'>
			<mask id='path-1-inside-1_7401_185' fill='#fff'>
				<rect width='7.5' height='7.5' x='1.667' y='1.667' rx='0.833' />
			</mask>
			<rect width='7.5' height='7.5' x='1.667' y='1.667' stroke='currentColor' strokeWidth='3.333' mask='url(#path-1-inside-1_7401_185)' rx='0.833' />
			<mask id='path-2-inside-2_7401_185' fill='#fff'>
				<rect width='7.5' height='7.5' x='10.834' y='1.667' rx='0.833' />
			</mask>
			<rect width='7.5' height='7.5' x='10.834' y='1.667' stroke='currentColor' strokeWidth='3.333' mask='url(#path-2-inside-2_7401_185)' rx='0.833' />
			<mask id='path-3-inside-3_7401_185' fill='#fff'>
				<rect width='7.5' height='7.5' x='1.667' y='10.833' rx='0.833' />
			</mask>
			<rect width='7.5' height='7.5' x='1.667' y='10.833' stroke='currentColor' strokeWidth='3.333' mask='url(#path-3-inside-3_7401_185)' rx='0.833' />
			<mask id='path-4-inside-4_7401_185' fill='#fff'>
				<rect width='7.5' height='7.5' x='10.834' y='10.833' rx='0.833' />
			</mask>
			<rect width='7.5' height='7.5' x='10.834' y='10.833' stroke='currentColor' strokeWidth='3.333' mask='url(#path-4-inside-4_7401_185)' rx='0.833' />
		</svg>
	)
}

export default CategoryIcon
