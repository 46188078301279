import React, { PropsWithChildren } from 'react'
import { IconRegularError, Spinner } from '@notino/react-styleguide'

// assets
import EmptyStateIcon from '../../assets/icons/EmptyStateIcon'

// hooks
import useMessages from '../../hooks/useMessages'

// styles
import * as SC from './FetchResultStyles'

// types
type FetchResultProps = {
	initialLoading?: boolean
	isRefetching?: boolean
	isError?: boolean
	errorIcon?: React.ReactNode
	errorTitle?: string
	errorLabel?: string
	empty?: boolean
	emptyTitle?: React.ReactNode
	emptyLabel?: React.ReactNode
	emptyIcon?: React.ReactNode
	className?: string
	tryAgainButton?: {
		label?: string
		onClick: (e: React.SyntheticEvent<HTMLElement>) => void
	}
	padding?: string
	maxWidth?: string
} & PropsWithChildren

// component
const FetchResult = (props: FetchResultProps) => {
	const {
		children,
		isError,
		errorIcon = <IconRegularError color={'icon.disabled'} />,
		errorTitle,
		errorLabel,
		initialLoading,
		isRefetching,
		empty,
		emptyLabel,
		emptyTitle,
		emptyIcon = <EmptyStateIcon />,
		className,
		tryAgainButton,
		padding,
		maxWidth = '280px'
	} = props

	const { messages } = useMessages()

	return (
		<SC.FetchResultWrapper className={className}>
			{(() => {
				if (initialLoading) {
					return (
						<SC.StateWrapper $padding={padding} $maxWidth={maxWidth}>
							<Spinner color={'icon.primary'} size={40} />
						</SC.StateWrapper>
					)
				}

				if (isError) {
					return (
						<SC.StyledError
							$padding={padding}
							icon={errorIcon}
							title={errorTitle || messages['Something went wrong. Data was not loaded.']}
							label={errorLabel}
							button={tryAgainButton}
							maxWidth={maxWidth}
						/>
					)
				}

				if (empty) {
					return (
						<SC.StyledEmpty
							$padding={padding}
							icon={emptyIcon}
							title={emptyTitle || messages['Unfortunately, no data was found']}
							label={emptyLabel}
							maxWidth={maxWidth}
						/>
					)
				}

				return (
					<SC.SpinWrapper>
						{isRefetching && (
							<SC.SpinnerWrapper>
								<SC.Spinner size={24} color={'icon.primary'} />
							</SC.SpinnerWrapper>
						)}
						<SC.SpinContent $isRefetching={isRefetching}>{children}</SC.SpinContent>
					</SC.SpinWrapper>
				)
			})()}
		</SC.FetchResultWrapper>
	)
}

export default FetchResult
