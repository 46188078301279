import styled from 'styled-components'
import { Button } from '@notino/react-styleguide'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'

// styles
import { resetButtonStyles } from '../../../styles/helpers'

export const PromoBannerScreenWrapper = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;
`

export const CloseButton = styled.button`
	${resetButtonStyles};
	position: absolute;
	right: 20px;
	top: 20px;
	z-index: 2;
`

export const MainContentWrapper = styled(OverlayScrollbarsComponent)`
	width: 100%;
	height: 100%;
	overflow-y: auto;
`

export const PromoBannerLink = styled.a`
	width: 100%;
	display: block;
`

export const Picture = styled.picture`
	width: 100%;
	display: block;

	img {
		display: block;
		width: 100%;
		height: auto;
	}
`

export const PositionedButton = styled(Button)`
	position: absolute;
	left: 24px;
	right: 24px;
	bottom: 24px;
`
