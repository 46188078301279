/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'
import { NotinoBodyLarge } from '../../styles/helpers'
import { cssReset } from '../../styles/globalStyles'

export const ModalText = styled.p`
	${cssReset};
	${NotinoBodyLarge};
	margin-bottom: 1rem;
`
