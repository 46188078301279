import React from 'react'
import { ButtonModel, IconRegularClock } from '@notino/react-styleguide'

// types
import { SalonServicesResponse, ServiceCardCallbackData, ServiceDetailData } from '../../../../types/types'

// styles
import * as SC from './SalonServicesCardsStyles'

// hooks
import useMessages from '../../../../hooks/useMessages'

// components
import RenderPrice from '../../../../components/RenderPrice/RenderPrice'
import RenderDuration from '../../../../components/RenderDuration/RenderDuration'
import InfoLine from '../../../../atoms/InfoLine/InfoLine'

// utils
import { INFO_LINE_TYPE } from '../../../../atoms/InfoLine/types'

type IndustryData = NonNullable<SalonServicesResponse['groupedServicesByCategory'][0]['category']>

type SalonServicesCardsProps = {
	isReservationBookingModal?: boolean
	industryData: IndustryData
	phoneNumber: string
	onServiceCardButtonClick: (data: ServiceCardCallbackData) => void
	setServiceDetail: (data: ServiceDetailData) => void
}

type ServicesCardsSectionProps = Omit<SalonServicesCardsProps, 'industryData'> & { secondCategoryData: IndustryData['children']; industryID: string }

const filterIndustryCategoryByAvailability = (industryData: IndustryData, availableForReservation: boolean): NonNullable<IndustryData> => {
	return {
		...industryData,
		children: industryData?.children
			? industryData.children.reduce<IndustryData['children']>((acc, child) => {
					if (!child.category) {
						return acc
					}

					return [
						...acc,
						{
							...child,
							category: {
								...child.category,
								children: child.category?.children?.filter(
									(grandchild) => grandchild.service?.availableForReservation === availableForReservation
								)
							}
						}
					]
				}, [])
			: []
	}
}

const ServicesCardsSection = ({
	industryID,
	secondCategoryData,
	onServiceCardButtonClick,
	isReservationBookingModal = false,
	phoneNumber,
	setServiceDetail
}: ServicesCardsSectionProps) => {
	const { messages } = useMessages()

	const isOdd = (number: number) => {
		return number % 2 !== 0
	}

	return (
		<SC.IndustryWrapper>
			{secondCategoryData.map((secondCategory) => {
				if (!secondCategory.category || !secondCategory.category.children.length) {
					return null
				}

				const secondLevelCategoryID = secondCategory.category.id

				return (
					<SC.CardWrapper key={secondLevelCategoryID}>
						<SC.SecondCategoryTitle>{secondCategory.category.name}</SC.SecondCategoryTitle>
						<SC.Divider />
						<SC.SecondCategoryContent $isReservationBookingModal={isReservationBookingModal}>
							{secondCategory.category.children.map((serviceCategory) => {
								const { durationFrom, durationTo, priceFrom, priceTo } = serviceCategory.service.rangePriceAndDurationData
								const serviceID = serviceCategory.service.id
								const serviceCategoryID = serviceCategory.category.id
								const serviceCategoryParameterID = serviceCategory.service.serviceCategoryParameter?.id ?? null
								const description = serviceCategory.service.description || serviceCategory.category.description
								const isAvailableForReservations = serviceCategory.service.availableForReservation

								const totalLength = secondCategory.category?.children?.length || 0
								const isOddLength = isOdd(totalLength)
								const isClickable = !!description

								return (
									<SC.CategoryItem key={serviceCategory.category.id} $isOdd={isOddLength}>
										<SC.CategoryItemContainer
											$isClickable={isClickable}
											onClick={() => {
												if (isClickable) {
													setServiceDetail({
														category: serviceCategory.category,
														service: serviceCategory.service,
														secondLevelCategoryID,
														industryID
													})
												}
											}}
										>
											<SC.CategoryItemInfo>
												<SC.ServiceCategoryTitle>{serviceCategory.category.name}</SC.ServiceCategoryTitle>
												{description && <SC.ServiceDescription>{description}</SC.ServiceDescription>}
												<SC.ServiceCardFooter>
													<SC.PriceAndDurationContainer>
														{durationFrom !== undefined && !durationFrom !== null && (
															<SC.ServiceDuration>
																<SC.DurationIcon>
																	<IconRegularClock color={'icon.primary'} />
																</SC.DurationIcon>
																<SC.DurationText>
																	<RenderDuration from={durationFrom} to={durationTo} />
																</SC.DurationText>
															</SC.ServiceDuration>
														)}
														{priceFrom !== undefined && !priceFrom !== null && (
															<SC.ServicePrice>
																<RenderPrice from={priceFrom} to={priceTo} />
															</SC.ServicePrice>
														)}
													</SC.PriceAndDurationContainer>
												</SC.ServiceCardFooter>
											</SC.CategoryItemInfo>
											{isAvailableForReservations ? (
												<SC.BookOnlineButton
													buttonStyle={ButtonModel.Styles.secondary}
													fullWidth={false}
													onClick={(e) => {
														e.stopPropagation()
														onServiceCardButtonClick({
															industryID,
															serviceID,
															serviceCategoryParameterID,
															gaSpecificData: { secondLevelCategoryID, serviceCategoryID }
														})
													}}
												>
													{messages['Book online']}
												</SC.BookOnlineButton>
											) : (
												<SC.BookOnlineButton
													buttonStyle={ButtonModel.Styles.secondary}
													fullWidth={false}
													onClick={(e) => {
														e.stopPropagation()
														const phoneUrl = `tel:${phoneNumber}`
														try {
															window.location.href = phoneUrl
														} catch (error) {
															// eslint-disable-next-line no-console
															console.error('Error making phone call:', error)
														}
													}}
												>
													{messages.Call}
												</SC.BookOnlineButton>
											)}
										</SC.CategoryItemContainer>
										<SC.CategoryItemDivider />
									</SC.CategoryItem>
								)
							})}
						</SC.SecondCategoryContent>
					</SC.CardWrapper>
				)
			})}
		</SC.IndustryWrapper>
	)
}

const SalonServicesCards = ({
	industryData,
	onServiceCardButtonClick,
	isReservationBookingModal = false,
	phoneNumber,
	setServiceDetail
}: SalonServicesCardsProps) => {
	const { messages } = useMessages()

	const notAvailableForOnlineReservationItems = filterIndustryCategoryByAvailability(industryData, false)?.children
	const availableForOnlineReservationItems = filterIndustryCategoryByAvailability(industryData, true)?.children

	// NOTE: If there are children in the second category (non available for online booking category), we need to display a divider and items
	const hasChildren = notAvailableForOnlineReservationItems.some((item) => item.category && item.category.children && item.category.children.length > 0)

	return (
		<>
			<ServicesCardsSection
				phoneNumber={phoneNumber}
				industryID={industryData.id}
				secondCategoryData={availableForOnlineReservationItems}
				onServiceCardButtonClick={onServiceCardButtonClick}
				setServiceDetail={setServiceDetail}
				isReservationBookingModal={isReservationBookingModal}
			/>
			{hasChildren && (
				<>
					<SC.PhoneDividerWrapper>
						<SC.Divider />
						<SC.PhoneIcon color={'icon.secondary'} />
						<SC.PhoneText>{messages['Book by phone']}</SC.PhoneText>
						<SC.Divider />
					</SC.PhoneDividerWrapper>
					<SC.InfoLineWrapper>
						<InfoLine
							type={INFO_LINE_TYPE.INFO}
							message={messages['Services in this list are not yet available for online booking. Please contact salon to make a reservation.']}
						/>
					</SC.InfoLineWrapper>
					<ServicesCardsSection
						phoneNumber={phoneNumber}
						industryID={industryData.id}
						secondCategoryData={notAvailableForOnlineReservationItems}
						onServiceCardButtonClick={onServiceCardButtonClick}
						setServiceDetail={setServiceDetail}
						isReservationBookingModal={isReservationBookingModal}
					/>
				</>
			)}
		</>
	)
}

export default SalonServicesCards
