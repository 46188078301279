/* eslint-disable global-require */
import React, { useContext } from 'react'
import { useIntl } from 'react-intl'

// utils
import { AppContext } from '../../../../utils/appProvider'
import { PAGE_LINKS } from '../../../../utils/helper'
import { MyLocationContext } from '../../../../utils/myLocationProvider'

// styles
import * as SC from './DiscoverSalonsSectionStyles'
import { HomePageContainer, HomePageSectionLink } from '../../HomePageStyles'
import { BREAKPOINTS_INTS } from '../../../../styles/constants'

// hooks
import useMessages from '../../../../hooks/useMessages'

// types
import { CmsHomepageData } from '../../../../types/types'

type Props = {
	cmsData: CmsHomepageData['discoverSalonData'] | undefined
}

const getLocaleSpecificAssets = (locale: string, assetsPath: string, cmsData: Props['cmsData']) => {
	let discoverSalonsImageLgJpg: string
	let discoverSalonsImageSmJpg: string
	let discoverSalonsImageLgWebp: string
	let discoverSalonsImageSmWebp: string

	switch (locale) {
		case 'ro':
			discoverSalonsImageLgWebp = `${assetsPath}/${require('../../../../assets/images/homepage/discover-salons-image-lg-ro.webp')}`
			discoverSalonsImageSmWebp = `${assetsPath}/${require('../../../../assets/images/homepage/discover-salons-image-sm-ro.webp')}`
			discoverSalonsImageLgJpg = `${assetsPath}/${require('../../../../assets/images/homepage/discover-salons-image-lg-ro.jpg')}`
			discoverSalonsImageSmJpg = `${assetsPath}/${require('../../../../assets/images/homepage/discover-salons-image-sm-ro.jpg')}`
			break
		case 'bg':
			discoverSalonsImageLgWebp = `${assetsPath}/${require('../../../../assets/images/homepage/discover-salons-image-lg-bg.webp')}`
			discoverSalonsImageSmWebp = `${assetsPath}/${require('../../../../assets/images/homepage/discover-salons-image-sm-bg.webp')}`
			discoverSalonsImageLgJpg = `${assetsPath}/${require('../../../../assets/images/homepage/discover-salons-image-lg-bg.jpg')}`
			discoverSalonsImageSmJpg = `${assetsPath}/${require('../../../../assets/images/homepage/discover-salons-image-sm-bg.jpg')}`
			break
		case 'hu':
			discoverSalonsImageLgWebp = `${assetsPath}/${require('../../../../assets/images/homepage/discover-salons-image-lg-hu.webp')}`
			discoverSalonsImageSmWebp = `${assetsPath}/${require('../../../../assets/images/homepage/discover-salons-image-sm-hu.webp')}`
			discoverSalonsImageLgJpg = `${assetsPath}/${require('../../../../assets/images/homepage/discover-salons-image-lg-hu.jpg')}`
			discoverSalonsImageSmJpg = `${assetsPath}/${require('../../../../assets/images/homepage/discover-salons-image-sm-hu.jpg')}`
			break
		case 'sk':
			discoverSalonsImageLgWebp = `${assetsPath}/${require('../../../../assets/images/homepage/discover-salons-image-lg-sk.webp')}`
			discoverSalonsImageSmWebp = `${assetsPath}/${require('../../../../assets/images/homepage/discover-salons-image-sm-sk.webp')}`
			discoverSalonsImageLgJpg = `${assetsPath}/${require('../../../../assets/images/homepage/discover-salons-image-lg-sk.jpg')}`
			discoverSalonsImageSmJpg = `${assetsPath}/${require('../../../../assets/images/homepage/discover-salons-image-sm-sk.jpg')}`
			break
		case 'cs':
		default:
			discoverSalonsImageLgWebp = `${assetsPath}/${require('../../../../assets/images/homepage/discover-salons-image-lg-cs.webp')}`
			discoverSalonsImageSmWebp = `${assetsPath}/${require('../../../../assets/images/homepage/discover-salons-image-sm-cs.webp')}`
			discoverSalonsImageLgJpg = `${assetsPath}/${require('../../../../assets/images/homepage/discover-salons-image-lg-cs.jpg')}`
			discoverSalonsImageSmJpg = `${assetsPath}/${require('../../../../assets/images/homepage/discover-salons-image-sm-cs.jpg')}`
			break
	}

	if (cmsData?.image.desktop) {
		discoverSalonsImageLgWebp = ''
		discoverSalonsImageLgJpg = cmsData.image.desktop
	}

	if (cmsData?.image.mobile) {
		discoverSalonsImageSmWebp = ''
		discoverSalonsImageSmJpg = cmsData.image.mobile
	}

	return { discoverSalonsImageLgJpg, discoverSalonsImageSmJpg, discoverSalonsImageLgWebp, discoverSalonsImageSmWebp }
}

// component
const DiscoverSalonsSection = (props: Props) => {
	const { cmsData } = props

	const { assetsPath } = useContext(AppContext)

	const { locale } = useIntl()
	const { messages } = useMessages()

	const { myLocation } = useContext(MyLocationContext)

	const title = cmsData?.title || messages['discover-section-title']
	const description = cmsData?.subtitle || messages['discover-section-description']
	const sectionLink = PAGE_LINKS['/salons'](locale, { ...myLocation })

	const { discoverSalonsImageLgJpg, discoverSalonsImageSmJpg, discoverSalonsImageSmWebp, discoverSalonsImageLgWebp } = getLocaleSpecificAssets(
		locale,
		assetsPath,
		cmsData
	)
	const discoverSalonsImageAlt = cmsData?.image.alt || messages['discover-section-salons-image-alt']
	const discoverSalonsImageLink = cmsData?.image.link

	const picture = (
		<SC.Picture>
			{/* WebP verzie */}
			{/* TODO: Aktualne to moze byt empty string, pretoze z CMSka nam zatial WepP varianty nechodia */}
			{discoverSalonsImageLgWebp && <source srcSet={discoverSalonsImageLgWebp} type='image/webp' media={`(min-width: ${BREAKPOINTS_INTS.sm}px)`} />}
			{discoverSalonsImageSmWebp && <source srcSet={discoverSalonsImageSmWebp} type='image/webp' media={`(max-width: ${BREAKPOINTS_INTS.sm - 1}px)`} />}

			{/* JPEG fallback */}
			<source srcSet={discoverSalonsImageLgJpg} media={`(min-width: ${BREAKPOINTS_INTS.sm}px)`} />
			<source srcSet={discoverSalonsImageSmJpg} media={`(max-width: ${BREAKPOINTS_INTS.sm - 1}px)`} />

			{/* Fallback obrázok */}
			<img src={discoverSalonsImageLgJpg} loading='lazy' alt={discoverSalonsImageAlt} width={640} height={400} />
		</SC.Picture>
	)

	return (
		<section>
			<HomePageContainer>
				<SC.Layout>
					<SC.InfoCol>
						<SC.SectionTitle $marginBottom={'16px'}>{title}</SC.SectionTitle>
						<SC.SectionDescription $marginBottom={'16px'}>{description}</SC.SectionDescription>
						<HomePageSectionLink href={sectionLink}>{messages['discover-section-link']}</HomePageSectionLink>
					</SC.InfoCol>
					<SC.ImageCol>
						{discoverSalonsImageLink ? (
							<SC.ImageLink href={discoverSalonsImageLink} target={'_blank'} rel={'noopener noreferrer'}>
								{picture}
							</SC.ImageLink>
						) : (
							picture
						)}
					</SC.ImageCol>
				</SC.Layout>
			</HomePageContainer>
		</section>
	)
}

export default DiscoverSalonsSection
