import React from 'react'

// types
import { InputLabelProps } from './types'

// styles
import * as SC from './InputLabelStyles'

const InputLabel = (props: InputLabelProps) => {
	const { children, required, htmlFor } = props

	return (
		<SC.InputLabel htmlFor={htmlFor} $required={required}>
			{children}
		</SC.InputLabel>
	)
}

export default InputLabel
