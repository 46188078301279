import React from 'react'
import { IDropdownOption } from '@notino/react-styleguide'

// utils
import { OPENING_HOURS_STATUS, SALONS_FILTER_TYPE } from '../../../../utils/enums'
import { SALONS_FILTER_ITEMS_CONFIG } from '../../../../utils/helper'

// assets
import FilterIcon from '../../../../assets/icons/FilterIcon'

// components
import Chip from '../../../../atoms/Chip/Chip'
import ElementsSlider from '../../../../components/ElementsSlider/ElementsSlider'

// types
import { FilterValuesType, Cosmetic, Language, LoadSalonsFromFiltersParams, GetSalonsFilterCountResponse } from '../../../../types/types'
import { ChipProps } from '../../../../atoms/Chip/types'

// styles
import * as SC from './SalonsChipsStyles'

// types
type SalonsChipsProps = {
	submittedFilterValues: FilterValuesType
	defaultFilterValues: FilterValuesType
	filterItemsConfig: ReturnType<typeof SALONS_FILTER_ITEMS_CONFIG>
	setFilterSidebarOpen: (open: boolean) => void
	setFilterSidebarDetailOpen: (open: boolean) => void
	setFilterSidebarDetailType: (filterSidebarDetailType: SALONS_FILTER_TYPE) => void
	setFilterDetailOpenedDirectly: (openFromList: boolean) => void
	loadSalonsFromFilters: (params: LoadSalonsFromFiltersParams) => void
	currencySymbol: string | undefined
	cosmetics: Cosmetic[]
	languages: Language[]
	sortingOptions: IDropdownOption[]
	initialClientDataLoading?: boolean
	filterCounts: GetSalonsFilterCountResponse | undefined
}

type ChipPropsWithOrder = ChipProps & { order: number; show: boolean }

const SKELTON_COUNTS = 8

// sizes are in rem
const getSkeletons = () => {
	return (
		<>
			{/** Main open button chip skeleton */}
			<SC.Skeleton height={2} width={3} animated />
			{/** All other regular chips skeleton */}
			{Array.from(Array(SKELTON_COUNTS)).map((_, index) => {
				const width = index === 3 || index === 7 ? 6 : 5
				return <SC.Skeleton key={index} height={2} width={width} animated />
			})}
		</>
	)
}

// component
const SalonsChips = (props: SalonsChipsProps) => {
	const {
		submittedFilterValues,
		defaultFilterValues,
		filterItemsConfig,
		setFilterSidebarOpen,
		setFilterSidebarDetailOpen,
		setFilterSidebarDetailType,
		setFilterDetailOpenedDirectly,
		loadSalonsFromFilters,
		currencySymbol,
		cosmetics,
		languages,
		sortingOptions,
		initialClientDataLoading,
		filterCounts
	} = props

	// submitted filter values
	const {
		serviceTotalPriceRange: { priceFrom, priceTo },
		hasAvailableReservationSystem,
		openingHours,
		exactRating,
		cosmeticIDs,
		languageIDs,
		orderBy,
		availableReservations: { timeSlot, dateFrom, dateTo }
	} = submittedFilterValues

	const isPriceFilterEqualToDefault =
		priceFrom === defaultFilterValues.serviceTotalPriceRange.priceFrom && priceTo === defaultFilterValues.serviceTotalPriceRange.priceTo
	const isHasAvailableRSFilterEqualToDefault = hasAvailableReservationSystem === defaultFilterValues.hasAvailableReservationSystem
	const isOpeningHoursFilterEqualToDefault = openingHours === defaultFilterValues.openingHours
	const isRatingFilterEqualToDefault = exactRating.length === defaultFilterValues.exactRating.length
	const isCosmeticsFilterEqualToDefault = cosmeticIDs.length === defaultFilterValues.cosmeticIDs.length
	const isLanguagesFilterEqualToDefault = submittedFilterValues.languageIDs.length === defaultFilterValues.cosmeticIDs.length
	const isOrderByFilterEqualToDefault = submittedFilterValues.orderBy === defaultFilterValues.orderBy

	let isDateFilterEqualToDefault = true
	if (dateFrom) {
		isDateFilterEqualToDefault = defaultFilterValues.availableReservations.dateFrom
			? dateFrom.isSame(defaultFilterValues.availableReservations.dateFrom, 'date')
			: false
	}
	if (dateTo) {
		isDateFilterEqualToDefault = defaultFilterValues.availableReservations.dateTo
			? dateTo.isSame(defaultFilterValues.availableReservations.dateTo, 'date')
			: false
	}
	if (timeSlot !== defaultFilterValues.availableReservations.timeSlot) {
		isDateFilterEqualToDefault = false
	}

	const isFilterEqualToDefault =
		isPriceFilterEqualToDefault &&
		isHasAvailableRSFilterEqualToDefault &&
		isOpeningHoursFilterEqualToDefault &&
		isRatingFilterEqualToDefault &&
		isCosmeticsFilterEqualToDefault &&
		isDateFilterEqualToDefault &&
		isLanguagesFilterEqualToDefault &&
		isOrderByFilterEqualToDefault

	const openFilterSidebar = () => {
		// opens main sidebar, not a detail, so this needs to be false
		setFilterDetailOpenedDirectly(false)
		setFilterSidebarOpen(true)
	}

	const openFilterSidebarDetail = (detailType: SALONS_FILTER_TYPE) => {
		setFilterSidebarDetailType(detailType)
		setFilterSidebarDetailOpen(true)
		setFilterDetailOpenedDirectly(true)
	}

	const getFilterChips = () => {
		const cosmeticsSelectedLabel = !isCosmeticsFilterEqualToDefault
			? filterItemsConfig[SALONS_FILTER_TYPE.COSMETICS].getSelectedTitle(cosmetics, cosmeticIDs)
			: null

		const languagesSelectedLabel = !isLanguagesFilterEqualToDefault
			? filterItemsConfig[SALONS_FILTER_TYPE.LANGUAGES].getSelectedTitle(languages, languageIDs)
			: null

		const applyHasAvailableRSFilter = () => {
			loadSalonsFromFilters({ hasAvailableReservationSystem: !hasAvailableReservationSystem })
		}

		const applyOpeningHoursFilter = () => {
			loadSalonsFromFilters({ openingHoursStatus: !openingHours ? OPENING_HOURS_STATUS.OPEN : OPENING_HOURS_STATUS.ALL })
		}

		const showCosmeticsFilters = filterCounts ? !!(Object.values(filterCounts.cosmetics).some((value) => value) || cosmeticIDs.length) : true
		const showRatingsFilters = filterCounts ? !!(Object.values(filterCounts.exactRating).some((value) => value) || exactRating.length) : true
		const showLanguagesFilters = filterCounts ? !!(Object.values(filterCounts.languages).some((value) => value) || languageIDs.length) : true

		// all filter types except "sorting", because it is already presented in salons list as select field
		const filterChips: ChipPropsWithOrder[] = [
			{
				key: SALONS_FILTER_TYPE.PRICE,
				order: 1,
				show: true,
				label: !isPriceFilterEqualToDefault
					? filterItemsConfig[SALONS_FILTER_TYPE.PRICE].getSelectedTitle(
							currencySymbol,
							defaultFilterValues.serviceTotalPriceRange.priceFrom,
							defaultFilterValues.serviceTotalPriceRange.priceTo,
							priceFrom,
							priceTo
						)
					: filterItemsConfig[SALONS_FILTER_TYPE.PRICE].title,
				isSelected: !isPriceFilterEqualToDefault,
				onClick: () => openFilterSidebarDetail(SALONS_FILTER_TYPE.PRICE),
				onCloseBtnClick: () => {
					loadSalonsFromFilters({ serviceTotalPriceFrom: null, serviceTotalPriceTo: null })
				}
			},
			{
				key: SALONS_FILTER_TYPE.AVAILABLE_RS,
				order: 2,
				show: true,
				label: !isHasAvailableRSFilterEqualToDefault
					? filterItemsConfig[SALONS_FILTER_TYPE.AVAILABLE_RS].getSelectedTitle(hasAvailableReservationSystem)
					: filterItemsConfig[SALONS_FILTER_TYPE.AVAILABLE_RS].title,
				isSelected: !isHasAvailableRSFilterEqualToDefault,
				onClick: applyHasAvailableRSFilter,
				onCloseButtonClick: applyHasAvailableRSFilter
			},
			{
				key: SALONS_FILTER_TYPE.OPENING_HOURS,
				order: 3,
				show: true,
				label: !isOpeningHoursFilterEqualToDefault
					? filterItemsConfig[SALONS_FILTER_TYPE.OPENING_HOURS].getSelectedTitle(openingHours)
					: filterItemsConfig[SALONS_FILTER_TYPE.OPENING_HOURS].title,
				isSelected: !isOpeningHoursFilterEqualToDefault,
				onClick: applyOpeningHoursFilter,
				onCloseButtonClick: applyOpeningHoursFilter
			},
			{
				key: SALONS_FILTER_TYPE.RATING,
				order: 4,
				show: showRatingsFilters,
				label: !isRatingFilterEqualToDefault
					? filterItemsConfig[SALONS_FILTER_TYPE.RATING].getSelectedTitle(exactRating)
					: filterItemsConfig[SALONS_FILTER_TYPE.RATING].title,
				isSelected: !isRatingFilterEqualToDefault,
				onClick: () => openFilterSidebarDetail(SALONS_FILTER_TYPE.RATING),
				onCloseBtnClick: () => {
					loadSalonsFromFilters({ exactRating: defaultFilterValues.exactRating })
				}
			},
			{
				key: SALONS_FILTER_TYPE.COSMETICS,
				order: 5,
				show: showCosmeticsFilters,
				label: cosmeticsSelectedLabel?.label || filterItemsConfig[SALONS_FILTER_TYPE.COSMETICS].title,
				count: cosmeticsSelectedLabel?.count,
				isSelected: !isCosmeticsFilterEqualToDefault,
				onClick: () => openFilterSidebarDetail(SALONS_FILTER_TYPE.COSMETICS),
				onCloseBtnClick: () => {
					loadSalonsFromFilters({ cosmeticIDs: defaultFilterValues.cosmeticIDs })
				}
			},
			{
				key: SALONS_FILTER_TYPE.DATE,
				order: 6,
				show: true,
				label: !isDateFilterEqualToDefault
					? filterItemsConfig[SALONS_FILTER_TYPE.DATE].getSelectedTitle(timeSlot, dateFrom, dateTo)
					: filterItemsConfig[SALONS_FILTER_TYPE.DATE].title,
				isSelected: !isDateFilterEqualToDefault,
				onClick: () => openFilterSidebarDetail(SALONS_FILTER_TYPE.DATE),
				onCloseBtnClick: () => {
					loadSalonsFromFilters({
						avResTimeSlotDayPart: defaultFilterValues.availableReservations.timeSlot,
						avResTimeSlotDateFrom: null,
						avResTimeSlotDateTo: null
					})
				}
			},
			{
				key: SALONS_FILTER_TYPE.LANGUAGES,
				order: 7,
				show: showLanguagesFilters,
				label: languagesSelectedLabel?.label || filterItemsConfig[SALONS_FILTER_TYPE.LANGUAGES].title,
				count: languagesSelectedLabel?.count,
				isSelected: !isLanguagesFilterEqualToDefault,
				onClick: () => openFilterSidebarDetail(SALONS_FILTER_TYPE.LANGUAGES),
				onCloseBtnClick: () => {
					loadSalonsFromFilters({ languageIDs: defaultFilterValues.languageIDs })
				}
			},
			{
				key: SALONS_FILTER_TYPE.SORTING,
				order: 8,
				show: true,
				label: !isOrderByFilterEqualToDefault
					? filterItemsConfig[SALONS_FILTER_TYPE.SORTING].getSelectedTitle(sortingOptions, orderBy, defaultFilterValues.orderBy)
					: filterItemsConfig[SALONS_FILTER_TYPE.SORTING].title,
				isSelected: !isOrderByFilterEqualToDefault,
				onClick: () => openFilterSidebarDetail(SALONS_FILTER_TYPE.SORTING),
				onCloseBtnClick: () => {
					loadSalonsFromFilters({ orderBy: defaultFilterValues.orderBy })
				}
			}
		]
			.filter((chip) => chip.show)
			.sort((a, b) => {
				if (a.isSelected === b.isSelected) {
					// If both have the same isSelected value, compare by the "order" property.
					return a.order - b.order
				}
				// If isSelected is different, put the "true" values first.
				return a.isSelected ? -1 : 1
			})

		return filterChips
	}

	return (
		<>
			<SC.Divider />
			<ElementsSlider overflowX={'scroll'}>
				<SC.ChipsContent>
					{initialClientDataLoading ? (
						getSkeletons()
					) : (
						<>
							{/** Main open button chip */}
							<Chip label={<FilterIcon />} isSelected={!isFilterEqualToDefault} isMainButtonChip onClick={openFilterSidebar} />
							{/** All other regular chips */}
							{getFilterChips().map((chipProps) => (
								<Chip {...chipProps} />
							))}
						</>
					)}
				</SC.ChipsContent>
			</ElementsSlider>
		</>
	)
}

export default SalonsChips
