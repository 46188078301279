import RcTextArea from 'rc-textarea'
import styled from 'styled-components'
import { theme } from '@notino/react-styleguide'

// styles
import { NotinoLabelLarge400 } from '../../styles/helpers'
import { cssReset } from '../../styles/globalStyles'

export const Input = styled(RcTextArea)<{ $isPristine?: boolean; $isSearch?: boolean; $isInvalid?: boolean }>`
	&.rc-textarea {
		${cssReset};
		${NotinoLabelLarge400};
		padding: 0.75rem 1rem;
		width: 100%;
		overflow: hidden;
		border: 1px solid ${({ $isInvalid }) => ($isInvalid ? theme.color.border.negative : theme.color.border.default)};
		border-radius: 2px;
		display: inline-flex;
		gap: 0.625rem;
		outline: 2px solid transparent;
		outline-offset: -2px;
		transition:
			border 0.3s ease,
			outline 0.3s ease;
		${NotinoLabelLarge400};
		color: ${({ $isPristine }) => ($isPristine ? theme.color.text.tertiary : theme.color.text.primary)};

		&::placeholder {
			color: ${theme.color.text.tertiary};
			${NotinoLabelLarge400};
		}

		&:not(:disabled) {
			&:hover {
				border-color: ${({ $isInvalid }) => ($isInvalid ? theme.color.border.negative : theme.color.common.black)};
			}

			&:focus-within {
				border-color: ${({ $isInvalid }) => ($isInvalid ? theme.color.border.negative : theme.color.common.black)};
				outline-color: ${({ $isInvalid }) => ($isInvalid ? theme.color.border.negative : theme.color.common.black)};
			}
		}

		&:disabled {
			background-color: #f5f5f5;
			border-color: ${({ $isInvalid }) => ($isInvalid ? theme.color.border.negative : '#e6e6e6;')};
			color: ${theme.color.text.disabled};
		}
	}
`
