/* eslint-disable global-require */
import React, { useContext, useState } from 'react'
import { IconSolidInfo, Modal, IconRegularPlay } from '@notino/react-styleguide'
import { useIntl } from 'react-intl'

// components
import StoreDownloadButtons from '../../../../components/StoreDownloadButtons/StoreDownloadButtons'
import VideoModal from '../../../../components/VideoModal/VideoModal'

// utils
import { AppContext } from '../../../../utils/appProvider'

// styles
import * as SC from './AppDownloadSectionStyles'
import { BREAKPOINTS_INTS } from '../../../../styles/constants'
import { HomePageContainer } from '../../HomePageStyles'

// hooks
import useMessages from '../../../../hooks/useMessages'

// types
import { CmsAppPromoData } from '../../../../types/types'

type Props = {
	cmsData: CmsAppPromoData | undefined
}

type VideoData = {
	url: string
	width?: number
	height?: number
}

const getLocaleSpecificAssets = (locale: string, assetsPath: string, cmsData: Props['cmsData']) => {
	let desktopBg = `${assetsPath}/${require('../../../../assets/images/homepage/app-download-bg-desktop.jpg')}`
	let mobileBg = `${assetsPath}/${require('../../../../assets/images/homepage/app-download-bg-mobile.jpg')}`
	let imageDesktop: string
	let imageMobile: string
	let imageDesktopWebP: string
	let imageMobileWebP: string
	let link: string | undefined
	let alt: string | undefined
	let videoData: VideoData | undefined

	switch (locale) {
		case 'sk':
			imageDesktop = `${assetsPath}/${require('../../../../assets/images/homepage/app-download-preview-lg-sk.jpg')}`
			imageMobile = `${assetsPath}/${require('../../../../assets/images/homepage/app-download-preview-sm-sk.jpg')}`
			imageDesktopWebP = `${assetsPath}/${require('../../../../assets/images/homepage/app-download-preview-lg-sk.webp')}`
			imageMobileWebP = `${assetsPath}/${require('../../../../assets/images/homepage/app-download-preview-sm-sk.webp')}`
			videoData = {
				url: 'https://dmrz39codqjlj.cloudfront.net/static/WSK_SK_kristina-banner_960x600.mp4'
			}
			break
		case 'cs':
			imageDesktop = `${assetsPath}/${require('../../../../assets/images/homepage/app-download-preview-lg-cs.jpg')}`
			imageMobile = `${assetsPath}/${require('../../../../assets/images/homepage/app-download-preview-sm-cs.jpg')}`
			imageDesktopWebP = `${assetsPath}/${require('../../../../assets/images/homepage/app-download-preview-lg-cs.webp')}`
			imageMobileWebP = `${assetsPath}/${require('../../../../assets/images/homepage/app-download-preview-sm-cs.webp')}`
			videoData = {
				url: 'https://dmrz39codqjlj.cloudfront.net/static/WSK_CZ_ewa-banner_960x600.mp4'
			}
			break
		case 'ro':
			imageDesktop = `${assetsPath}/${require('../../../../assets/images/homepage/app-download-preview-lg-ro.jpg')}`
			imageMobile = `${assetsPath}/${require('../../../../assets/images/homepage/app-download-preview-sm-ro.jpg')}`
			imageDesktopWebP = `${assetsPath}/${require('../../../../assets/images/homepage/app-download-preview-lg-ro.webp')}`
			imageMobileWebP = `${assetsPath}/${require('../../../../assets/images/homepage/app-download-preview-sm-ro.webp')}`
			break
		case 'hu':
		case 'bg':
		default:
			imageDesktop = `${assetsPath}/${require('../../../../assets/images/homepage/app-download-preview-lg-all.jpg')}`
			imageMobile = `${assetsPath}/${require('../../../../assets/images/homepage/app-download-preview-sm-all.jpg')}`
			imageDesktopWebP = `${assetsPath}/${require('../../../../assets/images/homepage/app-download-preview-lg-all.webp')}`
			imageMobileWebP = `${assetsPath}/${require('../../../../assets/images/homepage/app-download-preview-sm-all.webp')}`
			break
	}

	if (cmsData) {
		if (cmsData.background.desktop) {
			desktopBg = cmsData.background.desktop
		}

		if (cmsData.background.mobile) {
			mobileBg = cmsData.background.mobile
		}

		if (cmsData.videoThumbnail.mobile) {
			imageMobileWebP = ''
			imageMobile = cmsData.videoThumbnail.mobile
		}

		if (cmsData.videoThumbnail.desktop) {
			imageDesktopWebP = ''
			imageDesktop = cmsData.videoThumbnail.desktop
		}

		if (cmsData.video) {
			videoData = {
				url: cmsData.video.url,
				width: cmsData.video.width,
				height: cmsData.video.height
			}
		} else {
			videoData = undefined

			if (cmsData.videoThumbnail.link) {
				link = cmsData.videoThumbnail.link
			}
		}
	}

	return { videoData, imageDesktop, imageMobile, imageDesktopWebP, imageMobileWebP, desktopBg, mobileBg, alt, link }
}

// component
const AppDownloadSection = (props: Props) => {
	const { cmsData } = props

	const { assetsPath } = useContext(AppContext)

	const { locale } = useIntl()
	const { messages } = useMessages()

	const [visibleInfoBanner, setVisibleInfoBanner] = useState(false)
	const [isVideoModalOpen, setIsVideoModalOpen] = useState(false)

	const qrCode = `${assetsPath}/${require('../../../../assets/images/app-qr-code.jpg')}`
	const qrCodeWebP = `${assetsPath}/${require('../../../../assets/images/app-qr-code.webp')}`
	const infoBannerText = cmsData?.tooltip || messages['app-download-section-info-modal-text']

	const title = cmsData?.title || messages['app-download-section-title']
	const description = cmsData?.subtitle || messages['app-download-section-description']

	const { videoData, imageDesktop, imageMobile, imageDesktopWebP, imageMobileWebP, desktopBg, mobileBg, alt, link } = getLocaleSpecificAssets(
		locale,
		assetsPath,
		cmsData
	)
	const thumbnailAlt = alt || messages['app-download-section-preview-image-alt']

	const picture = (
		<SC.Picture>
			{/* WebP varianty */}
			{/* TODO: Aktualne to moze byt empty string, pretoze z CMSka nam zatial WepP varianty nechodia */}
			{imageDesktopWebP && <source srcSet={imageDesktopWebP} type='image/webp' media={`(min-width: ${BREAKPOINTS_INTS.sm}px)`} />}
			{imageMobileWebP && <source srcSet={imageMobileWebP} type='image/webp' media={`(max-width: ${BREAKPOINTS_INTS.sm - 1}px)`} />}

			{/* JPEG fallback */}
			<source srcSet={imageDesktop} media={`(min-width: ${BREAKPOINTS_INTS.sm}px)`} />
			<source srcSet={imageMobile} media={`(max-width: ${BREAKPOINTS_INTS.sm - 1}px)`} />

			{/* Záložný <img> tag pre maximálnu kompatibilitu */}
			<img src={imageDesktop} alt={thumbnailAlt} loading='lazy' width={640} height={400} />
		</SC.Picture>
	)

	const getImageContent = () => {
		if (videoData) {
			return (
				<SC.VideoPreviewButton onClick={() => setIsVideoModalOpen(true)}>
					<SC.PictureContainer>{picture}</SC.PictureContainer>
					<SC.PlayIcon>
						<IconRegularPlay color={'icon.inverse'} />
					</SC.PlayIcon>
				</SC.VideoPreviewButton>
			)
		}

		if (link) {
			return (
				<SC.ImageLink href={link} target={'_blank'} rel={'noopener noreferrer'}>
					{picture}
				</SC.ImageLink>
			)
		}

		return picture
	}

	return (
		<SC.AppDownloadSection $mobileBg={mobileBg} $desktopBg={desktopBg}>
			<HomePageContainer>
				<SC.Layout>
					<SC.InfoCol>
						<SC.SectionTitle $marginBottom={'20px'}>{title}</SC.SectionTitle>
						<SC.SectionDescription $marginBottom={'20px'}>{description}</SC.SectionDescription>
						<SC.DownloadsSectionWrapper>
							<SC.QRCodeImageWrapper>
								<picture>
									{/* WebP verzia pre prehliadače, ktoré ju podporujú */}
									<source srcSet={qrCodeWebP} type='image/webp' />
									{/* JPEG fallback pre prehliadače, ktoré WebP nepodporujú */}
									<img src={qrCode} width={86} height={86} alt={messages['Download Notino app via QR code']} loading='lazy' />
								</picture>
							</SC.QRCodeImageWrapper>

							<SC.StoreDownloadButtonsWrapper>
								<StoreDownloadButtons />
							</SC.StoreDownloadButtonsWrapper>
							{infoBannerText && (
								<SC.InfoIconButton type={'button'} onClick={() => setVisibleInfoBanner(true)}>
									<IconSolidInfo width={'1.25rem'} height={'1.25rem'} color={'icon.tertiary'} />
								</SC.InfoIconButton>
							)}
						</SC.DownloadsSectionWrapper>
					</SC.InfoCol>
					<SC.ImageCol>
						<SC.ImageContainer>{getImageContent()}</SC.ImageContainer>
					</SC.ImageCol>
				</SC.Layout>
			</HomePageContainer>
			{infoBannerText && visibleInfoBanner && (
				<Modal show onClose={() => setVisibleInfoBanner(false)}>
					<SC.InfoModalText>{infoBannerText}</SC.InfoModalText>
				</Modal>
			)}
			{/* TODO: TO REPLACE: correct assets will be provided later by Notino marketing department */}
			{/* with and height should be set according to video provided by Notino marketing */}
			{isVideoModalOpen && videoData && (
				<VideoModal show onClose={() => setIsVideoModalOpen(false)} videoSrc={videoData.url} width={videoData.width} height={videoData.height} />
			)}
		</SC.AppDownloadSection>
	)
}

export default AppDownloadSection
