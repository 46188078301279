import styled, { css } from 'styled-components'
import { theme } from '@notino/react-styleguide'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'

// styles
import { RESERVATION_BOOKING_MODAL_MOBILE_BREAKPOINT } from '../../../styles/constants'
import { NotinoTitleMedium, NotinoTitleSmall, resetButtonStyles } from '../../../styles/helpers'

// utils
import { RESERVATION_BOOKING_MODAL_FIXED_HEADER_HEIGHT } from '../../../utils/enums'

export const LayoutWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	overflow: hidden;
	// crate new layer for fixed position of "FixedHeader" component
	transform: translateZ(0);
`

export const ScrollableContent = styled(OverlayScrollbarsComponent)`
	flex: 1;
	min-width: 0;
	min-height: 0;
	overflow: auto;
	position: relative;
	z-index: 1;
	transition: height 0.3s ease;
`

export const FixedHeader = styled.header<{ $isScrolled: boolean }>`
	width: 100%;
	height: ${RESERVATION_BOOKING_MODAL_FIXED_HEADER_HEIGHT}px;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 3;
	transition: all ease 0.3s;
	border-bottom: 1px solid transparent;
	box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0);

	${({ $isScrolled }) =>
		$isScrolled
			? css`
					background-color: ${theme.color.background.primary};
					color: ${theme.color.text.primary};
					border-color: rgba(5, 5, 5, 0.06);
					box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.05);
				`
			: css`
					background-color: transparent;
					color: ${theme.color.text.inverse};
				`};
`

export const FixedHeaderContent = styled.div`
	position: relative;
	padding: 0 50px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`

export const CloseButton = styled.button`
	${resetButtonStyles};
	position: absolute;
	right: 20px;
	top: 50%;
	transform: translateY(-50%);
`

export const BackButton = styled.button`
	${resetButtonStyles};
	position: absolute;
	left: 20px;
	top: 50%;
	transform: translateY(-50%);
`

export const LayoutHeader = styled.div<{ $backgroundImage?: string }>`
	${({ $backgroundImage }) => css`
		color: ${theme.color.text.inverse};
		width: 100%;
		height: 230px;
		background-color: #9f9f9f; // NOTE: no such color in the notino pallet for background
		background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%), url('${$backgroundImage || ''}');
		background-size: cover;
		background-position: center;
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		padding: 20px;
	`}
`

export const HeaderTitle = styled.div`
	${NotinoTitleSmall};

	@media (min-width: ${RESERVATION_BOOKING_MODAL_MOBILE_BREAKPOINT}) {
		${NotinoTitleMedium};
	}
`

export const HeaderContent = styled.div``

export const LayoutContent = styled.div`
	z-index: 1;
	padding: 20px;

	@media (min-width: ${RESERVATION_BOOKING_MODAL_MOBILE_BREAKPOINT}) {
		padding: 24px;
	}
`

export const LayoutFooter = styled.div`
	flex-shrink: 0;
	border-top: 1px solid rgba(5, 5, 5, 0.06);
	box-shadow: 0px -4px 16px 0px rgba(0, 0, 0, 0.08);
	position: relative;
	z-index: 2;
	padding: 24px;
`
