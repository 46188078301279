import React from 'react'
import RcTooltip from 'rc-tooltip'
import { TooltipProps } from 'rc-tooltip/lib/Tooltip'

type Props = {
	content: React.ReactNode
} & Omit<TooltipProps, 'placement' | 'overlay'>

const Tooltip = ({ content, children, ...restProps }: Props) => {
	return (
		<RcTooltip placement='top' trigger={['hover', 'click']} overlay={content} {...restProps}>
			{children}
		</RcTooltip>
	)
}

export default Tooltip
