/* eslint-disable global-require */
import React, { PropsWithChildren, useContext } from 'react'
import { useForm } from 'react-hook-form'
import { Button, ButtonModel } from '@notino/react-styleguide'

// hooks
import useMessages from '../../../hooks/useMessages'

// styles
import * as SC from './Step1ServiceParameterSelectionStyles'
import { RESERVATION_BOOKING_MODAL_MOBILE_BREAKPOINT } from '../../../styles/constants'

// components
import ReservationBookingModalLayout from '../ReservationBookingModalLayout/ReservationBookingModalLayout'
import RenderPrice from '../../RenderPrice/RenderPrice'
import HeaderContent from '../HeaderContent/HeaderContent'
import RadioButtonsFormField from '../../../formFields/RadioButtonsFormField/RadioButtonsFormField'
import HookFormField from '../../../formFields/HookFormField'

// types
import {
	ReservationBookingSelectedFormValues,
	ReservationBookingServiceParameterSelectionForm,
	SalonServiceResponse,
	ServiceCategoryParameterData
} from '../../../types/types'
import { RadioButtonOption } from '../../../formFields/RadioButtonsFormField/types'

// utils
import { FORM, RESERVATION_BOOKING_MODAL_STEP } from '../../../utils/enums'
import { getModalSubtypeSelectedEvent } from '../../../utils/dataLayerEvents'
import { pushToDataLayer } from '../../../utils/dataLayer'
import { AppContext } from '../../../utils/appProvider'

type Props = PropsWithChildren<{
	selectedFormValues: ReservationBookingSelectedFormValues
	headerImage: string | undefined
	selectedIndustryID: string | undefined
	selectedServiceData: SalonServiceResponse['service']
	serviceCategoryParameterData: ServiceCategoryParameterData
	onClose: () => void
	onBackButtonClick?: () => void
	handleSubmitParameterSelectionFrom: (values: ReservationBookingServiceParameterSelectionForm) => void
}>

const Step1ServiceParameterSelection = (props: Props) => {
	const {
		onClose,
		onBackButtonClick,
		selectedFormValues,
		selectedIndustryID,
		serviceCategoryParameterData,
		headerImage,
		selectedServiceData,
		handleSubmitParameterSelectionFrom
	} = props
	const { messages } = useMessages()
	const { assetsPath } = useContext(AppContext)

	const { control, handleSubmit, watch } = useForm<ReservationBookingServiceParameterSelectionForm>({
		defaultValues: {
			serviceCategoryParameterValueID: selectedFormValues[RESERVATION_BOOKING_MODAL_STEP.SERVICE_PARAMETER_SELECTION].serviceCategoryParameterValueID
		},
		values: {
			serviceCategoryParameterValueID: selectedFormValues[RESERVATION_BOOKING_MODAL_STEP.SERVICE_PARAMETER_SELECTION].serviceCategoryParameterValueID
		}
	})

	const serviceCategoryParameterValueIDValue = watch('serviceCategoryParameterValueID')
	const selectedServiceCategoryParameterValue = serviceCategoryParameterData?.values.find((value) => value.id === serviceCategoryParameterValueIDValue)

	// TODO: skontrolovat dlhsi text, ci nerozbije layout
	const serviceCategoryParameterOptions: RadioButtonOption[] =
		serviceCategoryParameterData?.values.map((value) => ({
			id: value.id,
			label: value.value || value.id,
			description: <RenderPrice from={value.priceAndDurationData.priceFrom} to={value.priceAndDurationData.priceTo} />
		})) || []

	// NOTE: Button from Notino style-guide library doesn't recognize "form" as a prop, however, when passed to the component, it works fine
	const unknownButtonProps = {
		form: FORM.RESERVATION_BOOKING_MODAL_SERVICE_PARAMETER_SELECTION
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} as any

	// NOTE: this screen is visible only for czech version for now
	const bannerDesktop = `${assetsPath}/${require(`../../../assets/images/reservation-modal/parameter-selection-banner-desktop-cs.jpg`)}`
	const bannerMobile = `${assetsPath}/${require(`../../../assets/images/reservation-modal/parameter-selection-banner-mobile-cs.jpg`)}`
	const promoBannerLink = ''

	const picture = (
		<SC.Picture>
			<source srcSet={bannerDesktop} media={`(min-width: ${RESERVATION_BOOKING_MODAL_MOBILE_BREAKPOINT})`} />
			<source srcSet={bannerMobile} />
			<img src={bannerDesktop} alt={messages['app-promo-banner-alt-text']} />
		</SC.Picture>
	)

	return (
		<ReservationBookingModalLayout
			title={serviceCategoryParameterData?.name ?? messages['Service parameter']}
			headerImage={headerImage}
			onBackButtonClick={onBackButtonClick}
			onClose={onClose}
			headerContent={<HeaderContent title={selectedServiceData.category.child.child?.name ?? ''} />}
			footerContent={
				<Button
					{...unknownButtonProps}
					type={'submit'}
					buttonStyle={ButtonModel.Styles.primary}
					fullWidth
					disabled={!serviceCategoryParameterValueIDValue}
					onClick={() => {
						if (serviceCategoryParameterValueIDValue) {
							const event = getModalSubtypeSelectedEvent({
								category: selectedIndustryID,
								subcategory: selectedServiceData.category.child?.id,
								type: selectedServiceData.category.child.child?.id,
								subtype: selectedServiceCategoryParameterValue?.categoryParameterValueID
							})
							pushToDataLayer(event)
						}
					}}
				>
					{messages.Continue}
				</Button>
			}
		>
			<SC.Form id={FORM.RESERVATION_BOOKING_MODAL_SERVICE_PARAMETER_SELECTION} onSubmit={handleSubmit(handleSubmitParameterSelectionFrom)}>
				<HookFormField
					control={control}
					name={'serviceCategoryParameterValueID'}
					component={RadioButtonsFormField}
					options={serviceCategoryParameterOptions}
					inputStyle={'bordered'}
				/>
			</SC.Form>
			{promoBannerLink ? (
				<SC.PromoBannerLink href={promoBannerLink} target={'blank'} rel={'noopener noreferrer'}>
					{picture}
				</SC.PromoBannerLink>
			) : (
				picture
			)}
		</ReservationBookingModalLayout>
	)
}

export default Step1ServiceParameterSelection
