import styled from 'styled-components'
import { Heading, theme } from '@notino/react-styleguide'

// components
import DefaultContainer from '../../components/DefaultContainer/DefaultContainer'
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'

// styles
import { SALONS_PAGE_MOBILE_BREAKPOINT } from '../../styles/constants'

export const Container = styled(DefaultContainer)``

export const SalonsListPageWrapper = styled.div`
	display: flex;
	gap: 32px;
`

export const SalonsPageBreadcrumbs = styled(Breadcrumb)`
	margin-bottom: 24px;
`

export const Grid = styled.div`
	flex: 1;
	min-width: 0; // important so the Grid does not stretch when children overflow
`

export const SalonsSearchWrapper = styled.div`
	width: 100%;
	max-width: 100%;
	height: 44px;

	@media (min-width: ${SALONS_PAGE_MOBILE_BREAKPOINT}) {
		max-width: 382px;
	}
`

export const MainTitle = styled(Heading.H1)`
	font-weight: 500;
	margin-top: 0;
	margin-bottom: 20px;
	text-align: left !important; // override for notino global styles
`

export const TopLevelCategoriesSliderWrapper = styled.div`
	margin-top: 16px;

	@media (min-width: ${SALONS_PAGE_MOBILE_BREAKPOINT}) {
		margin-top: 40px;
	}
`

export const TopLevelCategoryTitle = styled.div`
	text-align: center;
	font-size: 12px;
	font-weight: 400;
	line-height: 20px;
	color: ${theme.color.text.primary} !important; // fix color for iphone

	/* line clamp */
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
`

export const TopLevelCategoryButton = styled.a`
	text-decoration: none;
	display: block;
`

export const TopLevelCategoryImgWrapper = styled.div`
	width: 80px;
	height: 80px;
	border-radius: 999px;
	position: relative;
	overflow: hidden;
	margin-bottom: 10px;
	margin-left: auto;
	margin-right: auto;
`

export const TopLevelCategoryImg = styled.img`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
`
