import React, { useState } from 'react'
import Collapse from 'rc-collapse'
import { IconRegularChevronDown, IconRegularChevronUp, SemanticColorKey } from '@notino/react-styleguide'
import dayjs from 'dayjs'
import useMessages from '../../../../hooks/useMessages'

// types
import { OpeningHoursData } from '../../../../types/types'

// assets
import ClockIcon from '../../../../assets/icons/ClockIcon'

// utils
import { DAYS, OPEN_HOURS_STATE } from '../../../../utils/enums'

// styles
import * as SC from './OpeningHoursStyles'

const COLLAPSE_KEY = 'opening-hours'

type Props = {
	openingHours: NonNullable<OpeningHoursData>
	darkBackground?: boolean
	openingStatusMarginLeft?: number
}

const OpeningHours = (props: Props) => {
	const { openingHours, darkBackground, openingStatusMarginLeft } = props
	const [isExpanded, setIsExpanded] = useState<boolean>(false)
	const actualDay = dayjs().day()
	const chevronIconColor: SemanticColorKey = darkBackground ? 'icon.disabled' : 'icon.secondary'

	const { messages } = useMessages()

	const returnDayLocale = (dayEnum: string) => {
		switch (dayEnum) {
			case DAYS.MONDAY:
				return messages.Monday
			case DAYS.TUESDAY:
				return messages.Tuesday
			case DAYS.WEDNESDAY:
				return messages.Wednesday
			case DAYS.THURSDAY:
				return messages.Thursday
			case DAYS.FRIDAY:
				return messages.Friday
			case DAYS.SATURDAY:
				return messages.Saturday
			case DAYS.SUNDAY:
				return messages.Sunday
			default:
				return ''
		}
	}

	return (
		<SC.OpeningHoursContainer>
			<Collapse
				onChange={(key) => {
					if (Array.isArray(key)) {
						setIsExpanded(key.includes(COLLAPSE_KEY))
					} else {
						setIsExpanded(key === COLLAPSE_KEY)
					}
				}}
				destroyInactivePanel
				accordion
				items={[
					{
						id: 'salon-detail-contacts-opening-hours',
						key: COLLAPSE_KEY,
						label: (
							<SC.HeaderContainerOpeningHours>
								<ClockIcon /> <SC.HeaderTitleOpeningHours>{messages['Opening hours']}</SC.HeaderTitleOpeningHours>
							</SC.HeaderContainerOpeningHours>
						),
						extra: isExpanded ? <IconRegularChevronUp color={chevronIconColor} /> : <IconRegularChevronDown color={chevronIconColor} />,
						showArrow: false,
						children: (
							<SC.OpeningHours>
								{openingHours.openingHours.map((openHours, openHoursIndex) => (
									<SC.OpeningHoursItem key={openHours.day}>
										<SC.OpeningHoursItemValue
											$isClosed={openHours?.timeRanges?.length <= 0 && openHours.state !== OPEN_HOURS_STATE.CUSTOM_ORDER}
											$isActualDay={
												actualDay === openHoursIndex + 1 &&
												(openHours?.timeRanges?.length > 0 || openHours.state === OPEN_HOURS_STATE.CUSTOM_ORDER)
											}
										>
											{returnDayLocale(openHours.day)}
										</SC.OpeningHoursItemValue>
										<SC.OpeningHoursItemValue
											$isClosed={openHours?.timeRanges?.length <= 0 && openHours.state !== OPEN_HOURS_STATE.CUSTOM_ORDER}
											$isActualDay={
												actualDay === openHoursIndex + 1 &&
												(openHours?.timeRanges?.length > 0 || openHours.state === OPEN_HOURS_STATE.CUSTOM_ORDER)
											}
										>
											{openHours.state === OPEN_HOURS_STATE.CUSTOM_ORDER && <div>{messages['On request']}</div>}
											{openHours.state !== OPEN_HOURS_STATE.CUSTOM_ORDER &&
												openHours?.timeRanges.map((timeRange, timeRangesIndex) => {
													return <div key={timeRangesIndex}>{`${timeRange.timeFrom} - ${timeRange.timeTo}`}</div>
												})}
											{openHours.state !== OPEN_HOURS_STATE.CUSTOM_ORDER && openHours?.timeRanges?.length <= 0 && messages.Close}
										</SC.OpeningHoursItemValue>
									</SC.OpeningHoursItem>
								))}
							</SC.OpeningHours>
						)
					}
				]}
			/>
			{!isExpanded ? (
				<SC.OpeningStatus $marginLeft={openingStatusMarginLeft}>
					<SC.OpeningStatusValue $isOpen={Boolean(openingHours.isOpen)}>{openingHours.isOpen ? messages.Open : messages.Close}</SC.OpeningStatusValue>
					{openingHours.message && ` - ${openingHours.message}`}
				</SC.OpeningStatus>
			) : null}
		</SC.OpeningHoursContainer>
	)
}

export default OpeningHours
