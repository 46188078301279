import styled, { keyframes } from 'styled-components'
import { Heading } from '@notino/react-styleguide'

// components
import DefaultContainer from '../../components/DefaultContainer/DefaultContainer'

export const Container = styled(DefaultContainer)`
	padding-top: 0;
`

const dotAnimation = keyframes`
  0% {
    opacity: 0;
  }
  33% {
    opacity: 1;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`
export const Dot = styled.span`
	display: inline-block;
	animation: ${dotAnimation} 2s infinite;
	font-weight: 500;

	&:nth-child(1) {
		animation-delay: 0s;
	}

	&:nth-child(2) {
		animation-delay: 0.2s;
	}

	&:nth-child(3) {
		animation-delay: 0.4s;
	}
`
export const Title = styled(Heading.H1)`
	font-weight: 500;
	margin-top: 0;
	text-align: center !important; // override for notino global styles
`
