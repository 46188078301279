import React from 'react'

const EmptyStateIcon = () => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='72' height='72' fill='none' viewBox='0 0 72 72'>
			<path
				fill='currentColor'
				d='m37.646 31.912-.377 11.519h-2.734l-.387-11.519zm-3.59 15.085q0-.73.502-1.208.513-.49 1.344-.49.843 0 1.345.49.501.48.501 1.208 0 .706-.501 1.196-.502.49-1.345.49-.831 0-1.344-.49a1.6 1.6 0 0 1-.501-1.196'
			/>
			<path
				stroke='currentColor'
				strokeMiterlimit='10'
				strokeWidth='1.765'
				d='M11.983 57.05c-1.841 0-2.966-2.116-2-3.758L34 12.351a2.297 2.297 0 0 1 4.008 0l24.017 40.942c.967 1.641-.167 3.758-2 3.758z'
			/>
		</svg>
	)
}

export default EmptyStateIcon
