import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Modal, ModalModel } from '@notino/react-styleguide'
import { useIntl } from 'react-intl'

// components
import dayjs from 'dayjs'
import Step0ServiceSelection from './Step0ServiceSelection/Step0ServiceSelection'
import Step1ServiceParameterSelection from './Step1ServiceParameterSelection/Step1ServiceParameterSelection'
import Step2EmployeeAndDateSelection from './Step2EmployeeAndDateSelection/Step2EmployeeAndDateSelection'
import Step3BookingConfirmation from './Step3BookingConfirmation/Step3BookingConfirmation'
import Step4Summary from './Step4Summary/Step4Summary'
import ReservationBookingModalSkeleton from './ReservationBookingModalSkeleton/ReservationBookingModalSkeleton'
import LoginScreen from '../../atoms/LoginScreen/LoginScreen'
import PromoBanner from './PromoBannerScreen/PromoBannerScreen'

// types
import {
	ConfigResponse,
	DocumentsResponse,
	PostCalendarEventsReservationResponse,
	ReservationBookingEmployeeAndDateSelectionSubmittedForm,
	ReservationBookingModalCloseType,
	ReservationBookingModalInitData,
	ReservationBookingSelectedFormValues,
	ReservationBookingServiceParameterSelectionForm,
	SalonResponse,
	SalonServiceResponse,
	SalonServicesResponse,
	ServiceCardCallbackData
} from '../../types/types'

// utils
import { DEFAULT_DATE_FORMAT, DOCUMENTS, RESERVATION_BOOKING_MODAL_STEP, RESERVATIONS_TIME_SLOTS, SCREEN_STATE_KEY } from '../../utils/enums'
import { SelectedIndustryData } from './reservationBookingModalHelpers'
import { AppContext } from '../../utils/appProvider'
import { findServiceCategoryById, getPhoneNumber, hasMultipleIndustriesSameService } from '../../utils/helper'
import { getModalBackButtonEvent, getModalClosedEvent, getReservationSelectServiceEvent } from '../../utils/dataLayerEvents'
import { pushToDataLayer } from '../../utils/dataLayer'

// hooks
import useAuthRedirect from '../../hooks/useAuthRedirect'

// styles
import * as SC from './ReservationBookingModalStyles'

type Props = {
	initData: ReservationBookingModalInitData
	configData: ConfigResponse
	salonData: {
		id: string
		availableReservationSystem: boolean
		images: NonNullable<SalonResponse['salon']>['images']
		phones: NonNullable<SalonResponse['salon']>['phones']
	}
	salonServicesData: SalonServicesResponse
	isOpen: boolean
	onClose: () => void
	usePersistedValue?: boolean
}

const getInitialStep = (data: ReservationBookingModalInitData) => {
	if (
		data.employeeAndDateSelectionData?.date &&
		data.employeeAndDateSelectionData?.timeSlot?.timeFrom &&
		data.employeeAndDateSelectionData?.timeSlot?.timeTo
	) {
		return RESERVATION_BOOKING_MODAL_STEP.BOOKING_CONFIRMATION
	}

	if (data.initServiceID) {
		return RESERVATION_BOOKING_MODAL_STEP.PROMO_BANNER
	}

	return RESERVATION_BOOKING_MODAL_STEP.SERVICE_SELECTION
}

type InitSelectedFormValuesArgs = {
	defaultPhonePrefixCountryCode: string
	serviceEmployees: SalonServiceResponse['service']['employees']
	initData: Pick<ReservationBookingModalInitData, 'parameterSelectionData' | 'bookingConfirmationData' | 'employeeAndDateSelectionData'> | null
}

const DEFAULT_SELECTED_FORM_VALUES = (args: InitSelectedFormValuesArgs): ReservationBookingSelectedFormValues => {
	const { defaultPhonePrefixCountryCode, serviceEmployees, initData } = args
	const { parameterSelectionData, employeeAndDateSelectionData, bookingConfirmationData } = initData || {}
	const { date, employeeID, timeSlot } = employeeAndDateSelectionData || {}

	const validInitDate = date && dayjs(date).isValid() ? dayjs(date) : dayjs()
	let validInitEmployeeID = null

	if (serviceEmployees.length === 1) {
		// if only one employee is assigned to the service, we will preselect him
		validInitEmployeeID = serviceEmployees[0].id
	}

	if (employeeID) {
		// check if employee still exists in the service and if not use currently assigned value
		validInitEmployeeID = serviceEmployees.find((employee) => employee.id === employeeID)?.id || validInitEmployeeID
	}

	let validTimeSlot: ReservationBookingEmployeeAndDateSelectionSubmittedForm['timeSlot'] = {
		timeFrom: '',
		timeTo: '',
		employeeID: '',
		dayPeriod: RESERVATIONS_TIME_SLOTS.MORNING
	}

	if (validInitDate && timeSlot) {
		// time slots data could be initialized only when there is a valid init date
		let validTimeSlotEmployee = ''

		if (serviceEmployees.length === 1) {
			// if only one employee is assigned to the service, we will preselect him
			validTimeSlotEmployee = serviceEmployees[0].id
		}

		// check if employee still exists in the service and if not use currently assigned value
		validTimeSlotEmployee = serviceEmployees.find((employee) => employee.id === timeSlot.employeeID)?.id || validTimeSlotEmployee

		validTimeSlot = {
			...validTimeSlot,
			timeFrom: timeSlot.timeFrom,
			timeTo: timeSlot.timeTo,
			employeeID: validTimeSlotEmployee
		}
	}

	return {
		[RESERVATION_BOOKING_MODAL_STEP.SERVICE_PARAMETER_SELECTION]: {
			serviceCategoryParameterValueID: parameterSelectionData?.serviceCategoryParameterValueID ?? ''
		},
		[RESERVATION_BOOKING_MODAL_STEP.EMPLOYEE_AND_DATE_SELECTION]: {
			employeeID: validInitEmployeeID,
			date: validInitDate.format(DEFAULT_DATE_FORMAT),
			timeSlot: validTimeSlot
		},
		[RESERVATION_BOOKING_MODAL_STEP.BOOKING_CONFIRMATION]: {
			firstName: bookingConfirmationData?.firstName ?? '',
			lastName: bookingConfirmationData?.lastName ?? '',
			email: bookingConfirmationData?.email ?? '',
			notAllowMarketing: bookingConfirmationData?.notAllowMarketing ?? false,
			phone: {
				phoneNumber: bookingConfirmationData?.phone?.phoneNumber ?? '',
				phonePrefixCountryCode: bookingConfirmationData?.phone?.phonePrefixCountryCode ?? defaultPhonePrefixCountryCode
			},
			agreement: bookingConfirmationData?.agreement ?? false,
			note: bookingConfirmationData?.note ?? '',
			isVisitor: bookingConfirmationData?.isVisitor ?? false,
			visitor: {
				firstName: bookingConfirmationData?.visitor?.firstName ?? '',
				lastName: bookingConfirmationData?.visitor?.lastName ?? ''
			}
		}
	}
}

const ReservationBookingModal = (props: Props) => {
	const { initData, isOpen, onClose, salonData, salonServicesData, configData } = props
	const { initIndustryID, initServiceID, initAvResTimeSlotDayPart } = initData

	const { locale } = useIntl()
	const { apiBrowser } = useContext(AppContext)

	const defaultPhonePrefixCountryCode =
		configData.rolloutCountries.find((country) => country.languageCode === locale)?.code ?? configData.allCountries[0].code

	const [initialLoading, setIsInitialLoading] = useState(false)
	const [isLoadingError, setIsLoadingError] = useState(false)
	const [currentStep, setCurrentStep] = useState<RESERVATION_BOOKING_MODAL_STEP | null>(null)

	const [selectedServiceData, setSelectedServiceData] = useState<SalonServiceResponse['service'] | null>(null)
	const [selectedIndustryData, setSelectedIndustryData] = useState<SelectedIndustryData | null>(null)
	const [selectedFormValues, setSelectedFormValues] = useState<ReservationBookingSelectedFormValues>(
		DEFAULT_SELECTED_FORM_VALUES({ defaultPhonePrefixCountryCode, serviceEmployees: selectedServiceData?.employees || [], initData })
	)
	const [submittedReservationData, setSubmittedReservationData] = useState<PostCalendarEventsReservationResponse['reservationCalendarEvent'] | null>(null)

	const [documents, setDocuments] = useState<DocumentsResponse['documents']>()
	const phoneNumber = getPhoneNumber(salonData.phones[0], configData.rolloutCountries)
	const selectedServiceCategoryParameterData = selectedServiceData?.serviceCategoryParameter
	const selectedServiceCategoryParameterValueData = selectedServiceCategoryParameterData
		? selectedServiceCategoryParameterData.values.find(
				(value) => selectedFormValues[RESERVATION_BOOKING_MODAL_STEP.SERVICE_PARAMETER_SELECTION].serviceCategoryParameterValueID === value.id
			)
		: undefined

	const hasServicesSelectionStep = !initServiceID
	const hasParameterSelectionStep = !!selectedServiceData?.serviceCategoryParameter
	const { images } = salonData

	// use salon image as fallback if selected industry is not known
	// this can happen when you open a custom service from the reservation detail page and the service is assigned to more then a one industry
	// we can't determine to which industry service belongs in this case (as opposite to when user goes with standard flow from salon detail page, where it is possible even for the custom service)
	const fallbackHeaderImage = (images.find((image) => image.isCover) || images[0])?.resizedImages.medium
	const headerImage = selectedIndustryData?.image || fallbackHeaderImage

	const isInitRef = useRef(true)

	const getSelectedServiceAndIndustryData = useCallback(
		async (serviceID: string, industryID: string | null, servicesData: SalonServicesResponse['groupedServicesByCategory']) => {
			const serviceData = await apiBrowser.b2c.getSalonServiceData(serviceID)
			const foundIndustryData = servicesData.find((industry) => industry.category?.id === industryID)?.category
			return {
				serviceData: serviceData?.service,
				industryData: foundIndustryData ? { industryID: foundIndustryData.id, image: foundIndustryData.image.resizedImages.medium } : null
			}
		},
		[apiBrowser]
	)

	useEffect(() => {
		;(async () => {
			if (isOpen) {
				if (isInitRef.current) {
					setCurrentStep(getInitialStep(initData))

					if (initServiceID) {
						// if we already know serviceID, the first service selection step is skipped
						// if there is no initIndustryID provided, we'll try to find one from the services list based on the initServiceID
						// industry data could be null in this case
						// this can happen when you open a custom service from the reservation detail page and the service is assigned to more then a one industry

						let industryId = initIndustryID

						if (!initIndustryID && !hasMultipleIndustriesSameService(salonServicesData.groupedServicesByCategory, initServiceID)) {
							const { industryData } = findServiceCategoryById(salonServicesData.groupedServicesByCategory, initServiceID)
							industryId = industryData?.id ?? null
						}

						const { serviceData, industryData } = await getSelectedServiceAndIndustryData(
							initServiceID,
							industryId,
							salonServicesData.groupedServicesByCategory
						)
						if (serviceData) {
							setSelectedServiceData(serviceData)
							setSelectedIndustryData(industryData)
							setSelectedFormValues(
								DEFAULT_SELECTED_FORM_VALUES({
									defaultPhonePrefixCountryCode,
									serviceEmployees: serviceData.employees,
									initData
								})
							)
						} else {
							setIsLoadingError(true)
						}
					} else {
						const foundIndustryData =
							salonServicesData.groupedServicesByCategory.find((industry) => industry.category?.id === initIndustryID)?.category ||
							salonServicesData.groupedServicesByCategory[0].category
						const industryData: SelectedIndustryData | null = foundIndustryData
							? { industryID: foundIndustryData.id, image: foundIndustryData.image.resizedImages.medium }
							: null
						// it is necessary that selected industry data exists for the first service selection step
						if (foundIndustryData) {
							setSelectedIndustryData(industryData)
						} else {
							setIsLoadingError(true)
						}
					}
					const documentsData = await apiBrowser.b2c.getDocuments({ assetTypes: [DOCUMENTS.B2C_RESERVATIONS_TERMS, DOCUMENTS.B2C_PRIVACY_POLICY] })
					setDocuments(documentsData?.documents)
				}
				isInitRef.current = false
				setIsInitialLoading(false)
			} else {
				isInitRef.current = true
				setIsInitialLoading(true)
			}
		})()
	}, [
		apiBrowser,
		isOpen,
		initServiceID,
		initIndustryID,
		initData,
		salonServicesData.groupedServicesByCategory,
		defaultPhonePrefixCountryCode,
		getSelectedServiceAndIndustryData
	])

	const onServiceCardButtonClick = async (data: ServiceCardCallbackData) => {
		setIsInitialLoading(true)
		const { serviceData, industryData } = await getSelectedServiceAndIndustryData(
			data.serviceID,
			data.industryID,
			salonServicesData.groupedServicesByCategory
		)

		const event = getReservationSelectServiceEvent({
			event: 'reservation_service',
			category: data.industryID,
			subcategory: data.gaSpecificData.secondLevelCategoryID,
			type: data.gaSpecificData.serviceCategoryID
		})
		pushToDataLayer(event)

		if (!serviceData) {
			setIsInitialLoading(false)
			setIsLoadingError(true)
			return
		}

		if (!selectedServiceData) {
			// set default form values if it is the first service selection
			setSelectedFormValues(
				DEFAULT_SELECTED_FORM_VALUES({
					defaultPhonePrefixCountryCode,
					serviceEmployees: serviceData.employees,
					initData
				})
			)
		} else if (selectedServiceData && serviceData.id !== selectedServiceData?.id) {
			// reset form values in case user selected a different service
			setSelectedFormValues(
				DEFAULT_SELECTED_FORM_VALUES({
					defaultPhonePrefixCountryCode,
					serviceEmployees: serviceData.employees,
					initData: {
						...initData,
						employeeAndDateSelectionData: null,
						parameterSelectionData: null
					}
				})
			)
		}

		setSelectedServiceData(serviceData)
		setSelectedIndustryData(industryData)
		setCurrentStep(RESERVATION_BOOKING_MODAL_STEP.PROMO_BANNER)
		setIsInitialLoading(false)
	}

	const onPromoBannerScreenContinue = () => {
		setCurrentStep(
			selectedServiceCategoryParameterData
				? RESERVATION_BOOKING_MODAL_STEP.SERVICE_PARAMETER_SELECTION
				: RESERVATION_BOOKING_MODAL_STEP.EMPLOYEE_AND_DATE_SELECTION
		)
	}

	const handleSubmitParameterSelectionFrom = (values: ReservationBookingServiceParameterSelectionForm) => {
		setSelectedFormValues((prev) => ({ ...prev, [RESERVATION_BOOKING_MODAL_STEP.SERVICE_PARAMETER_SELECTION]: { ...values } }))
		setCurrentStep(RESERVATION_BOOKING_MODAL_STEP.EMPLOYEE_AND_DATE_SELECTION)
	}

	const handleSubmitEmployeeAndDateSelectionForm = (values: ReservationBookingEmployeeAndDateSelectionSubmittedForm) => {
		setSelectedFormValues((prev) => ({ ...prev, [RESERVATION_BOOKING_MODAL_STEP.EMPLOYEE_AND_DATE_SELECTION]: { ...values } }))
		setCurrentStep(RESERVATION_BOOKING_MODAL_STEP.BOOKING_CONFIRMATION)
	}

	const onSubmitBookingConfirmationForm = (submittedData?: PostCalendarEventsReservationResponse['reservationCalendarEvent']) => {
		setSubmittedReservationData(submittedData ?? null)
		setCurrentStep(RESERVATION_BOOKING_MODAL_STEP.SUMMARY)
	}

	const getDataToPersist = (): ReservationBookingModalInitData => {
		return {
			initIndustryID: selectedIndustryData?.industryID ?? null,
			initServiceID: selectedServiceData?.id ?? null,
			initAvResTimeSlotDayPart,
			initServiceCategoryParameterID: selectedServiceCategoryParameterData?.id ?? null,
			parameterSelectionData: selectedFormValues[RESERVATION_BOOKING_MODAL_STEP.SERVICE_PARAMETER_SELECTION],
			employeeAndDateSelectionData: selectedFormValues[RESERVATION_BOOKING_MODAL_STEP.EMPLOYEE_AND_DATE_SELECTION],
			bookingConfirmationData: selectedFormValues[RESERVATION_BOOKING_MODAL_STEP.BOOKING_CONFIRMATION]
		}
	}

	const { onLogin, onRegister, clearSnapshot } = useAuthRedirect(SCREEN_STATE_KEY.RESERVATION_SUMMARY_MODAL_SALON_CATEGORY_DETAIL, getDataToPersist())

	const handleCloseReservationBookingModal = (closeType: ReservationBookingModalCloseType | null = 'button_x', gaLabel?: string) => {
		if (currentStep && closeType) {
			const event = getModalClosedEvent({
				reservationStep: currentStep,
				interaction: closeType,
				elementType: gaLabel
			})
			pushToDataLayer(event)
		}

		onClose()
		setSelectedFormValues(DEFAULT_SELECTED_FORM_VALUES({ defaultPhonePrefixCountryCode, serviceEmployees: selectedServiceData?.employees || [], initData }))
		setSelectedServiceData(null)
		setSelectedIndustryData(null)
		setCurrentStep(null)
		setSubmittedReservationData(null)

		clearSnapshot()
	}

	const onRedirectToHomepageButtonClick = () => {
		if (currentStep) {
			const event = getModalClosedEvent({
				reservationStep: currentStep,
				interaction: 'button_close'
			})
			pushToDataLayer(event)
		}

		clearSnapshot()
	}

	const onBackButtonClick = () => {
		if (currentStep) {
			const event = getModalBackButtonEvent({ reservationStep: currentStep })
			pushToDataLayer(event)
		}

		if (currentStep === RESERVATION_BOOKING_MODAL_STEP.SUMMARY) {
			setCurrentStep(RESERVATION_BOOKING_MODAL_STEP.BOOKING_CONFIRMATION)
			return
		}

		if (currentStep === RESERVATION_BOOKING_MODAL_STEP.BOOKING_CONFIRMATION) {
			setCurrentStep(RESERVATION_BOOKING_MODAL_STEP.EMPLOYEE_AND_DATE_SELECTION)
			return
		}

		if (currentStep === RESERVATION_BOOKING_MODAL_STEP.EMPLOYEE_AND_DATE_SELECTION) {
			if (hasParameterSelectionStep) {
				setCurrentStep(RESERVATION_BOOKING_MODAL_STEP.SERVICE_PARAMETER_SELECTION)
				return
			}

			if (hasServicesSelectionStep) {
				setCurrentStep(RESERVATION_BOOKING_MODAL_STEP.SERVICE_SELECTION)
				return
			}
		}

		if (currentStep === RESERVATION_BOOKING_MODAL_STEP.SERVICE_PARAMETER_SELECTION) {
			if (hasServicesSelectionStep) {
				setCurrentStep(RESERVATION_BOOKING_MODAL_STEP.SERVICE_SELECTION)
				return
			}
		}
		// Fallback: do tohto stavu by sa to aplikacne nemalo dostat
		setCurrentStep(RESERVATION_BOOKING_MODAL_STEP.SERVICE_SELECTION)
	}

	return (
		<SC.ModalWrapper $isSummaryStep={currentStep === RESERVATION_BOOKING_MODAL_STEP.SUMMARY}>
			<Modal
				show={isOpen}
				fullscreenOnMobile
				onClose={onClose}
				breakpoint={ModalModel.Breakpoints.md}
				size={ModalModel.Sizes.s600}
				disableOnBlurClose
				noBorders
			>
				<SC.Content>
					{(() => {
						if (initialLoading) {
							return <ReservationBookingModalSkeleton onClose={handleCloseReservationBookingModal} />
						}

						if (isLoadingError) {
							return <SC.StyledError />
						}

						if (currentStep === RESERVATION_BOOKING_MODAL_STEP.SERVICE_SELECTION && selectedIndustryData) {
							return (
								<Step0ServiceSelection
									selectedIndustryData={selectedIndustryData}
									salonServicesData={salonServicesData}
									fallbackHeaderImage={fallbackHeaderImage}
									phoneNumber={phoneNumber}
									onClose={handleCloseReservationBookingModal}
									onServiceCardButtonClick={onServiceCardButtonClick}
								/>
							)
						}

						return (
							<LoginScreen
								onContinueAsGuest={clearSnapshot}
								onLogin={onLogin}
								onRegister={onRegister}
								onClose={() => handleCloseReservationBookingModal('button_x', 'sso')} // @see https://goodrequest.atlassian.net/browse/NOT-11439?focusedCommentId=104125
							>
								{currentStep === RESERVATION_BOOKING_MODAL_STEP.PROMO_BANNER && <PromoBanner onContinue={onPromoBannerScreenContinue} />}

								{currentStep === RESERVATION_BOOKING_MODAL_STEP.SERVICE_PARAMETER_SELECTION &&
									selectedServiceCategoryParameterData &&
									selectedServiceData && (
										<Step1ServiceParameterSelection
											selectedFormValues={selectedFormValues}
											selectedIndustryID={selectedIndustryData?.industryID}
											selectedServiceData={selectedServiceData}
											serviceCategoryParameterData={selectedServiceCategoryParameterData}
											headerImage={headerImage}
											onClose={handleCloseReservationBookingModal}
											onBackButtonClick={hasServicesSelectionStep ? onBackButtonClick : undefined}
											handleSubmitParameterSelectionFrom={handleSubmitParameterSelectionFrom}
										/>
									)}
								{currentStep === RESERVATION_BOOKING_MODAL_STEP.EMPLOYEE_AND_DATE_SELECTION && selectedServiceData && (
									<Step2EmployeeAndDateSelection
										salonID={salonData.id}
										selectedFormValues={selectedFormValues}
										selectedIndustryID={selectedIndustryData?.industryID}
										selectedServiceData={selectedServiceData}
										selectedServiceCategoryParameterValueData={selectedServiceCategoryParameterValueData}
										headerImage={headerImage}
										onClose={handleCloseReservationBookingModal}
										onBackButtonClick={hasParameterSelectionStep || hasServicesSelectionStep ? onBackButtonClick : undefined}
										handleSubmitEmployeeAndDateSelectionForm={handleSubmitEmployeeAndDateSelectionForm}
										initAvResTimeSlotDayPart={initAvResTimeSlotDayPart}
									/>
								)}
								{currentStep === RESERVATION_BOOKING_MODAL_STEP.BOOKING_CONFIRMATION && selectedServiceData && (
									<Step3BookingConfirmation
										salonID={salonData.id}
										configData={configData}
										documentsData={documents}
										selectedFormValues={selectedFormValues}
										selectedIndustryID={selectedIndustryData?.industryID}
										selectedServiceData={selectedServiceData}
										selectedServiceCategoryParameterValueData={selectedServiceCategoryParameterValueData}
										headerImage={headerImage}
										onClose={handleCloseReservationBookingModal}
										onBackButtonClick={onBackButtonClick}
										onSubmitBookingConfirmationForm={onSubmitBookingConfirmationForm}
										onLogin={onLogin}
										onRegister={onRegister}
									/>
								)}
								{currentStep === RESERVATION_BOOKING_MODAL_STEP.SUMMARY && submittedReservationData && selectedServiceData && (
									<Step4Summary
										onClose={handleCloseReservationBookingModal}
										onRedirectToHomepage={onRedirectToHomepageButtonClick}
										submittedReservationData={submittedReservationData}
										selectedServiceData={selectedServiceData}
									/>
								)}
							</LoginScreen>
						)
					})()}
				</SC.Content>
			</Modal>
		</SC.ModalWrapper>
	)
}

export default ReservationBookingModal
