import React from 'react'

// types
import { FormItemProps } from './types'

// components
import InputLabel from '../InputLabel/InputLabel'
import InputError from '../InputError/InputError'

// styles
import * as SC from './FormItemStyles'

const FormItem = (props: FormItemProps) => {
	const { children, required, htmlFor, label, error } = props

	return (
		<SC.FormFieldWrapper>
			{label && (
				<InputLabel required={required} htmlFor={htmlFor}>
					{label}
				</InputLabel>
			)}
			{children}
			{error && <InputError htmlFor={htmlFor}>{error}</InputError>}
		</SC.FormFieldWrapper>
	)
}

export default FormItem
