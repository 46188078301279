import styled from 'styled-components'
import { theme } from '@notino/react-styleguide'
import { NotinoLabelSmall, NotinoTitleSmall, multiLineClamp } from '../../../styles/helpers'

export const HeaderContentTitle = styled.div`
	${NotinoTitleSmall};
	color: ${theme.color.text.inverse};
	${multiLineClamp(2)};
`

export const HeaderContentDescription = styled.div`
	${NotinoLabelSmall};
	color: #e5e5e5; // NOTE: no such color in the notino pallet for text
	margin-top: 4px;
	${multiLineClamp(2)};
`
