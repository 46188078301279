import styled from 'styled-components'
import { theme } from '@notino/react-styleguide'
import { NotinoBodyMedium, NotinoLabelRegular400 } from '../../styles/helpers'

export const CheckGroup = styled.div<{ $alignment?: 'horizontal' | 'vertical'; $gap: string }>`
	display: flex;
	gap: ${({ $gap }) => $gap};
	flex-direction: ${({ $alignment }) => ($alignment === 'vertical' ? 'column' : 'row')};
	flex-wrap: wrap;
`

export const CheckWrapper = styled.div<{ $checked?: boolean }>`
	border: ${({ $checked }) => ($checked ? `1px solid ${theme.color.common.magenta50}` : `1px solid ${theme.color.border.divider}`)};
	background: ${({ $checked }) => ($checked ? theme.color.background.highlight : theme.color.background.primary)};
	padding: 8px 20px;
	position: relative;
	border-radius: 999px;
	display: flex;
	cursor: pointer;
	align-items: center;
	justify-content: center;
	${NotinoLabelRegular400};
`

export const Label = styled.label<{ $checked?: boolean }>`
	${NotinoBodyMedium};
	display: block;
	color: ${({ $checked }) => ($checked ? theme.color.text.inverse : theme.color.text.primary)};
	outline: 2px solid transparent;
`

export const Checkbox = styled.input`
	position: absolute;
	opacity: 0;
	cursor: pointer;

	&:focus-visible ~ ${Label} {
		outline-color: ${theme.color.common.black};
	}
`

export const CheckboxWrapper = styled.div`
	position: relative;
`
