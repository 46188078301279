import { css, styled, theme } from '@notino/react-styleguide'
import { Rating } from '@smastrom/react-rating'
import { NotinoLabelRegular } from '../../styles/helpers'
import '@smastrom/react-rating/style.css'

export const RatingWrapper = styled.div`
	display: flex;
	gap: 4px;
	align-items: center;
`

export const Rate = styled(Rating)<{ $width: string; $filledColor: string; $backgroundColor: string }>`
	${({ $width, $filledColor, $backgroundColor }) => css`
		width: ${$width};

		&.rr--focus-reset {
			outline: 2px solid ${theme.color.text.primary};
		}

		.rr--box {
			transition: color 0.3s ease;

			&.rr--on {
				color: ${$filledColor};
			}

			&.rr--off {
				color: ${$backgroundColor};
			}

			&:focus-visible {
				svg {
					outline: 2px solid ${theme.color.text.primary};
				}
			}
		}
	`}
`

export const RatingLabel = styled.span`
	${NotinoLabelRegular};
`
