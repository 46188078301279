import React from 'react'

// types
import { DateTimeSlotPickerFormFieldProps } from './types'
import { TimeSlotValueType } from '../../atoms/DateTimeSlotPicker/types'

// styles
import DateTimeSlotPicker from '../../atoms/DateTimeSlotPicker/DateTimeSlotPicker'

const DateTimeSlotPickerFormField = <TimeSlotValue extends string = TimeSlotValueType>(props: DateTimeSlotPickerFormFieldProps<TimeSlotValue>) => {
	const {
		input: { value, onChange },
		...restProps
	} = props
	return <DateTimeSlotPicker value={value} onChange={onChange} {...restProps} />
}

export default DateTimeSlotPickerFormField
