import React, { PropsWithChildren, useEffect, useRef, useState } from 'react'

// hooks
import useScrolledElement from '../../hooks/useScrolledElement'

// utils
import { isElementOverflownX } from '../../utils/helper'

// styles
import * as SC from './ElementsSliderStyles'

type Props = PropsWithChildren<{
	initScrolledElementID?: string
	initScrollBehavior?: ScrollBehavior
	fading?: boolean
	gap?: string
	overflowX?: 'auto' | 'scroll'
}>

const ElementsSlider = (props: Props) => {
	const { children, initScrolledElementID, fading = true, initScrollBehavior = 'instant', gap = '0px', overflowX = 'auto' } = props

	const [isOverflown, setIsOverflown] = useState(false)
	const scrollElementRef = useRef<HTMLDivElement>(null)
	const initElementScrolledRef = useRef(false)

	const { isScrolledLeft, isScrolledRight } = useScrolledElement(fading ? scrollElementRef.current : null)

	useEffect(() => {
		if (!fading) {
			return undefined
		}

		const checkOverflow = () => {
			if (scrollElementRef.current) {
				setIsOverflown(isElementOverflownX(scrollElementRef.current))
			}
		}

		checkOverflow()
		window.addEventListener('resize', checkOverflow)

		return () => {
			window.removeEventListener('resize', checkOverflow)
		}
	}, [fading])

	useEffect(() => {
		const container = scrollElementRef.current

		if (container && initScrolledElementID && !initElementScrolledRef.current) {
			const targetElement = document.getElementById(initScrolledElementID)

			if (targetElement) {
				const containerRect = container.getBoundingClientRect()
				const targetElementRect = targetElement.getBoundingClientRect()

				// Calculate the scroll position
				const targetElementLeft = targetElementRect.left - containerRect.left
				const targetElementRight = targetElementRect.right - containerRect.left
				const containerWidth = containerRect.width

				// Determine if the targetElement is out of view
				if (targetElementLeft < 0 || targetElementRight > containerWidth) {
					// Calculate the new scroll position to center the targetElement
					const newScrollLeft = targetElement.offsetLeft - container.clientWidth / 2 + targetElement.clientWidth / 2

					// Ensure the scroll position is within bounds
					const maxScrollLeft = container.scrollWidth - container.clientWidth
					const boundedScrollLeft = Math.min(Math.max(newScrollLeft, 0), maxScrollLeft)

					// Scroll the container
					container.scrollTo({
						left: boundedScrollLeft,
						behavior: initScrollBehavior
					})

					initElementScrolledRef.current = true
				}
			}
		}
	}, [initScrolledElementID, initScrollBehavior])

	return (
		<SC.ElementsSliderWrapper>
			{fading && isOverflown && isScrolledLeft && <SC.FadeLeft />}
			<SC.ElementsSlider ref={scrollElementRef} $overflowX={overflowX}>
				<SC.ElementsSliderContent $gap={gap}>{children}</SC.ElementsSliderContent>
			</SC.ElementsSlider>
			{fading && isOverflown && isScrolledRight && <SC.FadeRight />}
		</SC.ElementsSliderWrapper>
	)
}

export default ElementsSlider
