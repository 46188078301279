import { Spinner as NotinoSpinner, theme } from '@notino/react-styleguide'
import styled, { css } from 'styled-components'

// components
import Empty from '../../atoms/Empty/Empty'
import ErrorScreen from '../../atoms/ErrorScreen/ErrorScreen'

export const FetchResultWrapper = styled.div``

export const StateWrapper = styled.div<{ $padding?: string; $maxWidth?: string }>`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: 400px;
	color: ${theme.color.text.primary};
	gap: 16px;
	max-width: ${({ $maxWidth }) => $maxWidth ?? '100%'};
`

export const EmptyIconWrapper = styled.div`
	display: inline-block;
	width: 72px;
	height: 72px;
	color: ${theme.color.text.disabled};

	svg {
		width: 100%;
		height: 100%;
	}
`

export const ErrorIconWrapper = styled.div`
	display: inline-block;
	width: 72px;
	height: 72px;
	color: ${theme.color.text.primary};

	svg {
		width: 100%;
		height: 100%;
	}
`

export const StateLabel = styled.p`
	text-align: center;
	max-width: 280px;
`

export const StyledEmpty = styled(Empty)<{ $padding?: string }>`
	padding: 16px;
`

export const StyledError = styled(ErrorScreen)<{ $padding?: string }>`
	padding: 16px;
`

export const SpinWrapper = styled.div`
	position: relative;
`

export const SpinnerWrapper = styled.div`
	position: absolute;
	top: 0;
	inset-inline-start: 0;
	z-index: 4;
	width: 100%;
	height: 100%;
	max-height: 400px;
	min-height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
`

export const SpinContent = styled.div<{ $isRefetching?: boolean }>`
	position: relative;
	z-index: 3;

	${({ $isRefetching }) =>
		$isRefetching &&
		css`
			user-select: none;
			&::after {
				position: absolute;
				top: 0;
				inset-inline-end: 0;
				bottom: 0;
				inset-inline-start: 0;
				z-index: 10;
				width: 100%;
				height: 100%;
				background: ${theme.color.background.primary};
				opacity: 0.5;
				transition: all 0.3s;
				content: '';
			}
		`}
`

export const Spinner = styled(NotinoSpinner)``
