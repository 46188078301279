/* eslint-disable global-require */
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { FullWidthBannersComponent, Modal } from '@notino/react-styleguide'

// types
import { CmsBannersData, SalonPageBannersSlide } from '../../../../types/types'

// styles
import * as SC from './SalonsPageBannerStyles'

// utils
import { SALONS_PAGE_BANNER_SETTINGS } from '../../../../utils/enums'
import { AppContext } from '../../../../utils/appProvider'

type SalonsPageBannerProps = {
	cmsData: CmsBannersData | undefined
}

type InfoBannerData = {
	text: string
}

type GetSalonsPageBannersArgs = {
	bannersData: CmsBannersData | undefined
	assetsPath: string
	locale: string
	onInfoBannerClick: (data: InfoBannerData) => void
}

export const getSalonsPageBannerSlides = (args: GetSalonsPageBannersArgs) => {
	const { bannersData, assetsPath, locale, onInfoBannerClick } = args

	let slides: SalonPageBannersSlide[] = []
	if (!bannersData || bannersData.banners.length === 0) {
		const backgroundImage = `${assetsPath}/${require('../../../../assets/images/salons-view/top-banner-background.jpg')}`
		let desktopImage: string
		let mobileImage: string
		let urlLink: string

		switch (locale) {
			case 'ro':
				desktopImage = `${assetsPath}/${require('../../../../assets/images/salons-view/top-banner-ro-desktop.jpg')}`
				mobileImage = `${assetsPath}/${require('../../../../assets/images/salons-view/top-banner-ro-mobile.jpg')}`
				urlLink = 'https://www.notino.ro/salons-promo/'
				break
			case 'bg':
				desktopImage = `${assetsPath}/${require('../../../../assets/images/salons-view/top-banner-bg-desktop.jpg')}`
				mobileImage = `${assetsPath}/${require('../../../../assets/images/salons-view/top-banner-bg-mobile.jpg')}`
				urlLink = 'https://www.notino.bg/salons-promo/'
				break
			case 'hu':
				desktopImage = `${assetsPath}/${require('../../../../assets/images/salons-view/top-banner-hu-desktop.jpg')}`
				mobileImage = `${assetsPath}/${require('../../../../assets/images/salons-view/top-banner-hu-mobile.jpg')}`
				urlLink = 'https://www.notino.hu/salons-promo/'
				break
			case 'sk':
				desktopImage = `${assetsPath}/${require('../../../../assets/images/salons-view/top-banner-sk-desktop.jpg')}`
				mobileImage = `${assetsPath}/${require('../../../../assets/images/salons-view/top-banner-sk-mobile.jpg')}`
				urlLink = 'https://www.notino.sk/salons-promo/'
				break
			case 'cs':
			default:
				desktopImage = `${assetsPath}/${require('../../../../assets/images/salons-view/top-banner-cs-desktop.jpg')}`
				mobileImage = `${assetsPath}/${require('../../../../assets/images/salons-view/top-banner-cs-mobile.jpg')}`
				urlLink = 'https://www.notino.cz/salons-promo/'
				break
		}

		const slide: SalonPageBannersSlide = {
			background: {
				color: undefined,
				image: backgroundImage,
				repeat: 0
			},
			image: {
				desktop: desktopImage,
				mobile: mobileImage
			},
			imageAlt: undefined,
			link: urlLink,
			title: ''
		}

		slides = [slide]
	} else {
		slides = bannersData.banners.map((banner) => {
			const { image, infoText, backgroundColor, backgroundImage } = banner
			const editedBackgroundColor = backgroundColor?.replace('#', '')

			let resolvedBackgroundColor

			if (backgroundImage) {
				resolvedBackgroundColor = SALONS_PAGE_BANNER_SETTINGS.DEFAULT_BACKGROUND_COLOR
			} else {
				resolvedBackgroundColor = editedBackgroundColor || SALONS_PAGE_BANNER_SETTINGS.DEFAULT_BACKGROUND_COLOR
			}

			return {
				background: {
					color: resolvedBackgroundColor,
					image: backgroundImage,
					repeat: 0
				},
				image: {
					desktop: image.desktop,
					mobile: image.mobile
				},
				imageAlt: image.alt,
				link: image.link || '',
				onInfoButtonClick: infoText ? () => onInfoBannerClick({ text: infoText }) : undefined,
				title: ''
			}
		})
	}

	return slides
}

// component
const SalonsPageBanner = (props: SalonsPageBannerProps) => {
	const { cmsData } = props
	const { assetsPath, locale } = useContext(AppContext)

	const [infoBannerData, setInfoBannerData] = useState<InfoBannerData | null>(null)

	const slides = useMemo(
		() => getSalonsPageBannerSlides({ bannersData: cmsData, assetsPath, locale, onInfoBannerClick: setInfoBannerData }),
		[cmsData, assetsPath, locale]
	)

	const [currentSlides, setCurrentSlides] = useState(slides.slice(0, 1))

	useEffect(() => {
		/**
		 * Delay setting all slides until the document is fully loaded for a better CWV results
		 * When there is only one slide, <FullWidthBannersComponent> will not render the slider, but only the simple image with background
		 */
		if (slides.length <= 1) {
			return undefined
		}

		if (document.readyState === 'complete') {
			setCurrentSlides(slides)
			return undefined
		}

		const handleReadyStateChange = () => {
			if (document.readyState === 'complete') {
				setCurrentSlides(slides)
			}
		}

		document.addEventListener('readystatechange', handleReadyStateChange)

		return () => {
			document.removeEventListener('readystatechange', handleReadyStateChange)
		}
	}, [slides])

	return (
		<>
			<SC.SalonsPageBannerContainer>
				<SC.SliderWrapper>
					<FullWidthBannersComponent
						defaultBackground={{
							color: currentSlides[0].background?.color,
							image: currentSlides[0].background?.image,
							repeat: 0
						}}
						items={currentSlides}
						className='salons-page-banner'
					/>
				</SC.SliderWrapper>
			</SC.SalonsPageBannerContainer>
			{infoBannerData && (
				<Modal show onClose={() => setInfoBannerData(null)}>
					<SC.ModalText>{infoBannerData.text}</SC.ModalText>
				</Modal>
			)}
		</>
	)
}

export default SalonsPageBanner
