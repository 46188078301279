import React, { PropsWithChildren } from 'react'
import { NumberParam, useQueryParams } from 'use-query-params'

// types
import { ContextProps, HomePageServerQueryType } from '../types/types'

// utils
import { getValidHomePageQuery } from '../utils/helper'
import HomePage from '../pages/HomePage/HomePage'

type SalonsRouteProps = PropsWithChildren<ContextProps & {}>

const HomePageRoute: React.FC<SalonsRouteProps> = (props) => {
	// NOTE: when adding new parameter, also add it to HomePageQueryType and HomePageSetQueryType in interfaces
	const [query] = useQueryParams({
		latMy: NumberParam,
		lonMy: NumberParam
	})

	const validQuery = getValidHomePageQuery(query as HomePageServerQueryType)

	return <HomePage query={validQuery} {...props} />
}

export default HomePageRoute
