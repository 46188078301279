import React from 'react'
import { IconSolidInfo } from '@notino/react-styleguide'
import { useIntl } from 'react-intl'
import * as SC from './BasicSalonStyles'
import { ConfigResponse, SalonDetail } from '../../../../types/types'
import { SALON_PAGE_MOBILE_BREAKPOINT_KEY } from '../../../../styles/constants'
import GoogleMaps from '../../../../components/GoogleMaps/GoogleMaps'
import { DesktopOnly, MobileOnly } from '../../../../styles/helpers'
import SalonContacts from '../SalonContacts/SalonContacts'
import useMessages from '../../../../hooks/useMessages'
import { MAP } from '../../../../utils/enums'

type Props = {
	salonData: SalonDetail
	countriesData: ConfigResponse['rolloutCountries']
}

const BasicSalon = (props: Props) => {
	const { salonData, countriesData } = props
	const { messages } = useMessages()
	const { locale } = useIntl()

	const linkFromTranslations = messages['notino-partner-link']
	const FALLBACK_LINK = 'https://partner.notino.cz/#jak-na-to'
	const link = !linkFromTranslations || linkFromTranslations === '_NEPRELOZENE_' ? FALLBACK_LINK : linkFromTranslations

	const partnerLink = (
		<SC.NotinoPartnerLinkContainer>
			<IconSolidInfo color={'icon.primary'} width='16' />
			<SC.NotinoPartnerLink href={link} target='_blank' rel={'noopener noreferrer'}>
				{messages['Is this your salon?']}
			</SC.NotinoPartnerLink>
		</SC.NotinoPartnerLinkContainer>
	)

	const lng = salonData.address?.longitude
	const lat = salonData.address?.latitude

	let mapCenter: google.maps.LatLngLiteral = MAP.locations[locale as keyof typeof MAP.locations] || MAP.defaultLocation
	const markers = []

	if (lng !== undefined && lat !== undefined) {
		mapCenter = { lat, lng }
		markers.push({
			id: salonData.id,
			position: { lat, lng },
			extra: {}
		})
	}

	const googleMaps = <GoogleMaps defaultCenter={mapCenter} defaultSelectedMarkerID={salonData.id} markers={markers} />

	return (
		<div>
			{salonData.name && <SC.Heading>{salonData.name}</SC.Heading>}

			<MobileOnly $breakpoint={SALON_PAGE_MOBILE_BREAKPOINT_KEY}>
				<SC.MapContainer id={'google-map'}>{googleMaps}</SC.MapContainer>
				<SalonContacts salonData={salonData} countriesData={countriesData} />
				{partnerLink}
			</MobileOnly>

			<DesktopOnly $breakpoint={SALON_PAGE_MOBILE_BREAKPOINT_KEY}>
				<SC.Columns>
					<SC.MainColumn>
						<SC.MapContainer>{googleMaps}</SC.MapContainer>
					</SC.MainColumn>
					<SC.SideColumn>
						<SalonContacts salonData={salonData} countriesData={countriesData} />
						{partnerLink}
					</SC.SideColumn>
				</SC.Columns>
			</DesktopOnly>
		</div>
	)
}

export default BasicSalon
