import React, { forwardRef } from 'react'
import { TextAreaRef } from 'rc-textarea'

// types
import { TextAreaProps } from './types'

// styles
import * as SC from './TextAreaStyles'

const TextArea = forwardRef<TextAreaRef, TextAreaProps>((props, ref) => {
	const { isPristine, invalid, ...restProps } = props

	return <SC.Input ref={ref} $isPristine={isPristine} $isInvalid={invalid} {...restProps} />
})

export default TextArea
