import React, { PropsWithChildren, useContext } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, ButtonModel, IconRegularInfo, Spinner } from '@notino/react-styleguide'
import { useForm } from 'react-hook-form'
import dayjs from 'dayjs'

// hooks
import useMessages from '../../../hooks/useMessages'

// styles
import * as SC from './Step3BookingConfirmationStyles'

// components
import ReservationBookingModalLayout from '../ReservationBookingModalLayout/ReservationBookingModalLayout'
import HeaderContent from '../HeaderContent/HeaderContent'
import HookFormField from '../../../formFields/HookFormField'
import InputFormField from '../../../formFields/InputFormField/InputFormField'
import PhoneFormField from '../../../formFields/PhoneFormField/PhoneFormField'
import TextAreaFormField from '../../../formFields/TextAreaFormField/TextAreaFormField'
import CheckboxFormField from '../../../formFields/CheckboxFormField/CheckboxFormField'
import FetchResult from '../../FetchResult/FetchResult'
import InfoLine from '../../../atoms/InfoLine/InfoLine'
import Tooltip from '../../Tooltip/Tooltip'

// types
import {
	ConfigResponse,
	DocumentsResponse,
	PostCalendarEventsReservationBody,
	PostCalendarEventsReservationResponse,
	ReservationBookingConfirmationForm,
	ReservationBookingSelectedFormValues,
	SalonServiceResponse,
	ServiceCategoryParameterValueData
} from '../../../types/types'

// utils
import { APP_CONTAINER_ID, DEFAULT_DATE_FORMAT, DOCUMENTS, FORM, RESERVATION_BOOKING_MODAL_STEP, VALIDATION_MAX_LENGTH } from '../../../utils/enums'
import { INFO_LINE_TYPE } from '../../../atoms/InfoLine/types'
import { AppContext } from '../../../utils/appProvider'
import { POST_RESERVATION_ERROR_KEYS_MAP } from '../../../utils/clientErrorValidations'
import { getAnyoneAvailableOption, getEmployeeOption, RESERVATION_BOOKING_MODAL_Z_INDEXES } from '../reservationBookingModalHelpers'
import { getModalReservationConfirmEvent } from '../../../utils/dataLayerEvents'
import { pushToDataLayer } from '../../../utils/dataLayer'

// components
import ResourceCard from '../../ResourceCard/ResourceCard'
import RenderPrice from '../../RenderPrice/RenderPrice'
import RenderDuration from '../../RenderDuration/RenderDuration'

// assets
import UserIcon from '../../../assets/icons/UserIcon'
import CalendarIcon from '../../../assets/icons/CalendarIcon'

type Props = PropsWithChildren<{
	salonID: string
	configData: ConfigResponse
	selectedFormValues: ReservationBookingSelectedFormValues
	selectedIndustryID: string | undefined
	selectedServiceData: SalonServiceResponse['service']
	selectedServiceCategoryParameterValueData: ServiceCategoryParameterValueData | undefined
	headerImage: string | undefined
	onClose: () => void
	onBackButtonClick: () => void
	onSubmitBookingConfirmationForm: (submittedValues?: PostCalendarEventsReservationResponse['reservationCalendarEvent']) => void
	documentsData: DocumentsResponse['documents'] | undefined
	onLogin: () => void
	onRegister: () => void
}>

type AgreementLinkProps = PropsWithChildren<{ link: string }>

const AgreementLink = (props: AgreementLinkProps) => {
	const { children, link } = props

	return (
		<SC.AgreementLink href={link} onClick={(e) => e.stopPropagation()} target='_blank' rel={'noopener noreferrer'}>
			{children}
		</SC.AgreementLink>
	)
}

const Step3BookingConfirmation = (props: Props) => {
	const {
		onClose,
		onBackButtonClick,
		onSubmitBookingConfirmationForm,
		salonID,
		selectedFormValues,
		selectedIndustryID,
		selectedServiceData,
		selectedServiceCategoryParameterValueData,
		configData,
		documentsData,
		headerImage,
		onLogin,
		onRegister
	} = props

	const { formatDate } = useIntl()
	const { messages } = useMessages()
	const { apiBrowser, isAuthorized } = useContext(AppContext)

	const reservationTermsLink = documentsData?.find((document) => document.assetType === DOCUMENTS.B2C_RESERVATIONS_TERMS)?.files[0]?.original
	const privacyPolicyLink = documentsData?.find((document) => document.assetType === DOCUMENTS.B2C_PRIVACY_POLICY)?.files[0]?.original

	const { date, timeSlot, employeeID: formValuesEmployeeID } = selectedFormValues[RESERVATION_BOOKING_MODAL_STEP.EMPLOYEE_AND_DATE_SELECTION]

	const employeeID = formValuesEmployeeID || timeSlot.employeeID

	const selectedEmployeeData = selectedServiceData.employees.find((employee) => employee.id === employeeID)
	const selectedEmployeeOption = selectedEmployeeData
		? getEmployeeOption(selectedEmployeeData, selectedServiceCategoryParameterValueData)
		: getAnyoneAvailableOption(selectedServiceData, selectedServiceCategoryParameterValueData)

	const {
		control,
		handleSubmit,
		watch,
		setError,
		formState: { isSubmitting }
	} = useForm<ReservationBookingConfirmationForm>({
		defaultValues: {
			firstName: selectedFormValues[RESERVATION_BOOKING_MODAL_STEP.BOOKING_CONFIRMATION].firstName,
			lastName: selectedFormValues[RESERVATION_BOOKING_MODAL_STEP.BOOKING_CONFIRMATION].lastName,
			email: selectedFormValues[RESERVATION_BOOKING_MODAL_STEP.BOOKING_CONFIRMATION].email,
			phone: selectedFormValues[RESERVATION_BOOKING_MODAL_STEP.BOOKING_CONFIRMATION].phone,
			agreement: selectedFormValues[RESERVATION_BOOKING_MODAL_STEP.BOOKING_CONFIRMATION].agreement,
			note: selectedFormValues[RESERVATION_BOOKING_MODAL_STEP.BOOKING_CONFIRMATION].note,
			isVisitor: selectedFormValues[RESERVATION_BOOKING_MODAL_STEP.BOOKING_CONFIRMATION].isVisitor,
			notAllowMarketing: selectedFormValues[RESERVATION_BOOKING_MODAL_STEP.BOOKING_CONFIRMATION].notAllowMarketing,
			visitor: {
				firstName: selectedFormValues[RESERVATION_BOOKING_MODAL_STEP.BOOKING_CONFIRMATION].visitor?.firstName,
				lastName: selectedFormValues[RESERVATION_BOOKING_MODAL_STEP.BOOKING_CONFIRMATION].visitor?.lastName
			}
		},
		values: {
			firstName: selectedFormValues[RESERVATION_BOOKING_MODAL_STEP.BOOKING_CONFIRMATION].firstName,
			lastName: selectedFormValues[RESERVATION_BOOKING_MODAL_STEP.BOOKING_CONFIRMATION].lastName,
			email: selectedFormValues[RESERVATION_BOOKING_MODAL_STEP.BOOKING_CONFIRMATION].email,
			phone: selectedFormValues[RESERVATION_BOOKING_MODAL_STEP.BOOKING_CONFIRMATION].phone,
			agreement: selectedFormValues[RESERVATION_BOOKING_MODAL_STEP.BOOKING_CONFIRMATION].agreement,
			note: selectedFormValues[RESERVATION_BOOKING_MODAL_STEP.BOOKING_CONFIRMATION].note,
			isVisitor: selectedFormValues[RESERVATION_BOOKING_MODAL_STEP.BOOKING_CONFIRMATION].isVisitor,
			notAllowMarketing: selectedFormValues[RESERVATION_BOOKING_MODAL_STEP.BOOKING_CONFIRMATION].notAllowMarketing,
			visitor: selectedFormValues[RESERVATION_BOOKING_MODAL_STEP.BOOKING_CONFIRMATION].visitor
				? {
						firstName: selectedFormValues[RESERVATION_BOOKING_MODAL_STEP.BOOKING_CONFIRMATION].visitor.firstName,
						lastName: selectedFormValues[RESERVATION_BOOKING_MODAL_STEP.BOOKING_CONFIRMATION].visitor.lastName
					}
				: undefined
		}
	})

	const { firstName, lastName, email, phone, agreement, isVisitor, visitor } = watch()
	const { firstName: visitorFirstName, lastName: visitorLastName } = visitor || {}
	const { phoneNumber, phonePrefixCountryCode } = phone

	const isVisitorFilledIn = !isVisitor || !!(visitorFirstName && visitorLastName)
	const disabledSubmitButton =
		isSubmitting || !firstName || !lastName || !email || !phonePrefixCountryCode || !phoneNumber || !agreement || !isVisitorFilledIn

	// NOTE: Button from Notino style-guide library doesn't recognize "form" as a prop, however, when passed to the component, it works fine
	const unknownButtonProps = {
		form: FORM.RESERVATION_BOOKING_MODAL_BOOKING_CONFIRMATION
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} as any

	const handleSubmitBookingConfirmationForm = async (values: ReservationBookingConfirmationForm) => {
		try {
			let requestBody: PostCalendarEventsReservationBody = {
				start: {
					date,
					time: timeSlot.timeFrom
				},
				allowMarketing: !values.notAllowMarketing,
				end: {
					date,
					time: timeSlot.timeTo
				},
				customerNote: values.note?.trim() || null,
				customer: {
					firstName: values.firstName,
					lastName: values.lastName,
					email: values.email,
					phonePrefixCountryCode: values.phone.phonePrefixCountryCode,
					phone: values.phone.phoneNumber.replace(/\s+/g, '')
				},
				employeeID,
				serviceID: selectedServiceData.id,
				serviceCategoryParameterValueID: selectedServiceCategoryParameterValueData?.id
			}

			if (values.isVisitor) {
				if (!values.visitor?.firstName || !values.visitor?.lastName) {
					if (!values.visitor?.firstName) {
						setError('visitor.firstName', { message: messages['This field is required'] })
					}

					if (!values.visitor?.lastName) {
						setError('visitor.lastName', { message: messages['This field is required'] })
					}
					return
				}

				requestBody = {
					...requestBody,
					visitor: {
						firstName: values.visitor.firstName,
						lastName: values.visitor.lastName
					}
				}
			}

			const response = await apiBrowser.b2c.postCalendarEventsReservation(salonID, requestBody, {
				formErrors: { setError, errorsMap: POST_RESERVATION_ERROR_KEYS_MAP }
			})
			// after success submit push dataLayer event
			const event = getModalReservationConfirmEvent({
				category: selectedIndustryID,
				subcategory: selectedServiceData.category.child?.id,
				type: selectedServiceData.category.child.child?.id,
				subtype: selectedServiceCategoryParameterValueData?.categoryParameterValueID,
				employee_id: employeeID,
				date: dayjs(date).format(DEFAULT_DATE_FORMAT),
				slot: `${timeSlot.timeFrom} - ${timeSlot.timeTo}`
			})
			pushToDataLayer(event)

			onSubmitBookingConfirmationForm(response.reservationCalendarEvent)
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error(error)
		}
	}

	return (
		<ReservationBookingModalLayout
			title={messages['Booking confirmation']}
			headerImage={headerImage}
			onBackButtonClick={onBackButtonClick}
			onClose={onClose}
			headerContent={
				<HeaderContent
					title={selectedServiceData.category.child.child?.name ?? ''}
					description={
						selectedServiceCategoryParameterValueData?.value && selectedServiceData.serviceCategoryParameter?.name
							? `${selectedServiceData.serviceCategoryParameter.name}: ${selectedServiceCategoryParameterValueData.value}`
							: selectedServiceCategoryParameterValueData?.value
					}
				/>
			}
			footerContent={
				<Button {...unknownButtonProps} type={'submit'} buttonStyle={ButtonModel.Styles.primary} fullWidth disabled={disabledSubmitButton}>
					{isSubmitting ? <Spinner size={16} color={'icon.disabled'} /> : messages['Make a reservation']}
				</Button>
			}
		>
			<FetchResult>
				<SC.Form id={FORM.RESERVATION_BOOKING_MODAL_BOOKING_CONFIRMATION} onSubmit={handleSubmit(handleSubmitBookingConfirmationForm)} noValidate>
					<SC.CardFlexWrapper>
						<SC.CardWrapperEmployee>
							<ResourceCard
								title={selectedEmployeeOption.label}
								customIcon={<UserIcon />}
								avatarUrl={selectedEmployeeOption.extra?.avatar}
								infoContent={
									<SC.DateWrapper>
										<RenderPrice
											from={selectedEmployeeOption.extra?.priceAndDurationData?.priceFrom}
											to={selectedEmployeeOption.extra?.priceAndDurationData?.priceTo}
										/>
										<SC.DurationWrapper>
											<SC.ClockIconWrapper color={'icon.secondary'} />
											<RenderDuration
												from={selectedEmployeeOption.extra?.priceAndDurationData?.durationFrom}
												to={selectedEmployeeOption.extra?.priceAndDurationData?.durationTo}
											/>
										</SC.DurationWrapper>
									</SC.DateWrapper>
								}
							/>
						</SC.CardWrapperEmployee>
						<SC.CardWrapperDate>
							<ResourceCard
								customIcon={<CalendarIcon />}
								title={messages.Date}
								infoContent={
									<SC.DateWrapper>
										<SC.Date>{formatDate(date, { dateStyle: 'medium' })}</SC.Date>
										<SC.Time>
											{timeSlot.timeFrom} - {timeSlot.timeTo}
										</SC.Time>
									</SC.DateWrapper>
								}
							/>
						</SC.CardWrapperDate>
					</SC.CardFlexWrapper>
					<SC.FormSectionWrapper>
						<HookFormField
							control={control}
							name={'note'}
							component={TextAreaFormField}
							label={messages.Note}
							placeholder={messages['I would like to...']}
							maxLength={VALIDATION_MAX_LENGTH.LENGTH_1500}
							autoSize={{
								minRows: 1
							}}
						/>
					</SC.FormSectionWrapper>
					<SC.Divider $spacing={20} />
					<SC.FormSectionTitle>{messages.Client}</SC.FormSectionTitle>
					<SC.FormSectionWrapper>
						<SC.NameFormSection>
							<HookFormField
								control={control}
								name={'firstName'}
								component={InputFormField}
								label={messages.Name}
								placeholder={messages['Your name']}
								maxLength={VALIDATION_MAX_LENGTH.LENGTH_255}
								required
							/>
							<HookFormField
								control={control}
								name={'lastName'}
								component={InputFormField}
								label={messages.Surname}
								placeholder={messages['Your surname']}
								maxLength={VALIDATION_MAX_LENGTH.LENGTH_255}
								required
							/>
						</SC.NameFormSection>
						<HookFormField
							control={control}
							name={'email'}
							type={'email'}
							component={InputFormField}
							label={messages.Email}
							placeholder={messages['Your email']}
							maxLength={VALIDATION_MAX_LENGTH.LENGTH_255}
							required
						/>
						{!isAuthorized && (
							<InfoLine
								type={INFO_LINE_TYPE.INFO}
								message={
									<FormattedMessage
										id='To manage your reservations, you need to log in first. <login>Log in</login> or <createAccount>Create an account</createAccount>.'
										defaultMessage='To manage your reservations, you need to log in first. <login>Log in</login> or <createAccount>Create an account</createAccount>.'
										values={{
											login: (chunks) => <SC.AuthLink onClick={onLogin}>{chunks}</SC.AuthLink>,
											createAccount: (chunks) => <SC.AuthLink onClick={onRegister}>{chunks}</SC.AuthLink>
										}}
									/>
								}
							/>
						)}
						<HookFormField
							configData={configData}
							control={control}
							name={'phone'}
							component={PhoneFormField}
							required
							getPopupContainer={() => document.querySelector<HTMLDivElement>(`#${APP_CONTAINER_ID}`) ?? document.body}
							// NOTE: z-index must be higher than z-index of "ReservationBookingModal" to show the menu over the modal
							// TODO: the solution with z-indexes is not the best, there must be some better solution using getPopupContainer prop, but I can't find one atm due to lack of time
							dropdownStyle={{ zIndex: RESERVATION_BOOKING_MODAL_Z_INDEXES.SELECT_DROPDOWN }}
						/>
						<InfoLine
							type={INFO_LINE_TYPE.INFO}
							message={messages['Please check if the number you have entered is correct. The salon will use it to contact you.']}
						/>
						<HookFormField
							control={control}
							name={'isVisitor'}
							component={CheckboxFormField}
							alignment={'start'}
							checkboxGap={8}
							description={messages['I’m booking for someone else']}
						/>
						{isVisitor && (
							<SC.NameFormSection>
								<HookFormField
									control={control}
									name={'visitor.firstName'}
									component={InputFormField}
									label={messages.Name}
									placeholder={messages['Your name']}
									maxLength={VALIDATION_MAX_LENGTH.LENGTH_255}
									required
								/>
								<HookFormField
									control={control}
									name={'visitor.lastName'}
									component={InputFormField}
									label={messages.Surname}
									placeholder={messages['Your surname']}
									maxLength={VALIDATION_MAX_LENGTH.LENGTH_255}
									required
								/>
							</SC.NameFormSection>
						)}
						<SC.Divider />
						{!isAuthorized && (
							<SC.AllowMarketingCheckboxWrapper>
								<HookFormField
									control={control}
									name={'notAllowMarketing'}
									component={CheckboxFormField}
									alignment={'start'}
									checkboxGap={8}
									description={
										messages['I do not wish to receive information about news, promotions and discount coupons related to this booking']
									}
								/>
								<Tooltip
									styles={{ root: { maxWidth: 360 } }}
									content={
										<FormattedMessage
											id='Notino s.r.o. is entitled to contact its customers for direct marketing purposes. Please confirm if you object to this processing. For more information, see the <aDataProcessingPolicy>data processing policy.</aDataProcessingPolicy>'
											defaultMessage='Notino s.r.o. is entitled to contact its customers for direct marketing purposes. Please confirm if you object to this processing. For more information, see the <aDataProcessingPolicy>data processing policy.</aDataProcessingPolicy>'
											values={{
												aDataProcessingPolicy: (chunks) => <AgreementLink link={String(privacyPolicyLink)}>{chunks}</AgreementLink>
											}}
										/>
									}
								>
									<IconRegularInfo color={'text.tertiary'} style={{ cursor: 'help' }} />
								</Tooltip>
							</SC.AllowMarketingCheckboxWrapper>
						)}
						<HookFormField
							control={control}
							name={'agreement'}
							component={CheckboxFormField}
							alignment={'start'}
							checkboxGap={8}
							description={
								<SC.AgreementDescription>
									<FormattedMessage
										id='I agree to the <aTermsOfServices>Terms of services reservations</aTermsOfServices> and processing of <aPersonalData>personal data</aPersonalData> for the purpose of creating a reservation.'
										defaultMessage='I agree to the <aTermsOfServices>Terms of services reservations</aTermsOfServices> and processing of <aPersonalData>personal data</aPersonalData> for the purpose of creating a reservation.'
										values={{
											aTermsOfServices: (chunks) => <AgreementLink link={String(reservationTermsLink)}>{chunks}</AgreementLink>,
											aPersonalData: (chunks) => <AgreementLink link={String(privacyPolicyLink)}>{chunks}</AgreementLink>
										}}
									/>
								</SC.AgreementDescription>
							}
						/>
					</SC.FormSectionWrapper>
				</SC.Form>
			</FetchResult>
		</ReservationBookingModalLayout>
	)
}

export default Step3BookingConfirmation
