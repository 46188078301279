import styled from 'styled-components'
import { theme } from '@notino/react-styleguide'
import { NotinoLabelRegular400, NotinoTitleMedium } from '../../styles/helpers'

export const EmptyWrapper = styled.div<{ $minHeight: string }>`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: ${({ $minHeight }) => $minHeight};
	gap: 8px;
`

export const EmptyIconWrapper = styled.div`
	display: inline-block;
	width: 72px;
	height: 72px;
	color: ${theme.color.text.disabled};
	margin-bottom: 8px;

	svg {
		width: 100%;
		height: 100%;
	}
`

export const EmptyTitle = styled.div<{ $maxWidth: string }>`
	${NotinoTitleMedium};
	max-width: ${({ $maxWidth }) => $maxWidth};
	text-align: center;
	color: ${theme.color.text.primary};
	padding: 0;
`

export const EmptyLabel = styled.p<{ $maxWidth: string }>`
	text-align: center;
	max-width: ${({ $maxWidth }) => $maxWidth};
	color: ${theme.color.text.secondary};
	${NotinoLabelRegular400};
	padding: 0;
`

export const ButtonWrapper = styled.div`
	margin-top: 24px;
`
