import React from 'react'

// utils
import { decodePrice } from '../../utils/helper'

// types
import { Price } from '../../types/types'

type Props = {
	from?: Price | null | undefined
	to?: Price | null | undefined
}

const RenderPrice = (props: Props): React.ReactNode => {
	const { from, to } = props

	const decodedPriceFrom = decodePrice(from)
	const decodedPriceTo = decodePrice(to)

	const fromExists = decodedPriceFrom !== null && decodedPriceFrom !== undefined
	const toExists = decodedPriceTo !== null && decodedPriceTo !== undefined

	if (!fromExists && !toExists) {
		return null
	}

	let range
	const currencySymbol = from?.currencySymbol || to?.currencySymbol

	if (decodedPriceFrom !== decodedPriceTo) {
		range = (
			<>
				{fromExists ? decodedPriceFrom : ''} - {toExists ? decodedPriceTo : ''}
			</>
		)
	} else {
		range = decodedPriceFrom || decodedPriceTo
	}

	return (
		<>
			{range} {currencySymbol}
		</>
	)
}

export default RenderPrice
