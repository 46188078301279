import styled from 'styled-components'
import { Button, theme } from '@notino/react-styleguide'

// styles
import { NotinoLabelSmall400, resetButtonStyles } from '../../styles/helpers'

export const LoginScreenWrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
	width: 100%;
	max-width: 500px;
`

export const CloseButton = styled.button`
	${resetButtonStyles};
	position: absolute;
	right: 20px;
	top: 20px;
	z-index: 2;
`

export const MainContent = styled.div`
	flex: 1;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px;
	z-index: 1;
`

export const ImageWrapper = styled.div`
	width: 100%;
	max-width: 295px;
	margin: 20px auto;

	img {
		width: 100%;
		height: auto;
	}
`

export const Title = styled.div`
	font-size: 1.25rem;
	line-height: 2rem;
	font-weight: 500;
	text-align: center;
	margin-bottom: 8px;
`

export const Description = styled.div`
	${NotinoLabelSmall400};
	color: ${theme.color.text.secondary};
	text-align: center;
`

export const Footer = styled.div`
	width: 100%;
	padding: 20px;
	background: ${theme.color.background.primary};
	flex: 0 0 auto;
	display: flex;
	flex-direction: column;
	gap: 12px;
`

export const LinkButton = styled(Button)`
	background: transparent;
	border-color: transparent;
	text-decoration: underline;
	font-weight: 500;
	color: ${theme.color.text.primary};

	&:hover,
	&:focus,
	&:focus-visible {
		background: transparent;
		border-color: transparent;
		text-decoration: none;
		font-weight: 500;
		color: ${theme.color.text.primary};
	}
`
