import React from 'react'

// components
import Select from '../../atoms/Select/Select'

// types
import { SelectFormFieldProps } from './types'
import { BaseSelectOption, SelectValueType } from '../../atoms/Select/types'

// component
const SelectFormField = <ValuePropsType extends SelectValueType = string, OptionPropsType extends BaseSelectOption = BaseSelectOption>(
	props: SelectFormFieldProps<ValuePropsType, OptionPropsType>
) => {
	const {
		label,
		input: { value, onChange },
		...selectProps
	} = props

	return <Select<ValuePropsType, OptionPropsType> value={value} onChange={(newValue) => onChange(newValue)} {...selectProps} />
}

export default SelectFormField
