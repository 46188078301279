import React, { PropsWithChildren, useState } from 'react'
import { IconRegularClose, IconRegularChevronLeft } from '@notino/react-styleguide'
import { EventListeners } from 'overlayscrollbars'

// styles
import * as SC from './ReservationBookingModalLayoutStyles'

// types
import { SCROLLBAR_OPTIONS } from '../../../utils/helper'

type Props = PropsWithChildren<{
	onClose: () => void
	onBackButtonClick?: () => void
	title?: React.ReactNode
	headerImage?: string
	headerContent?: React.ReactNode
	footerContent?: React.ReactNode
}>

const ReservationBookingModalLayout = (props: Props) => {
	const { onClose, onBackButtonClick, title, footerContent, headerImage, headerContent, children } = props

	const [isScrolled, setIsScrolled] = useState(false)

	const handleScroll: EventListeners['scroll'] = (instance) => {
		const scrollElement = instance.elements().viewport
		const { scrollTop } = scrollElement

		const newScrolledState = scrollTop !== 0

		if (newScrolledState !== isScrolled) {
			setIsScrolled(newScrolledState)
		}
	}

	return (
		<SC.LayoutWrapper>
			<SC.FixedHeader $isScrolled={isScrolled}>
				<SC.FixedHeaderContent>
					{onBackButtonClick && (
						<SC.BackButton type={'button'} onClick={onBackButtonClick}>
							<IconRegularChevronLeft color={isScrolled ? 'icon.primary' : 'icon.inverse'} />
						</SC.BackButton>
					)}
					<SC.HeaderTitle>{title}</SC.HeaderTitle>
					<SC.CloseButton type={'button'} onClick={() => onClose()}>
						<IconRegularClose color={isScrolled ? 'icon.primary' : 'icon.inverse'} />
					</SC.CloseButton>
				</SC.FixedHeaderContent>
			</SC.FixedHeader>
			<SC.ScrollableContent
				options={SCROLLBAR_OPTIONS}
				events={{
					scroll: handleScroll
				}}
			>
				<SC.LayoutHeader $backgroundImage={headerImage}>{headerContent && <SC.HeaderContent>{headerContent}</SC.HeaderContent>}</SC.LayoutHeader>
				<SC.LayoutContent>{children}</SC.LayoutContent>
			</SC.ScrollableContent>
			{footerContent && <SC.LayoutFooter>{footerContent}</SC.LayoutFooter>}
		</SC.LayoutWrapper>
	)
}

export default ReservationBookingModalLayout
