import React, { ComponentProps } from 'react'

// hooks
import useMessages from '../../hooks/useMessages'

// components
import Empty from '../Empty/Empty'
import ErrorStateIcon from '../../assets/icons/ErrorStateIcon'

type Props = Omit<ComponentProps<typeof Empty>, 'button'> & {
	button?: { label?: string; href?: string; onClick?: (e: React.SyntheticEvent<HTMLElement>) => void }
	className?: string
}

const ErrorScreen = (props: Props) => {
	const { label, title, icon = <ErrorStateIcon />, minHeight = '300px', maxWidth, button, className = '' } = props
	const { messages } = useMessages()

	return (
		<Empty
			icon={icon}
			title={title || messages['Something went wrong. Data was not loaded.']}
			label={label}
			minHeight={minHeight}
			maxWidth={maxWidth}
			button={button ? { label: button.label || messages['Try again'], onClick: button.onClick, href: button.href } : undefined}
			className={className}
		/>
	)
}

export default ErrorScreen
