import React, { useContext, useEffect, useRef, useState, useCallback, ElementRef } from 'react'
import debounce from 'lodash.debounce'
import { IconSolidStar } from '@notino/react-styleguide'
import { useIntl } from 'react-intl'
import { InfoWindow, MapCameraChangedEvent, useMap } from '@vis.gl/react-google-maps'
import axios from 'axios'

// styles
import * as SC from './SalonsMapStyles'
import { SALON_PAGE_MOBILE_BREAKPOINT } from '../../styles/constants'

// hooks
import { useMediaQuery } from '../../hooks/useMediaQuery'

// utils
import { ABORT_KEYS, MAP, SALONS_MAP_DEBOUNCE_TIME_MS } from '../../utils/enums'
import { AppContext } from '../../utils/appProvider'
import { getCategoryIDs, PAGE_LINKS } from '../../utils/helper'
import { getMapViewItemListEvent } from '../../utils/dataLayerEvents'
import { pushToDataLayer } from '../../utils/dataLayer'
import { MyLocationContext } from '../../utils/myLocationProvider'

// components
import GoogleMaps, { GoogleMapPosition, MapMarker, OnMarkerClickFunc } from '../GoogleMaps/GoogleMaps'
import SalonDetail from '../SalonDetail/SalonDetail'

// types
import {
	MapBounds,
	MapPoints,
	Salon,
	SalonsListPageQueryType,
	ServiceTotalPriceCurrencyCode,
	SalonsCategoryPageQueryType,
	DefaultCityType
} from '../../types/types'

// assets
import ListIcon from '../../assets/icons/ListIcon'

// constants
const MAP_PADDING = 10
const TOGGLE_BUTTON_BOTTOM_OFFSET = 120 // offset of toggle button from bottom of container so it's OVER the cards (with some spacing)

// types
type Props = {
	query: SalonsListPageQueryType | SalonsCategoryPageQueryType
	toggleMapView: () => void
	currencyCode: ServiceTotalPriceCurrencyCode | undefined
	currentCategoryID?: string
	defaultCity: DefaultCityType | undefined
}

const getCurrentCenter = ({
	lat,
	lon,
	latMy,
	lonMy,
	defaultLocation
}: {
	lat: number | undefined
	lon: number | undefined
	latMy: number | undefined
	lonMy: number | undefined
	defaultLocation: { lat: number; lng: number }
}): GoogleMapPosition => {
	if (lat && lon) {
		return { lat, lng: lon }
	}

	if (latMy && lonMy) {
		return { lat: latMy, lng: lonMy }
	}

	return defaultLocation
}

type MarkerExtraType = { salonData: Salon }

const SalonsMap = (props: Props) => {
	const { apiBrowser } = useContext(AppContext)
	const { locale } = useIntl()
	const isDesktop = useMediaQuery(`(min-width: ${SALON_PAGE_MOBILE_BREAKPOINT})`)

	const { query, toggleMapView, currencyCode, currentCategoryID, defaultCity } = props
	const isCategoryQuery = 'categoryIDs' in query

	const defaultLocation =
		(defaultCity?.location?.latitude && defaultCity?.location?.longitude && { lat: defaultCity.location.latitude, lng: defaultCity.location.longitude }) ||
		MAP.locations[locale as keyof typeof MAP.locations] ||
		MAP.defaultLocation

	const { myLocation } = useContext(MyLocationContext)
	const { latMy, lonMy } = myLocation || {}
	const lat = isCategoryQuery ? query.lat : undefined
	const lon = isCategoryQuery ? query.lon : undefined

	const [currentMapPoints, setCurrentMapPoints] = useState<MapPoints | []>([])
	const [currentBounds, setCurrentBounds] = useState<MapBounds | null>(null)
	const [currentMarker, setCurrentMarker] = useState<google.maps.marker.AdvancedMarkerElement | null>(null)
	const [currentSalon, setCurrentSalon] = useState<Salon | null>(null)
	const [markersLoading, setMarkersLoading] = useState(true)

	const map = useMap()

	const googleMapsComponentRef = useRef<ElementRef<typeof GoogleMaps>>(null)
	const mapContainerRef = useRef<HTMLDivElement>(null)
	const cardsContainerRef = useRef<HTMLDivElement | null>(null)
	const currentCardRef = useRef<HTMLAnchorElement | null>(null)
	const shouldRefetchSalonsRef = useRef(true)

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const handleBoundsChanged = useCallback(
		debounce((event: MapCameraChangedEvent) => {
			// clicking on a pin in the map centers a map to that pin
			// this triggers handleBoundsChanged function - but in this situation we DON'T want to refetch salons
			// to prevent mobile cards from unnecessary re-renders and "jumping"
			if (!shouldRefetchSalonsRef.current) {
				shouldRefetchSalonsRef.current = true
				return
			}

			const latNW = event.detail.bounds.north
			const latSE = event.detail.bounds.south
			const lonNW = event.detail.bounds.west
			const lonSE = event.detail.bounds.east

			setCurrentBounds({ latNW, lonNW, latSE, lonSE })
		}, SALONS_MAP_DEBOUNCE_TIME_MS),
		[]
	)

	const handleInfoWindowCloseClick = () => {
		setCurrentMarker(null)
		setCurrentSalon(null)
		googleMapsComponentRef.current?.resetCurrentMarker()
	}

	const handleMarkerClick: OnMarkerClickFunc<MarkerExtraType> = (markerElement, { position, extra }) => {
		shouldRefetchSalonsRef.current = false

		if (position && map) {
			// zoom and center map to clicked marker
			map.panTo(new google.maps.LatLng(position.lat as number, position.lng as number))
			if (map.getZoom() !== MAP.defaultZoom) {
				map.setZoom(MAP.defaultZoom)
			}
		}
		setCurrentMarker(markerElement)
		setCurrentSalon(extra.salonData)
	}

	useEffect(() => {
		return () => {
			handleBoundsChanged.cancel()
		}
	}, [handleBoundsChanged])

	// scroll to map after triggering map view
	useEffect(() => {
		mapContainerRef?.current?.scrollIntoView({ block: 'center', behavior: 'smooth' })
	}, [])

	// updating salons when bounds and filters change
	useEffect(() => {
		const refetchSalons = async () => {
			if (!currentBounds || !query.isMapView) {
				return
			}

			setMarkersLoading(true)

			try {
				const data = await apiBrowser.b2c.getSalonsDataForMap(
					{
						...currentBounds,
						latMy,
						lonMy,
						openingHoursStatus: query.openingHoursStatus,
						exactRating: query.exactRating,
						languageIDs: query.languageIDs,
						cosmeticIDs: query.cosmeticIDs,
						orderBy: query.orderBy,
						serviceTotalPriceFrom: query.serviceTotalPriceFrom,
						serviceTotalPriceTo: query.serviceTotalPriceTo,
						serviceTotalPriceCurrencyCode: currencyCode,
						categoryIDs: isCategoryQuery ? getCategoryIDs(query.categoryIDs, currentCategoryID) : undefined,
						avResTimeSlotDayPart: query.avResTimeSlotDayPart,
						avResTimeSlotDateFrom: query.avResTimeSlotDateFrom,
						avResTimeSlotDateTo: query.avResTimeSlotDateTo
					},
					{ allowAbort: true, abortSignalKey: ABORT_KEYS.SALONS_MAP_ABORT_KEY }
				)

				if (data) {
					const { mapPoints } = data
					setCurrentMapPoints(mapPoints)

					// push to dataLayer
					const event = getMapViewItemListEvent(mapPoints.map((point) => point.salon))
					pushToDataLayer(event)
				}

				setMarkersLoading(false)
			} catch (error) {
				// set error, but only if it is not caused by cancel token
				if (axios.isAxiosError(error) && error.code !== 'ERR_CANCELED') {
					setMarkersLoading(false)
				}
			}
		}
		refetchSalons()
	}, [query, apiBrowser, currentBounds, currentCategoryID, currencyCode, isCategoryQuery, latMy, lonMy])

	// updating map center when selected location (city) changes
	useEffect(() => {
		if (!map) {
			return
		}
		if ((latMy && lonMy) || (lon && lat)) {
			map.setCenter(getCurrentCenter({ lat, lon, latMy, lonMy, defaultLocation: { lat: defaultLocation.lat, lng: defaultLocation.lng } }))
		}
	}, [latMy, lonMy, lat, lon, defaultLocation.lng, defaultLocation.lat, map])

	// cards scrolling
	useEffect(() => {
		if (!currentSalon) return

		const containerElement = cardsContainerRef?.current
		const cardElement = currentCardRef?.current
		if (!containerElement || !cardElement) return

		const leftOffsetWithPadding = (cardElement.offsetLeft ?? 0) - MAP_PADDING
		containerElement.scrollLeft = leftOffsetWithPadding
	}, [currentSalon])

	const markers: MapMarker<MarkerExtraType>[] = currentMapPoints.map((mapPoint) => ({
		id: mapPoint.salon.id,
		position: { lat: mapPoint.lat, lng: mapPoint.lon },
		extra: {
			salonData: mapPoint.salon
		}
	}))

	return (
		<SC.GoogleMapContainer ref={mapContainerRef} $mapPadding={MAP_PADDING}>
			<GoogleMaps<MarkerExtraType>
				ref={googleMapsComponentRef}
				defaultCenter={getCurrentCenter({ lat, lon, latMy, lonMy, defaultLocation }) as google.maps.LatLngLiteral}
				markers={markers}
				onMarkerClick={handleMarkerClick}
				onClick={handleInfoWindowCloseClick}
				onBoundsChanged={debounce(handleBoundsChanged, 400)}
				markersLoading={markersLoading}
			>
				{/* salon desktop popup windows */}
				{isDesktop && currentSalon && currentMarker && (
					<InfoWindow anchor={currentMarker} pixelOffset={[0, 20]} onCloseClick={handleInfoWindowCloseClick}>
						{currentSalon && (
							<SC.SalonDetailWrapper>
								<SalonDetail query={query} salon={currentSalon} currentCategoryID={currentCategoryID} />
							</SC.SalonDetailWrapper>
						)}
					</InfoWindow>
				)}
			</GoogleMaps>

			{/* salon mobile cards */}
			{!isDesktop && map && (
				<SC.ButtonAndCardsContainer $mapPadding={MAP_PADDING}>
					<SC.ToggleListButtonMobile
						onClick={toggleMapView}
						$mapPadding={MAP_PADDING}
						// button over cards when cards are rendered, otherwise button is at the bottom of the container
						$bottom={currentMapPoints.length > 0 ? TOGGLE_BUTTON_BOTTOM_OFFSET : 0}
					>
						<ListIcon />
					</SC.ToggleListButtonMobile>
					{currentMapPoints.length > 0 && (
						<SC.CardsContainer ref={cardsContainerRef} $mapPadding={MAP_PADDING}>
							{currentMapPoints.map((mapPoint) => {
								const salonHref = PAGE_LINKS['/salons/:salonSlug'](locale, mapPoint.salon.seoSlugName)
								const salonCategories = mapPoint.salon.categories
								const numberOfAdditionalCategories = salonCategories.length
								return (
									<SC.SalonCard
										href={salonHref}
										key={mapPoint.salon.id}
										ref={mapPoint.salon.id === currentSalon?.id ? currentCardRef : null}
										$isSelected={mapPoint.salon.id === currentSalon?.id}
									>
										<SC.SalonCardImageWrapper>
											<SC.SalonCardImage salon={mapPoint.salon} />
										</SC.SalonCardImageWrapper>
										<SC.SalonCardTextContent>
											<SC.SalonCardSalonName>{mapPoint.salon.name}</SC.SalonCardSalonName>
											{salonCategories.length > 0 && (
												<SC.SalonCardDescription>
													{salonCategories[0]?.name} {numberOfAdditionalCategories > 0 && `+${numberOfAdditionalCategories}`}
												</SC.SalonCardDescription>
											)}
											{mapPoint.salon.rating && (
												<SC.Rating>
													<IconSolidStar width='12' />
													<SC.RatingValue>{mapPoint.salon.rating}</SC.RatingValue>
												</SC.Rating>
											)}
										</SC.SalonCardTextContent>
									</SC.SalonCard>
								)
							})}
						</SC.CardsContainer>
					)}
				</SC.ButtonAndCardsContainer>
			)}
		</SC.GoogleMapContainer>
	)
}

export default SalonsMap
