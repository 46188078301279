import styled, { css } from 'styled-components'
import { Button } from '@notino/react-styleguide'
import { BREAKPOINTS, Breakpoint } from './constants'

// resets

export const resetButtonStyles = css`
	border: none;
	cursor: pointer;
	background: none;
	padding: 0;
`

export const resetListStyles = css`
	padding: 0;
	list-style: none;
`

export const resetLinkStyles = css`
	text-decoration: none;
	color: inherit;
`

export const screenReaderOnly = css`
	position: absolute;
	width: 1px;
	height: 1px;
	clip: rect(0 0 0 0);
	padding: 0;
	border: 0;
	margin: -1px;
	overflow: hidden;
`

// typography

export const NotinoBodyLarge = css`
	font-weight: 400;
	font-size: 1rem;
	line-height: 1.25rem;
`

export const NotinoBodyMedium = css`
	font-weight: 400;
	font-size: 0.875rem;
	line-height: 1.25rem;
`

export const NotinoBodySmall = css`
	font-weight: 400;
	font-size: 0.75rem;
	line-height: 1.25rem;
`

export const NotinoBodyRegular = css`
	font-weight: 400;
	font-size: 0.875rem;
	line-height: 1.5rem;
`

export const NotinoTitleDisplay = css`
	font-weight: 500;
	font-size: 2rem;
	line-height: 2.25rem;
`

export const NotinoTitleExtraLarge = css`
	font-weight: 500;
	font-size: 2.5rem;
	line-height: 2.75rem;
`

export const NotinoTitleExtraExtraLarge = css`
	font-weight: 500;
	font-size: 3rem;
	line-height: 3.5rem;
`

export const NotinoTitleLarge = css`
	font-weight: 500;
	font-size: 1.5rem;
	line-height: 2rem;
`

export const NotinoTitleSmall = css`
	font-weight: 500;
	font-size: 1rem;
	line-height: 1.25rem;
`

export const NotinoTitleMedium = css`
	font-weight: 500;
	font-size: 1.25rem;
	line-height: 1.5rem;
`

export const NotinoLabelRegular = css`
	font-weight: 500;
	font-size: 0.875rem;
	line-height: 1.25rem;
`

export const NotinoLabelSmall = css`
	font-weight: 500;
	font-size: 0.75rem;
	line-height: 1rem;
`

export const NotinoLabelLarge = css`
	font-weight: 500;
	font-size: 1rem;
	line-height: 1.25rem;
`

export const NotinoLabelLarge400 = css`
	font-weight: 400;
	font-size: 1rem;
	line-height: 1.25rem;
`

export const NotinoLabelRegular400 = css`
	font-weight: 400;
	font-size: 0.875rem;
	line-height: 1.25rem;
`

export const NotinoLabelRegular500 = css`
	font-weight: 500;
	font-size: 0.875rem;
	line-height: 1.25rem;
`

export const NotinoLabelSmall400 = css`
	font-weight: 400;
	font-size: 0.75rem;
	line-height: 1rem;
`

// shared

export const DesktopOnly = styled.div<{ $breakpoint: Breakpoint }>`
	display: none;

	@media (min-width: ${(p) => BREAKPOINTS[p.$breakpoint]}) {
		display: block;
	}
`

export const MobileOnly = styled.div<{ $breakpoint: Breakpoint }>`
	@media (min-width: ${(p) => BREAKPOINTS[p.$breakpoint]}) {
		display: none;
	}
`

export const truncate = css`
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
`

export const multiLineClamp = (lines: number) => css`
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: ${lines};
	white-space: normal;
	overflow: hidden;
	text-overflow: ellipsis;
	word-break: break-word;
`

export const FixedHrefButton = styled(Button)`
	// fix for notino button when using href
	display: flex;
	align-items: center;
`
