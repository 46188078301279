import styled from 'styled-components'

export const VideoContainer = styled.div`
	height: fit-content;
`

export const Video = styled.video`
	width: 100%;
	height: 100%;
	display: block;
`
