import { useEffect, useState } from 'react'

const useIsMobile = (mobileBreakpoint: number): boolean => {
	const [isMobile, setIsMobile] = useState(false)

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(Boolean(window.innerWidth && window.innerWidth < mobileBreakpoint))
		}
		window.addEventListener('resize', handleResize)
		handleResize()
		return () => window.removeEventListener('resize', handleResize)
	}, [mobileBreakpoint])

	return isMobile
}

export default useIsMobile
