import styled, { css } from 'styled-components'
import { IconRegularClose as NotinoCloseIcon, theme } from '@notino/react-styleguide'

export const Chip = styled.button<{ $isSelected?: boolean; $isMainButtonChip?: boolean }>`
	font-family: ${theme.fonts.primary};
	font-size: 12px;
	line-height: 16px;
	font-weight: 400;
	white-space: nowrap;
	border: 1px solid transparent;
	border-radius: 999px;
	background-color: ${theme.color.background.secondary};
	padding: 8px 16px;
	display: flex;
	align-items: center;
	height: 32px;
	transition:
		border-color 0.3s ease,
		outline-color 0.3s ease,
		color 0.3s ease;
	gap: 0;
	position: relative;

	${({ $isSelected, $isMainButtonChip }) =>
		$isSelected &&
		!$isMainButtonChip &&
		css`
			font-weight: 500;
			border-color: ${theme.color.border.divider};
		`}

	&:not(:disabled) {
		cursor: pointer;
		color: ${theme.color.text.primary};
		outline: 2px solid transparent;

		&:focus-visible {
			outline-color: ${theme.color.border.selected};
		}
	}

	&:disabled {
		color: ${theme.color.text.disabled};
	}
`

export const Label = styled.span`
	display: inline-block;
`

export const SelectedBadge = styled.span<{ $isSelected?: boolean; $disabled?: boolean }>`
	position: absolute;
	display: block;
	border-radius: 50%;
	width: 6px;
	height: 6px;
	top: 7px;
	right: 13px;
	background-color: ${theme.color.background.highlight};
	pointer-events: none;
	opacity: 0;
	transition:
		opacity 0.3s ease,
		background-color 0.3s ease;

	${({ $isSelected }) =>
		$isSelected &&
		css`
			opacity: 1;
		`}

	${({ $disabled }) =>
		$disabled &&
		css`
			background-color: #f06292;
		`}
`

export const Count = styled.span<{ $disabled?: boolean }>`
	display: inline-block;
	color: ${theme.color.text.tertiary};
	font-weight: 500;
	margin-left: 4px;

	${({ $disabled }) =>
		$disabled
			? css`
					color: ${theme.color.text.disabled};
				`
			: css`
					// NOTE: farba z novej palety
					color: ${theme.color.text.tertiary};
				`}
`

export const CloseButton = styled.span<{ $disabled?: boolean }>`
	display: inline-block;
	width: 12px;
	height: 12px;
	margin-left: 10px;
	transition: all 0.3s ease;
	overflow: hidden;

	${({ $disabled }) =>
		$disabled
			? css`
					color: ${theme.color.text.disabled};
				`
			: css`
					// NOTE: farba z novej palety
					color: ${theme.color.text.tertiary};
					cursor: pointer;
				`}
`

export const CloseIcon = styled(NotinoCloseIcon)<{ $isSelected?: boolean }>`
	color: ${theme.color.text.tertiary};
	width: 100%;
	height: 100%;
	display: block;
`
