import React from 'react'

// types
import { CheckboxFormFieldProps } from './types'

// components
import Checkbox from '../../atoms/Checkbox/Checkbox'
import FormItem from '../../atoms/FormItem/FormItem'

const CheckboxFormField = (props: CheckboxFormFieldProps) => {
	const {
		input: { value, onChange },
		meta: { invalid, error },
		htmlFor,
		label,
		required,
		description,
		...restProps
	} = props

	const invalidStatus = !!(error || invalid)

	return (
		<FormItem htmlFor={htmlFor} label={label} required={required} error={invalidStatus && error ? error : undefined}>
			<Checkbox checked={value} invalid={invalidStatus} onChange={(e) => onChange(e.target.checked)} {...restProps}>
				{description}
			</Checkbox>
		</FormItem>
	)
}

export default CheckboxFormField
