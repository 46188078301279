import styled, { css } from 'styled-components'
import { IconRegularChevronDown, IconRegularClock, theme } from '@notino/react-styleguide'
import Collapse from 'rc-collapse'

// styles
import { NotinoLabelLarge, NotinoLabelRegular400, NotinoLabelSmall400, resetButtonStyles } from '../../../styles/helpers'
import { RESERVATION_BOOKING_MODAL_MOBILE_BREAKPOINT } from '../../../styles/constants'

export const Form = styled.form``

export const CardWrapper = styled.div<{ $collapsible: boolean }>`
	background: ${theme.color.background.tertiary};
	padding: 16px 20px;
	cursor: ${({ $collapsible }) => ($collapsible ? 'pointer' : 'default')};
`
export const Divider = styled.div`
	border-top: 1px solid rgba(225, 225, 225, 1);
	width: 100%;
	margin-top: 16px;
	margin-bottom: 16px;
`

export const EmployeeCollapseWrapper = styled(Collapse)<{ $collapsible: boolean }>`
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin: -20px -20px 16px -20px;

	.rc-collapse-motion {
		transition:
			height 150ms,
			opacity 150ms;
	}

	.rc-collapse-item {
		.rc-collapse-header {
			cursor: ${({ $collapsible }) => ($collapsible ? 'pointer' : 'default')};

			.rc-collapse-header-text {
				${NotinoLabelRegular400}
				width: 100%;
			}
			.rc-collapse-expand-icon {
				display: none;
			}
		}

		.rc-collapse-content {
			&.rc-collapse-content-hidden {
				display: none;
			}
		}
	}

	@media (min-width: ${RESERVATION_BOOKING_MODAL_MOBILE_BREAKPOINT}) {
		margin: -24px -24px 16px -24px;
	}
`

export const TimeSlotsCollapseWrapper = styled(Collapse)`
	display: flex;
	flex-direction: column;
	gap: 8px;

	.rc-collapse-motion {
		transition:
			height 150ms,
			opacity 150ms;
	}

	.rc-collapse-item {
		margin-bottom: 8px;
		.rc-collapse-header {
			display: flex;
			gap: 2px;
			align-items: center;
			cursor: pointer;

			.rc-collapse-header-text {
				order: 1;
				${NotinoLabelRegular400}
			}
			.rc-collapse-expand-icon {
				order: 2;
			}
		}

		.rc-collapse-content {
			&.rc-collapse-content-hidden {
				display: none;
			}
		}
	}
`

export const ChevronIcon = styled(IconRegularChevronDown)<{ $isActive?: boolean }>`
	transform: rotate(${({ $isActive }) => ($isActive ? '180deg' : '0deg')});
`

export const CollapseContent = styled.div`
	padding-top: 8px;
	display: flex;
	flex-direction: column;
	gap: 8px;
`
export const EmployeeTitle = styled.div`
	${NotinoLabelRegular400}
`

export const EmployeeDescription = styled.div`
	${NotinoLabelSmall400};
	color: ${theme.color.text.tertiary};
	margin-bottom: 8px;
`
export const ClockIconWrapper = styled(IconRegularClock)`
	width: 16px;
	height: 16px;
`

export const PriceAndDurationWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
	color: ${theme.color.text.secondary};
`

export const DurationWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 4px;
`

export const TimeSlotEmployeeWrapper = styled.div`
	margin: 8px;
`

export const EmployeeOptionWrapper = styled.div<{ $isDisabled: boolean; $isTimeSlotEmployee: boolean }>`
	border-bottom: 1px solid ${theme.color.border.divider};
	cursor: pointer;

	${({ $isTimeSlotEmployee }) =>
		$isTimeSlotEmployee
			? css`
					padding: 16px 0;
				`
			: css`
					padding: 16px 20px;
					@media (min-width: ${RESERVATION_BOOKING_MODAL_MOBILE_BREAKPOINT}) {
						padding: 16px 24px;
					}
				`};

	${({ $isDisabled }) =>
		$isDisabled &&
		css`
			cursor: default;
			opacity: 0.5;
		`}
`

export const DayPeriodEmptyState = styled.div`
	${NotinoLabelRegular400};
	color: ${theme.color.text.tertiary};
	text-align: center;
`

export const EmptyStateLinkButton = styled.button`
	${resetButtonStyles};
	${NotinoLabelRegular400};
	color: ${theme.color.text.secondary};
	text-decoration: underline;

	&:hover {
		text-decoration: none;
	}
`

export const FooterWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 10px;
`

export const DateTimeInfo = styled.div``

export const SelectedDate = styled.div`
	${NotinoLabelLarge};
	color: ${theme.color.text.primary};
	text-transform: capitalize;
`

export const SelectedTimeSlot = styled.div`
	${NotinoLabelRegular400};
	color: ${theme.color.text.tertiary};
	height: 1.25rem;
`
