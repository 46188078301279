/* eslint-disable global-require */
import styled, { css } from 'styled-components'
import { Select, theme } from '@notino/react-styleguide'

// styles
import { BREAKPOINTS, CONTAINER_X_PADDING, SALONS_PAGE_MOBILE_BREAKPOINT, SALON_PAGE_MOBILE_BREAKPOINT } from '../../styles/constants'
import { NotinoLabelRegular400, NotinoLabelRegular500 } from '../../styles/helpers'

const CONTROL_TAB_HEIGHT = 50
const CONTROL_TAB_MARGIN = 20

export const SalonsGridWrapper = styled.div``

export const SalonsGridOverflow = styled.div`
	overflow: hidden;
	position: relative;
`

export const SalonsGridPositionWrapper = styled.div<{ $initialLoading?: boolean }>`
	${({ $initialLoading }) =>
		$initialLoading &&
		css`
			position: absolute;
			overflow: hidden;
			pointer-events: none;
		`}
`

export const SalonsGridView = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	gap: 30px 28px;
	position: relative;

	@media (min-width: ${BREAKPOINTS.sm}) {
		grid-template-columns: 1fr 1fr;
	}

	@media (min-width: ${SALON_PAGE_MOBILE_BREAKPOINT}) {
		gap: 40px 28px;
		grid-template-columns: 1fr 1fr 1fr;
	}
`

export const BottomNavigation = styled.div`
	position: relative;
	margin-top: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 48px;

	@media (min-width: ${SALONS_PAGE_MOBILE_BREAKPOINT}) {
		margin-top: 80px;
	}
`

export const PaginationWrapper = styled.div`
	display: none;

	@media (min-width: ${SALONS_PAGE_MOBILE_BREAKPOINT}) {
		display: block;
		position: absolute;
		right: 0;
	}
`

export const SortingAndPaginationDesktop = styled.div`
	display: none;
	justify-content: space-between;
	align-items: center;
	height: ${CONTROL_TAB_HEIGHT}px;
	margin: ${CONTROL_TAB_MARGIN}px 0;

	@media (min-width: ${SALONS_PAGE_MOBILE_BREAKPOINT}) {
		display: flex;
	}
`

export const SortingMobile = styled.div`
	margin-top: 6px;

	@media (min-width: ${SALONS_PAGE_MOBILE_BREAKPOINT}) {
		display: none;
	}
`

export const NoSalonsMessageWrapper = styled.div`
	padding-top: 140px;
	padding-bottom: 140px;
	text-align: center;
`

export const NoSalonsMessage = styled.h3`
	font-weight: 300;
	font-size: 20px;
	margin-top: 32px;
	margin-bottom: 0px; // resetting global notino margin on heading
`

export const ToggleListContainerDesktop = styled.div`
	display: none;

	@media (min-width: ${SALONS_PAGE_MOBILE_BREAKPOINT}) {
		text-align: end;
		height: ${CONTROL_TAB_HEIGHT}px;
		display: flex;
		align-items: center;
		margin: ${CONTROL_TAB_MARGIN}px 0;
	}
`

export const ToggleMapContainerMobile = styled.div`
	position: sticky;
	bottom: 28px;
	margin-top: 40px;
	z-index: 10;

	@media (min-width: ${SALON_PAGE_MOBILE_BREAKPOINT}) {
		display: none;
	}
`

export const ToggleMapButton = styled.button`
	${NotinoLabelRegular500};
	white-space: nowrap;
	color: ${theme.color.text.primary};
	text-decoration: underline;
	border: none;
	background: none;
	cursor: pointer;
	display: flex;
	align-items: center;
	gap: 8px;
	font-size: 14px;
`

export const ToggleButtonMobile = styled.button`
	${theme.typography.bodyRegular};
	color: white;
	background: ${theme.color.background.highlight};
	padding: 12px 12px;
	border-radius: 1000px;
	border: none;
	z-index: 10;
	cursor: pointer;
	display: flex;
	align-items: center;
	gap: 8px;
	position: relative;
	left: 50%;
	transform: translateX(-50%);

	@media (min-width: ${SALONS_PAGE_MOBILE_BREAKPOINT}) {
		display: none;
	}
`

export const ToggleMapButtonMobile = styled(ToggleButtonMobile)`
	position: relative;
	left: 50%;
	transform: translateX(-50%);
`

export const SalonsMapView = styled.div`
	position: relative;

	margin-top: 16px;

	@media (min-width: ${SALONS_PAGE_MOBILE_BREAKPOINT}) {
		margin-top: 0;
	}
`

// NOTE: notino neposkytuje lepsie moznosti stylovania Selectu, preto
// musime targetovat cez first-child, nth-child a sibling
export const SortingSelect = styled(Select)<{ $assetsPath: string }>`
	width: auto;

	// selector styles
	> div[role='combobox'] {
		flex-direction: row-reverse;

		// label styles
		> div:first-child {
			padding: 0.75rem 0.25rem;
			${NotinoLabelRegular400};
		}

		// arrow styles
		> div:nth-child(2) {
			padding: 0.75rem 0 0.75rem 1rem;
			width: 40px;
			height: 48px;

			svg {
				display: none;
			}

			${({ $assetsPath }) => css`
				&::after {
					content: url('${`${$assetsPath}/${require(`../../assets/icons/sort.svg`)}`}');
				}
			`}
		}
	}

	// dropdown styles
	> div[role='combobox'] ~ div {
		left: 0;
		right: unset;
	}

	@media (min-width: ${SALONS_PAGE_MOBILE_BREAKPOINT}) {
		> div[role='combobox'] ~ div {
			right: 0;
			left: unset;
		}
	}
`

export const SalonsSearchWrapper = styled.div`
	width: 100%;
	max-width: 100%;

	@media (min-width: ${SALONS_PAGE_MOBILE_BREAKPOINT}) {
		max-width: 382px;
	}
`

export const AppDownloadSectionWrapper = styled.div`
	margin-top: 48px;
	margin-left: -${CONTAINER_X_PADDING}px;
	margin-right: -${CONTAINER_X_PADDING}px;

	@media (min-width: ${SALONS_PAGE_MOBILE_BREAKPOINT}) {
		margin-left: 0;
		margin-right: 0;
	}
`

export const PromoBannerWrapper = styled.div`
	aspect-ratio: 652 / 504;

	img {
		display: block;
		height: auto;
		width: 100%;
	}
`
