import { useState, useEffect } from 'react'

/**
 * Hook to create a simple countdown timer.
 *
 * It takes a function `onFinish` which is called when the countdown is finished.
 * You can also specify the time in seconds for the countdown.
 * The hook returns an object with the single property `timeRemaining` which
 * is the remaining time of the countdown in seconds.
 *
 * @example
 * const MyComponent = () => {
 *   const { timeRemaining } = useCountdown(() => {
 *     // Do something when the countdown is finished
 *   })
 *
 *   return (
 *     <div>
 *       Time remaining: {timeRemaining} seconds
 *     </div>
 *   )
 * }
 */
const useCountdown = (onFinish: () => void, timeInSecods = 5) => {
	const [timeRemaining, setTimeRemaining] = useState(timeInSecods)

	useEffect(() => {
		const timerInterval = setInterval(() => {
			setTimeRemaining((prevTime) => {
				if (prevTime === 0) {
					clearInterval(timerInterval)
					onFinish()
					return 0
				}
				return Math.max(prevTime - 1, 0)
			})
		}, 1000)

		// Cleanup the interval when the component unmounts
		return () => clearInterval(timerInterval)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return { timeRemaining }
}

export default useCountdown
