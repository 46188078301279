import React, { PropsWithChildren } from 'react'
import { useParams } from 'react-router-dom'
import { ArrayParam, BooleanParam, NumberParam, NumericArrayParam, StringParam, useQueryParams, withDefault } from 'use-query-params'

// types
import { ContextProps, SalonsCategoryPageServerQueryType, SalonsCategoryPageSetQueryType } from '../types/types'

// utils
import { SORTING_OPTION } from '../utils/enums'
import { getValidSalonsPageQuery } from '../utils/helper'

// components
import SalonsCategoryPage from '../pages/SalonsCategoryPage/SalonsCategoryPage'

type SalonsCategoryRouteParams = {
	categorySlug: string
}

type SalonsCategoryRouteProps = PropsWithChildren<ContextProps & {}>

const SalonsCategoryRoute: React.FC<SalonsCategoryRouteProps> = (props) => {
	const { categorySlug } = useParams<SalonsCategoryRouteParams>()

	// NOTE: when adding new parameter, also add it to SalonsListPageQueryType and SalonsListPageSetQueryType in interfaces
	const [query, setQuery] = useQueryParams({
		page: withDefault(NumberParam, 1),
		openingHoursStatus: StringParam,
		latMy: NumberParam,
		lonMy: NumberParam,
		lat: NumberParam,
		lon: NumberParam,
		googlePlaceID: StringParam,
		orderBy: withDefault(StringParam, SORTING_OPTION.RECOMMENDED),
		categoryIDs: withDefault(ArrayParam, []),
		exactRating: withDefault(NumericArrayParam, []),
		languageIDs: withDefault(ArrayParam, []),
		cosmeticIDs: withDefault(ArrayParam, []),
		hasAvailableReservationSystem: BooleanParam,
		serviceTotalPriceFrom: NumberParam,
		serviceTotalPriceTo: NumberParam,
		avResTimeSlotDayPart: StringParam,
		avResTimeSlotDateFrom: StringParam,
		avResTimeSlotDateTo: StringParam,
		isMapView: BooleanParam
	})
	const validQuery = getValidSalonsPageQuery(query as SalonsCategoryPageServerQueryType)

	return <SalonsCategoryPage categorySlug={categorySlug as string} query={validQuery} setQuery={setQuery as SalonsCategoryPageSetQueryType} {...props} />
}

export default SalonsCategoryRoute
