import styled, { css } from 'styled-components'
import { IconRegularClose as NotinoCloseIcon, theme } from '@notino/react-styleguide'

// styles
import { NotinoBodySmall, NotinoLabelRegular400, resetButtonStyles, truncate } from '../../styles/helpers'
import { SALONS_PAGE_MOBILE_BREAKPOINT } from '../../styles/constants'

export const DialogMessage = styled.p`
	margin-bottom: 0 !important; // NOTE: !important overrides Notino main app heading global styles
`

export const Wrapper = styled.div`
	position: relative;
	width: 100%;
	height: 44px;
	min-width: 0;
`

export const Trigger = styled.div<{ $isActive: boolean }>`
	display: flex;
	align-items: center;
	gap: 8px;
	width: 100%;
	height: 100%;
	background: ${theme.color.background.primary};
	padding: 10px 16px;
	border: 1px solid transparent;
	color: ${theme.color.text.primary};
	cursor: pointer;
	outline: none;
	border-radius: 2px;
	transition:
		border 0.3s ease,
		outline 0.3s ease;

	&:hover,
	&:focus-visible {
		border: 1px solid ${theme.color.common.black};
	}

	${({ $isActive }) =>
		$isActive &&
		css`
			border: 1px solid ${theme.color.common.black};
		`}
`

export const TriggerText = styled.span`
	${NotinoLabelRegular400};
	${truncate};
	display: inline-block;
`

export const ClearButton = styled.button`
	${resetButtonStyles};
	outline: 1px solid transparent;
	width: 20px;
	height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: transparent;
	margin-left: auto;
	flex: 0 0 auto;

	&:focus-visible {
		outline-color: ${theme.color.common.black};
	}
`

export const CloseIcon = styled(NotinoCloseIcon)<{ $isSelected?: boolean }>`
	color: ${theme.color.text.primary};
	width: 16px;
	height: 16px;
`

export const Dropdown = styled.div`
	position: absolute;
	top: calc(100% + 4px);
	left: 0;
	padding: 10px;
	width: 100%;
	background: ${theme.color.background.primary};
	border: 1px solid ${theme.color.border.divider};
	border-radius: 2px;
	box-shadow: 0px 4px 6px -1px rgba(17, 24, 39, 0.06);
	display: flex;
	flex-direction: column;
	gap: 10px;
	z-index: 2;

	@media (min-width: ${SALONS_PAGE_MOBILE_BREAKPOINT}) {
		width: 544px;
	}
`
export const DrawerContentWrapper = styled.div`
	padding: 20px;
`

export const DrawerFooterWrapper = styled.div`
	max-height: auto;
	overflow: hidden;
`

export const FooterCtaButtonWrapper = styled.div`
	padding: 20px;
`

const footerBorders = css`
	position: relative;

	// border-top
	&::before {
		content: '';
		position: absolute;
		height: 1px;
		left: 0;
		right: 0;
		top: 0;
		background-color: #f5f5f5;
	}

	// border-bottom
	&::after {
		content: '';
		position: absolute;
		height: 1px;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #f5f5f5;
	}
`

export const FooterExtraContent = styled.div`
	padding: 12px 20px;
	${footerBorders};
`

export const DateFilterFooterLabel = styled.span`
	display: block;
	text-align: center;
	${NotinoBodySmall};
	color: ${theme.color.text.primary};
`
