import dayjs, { Dayjs } from 'dayjs'
import React, { useEffect, useRef, useState } from 'react'
import { IconRegularChevronDown, IconRegularChevronLeft, IconRegularChevronRight, IconRegularChevronUp } from '@notino/react-styleguide'

// types
import { DateCellData, DateCellsData, DatePickerProps, GetDateCellsDataArgs, SelectedViewDates, SelectedViewMonthGridDates } from './types'

// utils
import { DEFAULT_DATE_FORMAT } from '../../utils/enums'

// styles
import * as SC from './DatePickerStyles'

// hooks
import useMessages from '../../hooks/useMessages'

// components
import FetchResult from '../../components/FetchResult/FetchResult'

const DATE_LABEL_FORMAT = 'MMMM YYYY'

const generateUniqueAnimationKey = (prefix = 'id') => {
	return `${prefix}-${Math.random().toString(36).substr(2, 9)}`
}

const getWeekDays = () => {
	const firstDayOfWeek = dayjs().localeData().firstDayOfWeek()
	const weekdays = dayjs().localeData().weekdaysMin()
	return [...weekdays.slice(firstDayOfWeek), ...weekdays.slice(0, firstDayOfWeek)]
}

const getDateCellsData = (data: GetDateCellsDataArgs): DateCellsData => {
	const { selectedViewDates, value, responseData, isWeeklyView } = data
	const { startDate, endDate } = selectedViewDates

	// Calculate the total number of days to display
	const dayCellsCount = isWeeklyView ? 7 : endDate.diff(startDate, 'day') + 1

	// Get the weekday for grid shift (only relevant for monthly view)
	const startDayOfTheWeek = startDate.weekday()

	const dateCellsData: DateCellData[] = [...Array(dayCellsCount).keys()].map((index) => {
		const date = startDate.add(index, 'day').startOf('day')
		const isAvailable = responseData ? !!responseData?.find((d) => dayjs(d.date).isSame(date, 'date'))?.available : false

		const isSelected = !!value?.isSame(date, 'date')
		const isPast = date.isBefore(dayjs(), 'date')

		return {
			isPast,
			isAvailable,
			isSelected,
			isToday: date.isSame(dayjs(), 'date'),
			date,
			dateString: date.format(DEFAULT_DATE_FORMAT),
			day: date.date()
		}
	})

	return {
		// For monthly view, shift the grid according to the first day of the month
		gridShift: isWeeklyView ? 0 : startDayOfTheWeek + 1,
		data: dateCellsData
	}
}

export const getSelectedViewDates = (value: Dayjs, isWeeklyView: boolean): SelectedViewDates => {
	const differenceUnit = isWeeklyView ? 'week' : 'month'
	const startDate = value.startOf(differenceUnit)

	return { startDate, endDate: startDate.endOf(differenceUnit) }
}

export const isValueInTheSelectedView = (value: Dayjs, { startDate, endDate }: SelectedViewDates) => {
	return value.isBetween(startDate, endDate, 'date', '[]')
}

export const getFullMonthGridDatesFromSelectedDates = (selectedViewDates: SelectedViewDates, value: dayjs.Dayjs | undefined): SelectedViewMonthGridDates => {
	const startOfTheSelectedMonth = (value || selectedViewDates.startDate).startOf('month')
	return { startDate: startOfTheSelectedMonth.startOf('week'), endDate: startOfTheSelectedMonth.endOf('month').endOf('week'), startOfTheSelectedMonth }
}

const DatePicker = (props: DatePickerProps) => {
	const {
		value,
		onChange,
		availableDates,
		disabled,
		/**
		 * onChangeView
		 * it is highly recommended to wrap "onChangeView" function to useCallback hook for better performance, because it is used as one of dependency in useEffect dependencies array
		 */
		onChangeView,
		size = 'default',
		errorMsg,
		defaultView = 'weekly',
		allowUnselectValue = false,
		isLoading = false,
		afterChange
	} = props

	const { messages } = useMessages()

	const [isWeeklyView, setIsWeeklyView] = useState(defaultView === 'weekly')
	// start and end date of currently selected calendar view
	const [selectedViewDates, setSelectedViewDates] = useState(getSelectedViewDates(value || dayjs(), isWeeklyView))
	// start and end date of currently selected month grid
	// 1.9.2024 (first day of the month) could be Wednesday, start of the month grid in this case would be 29.8.2024 as dayjs(1.9.2024).startOf('week') => 28.9.2024
	// the same logic applies for the end date of the month grid... if 31.9.2024 (last day of the month) would be friday, end of the month grid would be 2.10.2024 as dayjs(31.9.2024).endOf('week') => 31.9.2024
	const [selectedViewMonthGridDates, setSelectedViewMonthGridDates] = useState<SelectedViewMonthGridDates>(
		getFullMonthGridDatesFromSelectedDates(selectedViewDates, value)
	)

	const [animationTransitionKey, setAnimationTransitionKey] = useState(generateUniqueAnimationKey())

	const weekDays = getWeekDays()
	const dayCellsData = getDateCellsData({ selectedViewDates, value, responseData: availableDates, isWeeklyView })

	const isValueChangedFromInsideComponent = useRef(false)
	const prevSelectedViews = useRef<{ prevSelectedViewDates: SelectedViewDates; prevSelectedViewMonthGridDates: SelectedViewMonthGridDates }>({
		prevSelectedViewDates: selectedViewDates,
		prevSelectedViewMonthGridDates: selectedViewMonthGridDates
	})

	// string value is better as use effect dependency than Dayjs object
	const valueString = value ? value.format(DEFAULT_DATE_FORMAT) : undefined

	useEffect(() => {
		if (isValueChangedFromInsideComponent.current) {
			isValueChangedFromInsideComponent.current = false
			return
		}

		// this use effect handle change of the calendar view when the value of calendar is changed from the outside of the component
		// if new date is located in the different calendar view than is currently active, it will scroll component to the view in which new selected date will be visible
		if (valueString) {
			const valueDayJs = dayjs(valueString)

			const isSameView = isValueInTheSelectedView(valueDayJs, selectedViewDates)
			if (!isSameView) {
				const newSelectedViewDates = getSelectedViewDates(valueDayJs, isWeeklyView)
				const newSelectedViewMonthGridDates = getFullMonthGridDatesFromSelectedDates(newSelectedViewDates, valueDayJs)
				setSelectedViewDates(newSelectedViewDates)
				setSelectedViewMonthGridDates(newSelectedViewMonthGridDates)
				setAnimationTransitionKey(generateUniqueAnimationKey())

				if (onChangeView) {
					onChangeView({
						isWeeklyView,
						type: 'outsideValueChange',
						selectedViewMonthGridDates: newSelectedViewMonthGridDates,
						selectedViewDates: newSelectedViewDates
					})
				}
			}
		}
	}, [valueString, selectedViewDates, isWeeklyView, onChangeView])

	const handleChangeDate = (newDate: Dayjs, disabledButton: boolean, data: DateCellData) => {
		if (disabledButton) {
			return
		}
		isValueChangedFromInsideComponent.current = true

		let newValue: Dayjs | undefined = newDate

		if (allowUnselectValue) {
			newValue = !value || (value && !newDate.isSame(value, 'date')) ? newDate : undefined
		}

		const newSelectedViewDates = getSelectedViewDates(newValue || selectedViewDates.startDate, isWeeklyView)
		const newSelectedViewMonthGridDates = getFullMonthGridDatesFromSelectedDates(newSelectedViewDates, newValue)

		// we need to change selected view or month grid if the new value is in different view or different month grid than currently selected
		if (!selectedViewMonthGridDates.startDate.isSame(newSelectedViewMonthGridDates.startDate, 'date')) {
			setSelectedViewDates(newSelectedViewDates)
			setSelectedViewMonthGridDates(newSelectedViewMonthGridDates)

			if (onChangeView) {
				onChangeView({
					isWeeklyView,
					type: 'onInternalValueChange',
					selectedViewMonthGridDates: newSelectedViewMonthGridDates,
					selectedViewDates: newSelectedViewDates
				})
			}
		}

		onChange(newValue)

		if (afterChange) {
			afterChange(newDate, data)
		}
	}

	const handleChangeCalendarViewFromArrows = (direction: 'next' | 'prev') => {
		isValueChangedFromInsideComponent.current = true
		const selectedViewDifferenceUnit = isWeeklyView ? 'week' : 'month'
		const newSelectedViewStartDate =
			direction === 'next'
				? selectedViewDates.startDate.add(1, selectedViewDifferenceUnit)
				: selectedViewDates.startDate.subtract(1, selectedViewDifferenceUnit)

		const newSelectedViewDates = getSelectedViewDates(newSelectedViewStartDate, isWeeklyView)
		const valueForMonthGird = value && isValueInTheSelectedView(value, newSelectedViewDates) ? value : undefined
		const newSelectedViewMonthGridDates = getFullMonthGridDatesFromSelectedDates(newSelectedViewDates, valueForMonthGird)

		setSelectedViewDates(newSelectedViewDates)
		setSelectedViewMonthGridDates(newSelectedViewMonthGridDates)
		setAnimationTransitionKey(generateUniqueAnimationKey())

		if (onChangeView) {
			onChangeView({
				isWeeklyView,
				type: direction,
				selectedViewMonthGridDates: newSelectedViewMonthGridDates,
				selectedViewDates: newSelectedViewDates
			})
		}
	}

	const onChangeWeeklyView = () => {
		isValueChangedFromInsideComponent.current = true
		let newSelectedViewDates: SelectedViewDates = selectedViewDates
		let onChangeType: 'expand' | 'collapse' = 'expand'
		let newIsWeeklyView = !isWeeklyView

		if (!isWeeklyView) {
			// change to weekly view
			if (value && isValueInTheSelectedView(value, selectedViewDates)) {
				// if selected date is in the currently selected view, collapse to the week that contains selected date
				newSelectedViewDates = getSelectedViewDates(value, newIsWeeklyView)
			} else if (
				prevSelectedViews.current.prevSelectedViewMonthGridDates.startOfTheSelectedMonth.isSame(
					selectedViewMonthGridDates.startOfTheSelectedMonth,
					'date'
				)
			) {
				newSelectedViewDates = prevSelectedViews.current.prevSelectedViewDates
			} else if (selectedViewMonthGridDates.startOfTheSelectedMonth.isSameOrBefore(dayjs(), 'date')) {
				// if currently selected view is current month, collapse to the week that contains current date, because past days are disabled
				newSelectedViewDates = getSelectedViewDates(dayjs(), newIsWeeklyView)
			} else {
				// otherwise collapse to the start of the week of the currently selected month
				newSelectedViewDates = getSelectedViewDates(selectedViewMonthGridDates.startOfTheSelectedMonth, newIsWeeklyView)
			}

			onChangeType = 'collapse'
		} else {
			prevSelectedViews.current = { prevSelectedViewDates: selectedViewDates, prevSelectedViewMonthGridDates: selectedViewMonthGridDates }
			newIsWeeklyView = false
			// change to monthly view
			newSelectedViewDates = getSelectedViewDates(selectedViewMonthGridDates.startOfTheSelectedMonth, newIsWeeklyView)
			onChangeType = 'expand'
		}

		setSelectedViewDates(newSelectedViewDates)
		setIsWeeklyView(newIsWeeklyView)

		if (onChangeView) {
			onChangeView({
				isWeeklyView: newIsWeeklyView,
				type: onChangeType,
				selectedViewDates: newSelectedViewDates,
				selectedViewMonthGridDates
			})
		}
	}

	const isPrevButtonDisabled = () => {
		const differenceUnit = isWeeklyView ? 'week' : 'month'
		return selectedViewDates.startDate.startOf(differenceUnit).isSame(dayjs().startOf(differenceUnit), 'date') || disabled
	}

	return (
		<FetchResult isRefetching={isLoading}>
			<SC.DatePickerWrapper>
				<SC.DatePickerHeader>
					<SC.SelectedDate>{selectedViewMonthGridDates.startOfTheSelectedMonth.format(DATE_LABEL_FORMAT)}</SC.SelectedDate>
					<SC.SliderButton type={'button'} onClick={() => handleChangeCalendarViewFromArrows('prev')} disabled={isPrevButtonDisabled()}>
						<IconRegularChevronLeft color={'icon.tertiary'} />
					</SC.SliderButton>
					<SC.SliderButton type={'button'} onClick={() => handleChangeCalendarViewFromArrows('next')} disabled={disabled}>
						<IconRegularChevronRight color={'icon.tertiary'} />
					</SC.SliderButton>
					<SC.ExpandButton type={'button'} onClick={onChangeWeeklyView} disabled={disabled}>
						{isWeeklyView ? (
							<>
								{messages['Expand calendar']}
								<IconRegularChevronDown color={disabled ? 'icon.tertiary' : 'icon.primary'} />
							</>
						) : (
							<>
								{messages['Collapse calendar']}
								<IconRegularChevronUp color={disabled ? 'icon.tertiary' : 'icon.primary'} />
							</>
						)}
					</SC.ExpandButton>
				</SC.DatePickerHeader>
				<SC.CalendarWrapper>
					<SC.CalendarGrid>
						{weekDays.map((day, index) => (
							<SC.HeaderCell $size={size} key={index}>
								{day}
							</SC.HeaderCell>
						))}
					</SC.CalendarGrid>

					<SC.CalendarTransitionAnimation key={animationTransitionKey}>
						<SC.CalendarOpacityAnimation key={`${animationTransitionKey}_${isWeeklyView}`}>
							<SC.CalendarGrid>
								{dayCellsData.data.map((dayCell) => {
									const { day, date, isAvailable, isSelected, isToday, isPast } = dayCell
									return (
										<SC.DayCell key={day} $gridShift={day === 1 ? dayCellsData.gridShift : undefined} $size={size}>
											<SC.DayCellButton
												$isAvailable={isAvailable}
												$isSelected={isSelected}
												$isToday={isToday}
												$isPast={isPast}
												$disabled={!!disabled}
												$size={size}
												onClick={() => handleChangeDate(date, isPast || !!disabled, dayCell)}
											>
												{day}
											</SC.DayCellButton>
										</SC.DayCell>
									)
								})}
							</SC.CalendarGrid>
						</SC.CalendarOpacityAnimation>
					</SC.CalendarTransitionAnimation>
				</SC.CalendarWrapper>
				{errorMsg && <SC.ErrorMsg>{errorMsg}</SC.ErrorMsg>}
			</SC.DatePickerWrapper>
		</FetchResult>
	)
}

export default DatePicker
