import React from 'react'

const CloseIcon = () => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'>
			<path
				fill='currentColor'
				fillRule='evenodd'
				d='M12 10.586L5.707 4.293 4.293 5.707 10.586 12l-6.293 6.293 1.414 1.414L12 13.414l6.293 6.293 1.414-1.414L13.414 12l6.293-6.293-1.414-1.414L12 10.586z'
				clipRule='evenodd'
			/>
		</svg>
	)
}

export default CloseIcon
