/* eslint-disable global-require */
import React, { useContext } from 'react'
import { useIntl } from 'react-intl'

// utils
import { AppContext } from '../../../../utils/appProvider'

// styles
import * as SC from './FooterSectionStyles'
import { HomePageContainer } from '../../HomePageStyles'
import { BREAKPOINTS_INTS } from '../../../../styles/constants'

// hooks
import useMessages from '../../../../hooks/useMessages'

// types
import { CmsHomepageData } from '../../../../types/types'

type Props = {
	cmsData: CmsHomepageData['npForBusinessData'] | undefined
}

const getLocaleSpecificAssets = (locale: string, assetsPath: string, cmsData: Props['cmsData']) => {
	let link: string | undefined
	let bannerDesktop: string
	let bannerMobile: string

	switch (locale) {
		case 'ro':
			link = 'https://bit.ly/4iaRQ3g'
			bannerDesktop = `${assetsPath}/${require('../../../../assets/images/homepage/footer-banner-desktop-ro.jpg')}`
			bannerMobile = `${assetsPath}/${require('../../../../assets/images/homepage/footer-banner-mobile-ro.jpg')}`
			break
		case 'bg':
			link = 'https://bit.ly/4hUAhVl'
			bannerDesktop = `${assetsPath}/${require('../../../../assets/images/homepage/footer-banner-desktop-bg.jpg')}`
			bannerMobile = `${assetsPath}/${require('../../../../assets/images/homepage/footer-banner-mobile-bg.jpg')}`
			break
		case 'hu':
			link = 'https://bit.ly/3QCdr9g'
			bannerDesktop = `${assetsPath}/${require('../../../../assets/images/homepage/footer-banner-desktop-hu.jpg')}`
			bannerMobile = `${assetsPath}/${require('../../../../assets/images/homepage/footer-banner-mobile-hu.jpg')}`
			break
		case 'sk':
			link = 'https://bit.ly/4bjSTLW'
			bannerDesktop = `${assetsPath}/${require('../../../../assets/images/homepage/footer-banner-desktop-sk.jpg')}`
			bannerMobile = `${assetsPath}/${require('../../../../assets/images/homepage/footer-banner-mobile-sk.jpg')}`
			break
		case 'cs':
		default:
			link = 'https://bit.ly/43bspdA'
			bannerDesktop = `${assetsPath}/${require('../../../../assets/images/homepage/footer-banner-desktop-cs.jpg')}`
			bannerMobile = `${assetsPath}/${require('../../../../assets/images/homepage/footer-banner-mobile-cs.jpg')}`
			break
	}

	if (cmsData?.image.desktop) {
		bannerDesktop = cmsData.image.desktop
	}

	if (cmsData?.image.mobile) {
		bannerMobile = cmsData.image.mobile
	}

	if (cmsData?.image.desktop && cmsData?.image.mobile) {
		link = cmsData?.image.link
	}

	return { link, bannerDesktop, bannerMobile }
}

// component
const FooterSection = (props: Props) => {
	const { cmsData } = props

	const { assetsPath } = useContext(AppContext)
	const { messages } = useMessages()

	const { locale } = useIntl()

	const backgroundImage = `${assetsPath}/${require('../../../../assets/images/homepage/footer-bg.jpg')}`

	const { link, bannerDesktop, bannerMobile } = getLocaleSpecificAssets(locale, assetsPath, cmsData)
	const bannerAlt = cmsData?.image.alt || messages['homepage-footer-banner-alt-text']

	const picture = (
		<SC.Picture>
			<source srcSet={bannerDesktop} media={`(min-width: ${BREAKPOINTS_INTS.md}px)`} width={1280} height={460} />
			<source srcSet={bannerMobile} width={772} height={332} />
			<img src={bannerDesktop} alt={bannerAlt} loading='lazy' width={1280} height={460} />
		</SC.Picture>
	)

	return (
		<SC.Section $backgroundImage={backgroundImage}>
			<HomePageContainer>
				{link ? (
					<SC.Link href={link} target='_blank' rel={'noopener noreferrer'}>
						{picture}
					</SC.Link>
				) : (
					picture
				)}
			</HomePageContainer>
		</SC.Section>
	)
}

export default FooterSection
