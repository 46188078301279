import { ToastContainer } from 'react-toastify'
import styled, { keyframes } from 'styled-components'
import { BREAKPOINTS } from '../../styles/constants'

const NOTIFICATION_OFFSET = 16 // in px

const moveInRight = keyframes`
	0% {
		transform: translateX(100%);
		opacity: 0;
	}
	100% {
		transform: translateX(0);
		opacity: 1;
	}
`

const moveOutRight = keyframes`
	0% {
		transform: translateX(0);
		opacity: 1;
	}
	100% {
		transform: translateX(100%);
		opacity: 0;
	}
`

const moveInTop = keyframes`
	0% {
		transform: translateY(-100%);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
`

const fadeOut = keyframes`
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
`

export const StyledToastContainer = styled(ToastContainer)`
	/* .toast-container */
	top: ${NOTIFICATION_OFFSET}px;
	left: ${NOTIFICATION_OFFSET}px;
	right: ${NOTIFICATION_OFFSET}px;
	width: calc(100% - 2 * ${NOTIFICATION_OFFSET}px);

	@media (min-width: ${BREAKPOINTS.sm}) {
		width: 360px;
		left: unset;
	}

	/* .toast is passed to toastClassName */
	.Toastify__toast {
		min-height: auto;
		width: 100%;
		padding: 0;
		margin-bottom: 16px;
		box-shadow: none;
		border-radius: 4px;
		background: transparent;

		&.enter-animation {
			animation: ${moveInTop} 300ms ease-out forwards;
		}

		&.exit-animation {
			animation: ${fadeOut} 300ms ease-out forwards;
		}

		& > div {
			width: 100%;
			max-width: 100%;
		}

		@media (min-width: ${BREAKPOINTS.sm}) {
			&.enter-animation {
				animation: ${moveInRight} 300ms ease-out forwards;
			}

			&.exit-animation {
				animation: ${moveOutRight} 300ms ease-out forwards;
			}
		}
	}
`
