import styled from 'styled-components'
import { theme } from '@notino/react-styleguide'

// styles
import { BREAKPOINTS, CONTAINER_X_PADDING, MAX_WIDTHS } from '../../styles/constants'

export const HomePageContainer = styled.div`
	width: 100%;
	max-width: ${MAX_WIDTHS.PAGE_DEFAULT}px;
	padding: 0 ${CONTAINER_X_PADDING}px;
	margin: 0 auto;
`

export const HomePageSectionTitle = styled.h2<{ $marginBottom?: string; $textAlign?: string }>`
	font-weight: 500;
	font-size: 1.5rem;
	line-height: 2rem;
	margin-top: 0;
	margin-bottom: ${({ $marginBottom }) => $marginBottom || '0'};
	text-align: ${({ $textAlign }) => $textAlign || 'left'} !important; // override for notino global styles
	overflow-wrap: break-word;
	word-break: break-word;

	@media (min-width: ${BREAKPOINTS.lg}) {
		font-size: 2rem;
		line-height: 2.5rem;
		letter-spacing: -0.3px;
	}
`
export const HomePageSectionDescription = styled.p<{ $marginBottom?: string; $textAlign?: string }>`
	color: ${theme.color.text.secondary};
	font-size: 1rem;
	line-height: 1.5rem;
	margin-bottom: ${({ $marginBottom }) => $marginBottom || '0'};
	text-align: ${({ $textAlign }) => $textAlign || 'left'};
	overflow-wrap: break-word;
	word-break: break-word;
`

export const HomePageSectionLink = styled.a`
	text-decoration: underline;
	color: ${theme.color.text.primary};
	font-size: 0.875rem;
	line-height: 1.25rem;
	font-weight: 500;
	cursor: pointer;

	&:hover {
		text-decoration: none;
	}
`

export const HomePageWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 64px;
	padding: 40px 0;

	@media (min-width: ${BREAKPOINTS.lg}) {
		gap: 80px;
		padding: 80px 0;
	}
`
