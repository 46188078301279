import styled, { css } from 'styled-components'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { BREAKPOINTS, CONTAINER_X_PADDING } from '../../../../styles/constants'
import { resetButtonStyles } from '../../../../styles/helpers'
import { SLIDER_SETTINGS } from './sliderSettings'

const ITEM_PADDING_X_MOBILE = 5 // in px
const ITEM_PADDING_X_DESKTOP = 20 // in px

export const SalonsSliderHeading = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 24px;
	margin-bottom: 24px;
`

export const ArrowsContainer = styled.div`
	display: none;

	@media (min-width: ${BREAKPOINTS.lg}) {
		display: flex;
		align-items: center;
		gap: 16px;
	}
`

export const ArrowButton = styled.button`
	${resetButtonStyles};
	width: 24px;
	height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;

	&:disabled {
		cursor: default;
	}
`

export const SalonsSliderContainer = styled.div`
	position: relative;
	margin-left: -${CONTAINER_X_PADDING}px;
	margin-right: -${CONTAINER_X_PADDING}px;
	overflow: hidden;

	@media (min-width: ${BREAKPOINTS.lg}) {
		margin-left: 0;
		margin-right: 0;
	}
`

export const SlickSliderContainer = styled.div<{ $isLoadingData?: boolean; $itemsCount: number }>`
	padding-left: ${CONTAINER_X_PADDING}px;
	padding-right: ${CONTAINER_X_PADDING}px;
	overflow: hidden;
	position: relative;
	z-index: 1;

	.slick-track {
		display: flex;
		margin-left: initial;
		margin-right: initial;

		.slick-slide {
			height: auto;
			display: flex;

			> div {
				width: 100%;
				display: flex;
			}
		}
	}

	.slick-list {
		margin-left: -${ITEM_PADDING_X_MOBILE}px;
		margin-right: -${ITEM_PADDING_X_MOBILE}px;
		overflow: visible;
	}

	@media (min-width: ${BREAKPOINTS.lg}) {
		padding-left: 0;
		padding-right: 0;

		.slick-list {
			margin-left: -${ITEM_PADDING_X_DESKTOP}px;
			margin-right: -${ITEM_PADDING_X_DESKTOP}px;
		}
	}

	${({ $isLoadingData }) =>
		$isLoadingData &&
		css`
			height: 0;
			overflow: hidden;
			opacity: 0;
		`}
`

export const SlideWrapper = styled.div`
	padding: 0 ${ITEM_PADDING_X_MOBILE}px;

	@media (min-width: ${BREAKPOINTS.lg}) {
		padding: 0 ${ITEM_PADDING_X_DESKTOP}px;
	}
`

export const PlaceholderContainer = styled.div`
	display: flex;
	overflow: hidden;
	position: relative;
	z-index: 2;
	margin-left: -${ITEM_PADDING_X_MOBILE}px;
	margin-right: -${ITEM_PADDING_X_MOBILE}px;
	padding-left: ${CONTAINER_X_PADDING}px;
	padding-right: ${CONTAINER_X_PADDING}px;

	@media (min-width: ${BREAKPOINTS.lg}) {
		margin-left: -${ITEM_PADDING_X_DESKTOP}px;
		margin-right: -${ITEM_PADDING_X_DESKTOP}px;
		padding-left: 0;
		padding-right: 0;
	}
`

export const PlaceholderWrapper = styled.div<{ $isEmptyScreen?: boolean }>`
	width: 30%;
	flex-shrink: 0;
	width: ${100 / SLIDER_SETTINGS.slidesToShow}%;

	${SLIDER_SETTINGS.responsive.map(({ breakpoint, settings }) => {
		return css`
			@media (max-width: ${breakpoint}px) {
				width: ${100 / settings.slidesToShow}%;
			}
		`
	})}

	${({ $isEmptyScreen }) =>
		$isEmptyScreen &&
		css`
			opacity: 0;
			pointer-events: none;
		`}
`

export const EmptyScreenContainer = styled.div`
	position: absolute;
	inset: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 ${CONTAINER_X_PADDING}px;
`

export const SSRContent = styled.div`
	position: absolute;
	overflow: hidden;
	opacity: 0;
	width: 1;
	height: 1;
	display: flex;
`
