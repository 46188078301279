import styled from 'styled-components'

// styles
import { theme } from '@notino/react-styleguide'
import { CONTAINER_X_PADDING, SALONS_PAGE_MOBILE_BREAKPOINT } from '../../../../styles/constants'
import { NotinoLabelRegular400, NotinoLabelRegular500, truncate } from '../../../../styles/helpers'

// components
import Button from '../../../../atoms/Button/Button'

// utils
import { BOOKING_FORM_Z_INDEXES } from '../../../../utils/helper'

export const SalonsBookingForm = styled.form`
	position: relative;
	background: ${theme.color.background.highlight};
	z-index: ${BOOKING_FORM_Z_INDEXES.BOOKING_FORM};
	display: flex;
	flex-direction: column;
	gap: 12px;
	padding: 20px;
	margin: 0 -${CONTAINER_X_PADDING}px;

	@media (min-width: ${SALONS_PAGE_MOBILE_BREAKPOINT}) {
		position: relative;
		margin: -42px auto;
		flex-direction: row;
		gap: 20px;
	}
`

export const FormFields = styled.div`
	display: grid;
	gap: 4px;

	@media (min-width: ${SALONS_PAGE_MOBILE_BREAKPOINT}) {
		flex: 1;
		grid-template-columns: repeat(4, 1fr);
		gap: 20px;
	}
`

export const SearchButton = styled(Button)`
	flex: 1;
	${NotinoLabelRegular500};
	min-height: 44px;
	height: 44px;

	@media (min-width: ${SALONS_PAGE_MOBILE_BREAKPOINT}) {
		flex: 0 0 auto;
	}
`

export const IndustryOptionWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
`

export const IndustryOptionImage = styled.div<{ $image?: string }>`
	width: 40px;
	height: 40px;
	background-color: ${theme.color.common.gray50};
	background-image: ${({ $image }) => `url("${$image}")`};
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
`

export const IndustryOptionText = styled.div`
	${NotinoLabelRegular400};
	${truncate}
`

export const EmptyContentTitle = styled.div`
	text-align: center;
`

export const EmptyContentDescription = styled.div`
	text-align: center;
`
