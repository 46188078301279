import React from 'react'

// styles
import * as SC from './TimeSlotOptionsSkeletonStyles'

const TimeSlotOptionsSkeleton = () => {
	return (
		<SC.TimeSlotOptionsSkeletonWrapper>
			{Array.from(Array(2)).map((_dayPeriod, dayPeriodIndex) => {
				return (
					<div key={dayPeriodIndex}>
						<SC.DayPeriodSkeletonTitle height={'1.25rem'} width={'3rem'} />
						<SC.DayPeriodSkeletonsWrapper>
							{Array.from(Array(10)).map((_skeleton, skeletonIndex) => {
								return <SC.TimeSlotOptionsSkeleton key={skeletonIndex} height={'38px'} width={'76px'} />
							})}
						</SC.DayPeriodSkeletonsWrapper>
					</div>
				)
			})}
		</SC.TimeSlotOptionsSkeletonWrapper>
	)
}

export default TimeSlotOptionsSkeleton
