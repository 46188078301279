import styled, { css } from 'styled-components'
import { theme } from '@notino/react-styleguide'
import { NotinoLabelRegular400, NotinoLabelSmall400, truncate } from '../../styles/helpers'

export const PhoneFieldsWrapper = styled.div`
	display: flex;
	gap: 12px;
`

export const PhonePrefixCol = styled.div`
	width: 125px;
`

export const PhoneNumberCol = styled.div`
	flex: 1;
`

export const PrefixFlag = styled.div<{ $image: string | undefined }>`
	width: 20px;
	height: 20px;
	background-image: ${({ $image }) => `url("${$image}")`};
	background-position: center center;
	background-size: contain;
	background-repeat: no-repeat;
	overflow: hidden;
	flex-shrink: 0;

	${({ $image }) =>
		!$image &&
		css`
			background-color: ${theme.color.common.gray80};
		`}
`

export const PrefixValueLabel = styled.div`
	${NotinoLabelRegular400};
	color: ${theme.color.text.primary};
`

export const PrefixValueWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`

export const PrefixOptionWrapper = styled.div`
	display: flex;
	gap: 8px;
	align-items: center;
	overflow: hidden;
	min-width: 0;
`

export const PrefixOptionLabel = styled.div`
	${NotinoLabelRegular400};
	color: ${theme.color.text.primary};
	${truncate};
`

export const PrefixOptionDescription = styled.div`
	${NotinoLabelSmall400};
	color: ${theme.color.text.tertiary};
	margin-left: auto;
`
