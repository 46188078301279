/* eslint-disable global-require */
import styled, { css } from 'styled-components'
import { theme } from '@notino/react-styleguide'

// utils
import { NotinoBodyLarge, NotinoBodyMedium, NotinoBodySmall } from '../../styles/helpers'

import { BREAKPOINTS, SALONS_PAGE_MOBILE_BREAKPOINT } from '../../styles/constants'

// types
import { DateTimeSlotPickerProps } from './types'

type SizeType = NonNullable<DateTimeSlotPickerProps['size']>
type LayoutType = NonNullable<DateTimeSlotPickerProps['layout']>

// cell width in PX
const CELL_WIDTH: Record<SizeType, number> = {
	small: 30,
	default: 40
}

export const DialogMessage = styled.p`
	margin-bottom: 0 !important; // NOTE: !important overrides Notino main app heading global styles
`

export const DateTimeSlotPickerWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: ${theme.color.background.primary};
	margin: 0 auto;
`

export const DateTimeSlotPickerHeader = styled.div`
	margin-bottom: 10px;
	width: 100%;
`

export const Year = styled.span`
	${NotinoBodySmall};
	color: #808080;
	width: 100%;
	text-align: center;
	display: inline-block;
`

export const SliderWrapper = styled.div`
	height: 40px;
	padding: 10px 0;
	background: ${theme.color.background.primary};
	position: relative;
	width: 100%;

	&::before {
		position: absolute;
		content: '';
		width: 60px;
		height: 100%;
		left: 0;
		top: 0;
		background: linear-gradient(to right, ${theme.color.background.primary}, transparent);
		z-index: 2;
	}

	&::after {
		position: absolute;
		content: '';
		width: 60px;
		height: 100%;
		right: 0;
		top: 0;
		background: linear-gradient(to left, ${theme.color.background.primary}, transparent);
		z-index: 2;
	}

	/* Arrows */
	.slick-prev,
	.slick-next {
		display: none !important;
	}

	/* Slider */
	.slick-slider {
		position: relative;
		display: block;
		box-sizing: border-box;
		user-select: none;
		touch-action: pan-y;
		-webkit-tap-highlight-color: transparent;
	}

	.slick-list {
		position: relative;
		display: block;
		overflow: hidden;
		margin: 0;
		padding: 0;
		transform: translate3d(0, 0, 0);

		&:focus {
			outline: none;
		}

		&.dragging {
			cursor: pointer;
			cursor: hand;
		}
	}

	.slick-track {
		position: relative;
		top: 0;
		left: 0;
		display: flex;
		margin-left: auto;
		margin-right: auto;
		align-items: center;
		transform: translate3d(0, 0, 0);

		&::before,
		&::after {
			display: table;
			content: '';
		}

		&::after {
			clear: both;
		}
	}

	.slick-loading .slick-track {
		visibility: hidden;
	}

	.slick-slide {
		display: none;
		float: left;
		min-height: 1px;
		cursor: pointer;

		img {
			display: block;
		}

		&.slick-loading {
			img {
				display: none;
			}
		}

		&.dragging {
			img {
				pointer-events: none;
			}
		}
	}

	.slick-arrow.slick-hidden {
		display: none;
	}

	.slick-loading {
		.slick-list {
			background: ${theme.color.background.primary} center center no-repeat;
		}

		.slick-slide {
			visibility: hidden;
		}
	}

	.slick-initialized {
		.slick-slide {
			display: block;
		}
	}

	.slick-vertical {
		.slick-slide {
			display: block;
			height: auto;
			border: 1px solid transparent;
		}
	}
`

export const SliderButton = styled.button<{ $isNext?: boolean; $assetsPath: string }>`
	font-size: 0;
	line-height: 0;
	position: absolute;
	top: 50%;
	display: block;
	width: 20px;
	height: 20px;
	padding: 0;
	transform: translate(0, -50%);
	cursor: pointer;
	color: transparent;
	border: none;
	outline: 2px solid transparent;
	background: transparent;
	z-index: 3;
	transition: outline 0.3s ease;

	&::before {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 20px;
		height: 20px;
	}

	&:not(:disabled) {
		&:focus-visible {
			outline-color: ${theme.color.common.black};
		}
	}

	&:disabled {
		cursor: default;
		&::before {
			opacity: 0.3;
		}
	}

	${({ $isNext, $assetsPath }) =>
		$isNext
			? css`
					right: -10px;
					&::before {
						content: url('${`${$assetsPath}/${require('../../assets/icons/chevron-right.svg')}`}');
					}
				`
			: css`
					left: -10px;
					&::before {
						content: url('${`${$assetsPath}/${require('../../assets/icons/chevron-left.svg')}`}');
					}
				`}
`

export const SliderItem = styled.div<{ $isActive?: boolean; $isDisabled?: boolean; $size: SizeType }>`
	color: #808080;
	${NotinoBodyLarge};
	padding: 0 15px;
	text-transform: capitalize;

	${({ $size }) =>
		$size === 'default'
			? css`
					${NotinoBodyLarge};
				`
			: css`
					${NotinoBodyMedium};
				`}

	${({ $isActive }) =>
		$isActive &&
		css`
			color: ${theme.color.text.primary};
			font-weight: 500;
		`}

	${({ $isDisabled }) =>
		$isDisabled &&
		css`
			color: #bfbfbf;
		`}
`

export const CalendarWrapper = styled.div`
	overflow: auto;
	width: 100%;
	min-width: 280px;
`
export const CalendarGrid = styled.div`
	display: grid;
	grid-template-columns: repeat(7, 1fr);
	grid-row-gap: 6px;
	width: 100%;
	justify-content: space-between;
	overflow: hidden;
`

export const HeaderCell = styled.div<{ $size: SizeType }>`
	${({ $size }) => css`
		width: 100%;
		height: ${CELL_WIDTH[$size]}px;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		font-weight: 500;
		font-size: ${$size === 'small' ? '0.875rem' : '1.25rem'};
		line-height: ${$size === 'small' ? '1rem' : '1.5rem'};
		color: #9f9f9f;
		text-transform: capitalize;
	`}
`

export const DayCell = styled.div<{
	$gridShift: number | undefined
	$isStartOfTheRange: boolean
	$isEndOfTheRange: boolean
	$isBetweenRanges: boolean
	$size: SizeType
}>`
	${({ $gridShift, $isBetweenRanges, $isStartOfTheRange, $isEndOfTheRange, $size }) => css`
		text-align: center;
		height: ${CELL_WIDTH[$size]}px;
		width: 100%;
		display: flex;
		justify-content: center;
		position: relative;
		background-color: transparent;

		${$gridShift &&
		css`
			grid-column-start: ${$gridShift};
		`}

		${$isBetweenRanges &&
		css`
			background-color: #e6e6e6;
		`}

	${$isStartOfTheRange &&
		css`
			&::after {
				content: '';
				position: absolute;
				top: 0;
				bottom: 0;
				left: 50%;
				right: -${CELL_WIDTH[$size] / 2}px;
				background-color: #e6e6e6;
				z-index: 1;
			}
		`}

		${$isEndOfTheRange &&
		css`
			&::after {
				content: '';
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 50%;
				background: #e6e6e6;
				z-index: 1;
			}
		`}
	`}
`

export const DayCellButton = styled.div<{
	$isAvailable: boolean
	$isSelected: boolean
	$isToday: boolean
	$size: SizeType
}>`
	${({ $isAvailable, $isSelected, $isToday, $size }) => css`
		width: ${CELL_WIDTH[$size]}px;
		height: ${CELL_WIDTH[$size]}px;
		border-radius: ${CELL_WIDTH[$size] / 2}px;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		cursor: pointer;
		font-size: ${$size === 'small' ? '0.875rem' : '1.25rem'};
		line-height: ${$size === 'small' ? '1rem' : '1.5rem'};
		font-weight: 300;
		color: #404040;
		position: relative;
		z-index: 2;
		background-color: transparent;
		border: 1px solid transparent;

		${$isSelected &&
		css`
			background-color: ${theme.color.background.inverse};
			border-color: ${theme.color.text.primary};
			color: ${theme.color.text.inverse};
		`}

		${$isToday &&
		css`
			border-color: ${theme.color.text.primary};
		`}

		${!$isAvailable &&
		css`
			color: #9f9f9f;
			cursor: default;

			&::after {
				position: absolute;
				content: '';
				height: 1px;
				width: 24px;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				background-color: #9f9f9f;
			}
		`}
	`}
`

export const RadioButtonsWrapper = styled.div``

export const DateTimeSlotPickerLayout = styled.div<{ $layout: LayoutType }>`
	${({ $layout }) =>
		$layout === 'row' &&
		css`
			${DateTimeSlotPickerWrapper} {
				margin-bottom: 10px;
			}

			${DateTimeSlotPickerHeader} {
				width: 100%;
				position: relative;
			}

			@media (min-width: ${BREAKPOINTS.sm}) {
				display: flex;
				gap: 10px;
				width: 100%;

				${DateTimeSlotPickerHeader} {
					padding: 0 16px;
				}

				${CalendarWrapper} {
					padding: 0 16px 16px 16px;
				}

				${DateTimeSlotPickerWrapper} {
					min-width: 288px;
					width: 50%;
					margin-bottom: 0;
				}

				${DateTimeSlotPickerHeader} {
					border-bottom: 1px solid ${theme.color.common.gray90};
				}

				${RadioButtonsWrapper} {
					flex: 1;
				}
			}

			@media (min-width: ${SALONS_PAGE_MOBILE_BREAKPOINT}) {
				max-width: 520px;

				${DateTimeSlotPickerWrapper} {
					width: 60%;
				}
			}
		`}
`

export const ErrorMsg = styled.p`
	${NotinoBodySmall};
	color: ${theme.color.text.negative};
	margin: 0;
	align-self: start;
`
