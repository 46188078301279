import React, { ComponentProps } from 'react'
import { IconSolidStar } from '@notino/react-styleguide'
import { useIntl } from 'react-intl'

// styles
import * as SC from './SalonSliderItemStyles'
import { PAGE_LINKS } from '../../../../utils/helper'
import { Salon } from '../../../../types/types'
import SalonImage from '../../../../components/SalonImage/SalonImage'

type Props = {
	salon: Salon
	imageLoading?: ComponentProps<typeof SalonImage>['loading']
}

const SalonSliderItem = (props: Props) => {
	const { salon, imageLoading } = props

	const { locale } = useIntl()

	const itemHref = PAGE_LINKS['/salons/:salonSlug'](locale, salon.seoSlugName)

	return (
		<SC.SalonDetailItem href={itemHref}>
			<SC.ImageWrapper>
				<SalonImage salon={salon} loading={imageLoading} width={298} height={149} />
			</SC.ImageWrapper>
			<SC.MainContent>
				{salon.logo?.resizedImages.small && (
					<SC.LogoWrapper>
						<img loading={imageLoading} src={salon.logo.resizedImages.thumbnail} alt='salon logo' width={40} height={40} />
					</SC.LogoWrapper>
				)}

				{salon.rating && (
					<SC.Rating>
						<IconSolidStar width='12' height='12' style={{ marginBottom: '2px' }} color={'icon.highlight'} />
						<span>{salon.rating.toFixed(1)}</span>
					</SC.Rating>
				)}

				{salon.name && <SC.Title title={salon.name}>{salon.name}</SC.Title>}

				{salon.formattedAddress && <SC.Address title={salon.formattedAddress}>{salon.formattedAddress}</SC.Address>}
			</SC.MainContent>
		</SC.SalonDetailItem>
	)
}

export default SalonSliderItem
