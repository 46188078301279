import React from 'react'

const CalendarIcon = () => {
	return (
		<svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<g clipPath='url(#clip0_25350_9938)'>
				<path
					d='M12.6667 2.66602H3.33333C2.59695 2.66602 2 3.26295 2 3.9993V13.3323C2 14.0687 2.59695 14.6656 3.33333 14.6656H12.6667C13.403 14.6656 14 14.0687 14 13.3323V3.9993C14 3.26295 13.403 2.66602 12.6667 2.66602Z'
					stroke='currentColor'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<path d='M2 6.66772H14' stroke='currentColor' strokeLinecap='square' strokeLinejoin='round' />
				<path d='M10.666 1.33362V4.00019' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
				<path d='M5.33398 1.33362V4.00019' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
			</g>
			<defs>
				<clipPath id='clip0_25350_9938'>
					<rect width='16' height='15.9995' fill='currentColor' transform='translate(0 0.00170898)' />
				</clipPath>
			</defs>
		</svg>
	)
}

export default CalendarIcon
