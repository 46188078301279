import styled, { css } from 'styled-components'
import { theme } from '@notino/react-styleguide'
import { NotinoLabelRegular500 } from '../../styles/helpers'

export const Button = styled.button<{ $fullWidth?: boolean }>`
	outline: 2px solid transparent;
	border: none;
	border-radius: 2px;
	${NotinoLabelRegular500};
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 12px 24px;
	transition: all 0.3s ease;
	min-height: 48px;

	&:not(:disabled) {
		cursor: pointer;
		background: ${theme.color.background.inverse};
		color: ${theme.color.text.inverse};

		&:hover {
			background: #404040;
		}

		&:focus-visible {
			outline-color: ${theme.color.text.primary};
		}
	}

	&:disabled {
		cursor: not-allowed;
		background: ${theme.color.background.secondary};
		color: ${theme.color.text.disabled};
	}

	${({ $fullWidth }) =>
		$fullWidth &&
		css`
			width: 100%;
			display: flex;
		`}
`
