import { IDropdownOption } from '@notino/react-styleguide'
import dayjs, { Dayjs } from 'dayjs'
import { Salon, SalonDetail, AppStoreType, PostCalendarEventsReservationResponse, ReservationBookingModalCloseType } from '../types/types'
import { DEFAULT_DATE_FORMAT, RESERVATION_BOOKING_MODAL_STEP, SESSION_STORAGE_KEYS, RESERVATIONS_TIME_SLOTS, SORTING_OPTION, SALONS_PER_PAGE } from './enums'
import { decodePrice } from './helper'

export const getPageViewEvent = (type: 'salon' | 'salons') => ({
	event: 'page_view',
	page: {
		path: window.location.pathname,
		title: document.title,
		type,
		unified_name: 'salons'
	},
	_clear: true
})

export const getToggleMapEvent = (isMapView: boolean) => ({
	event: 'element_click',
	element: {
		name: isMapView ? 'show_in_list' : 'show_on_map',
		action: 'click_on_element',
		interaction: 'click',
		type: 'salons'
	},
	_clear: true
})

export const getToggleOpeningHoursEvent = (checked: boolean) => ({
	event: 'filter_set',
	filter: {
		name: 'opening_hours',
		action: 'filtration_used',
		interaction: checked ? 'click_add' : 'click_remove',
		type: 'opening_hours'
	},
	_clear: true
})

export const getSubcategoryChangeEvent = (params: {
	clickedCategorySlug: string
	clickedCategoryID: string
	eventInteraction: 'click_add' | 'click_remove'
}) => {
	const { clickedCategorySlug, clickedCategoryID, eventInteraction } = params
	return {
		event: 'filter_set',
		filter: {
			name: clickedCategorySlug,
			id: clickedCategoryID,
			action: 'filtration_used',
			interaction: eventInteraction,
			type: 'salon_category'
		},
		_clear: true
	}
}

export const getTopLevelCategoryChangeEvent = (params: { categorySlug?: string; categoryID: string }) => {
	const { categorySlug, categoryID } = params
	return {
		event: 'filter_set',
		filter: {
			name: categorySlug,
			id: categoryID,
			action: 'filtration_used',
			interaction: 'click',
			type: 'salon_fields'
		},
		_clear: true
	}
}

export const getRatingsChangeEvent = (params: { checked: boolean; rating: number }) => {
	const { checked, rating } = params
	return {
		event: 'filter_set',
		filter: {
			name: `rating_${rating}`,
			action: 'filtration_used',
			interaction: checked ? 'click_add' : 'click_remove',
			type: 'rating'
		},
		_clear: true
	}
}

export const getBreadcrumbClickEvent = (params: { isMobile: boolean; name: string; pathQuery: string; levelIndex: number; text: string[] }) => {
	const { isMobile, name, pathQuery, levelIndex, text } = params
	return {
		event: 'breadcrumbs_click',
		breadcrumbs: {
			variant: isMobile ? 'mobile' : 'desktop',
			name,
			path_query: pathQuery,
			type: `level_${levelIndex}`,
			interaction: 'click',
			action: 'breadcrumbs_used',
			text
		},
		_clear: true
	}
}

export const getSearchFocusedEvent = (params: { isMobile: boolean }) => {
	const { isMobile } = params
	return {
		event: 'search_open',
		search: {
			interaction: 'click',
			action: 'click_to_search_field',
			variant: isMobile ? 'mobile' : 'desktop',
			type: 'salons'
		},
		_clear: true
	}
}

export const getPlaceSelectedEvent = (params: { inputValue: string }) => {
	const { inputValue } = params
	return {
		event: 'search',
		search: {
			term: inputValue,
			action: 'search_form_used',
			interaction: 'click',
			type: 'salons'
		},
		_clear: true
	}
}

export const getSalonSelectedFromSearchEvent = (params: { inputValue: string }) => {
	const { inputValue } = params
	return {
		event: 'search',
		search: {
			term: inputValue,
			action: 'search_form_used',
			interaction: 'click_through',
			type: 'salons'
		},
		_clear: true
	}
}

export const getViewItemListEvent = (params: { currentSortingOptionName: string; currentPage: number; salons: Salon[] }) => {
	const { currentSortingOptionName, currentPage, salons } = params

	/**
	 * When a user interacts with the salon booking form on the homepage and clicks the "search" button, they are redirected to the SalonsCategoryPage.
	 *
	 * Upon landing on the SalonsCategoryPage, or whenever filters, pagination, or other actions modify the salon list, a Google Analytics (GA) event is triggered.
	 *
	 * If the user arrived on the SalonsCategoryPage via the booking form:
	 * - The product_lists.list_name attribute of the GA event should be set to 'salons_search'.
	 * - This value should persist as long as the user interacts with the page (e.g., applying filters or pagination) until they leave or refresh the page.
	 *
	 * If the user enters the SalonsCategoryPage through other means—such as:
	 * - The category selection menu on the homepage, Breadcrumbs, The browser's back button,  Directly via URL
	 * The product_lists.list_name attribute should be set to 'salons_list'.
	 *
	 * To ensure the correct GA event is sent based on how the user navigated to the page:
	 *
	 * An IS_SALONS_SEARCH flag should be stored in session storage to track whether the user arrived from the booking form.
	 * This flag helps send the appropriate GA event when the page is rendered or updated.
	 */
	let isSalonsSearch = false

	try {
		isSalonsSearch = sessionStorage.getItem(SESSION_STORAGE_KEYS.IS_SALONS_SEARCH) === 'true'
	} catch (e) {
		// eslint-disable-next-line no-console
		console.error(e)
	}

	const products = salons.map((salon, index) => {
		return {
			product_code: salon.id,
			product_name: salon.name,
			rating: salon.rating,
			availability: salon.openingHoursData?.isOpen ? 'true' : 'false',
			kind: 'salons',
			list_position: index + 1 + SALONS_PER_PAGE * (currentPage - 1),
			list_name: 'salons_list'
		}
	})

	let eventName = 'view_item_list'

	if (isSalonsSearch && !products.length) {
		eventName = 'empty_search_result'
	}

	return {
		event: eventName,
		product_lists: {
			list_name: isSalonsSearch ? 'salons_search' : 'salons_list',
			sorting_type: currentSortingOptionName,
			products,
			type: 'salons'
		},
		_clear: true
	}
}

export const getMapViewItemListEvent = (salons: Salon[]) => {
	const products = salons.map((salon) => {
		return {
			product_code: salon.id,
			product_name: salon.name,
			rating: salon.rating,
			availability: salon.openingHoursData?.isOpen ? 'true' : 'false',
			kind: 'salons',
			list_name: 'salons_list'
		}
	})

	return {
		event: 'view_item_list',
		product_lists: {
			list_name: 'salons_map',
			products,
			type: 'salons'
		},
		_clear: true
	}
}

export const getSelectItemEvent = (params: { salon: Salon; listPosition?: number }) => {
	const { salon, listPosition } = params
	return {
		event: 'select_item',
		product: [
			{
				product_code: salon.id,
				product_name: salon.name,
				rating: salon.rating,
				availability: salon.openingHoursData?.isOpen ? 'true' : 'false',
				kind: 'salons',
				list_position: listPosition, // when clicking on item in map, list position does not make sense
				list_name: 'salons_list'
			}
		],
		_clear: true
	}
}

export const getSortingSetEvent = (params: { option: IDropdownOption }) => {
	const { option } = params
	return {
		event: 'sorting_set',
		sorting: {
			name: (option.id as SORTING_OPTION).toLowerCase(),
			type: 'salons'
		},
		_clear: true
	}
}

export const getViewItemEvent = (params: { salon: SalonDetail }) => {
	const { salon } = params
	return {
		event: 'view_item',
		products: [
			{
				product_code: salon?.id,
				product_name: salon?.name,
				rating: salon?.ratingsData?.rating,
				availability: salon?.openingHoursData?.isOpen ? 'true' : 'false',
				kind: 'salons'
			}
		],
		_clear: true
	}
}

export const getListPaginatingEvent = (params: { page: number }) => {
	const { page } = params
	return {
		event: 'element_click',
		element: {
			name: 'list_pagination',
			action: 'click_on_element',
			interaction: `paging_${page}`,
			type: 'salons'
		},
		_clear: true
	}
}

export const getMobileAppClickEvent = (params: { storeType: AppStoreType }) => {
	const { storeType } = params
	return {
		event: 'mobile_app_click',
		app: {
			name: 'salon',
			type: storeType,
			position: 'reservation_modal'
		},
		_clear: true
	}
}

export const getAddToCartEvent = (salon: SalonDetail) => {
	return {
		event: 'add_to_cart',
		product: {
			product_code: salon.id,
			product_name: salon.name,
			rating: salon.ratingsData.rating,
			availability: salon.openingHoursData?.isOpen ? 'true' : 'false',
			kind: 'salons',
			quantity: 1,
			cart_type: 'salon'
		},
		_clear: true
	}
}

export const getReservationBeginEvent = (params: {
	event: 'reservation_begin'
	mode: 'blank' | 'with_service'
	category: string | undefined
	subcategory: string | undefined
	type: string | undefined
}) => {
	const { event, mode, category, subcategory, type } = params

	return {
		event,
		reservation: {
			interaction: 'button_book_now',
			mode, // {blank|with_service}
			category, // kategorizace podle nabizenych services, co uz je vybrano
			subcategory,
			type,
			subtype: undefined // subtype represents categoryParametereValueID, which we don't know at the moment of the reservation process in which this event is send
		},
		_clear: true
	}
}

export const getReservationSelectServiceEvent = (params: { event: 'reservation_service'; category: string; subcategory: string; type: string }) => {
	const { event, category, subcategory, type } = params

	return {
		event,
		reservation: {
			interaction: 'button_book_now',
			// mode: '', // zde neudavat, nevidim smysl
			category, // kategorizace podle nabizenych services, co uz je vybrano
			subcategory,
			type,
			subtype: undefined // subtype represents categoryParametereValueID, which we don't know at the moment of the reservation process in which this event is send
		},
		_clear: true
	}
}

export const getModalCategoryClickedEvent = (params: { category: string }) => {
	const { category } = params
	return {
		event: 'element_click',
		element: {
			name: category, // 'kliknuta sluzba'
			type: 'reservation_service',
			interaction: 'click'
		},
		_clear: true
	}
}

export const getModalSubtypeSelectedEvent = (params: {
	category: string | undefined
	subcategory: string | undefined
	type: string | undefined
	subtype: string | undefined
}) => {
	const { category, subcategory, type, subtype } = params
	return {
		event: 'reservation_subtype_service',
		reservation: {
			interaction: 'button_continue',
			// mode: '', // zde neudavat, nevidim smysl
			category, // kategorizace podle nabizenych services, co uz je vybrano
			subcategory,
			type,
			subtype
		},
		_clear: true
	}
}

export const getModalReservationCalendarConfirmEvent = (params: {
	category: string | undefined
	subcategory: string | undefined
	type: string | undefined
	subtype: string | undefined
	employee_id: string
	date: string
	slot: string
}) => {
	const { category, subcategory, type, subtype, employee_id, date, slot } = params

	return {
		event: 'reservation_calendar_confirm',
		reservation: {
			interaction: 'button_calendar_confirm',
			category, // kategorizace podle nabizenych services, co uz je vybrano
			subcategory,
			type,
			subtype,
			employee_id,
			date,
			slot
		},
		_clear: true
	}
}

export const getModalReservationConfirmEvent = (params: {
	category: string | undefined
	subcategory: string | undefined
	type: string | undefined
	subtype: string | undefined
	employee_id: string
	date: string
	slot: string | undefined
}) => {
	const { category, subcategory, type, subtype, employee_id, date, slot } = params
	return {
		event: 'reservation_confirm',
		reservation: {
			interaction: 'button_confirm_reservation',
			category, // kategorizace podle nabizenych services, co uz je vybrano
			subcategory,
			type,
			subtype,
			employee_id,
			date, // date of the reservation
			slot //  time slot of the reservation
		},
		_clear: true
	}
}

export const getModalReservationPurchaseEvent = (args: {
	postReservationData: PostCalendarEventsReservationResponse['reservationCalendarEvent']
	industryID: string
	secondLevelCategoryID: string
	serviceCategoryID: string
}) => {
	const { postReservationData, industryID, secondLevelCategoryID, serviceCategoryID } = args
	const decodedPriceFrom = decodePrice(postReservationData.reservationData?.priceFrom)
	const decodedPriceTo = decodePrice(postReservationData.reservationData?.priceTo)
	const currencySymbol = postReservationData.reservationData?.priceFrom?.currencySymbol ?? ''
	const hasPriceFrom = decodedPriceFrom !== undefined && decodedPriceFrom !== null
	const hasPriceTo = decodedPriceTo !== undefined && decodedPriceTo !== null
	let price = ''

	if (hasPriceFrom && hasPriceTo && decodedPriceFrom !== decodedPriceTo) {
		price = `${decodedPriceFrom} - ${decodedPriceTo} ${currencySymbol}`
	} else {
		price = `${decodedPriceFrom || decodedPriceTo} ${currencySymbol}`
	}

	return {
		event: 'purchase_reservation',
		purchase: {
			id: postReservationData.id,
			cart_type: 'salons',
			is_paid: false,
			// new_customer: 'true' | 'false' // pokud ted mame k dispozici, jinak bez property
			email: postReservationData.customer?.email,
			phone: postReservationData.customer ? `${postReservationData.customer.phonePrefix}${postReservationData.customer.phone}` : undefined, // vcetne predvolby
			revenue: (decodedPriceFrom || decodedPriceTo) ?? undefined, // s DPH, pokud neni bez DPH, davejme prosim max castku s dph
			tax: 0, // ted tedy 0, pokud nemame castku bez DPH
			shipping: 0,
			total_value: price, // celkem castka, rozpeti castek, lokalni mena
			// payment_type: string // ted asi neni, nedavat
			// delivery_type: string // ted asi neni, nedavat
			// coupon_code:string // pokud existuje,
			employee_id: postReservationData.employee.id,
			date: dayjs(postReservationData.start.date).format(DEFAULT_DATE_FORMAT),
			slot: `${postReservationData.start.time} - ${postReservationData.end.time}`
		},
		products: [
			{
				product_code: postReservationData.service?.id, // nejaky identifikator
				product_name: postReservationData.service?.name, // objednana sluzba, podle moznosti
				brand_name: postReservationData.salon.name, // salon name
				brand_id: postReservationData.salon.id, // id salonu
				price: (decodedPriceFrom || decodedPriceTo) ?? undefined, // s DPH, pokud neni bez DPH, davejme prosim max castku s dph pro price
				full_price: price, // s dph co vidi uzivatel v lokalni mene i pokud je rozpeti
				quantity: 1,
				primary_category: industryID,
				primary_subcategory: secondLevelCategoryID,
				primary_type: serviceCategoryID, // pokud existuje
				primary_subtype: postReservationData.serviceCategoryParameterValue?.categoryParameterValue.id, // pokud existuje
				kind: 'Salons',
				group: 'service'
			}
		],
		_clear: true
	}
}

const getEventTypeFromReservationStep = (reservationStep?: RESERVATION_BOOKING_MODAL_STEP) => {
	switch (reservationStep) {
		case RESERVATION_BOOKING_MODAL_STEP.SERVICE_SELECTION:
			return 'service'

		case RESERVATION_BOOKING_MODAL_STEP.SERVICE_PARAMETER_SELECTION:
			return 'service_type'

		case RESERVATION_BOOKING_MODAL_STEP.EMPLOYEE_AND_DATE_SELECTION:
			return 'calendar'

		case RESERVATION_BOOKING_MODAL_STEP.BOOKING_CONFIRMATION:
			return 'confirmation'

		case RESERVATION_BOOKING_MODAL_STEP.SUMMARY:
			return 'thankyou'

		case RESERVATION_BOOKING_MODAL_STEP.PROMO_BANNER:
			return 'promo_banner'
		default:
			return 'unknown'
	}
}

export const getModalClosedEvent = ({
	reservationStep,
	interaction,
	elementType
}: {
	reservationStep: RESERVATION_BOOKING_MODAL_STEP
	interaction: NonNullable<ReservationBookingModalCloseType>
	elementType?: string
}) => {
	const type = elementType || getEventTypeFromReservationStep(reservationStep)
	return {
		event: 'reservation_close',
		element: {
			name: 'close_reservation',
			type: `reservation_${type}`, // reservation_{} - identifikator obrazovky, kde to zavrel: service, login (sso), promo, service_type, service_subtype, calendar, thankyou
			interaction // button_x, click_out…, button_close
		},
		_clear: true
	}
}

export const getModalBackButtonEvent = ({ reservationStep }: { reservationStep?: RESERVATION_BOOKING_MODAL_STEP }) => {
	const type = getEventTypeFromReservationStep(reservationStep)

	return {
		event: 'reservation_back',
		element: {
			name: 'back_reservation',
			type: `reservation_${type}`, // reservation_{} - identifikator obrazovky, kde to zavrel, service, service_type, service_subtype, calendar, thankyou
			interaction: 'button_back'
		},
		_clear: true
	}
}

export const getModalNextReservationEvent = () => {
	return {
		event: 'element_click',
		element: {
			name: 'next_reservation',
			type: 'reservation_modal',
			interaction: 'button_next'
		},
		_clear: true
	}
}

export const getServiceTabClickEvent = (params: { categoryId: string }) => {
	const { categoryId } = params
	return {
		event: 'element_click',
		element: {
			name: categoryId,
			type: 'pdp_service',
			interaction: 'click'
		},
		_clear: true
	}
}

export const getViewOnMapEvent = ({ buttonType }: { buttonType: 'button' | 'text_header' | 'text_detail' }) => {
	return {
		event: 'element_click',
		element: {
			name: 'map',
			type: buttonType, // ’button|text_header|text_detail’,
			interaction: 'pdp'
		}
	}
}

export const getBookingFormSubmitEvent = (params: {
	timing: number
	searchTerm: string
	dateFrom: Dayjs | undefined
	dateTo: Dayjs | undefined
	timeSlot: RESERVATIONS_TIME_SLOTS
	category: string | undefined
	subcategory: string | undefined
}) => {
	const { timing, searchTerm, dateFrom, dateTo, timeSlot, category, subcategory } = params

	const dateFromStr = dateFrom ? dayjs(dateFrom).format(DEFAULT_DATE_FORMAT) : undefined
	const dateToStr = dateTo ? dayjs(dateTo).format(DEFAULT_DATE_FORMAT) : undefined
	const date = dateFromStr ? `${dateFromStr}${dateToStr ? ` - ${dateToStr}` : ''}` : 'anytime'

	return {
		event: 'search',
		search: {
			timing, // cas od kliku do pole
			term: searchTerm.toLowerCase(), // 'zapsany hledany vyraz lowercase', pole lokace
			action: 'search_booking_form_used', // popisuje vyuziti vyhledavani
			interaction: 'click', // akce, uzivatele podle seznamu (key_enter, click…) NOTE: hardcoding click, because it's hard to find out if user clicked or pressed enter
			date, // if any specific date / date interval was selected ("YYYY-mm-dd" | "YYYY-mm-dd - YYYY-mm-dd" | ‘anytime’)
			time: timeSlot, // time:string // “anytime|just_date|morning|afternoon|evening”
			category, // stejne jako akce filter.name u akce filter_set
			subcategory, // stejne jako akce filter.name u akce filter_set nebo “anything”
			type: 'salons_booking' // odliseni
		},
		_clear: true
	}
}

export const getClickToSearchBookingEvent = ({ isMobile }: { isMobile: boolean }) => {
	return {
		event: 'search_open',
		search: {
			interaction: 'click',
			action: 'click_to_search_booking',
			type: 'salons_booking',
			variant: isMobile ? 'mobile' : 'desktop'
		},
		_clear: true
	}
}
