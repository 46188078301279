import React, { useState } from 'react'
import Tabs, { TabsProps } from 'rc-tabs'

// types
import {
	ConfigResponse,
	SalonDetail,
	SalonPageQueryType,
	SalonPageSetQueryType,
	SalonReviewsResponse,
	SalonServicesResponse,
	ServiceCardCallbackData,
	ServiceDetailData
} from '../../../../types/types'

// utils
import { getFirstLevelCategoriesNames, getGoogleMapsLink, getPhoneNumber } from '../../../../utils/helper'

// components
import OpeningHours from '../OpeningHours/OpeningHours'
import SalonAboutUsMobile from '../SalonAboutUsMobile/SalonAboutUsMobile'
import CtaButtons from '../CtaButtons/CtaButtons'
import SalonAboutUsDesktop from '../SalonAboutUs/SalonAboutUs'
import SalonContacts from '../SalonContacts/SalonContacts'
import SalonDetailCard from '../SalonDetailCard/SalonDetailCard'
import SalonPageReviews from '../SalonPageReviews/SalonPageReviews'
import SalonPricelists from '../SalonPricelists/SalonPricelists'
import SalonServicesCollapse from '../SalonServicesCollapse/SalonServicesCollapse'
import SalonServicesTabs from '../SalonServicesTabs/SalonServicesTabs'
import ProfiSalonHeader from '../ProfiSalonHeader/ProfiSalonHeader'
import GalleryDesktop from '../GalleryDesktop/GalleryDesktop'
import GalleryMobile from '../GalleryMobile/GalleryMobile'
import SalonServiceDetail from '../../../../components/SalonServiceDetail/SalonServiceDetail'

// hooks
import useMessages from '../../../../hooks/useMessages'

// styles
import * as SC from './ProfiSalonStyles'
import { DesktopOnly, MobileOnly } from '../../../../styles/helpers'
import { SALON_PAGE_MOBILE_BREAKPOINT_KEY } from '../../../../styles/constants'

type Props = {
	salonData: SalonDetail
	countriesData: ConfigResponse['rolloutCountries']
	salonServicesData: SalonServicesResponse
	salonReviewsData: SalonReviewsResponse
	onOpenBookingModal: (data?: ServiceCardCallbackData) => void
	query: SalonPageQueryType
	setQuery: SalonPageSetQueryType
	fetchSalonData: () => Promise<void>
	allowedReservations: boolean
}

const ProfiSalon = (props: Props) => {
	const { messages } = useMessages()

	const { salonData, countriesData, salonServicesData, salonReviewsData, onOpenBookingModal, query, setQuery, fetchSalonData, allowedReservations } = props

	const phoneNumber = getPhoneNumber(salonData.phones[0], countriesData)

	const [tabKey, setTabKey] = useState<string>(query.calendarEventID ? 'reviews' : 'overview')

	const [serviceDetail, setServiceDetail] = useState<ServiceDetailData | null>(null)

	const renderServices = salonServicesData && salonServicesData.groupedServicesByCategory?.length > 0
	const renderPricelists = salonData.pricelists && salonData.pricelists.length > 0

	const salonServices = getFirstLevelCategoriesNames(salonServicesData)

	const googleMapLink = getGoogleMapsLink({ lat: salonData.address?.latitude, lon: salonData.address?.longitude })

	const getTabsItems = () => {
		const tabsItems: TabsProps['items'] = [
			{
				key: 'overview',
				label: messages.Overview,
				children: (
					<>
						{salonData.openingHoursData && (
							<SC.OpeningHoursWrapper>
								<OpeningHours openingHours={salonData.openingHoursData} />
							</SC.OpeningHoursWrapper>
						)}
						{renderServices && (
							<SC.ServicesWrapper>
								<SalonServicesCollapse
									phoneNumber={phoneNumber}
									services={salonServicesData}
									onServiceCardButtonClick={onOpenBookingModal}
									setServiceDetail={setServiceDetail}
								/>
							</SC.ServicesWrapper>
						)}
						<SC.AboutUsWrapper>
							<SalonAboutUsMobile salonData={salonData} countriesData={countriesData} shouldClampDescription={true} />
						</SC.AboutUsWrapper>
					</>
				)
			}
		]

		tabsItems.push({
			key: 'reviews',
			label: messages.Reviews,
			children: (
				<SalonPageReviews salonData={salonData} reviewsData={salonReviewsData} fetchSalonData={fetchSalonData} query={query} setQuery={setQuery} />
			)
		})

		tabsItems.push({
			key: 'about-us',
			label: messages['About us'],
			children: <SalonAboutUsMobile salonData={salonData} countriesData={countriesData} hideFirstSectionHeading hideTeamSection />
		})

		if (renderPricelists) {
			tabsItems.push({
				key: 'pricelists',
				label: messages['Price list'],
				children: <SalonPricelists pricelists={salonData.pricelists} hideHeading />
			})
		}

		return tabsItems
	}

	return (
		<div>
			{/* desktop header */}
			<DesktopOnly $breakpoint={SALON_PAGE_MOBILE_BREAKPOINT_KEY}>
				<ProfiSalonHeader
					name={salonData.name}
					logoUrl={salonData.logo?.resizedImages?.small}
					address={salonData.formattedAddress}
					googleMapLink={googleMapLink}
					ratingsData={salonData.ratingsData}
					openingHoursData={salonData.openingHoursData}
					ratingId='header-desktop-rating'
				/>
			</DesktopOnly>

			{/* images */}
			<DesktopOnly $breakpoint={SALON_PAGE_MOBILE_BREAKPOINT_KEY}>
				<GalleryDesktop images={salonData.images} allowedReservations={allowedReservations} />
			</DesktopOnly>
			<MobileOnly $breakpoint={SALON_PAGE_MOBILE_BREAKPOINT_KEY}>
				<GalleryMobile images={salonData.images} allowedReservations={allowedReservations} />
			</MobileOnly>

			{/* mobile content */}
			<MobileOnly $breakpoint={SALON_PAGE_MOBILE_BREAKPOINT_KEY}>
				<ProfiSalonHeader
					name={salonData.name}
					address={salonData.formattedAddress}
					logoUrl={salonData.logo?.resizedImages?.small}
					ratingsData={salonData.ratingsData}
					ratingId='header-mobile-rating'
				/>
				<CtaButtons
					salonData={salonData}
					countriesData={countriesData}
					openReservationBookingModal={onOpenBookingModal}
					googleMapLink={googleMapLink}
				/>
				<SC.TabsContainer>
					<Tabs activeKey={tabKey} onChange={(activeKey) => setTabKey(activeKey)} items={getTabsItems()} animated={false} />
				</SC.TabsContainer>
			</MobileOnly>

			{/* desktop content */}
			<DesktopOnly $breakpoint={SALON_PAGE_MOBILE_BREAKPOINT_KEY}>
				<SC.Columns>
					<SC.MainColumn>
						{/* about us text */}
						{salonData.aboutUsFirst && <SC.AboutUsText>{salonData.aboutUsFirst}</SC.AboutUsText>}
						{/* cta buttons */}
						<CtaButtons
							salonData={salonData}
							countriesData={countriesData}
							openReservationBookingModal={onOpenBookingModal}
							googleMapLink={googleMapLink}
						/>
						{/* services */}
						{renderServices && (
							<>
								<SC.ServicesHeading>{messages.Services}</SC.ServicesHeading>
								<SalonServicesTabs
									phoneNumber={phoneNumber}
									services={salonServicesData}
									onServiceCardButtonClick={onOpenBookingModal}
									setServiceDetail={setServiceDetail}
									selectedTabKey={query.selectedIndustryID || undefined}
								/>
							</>
						)}
						{/* about us section */}
						<SalonAboutUsDesktop salonData={salonData} />
						{/* pricelists */}
						{renderPricelists && <SalonPricelists pricelists={salonData.pricelists} />}
						{/* ratings */}
						<SalonPageReviews
							salonData={salonData}
							reviewsData={salonReviewsData}
							fetchSalonData={fetchSalonData}
							query={query}
							setQuery={setQuery}
						/>
					</SC.MainColumn>
					<SC.SideColumn>
						<SalonContacts salonData={salonData} countriesData={countriesData} />
					</SC.SideColumn>
				</SC.Columns>
				<SalonDetailCard salonData={salonData} countries={countriesData} salonServices={salonServices} />
			</DesktopOnly>
			{serviceDetail && (
				<SalonServiceDetail
					onServiceCardButtonClick={onOpenBookingModal}
					phoneNumber={phoneNumber}
					serviceData={serviceDetail}
					onClose={() => setServiceDetail(null)}
				/>
			)}
		</div>
	)
}

export default ProfiSalon
