import React from 'react'
import useMessages from '../../hooks/useMessages'

// components
import Input from '../../atoms/Input/Input'
import FormItem from '../../atoms/FormItem/FormItem'
import Select from '../../atoms/Select/Select'

// types
import { InputFormFieldProps, PhoneFieldValue, PhonePrefixOption } from './types'
import { SelectProps } from '../../atoms/Select/types'

// styles
import * as SC from './PhoneFormFieldStyles'

// utils
import { transformToLowerCaseWithoutAccent } from '../../utils/helper'
import { VALIDATION_MAX_LENGTH } from '../../utils/enums'

type PrefixSelectProps = SelectProps<NonNullable<PhoneFieldValue['phonePrefixCountryCode'] | undefined>, PhonePrefixOption>

const labelRender: PrefixSelectProps['labelRender'] = (option) => {
	return (
		<SC.PrefixValueWrapper>
			<SC.PrefixFlag $image={option.extra?.countryFlag} />
			<SC.PrefixValueLabel>{option.label}</SC.PrefixValueLabel>
		</SC.PrefixValueWrapper>
	)
}

const optionRender: PrefixSelectProps['optionRender'] = (option) => {
	return (
		<SC.PrefixOptionWrapper>
			<SC.PrefixFlag $image={option.extra?.countryFlag} />
			<SC.PrefixOptionLabel>{option.extra?.countryName ?? option.label}</SC.PrefixOptionLabel>
			<SC.PrefixOptionDescription>{option.label}</SC.PrefixOptionDescription>
		</SC.PrefixOptionWrapper>
	)
}

const filterOptionFnc: PrefixSelectProps['filterOption'] = (inputValue, option) => {
	if (!option) {
		return true
	}
	const filterByCountryName = transformToLowerCaseWithoutAccent(option?.extra?.countryName)?.includes(transformToLowerCaseWithoutAccent(inputValue))
	const filterByPhonePrefix = transformToLowerCaseWithoutAccent(option?.label)?.includes(transformToLowerCaseWithoutAccent(inputValue))

	return filterByCountryName || filterByPhonePrefix
}

const PhoneFormField = (props: InputFormFieldProps) => {
	const { messages } = useMessages()

	const {
		input: { value, onChange, onBlur, onFocus },
		meta: { error, invalid },
		label = messages.Phone,
		htmlFor,
		required,
		configData,
		getPopupContainer,
		dropdownStyle,
		...restProps
	} = props

	const { phoneNumber, phonePrefixCountryCode } = value

	const invalidStatus = !!(error || invalid)

	const phonePrefixOptions: PhonePrefixOption[] =
		configData?.allCountries.map((country) => ({
			key: country.code,
			label: country.phonePrefix,
			value: country.code,
			extra: {
				countryName: country.name,
				countryFlag: country.flag
			}
		})) || []

	return (
		<FormItem htmlFor={htmlFor} label={label} required={required} error={invalidStatus && error ? error : undefined}>
			<SC.PhoneFieldsWrapper>
				<SC.PhonePrefixCol>
					<Select<NonNullable<PhoneFieldValue['phonePrefixCountryCode'] | undefined>, PhonePrefixOption>
						value={phonePrefixCountryCode}
						onChange={(newValue) => {
							if (newValue) {
								onChange({ ...value, phonePrefixCountryCode: newValue })
							}
						}}
						placeholder={messages['Phone prefix']}
						options={phonePrefixOptions}
						menuItemSelectedIcon={null}
						dropdownStyle={{ width: 270, ...(dropdownStyle || {}) }}
						getPopupContainer={getPopupContainer}
						labelRender={labelRender}
						optionRender={optionRender}
						showSearch
						filterOption={filterOptionFnc}
						invalid={invalidStatus}
					/>
				</SC.PhonePrefixCol>
				<SC.PhoneNumberCol>
					<Input
						value={phoneNumber}
						type={'tel'}
						onChange={(e) => onChange({ ...value, phoneNumber: e.target.value })}
						onBlur={onBlur}
						onFocus={onFocus}
						placeholder={messages['Your phone number']}
						invalid={invalidStatus}
						maxLength={VALIDATION_MAX_LENGTH.LENGTH_20}
						{...restProps}
					/>
				</SC.PhoneNumberCol>
			</SC.PhoneFieldsWrapper>
		</FormItem>
	)
}

export default PhoneFormField
