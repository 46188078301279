import React from 'react'
import { Ratings } from '@notino/react-styleguide'

// styles
import * as SC from './ReviewsSectionStyles'
import { HomePageContainer } from '../../HomePageStyles'

// hooks
import useMessages from '../../../../hooks/useMessages'

// types
import { CmsHomepageData } from '../../../../types/types'

type Props = {
	cmsData: CmsHomepageData['npTestimonialsData'] | undefined
}

type Review = {
	ratingId: string
	rating: number
	name: string
	title: string
	text: string
}

// component
const ReviewsSection = (props: Props) => {
	const { cmsData } = props

	const { messages } = useMessages()

	const REVIEWS: Review[] = cmsData?.testimonials.length
		? cmsData.testimonials.map((review, index) => {
				return {
					ratingId: `review-${index}`,
					rating: review.rating ?? 5,
					name: review.fullName ?? '',
					title: review.title ?? '',
					text: review.review ?? ''
				}
			})
		: [
				{
					ratingId: 'review-1',
					rating: 5,
					name: messages['review-1-reviewer-name'],
					title: messages['review-1-title'],
					text: messages['review-1-text']
				},
				{
					ratingId: 'review-2',
					rating: 5,
					name: messages['review-2-reviewer-name'],
					title: messages['review-2-title'],
					text: messages['review-2-text']
				},
				{
					ratingId: 'review-3',
					rating: 5,
					name: messages['review-3-reviewer-name'],
					title: messages['review-3-title'],
					text: messages['review-3-text']
				}
			]

	const hasMoreThanThreeReviews = REVIEWS.length > 3

	return (
		<section>
			<HomePageContainer>
				<SC.SectionTitle $marginBottom={'24px'} $textAlign={'center'}>
					{cmsData?.title || messages['review-section-title']}
				</SC.SectionTitle>
				<SC.Layout $hasMoreThanThreeReviews={hasMoreThanThreeReviews}>
					{REVIEWS.map((review, index) => {
						return (
							<SC.ReviewCard key={index}>
								<SC.ReviewHeading>
									<Ratings
										ratingId={review.ratingId}
										rating={review.rating}
										size={14}
										foreground={'text.highlight'}
										background={'background.tertiary'}
									/>
									<SC.ReviewerName>{review.name}</SC.ReviewerName>
								</SC.ReviewHeading>
								<SC.ReviewTitle>{review.title}</SC.ReviewTitle>
								<SC.ReviewText>{review.text}</SC.ReviewText>
							</SC.ReviewCard>
						)
					})}
				</SC.Layout>
			</HomePageContainer>
		</section>
	)
}

export default ReviewsSection
