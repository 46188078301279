import React from 'react'
import { Button, ButtonModel } from '@notino/react-styleguide'

// styles
import * as SC from './EmptyStyles'

// hooks
import useMessages from '../../hooks/useMessages'

// assets
import EmptyStateIcon from '../../assets/icons/EmptyStateIcon'
import { FixedHrefButton } from '../../styles/helpers'

type Props = {
	title?: React.ReactNode
	label?: React.ReactNode
	icon?: React.ReactNode
	minHeight?: string
	maxWidth?: string
	button?: {
		href?: string
		label: string
		onClick?: (e: React.SyntheticEvent<HTMLElement>) => void
	}
	className?: string
}

const Empty = (props: Props) => {
	const { label, title, icon = <EmptyStateIcon />, minHeight = '300px', maxWidth = '400px', button, className } = props
	const { messages } = useMessages()

	return (
		<SC.EmptyWrapper $minHeight={minHeight} className={className}>
			{icon && <SC.EmptyIconWrapper>{icon}</SC.EmptyIconWrapper>}
			<SC.EmptyTitle $maxWidth={maxWidth}>{title || messages['Unfortunately, no data was found']}</SC.EmptyTitle>
			{label && <SC.EmptyLabel $maxWidth={maxWidth}>{label}</SC.EmptyLabel>}
			{button && (
				<SC.ButtonWrapper>
					{button.href ? (
						<FixedHrefButton href={button.href} type={'button'} buttonStyle={ButtonModel.Styles.primary} onClick={button.onClick}>
							{button.label}
						</FixedHrefButton>
					) : (
						<Button type={'button'} buttonStyle={ButtonModel.Styles.primary} onClick={button.onClick}>
							{button.label}
						</Button>
					)}
				</SC.ButtonWrapper>
			)}
		</SC.EmptyWrapper>
	)
}

export default Empty
