/* eslint-disable global-require */
import styled, { css, keyframes } from 'styled-components'
import { theme } from '@notino/react-styleguide'

// utils
import { NotinoBodySmall, NotinoLabelRegular500, resetButtonStyles } from '../../styles/helpers'

// types
import { DatePickerProps } from './types'

type SizeType = NonNullable<DatePickerProps['size']>

// cell width in PX
const CELL_WIDTH: Record<SizeType, number> = {
	small: 30,
	default: 40
}

export const DatePickerWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: ${theme.color.background.primary};
	margin: 0 auto;
`

export const DatePickerHeader = styled.div`
	margin-bottom: 10px;
	width: 100%;
	display: flex;
	gap: 4px;
	align-items: center;
`

export const SelectedDate = styled.span`
	${NotinoLabelRegular500};
	color: ${theme.color.text.primary};
	text-transform: capitalize;
`

export const SliderButton = styled.button`
	${resetButtonStyles};
	width: 24px;
	height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	outline: 2px solid transparent;

	svg {
		width: 16px;
		height: 16px;
	}

	&:not(:disabled) {
		&:focus-visible {
			outline-color: ${theme.color.text.primary};
		}
	}

	&:disabled {
		cursor: default;
		&::before {
			opacity: 0.3;
		}
	}
`

export const ExpandButton = styled.button`
	${resetButtonStyles};
	${NotinoLabelRegular500};
	color: ${theme.color.text.primary};
	display: flex;
	align-items: center;
	gap: 4px;
	padding: 4px;
	text-decoration: underline;
	margin-left: auto;

	svg {
		flex-shrink: 0;
		height: 20px;
		width: 20px;
	}

	&:not(:disabled) {
		&:focus-visible {
			outline-color: ${theme.color.text.primary};
		}
	}

	&:disabled {
		cursor: default;
		&::before {
			opacity: 0.3;
		}
	}
`

export const CalendarWrapper = styled.div`
	overflow: hidden;
	width: 100%;
	min-width: 280px;
`
export const CalendarGrid = styled.div`
	display: grid;
	grid-template-columns: repeat(7, 1fr);
	grid-row-gap: 6px;
	width: 100%;
	justify-content: space-between;
	overflow: hidden;
`

export const HeaderCell = styled.div<{ $size: SizeType }>`
	${({ $size }) => css`
		width: 100%;
		height: ${CELL_WIDTH[$size]}px;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		font-weight: 500;
		font-size: ${$size === 'small' ? '0.875rem' : '1.25rem'};
		line-height: ${$size === 'small' ? '1rem' : '1.5rem'};
		color: #9f9f9f;
		text-transform: capitalize;
	`}
`

export const DayCell = styled.div<{
	$gridShift: number | undefined
	$size: SizeType
}>`
	${({ $gridShift, $size }) => css`
		text-align: center;
		height: ${CELL_WIDTH[$size]}px;
		width: 100%;
		display: flex;
		justify-content: center;
		position: relative;
		background-color: transparent;

		${$gridShift &&
		css`
			grid-column-start: ${$gridShift};
		`}
	`}
`

export const DayCellButton = styled.div<{
	$isAvailable: boolean
	$isSelected: boolean
	$isToday: boolean
	$isPast: boolean
	$size: SizeType
	$disabled: boolean
}>`
	${({ $isAvailable, $isSelected, $isToday, $size, $isPast, $disabled }) => css`
		width: ${CELL_WIDTH[$size]}px;
		height: ${CELL_WIDTH[$size]}px;
		border-radius: ${CELL_WIDTH[$size] / 2}px;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		cursor: pointer;
		font-size: ${$size === 'small' ? '0.875rem' : '1.25rem'};
		line-height: ${$size === 'small' ? '1rem' : '1.5rem'};
		font-weight: 300;
		color: #404040;
		position: relative;
		z-index: 2;
		background-color: transparent;
		border: 1px solid transparent;

		${($isPast || !$isAvailable) &&
		css`
			&::after {
				position: absolute;
				content: '';
				height: 1px;
				width: 24px;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				background-color: #9f9f9f;
			}
		`}

		${$isSelected &&
		css`
			background: ${theme.color.background.highlight};
			border-color: ${theme.color.common.magenta50};
			color: ${theme.color.text.inverse};
		`}

		${$isSelected &&
		!$isAvailable &&
		css`
			&::after {
				background-color: ${theme.color.background.primary};
			}
		`}

		${$isToday &&
		!$isSelected &&
		css`
			border-color: ${theme.color.common.white};
		`}

		${($isPast || $disabled) &&
		css`
			color: #9f9f9f;
			cursor: default;
		`}
	`}
`

export const ErrorMsg = styled.p`
	${NotinoBodySmall};
	color: ${theme.color.text.negative};
	margin: 0;
	align-self: start;
`

const transitionAnimation = keyframes`
	0% { transform: translateX(100%); }
	100% { transform: translateX(0); }
`

export const CalendarTransitionAnimation = styled.div`
	transform: translateX(100%);
	animation: ${transitionAnimation} 0.2s ease-out forwards;
`

const opacityAnimation = keyframes`
	0% { opacity: 0; }
	100% { opacity: 1; }
`

export const CalendarOpacityAnimation = styled.div`
	opacity: 0;
	animation: ${opacityAnimation} 0.2s ease-out forwards;
`
