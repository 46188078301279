import styled, { css } from 'styled-components'
import { Heading, theme } from '@notino/react-styleguide'
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'

// components
import DefaultContainer from '../../components/DefaultContainer/DefaultContainer'

// styles
import { BREAKPOINTS, SALONS_PAGE_MOBILE_BREAKPOINT } from '../../styles/constants'
import { NotinoLabelRegular400 } from '../../styles/helpers'

export const Container = styled(DefaultContainer)``

export const SalonsPageWrapper = styled.div`
	display: flex;
	gap: 32px;
`

export const SalonsPageBreadcrumbs = styled(Breadcrumb)`
	margin-bottom: 24px;

	@media (min-width: ${BREAKPOINTS.lg}) {
		margin-bottom: 24px;
	}
`

export const Grid = styled.div`
	flex: 1;
	min-width: 0; // important so the Grid does not stretch when children overflow
`

export const SalonsSearchWrapper = styled.div`
	width: 100%;
	max-width: 100%;

	@media (min-width: ${SALONS_PAGE_MOBILE_BREAKPOINT}) {
		max-width: 382px;
	}
`

export const TitleWrapper = styled.div`
	display: flex;
	margin-top: 24px;
	flex-direction: column;
	gap: 8px;
	margin-bottom: 16px;

	@media (min-width: ${SALONS_PAGE_MOBILE_BREAKPOINT}) {
		gap: 16px;
	}
`

export const MainTitle = styled(Heading.H1)`
	font-weight: 500;
	margin: 0;
	text-align: left !important; // override for notino global styles
`

export const CategoryDescriptionTitle = styled(Heading.H2)`
	text-align: center;
`

export const CategoryDescriptionContainer = styled.div`
	margin-top: 32px;

	@media (min-width: ${SALONS_PAGE_MOBILE_BREAKPOINT}) {
		margin-top: 64px;
	}
`

export const CategoryDescription = styled.div`
	${NotinoLabelRegular400};
	margin-bottom: 0;

	> p:not(:last-child) {
		margin-bottom: 16px;
	}
`

export const SubcategoriesSliderWrapper = styled.div`
	margin-top: 16px;

	@media (min-width: ${SALONS_PAGE_MOBILE_BREAKPOINT}) {
		margin-top: 40px;
	}
`

export const ChipsSlider = styled.div`
	display: flex;
	gap: 8px;
	white-space: nowrap;
	overflow: auto;
	padding-bottom: 4px;
	scroll-behavior: smooth;
	margin-top: 8px;
`

export const SubcategoryButton = styled.button<{ $isSelected: boolean }>`
	height: 32px;
	background-color: ${theme.color.background.primary};
	display: inline-flex;
	align-items: center;
	gap: 6px;
	font-size: 12px;
	padding: 0 16px;
	outline: none;
	border: 1px solid;
	transition: all 0.3s ease;
	white-space: nowrap;

	&:not(:disabled) {
		color: rgb(64, 64, 64);
		cursor: pointer;

		&:hover {
			background-color: rgb(245, 245, 245);
		}
	}

	&:not(:last-child) {
		margin-right: 8px;
	}

	${({ $isSelected }) =>
		$isSelected
			? css`
					border-color: ${theme.color.common.black};
					&:not(:disabled) {
						&:hover {
							border-color: ${theme.color.common.black};
						}
					}
				`
			: css`
					border-color: ${theme.color.border.divider};
					&:not(:disabled) {
						&:hover {
							border-color: ${theme.color.border.divider};
						}
					}
				`};

	@media (min-width: ${SALONS_PAGE_MOBILE_BREAKPOINT}) {
		&:first-child {
			margin-left: 0;
		}
		&:last-child {
			margin-right: 0;
		}
	}
`

export const CategoryButtonIcon = styled.img`
	height: 15px;
	width: 15px;
	object-fit: contain;
`
