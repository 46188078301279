import React from 'react'
import { Modal, ModalModel } from '@notino/react-styleguide'

import * as SC from './VideoModalStyles'

type Props = {
	show: boolean
	onClose: () => void
	videoSrc: string
	width?: number
	height?: number
}

const VideoModal = (props: Props) => {
	const { show, onClose, videoSrc, width, height } = props

	return (
		<Modal show={show} onClose={onClose} size={ModalModel.Sizes.s1200} noBorders centerVertically>
			<SC.VideoContainer>
				<SC.Video width={width} height={height} controls controlsList={'nodownload'} playsInline autoPlay>
					<source src={videoSrc} />
					<track kind='captions' />
				</SC.Video>
			</SC.VideoContainer>
		</Modal>
	)
}

export default VideoModal
