import { GetSalonsDataParams } from '../../types/types'
import { SORTING_OPTION } from '../../utils/enums'

export const RECOMMENDED_SALONS_PARAMS: GetSalonsDataParams = {
	hasAvailableReservationSystem: true,
	orderBy: SORTING_OPTION.RECOMMENDED,
	limit: 20,
	page: 1
}

export const SELECTED_CATEGORY_ID = '00000000-0000-0000-0000-000000000001'

export const SELECTED_CATEGORY_SALONS_PARAMS: GetSalonsDataParams = {
	hasAvailableReservationSystem: true,
	orderBy: SORTING_OPTION.RECOMMENDED,
	limit: 20,
	page: 1,
	categoryIDs: [SELECTED_CATEGORY_ID]
}

export const HERO_CATEGORIES_COUNT_MOBILE = 5
export const HERO_CATEGORIES_COUNT_DESKTOP = 8
