import React, { useId } from 'react'

const CheckIcon = () => {
	const id = useId() // Generate a unique ID

	// The mask must have a unique ID; otherwise, if this SVG is used more than once on the same screen, it can cause an issue where one instance may appear as a black square.
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' viewBox='0 0 20 20'>
			<mask id={`mask-${id}`} style={{ maskType: 'alpha' }} width='16' height='14' x='2' y='3' maskUnits='userSpaceOnUse'>
				<path
					fill='#fff'
					stroke='#fff'
					strokeWidth='0.208'
					d='M3.514 10.491l-.074-.073-.074.073-.94.94-.073.074.073.074 4.207 4.207.074.073.074-.073L17.389 5.18l.073-.074-.073-.073-.94-.94-.074-.074-.074.074-9.594 9.594-3.193-3.196z'
				/>
			</mask>
			<g fill='currentColor' mask={`url(#mask-${id})`}>
				<path d='M3.44 10.565l-.94.94 4.207 4.207L17.315 5.106l-.94-.94-9.668 9.669-3.267-3.27z' />
				<path d='M20 0H0v20h20V0z' />
			</g>
		</svg>
	)
}

export default CheckIcon
