import styled, { css } from 'styled-components'
import { theme } from '@notino/react-styleguide'
import { INFO_LINE_TYPE } from './types'
import { NotinoLabelRegular400, NotinoLabelRegular500, resetButtonStyles } from '../../styles/helpers'

export const InfoLine = styled.div<{ $type: INFO_LINE_TYPE }>`
	display: flex;
	gap: 8px;
	padding: 12px;

	${({ $type }) => {
		switch ($type) {
			case INFO_LINE_TYPE.WARNING:
				return css`
					background: #feefd1;
				`
			case INFO_LINE_TYPE.SUCCESS:
				return css`
					background: #f2fbf2;
				`
			case INFO_LINE_TYPE.ERROR:
				return css`
					background: #fff6f6;
				`
			case INFO_LINE_TYPE.INFO:
			default:
				return css`
					background: #f7f7f7;
				`
		}
	}}
`

export const IconWrapper = styled.div`
	flex-shrink: 0;
	width: 1.25rem;
	height: 1.25rem;

	svg {
		width: 1rem;
		height: 1rem;
	}
`

export const ContentWrapper = styled.div`
	flex: 1;
`

export const Title = styled.div<{ $type: INFO_LINE_TYPE }>`
	${NotinoLabelRegular500};
	margin-bottom: 4px;

	${({ $type }) => {
		switch ($type) {
			case INFO_LINE_TYPE.WARNING:
				return css`
					color: ${theme.color.text.primary};
				`
			case INFO_LINE_TYPE.SUCCESS:
				return css`
					color: ${theme.color.text.positive};
				`
			case INFO_LINE_TYPE.ERROR:
				return css`
					color: ${theme.color.text.negative};
				`
			case INFO_LINE_TYPE.INFO:
			default:
				return css`
					color: ${theme.color.text.primary};
				`
		}
	}}
`

export const Message = styled.div`
	${NotinoLabelRegular400};
	color: ${theme.color.text.secondary};
`

export const CloseButton = styled.button<{ $disabled?: boolean }>`
	${resetButtonStyles};
	flex-shrink: 0;
	width: 1.25rem;
	height: 1.25rem;
	display: flex;
	align-items: center;
	justify-content: center;
	color: ${theme.color.text.secondary};

	svg {
		width: 100%;
		height: 100%;
		color: ${theme.color.text.secondary};
	}
`
