import React from 'react'
import { IconRegularPinterest, IconRegularTiktok, IconRegularYoutube } from '@notino/react-styleguide'
import { SalonDetail } from '../../../../types/types'
import GlobeIcon from '../../../../assets/icons/GlobeIcon'
import FacebookIcon from '../../../../assets/icons/FacebookIcon'
import InstagramIcon from '../../../../assets/icons/InstagramIcon'
import { SOCIAL_LINK_VARIANT } from './SocialIconsStyles'
import * as SC from './SocialIconsStyles'

type Props = {
	salonData: SalonDetail
	variant?: SOCIAL_LINK_VARIANT
}

const SocialIcons = ({ salonData, variant = SOCIAL_LINK_VARIANT.LIGHT }: Props) => {
	return (
		<SC.Container>
			{salonData.socialLinkWebPage && (
				<SC.SocialLink $variant={variant} target='_blank' href={salonData.socialLinkWebPage} rel={'noopener noreferrer'}>
					<GlobeIcon />
				</SC.SocialLink>
			)}
			{salonData.socialLinkFB && (
				<SC.SocialLink $variant={variant} target='_blank' href={salonData.socialLinkFB} rel={'noopener noreferrer'}>
					<FacebookIcon />
				</SC.SocialLink>
			)}
			{salonData.socialLinkInstagram && (
				<SC.SocialLink $variant={variant} target='_blank' href={salonData.socialLinkInstagram} rel={'noopener noreferrer'}>
					<InstagramIcon />
				</SC.SocialLink>
			)}
			{salonData.socialLinkPinterest && (
				<SC.SocialLink $variant={variant} target='_blank' href={salonData.socialLinkPinterest} rel={'noopener noreferrer'}>
					<IconRegularPinterest color={'icon.inverse'} />
				</SC.SocialLink>
			)}
			{salonData.socialLinkYoutube && (
				<SC.SocialLink $variant={variant} target='_blank' href={salonData.socialLinkYoutube} rel={'noopener noreferrer'}>
					<IconRegularYoutube color={'icon.inverse'} />
				</SC.SocialLink>
			)}
			{salonData.socialLinkTikTok && (
				<SC.SocialLink $variant={variant} target='_blank' href={salonData.socialLinkTikTok} rel={'noopener noreferrer'}>
					<IconRegularTiktok color={'icon.inverse'} />
				</SC.SocialLink>
			)}
		</SC.Container>
	)
}

export default SocialIcons
