import { useIntl } from 'react-intl'

const useMessages = () => {
	/*
	NOTE: this custom hook is needed for type casting.
	Messages' type from useIntl is by default Record<string, string> | Record<string, MessageFormatElement[]>.
	In React 16, we could use messages[key] as children in JSX (for example <div>{message['message']}</div>)
    but in React 18, this throws TS error (Type 'string | MessageFormatElement[]' is not assignable to type 'ReactNode'.)
	Thus we cast messages to Record<string, string> and drop the MessageFormatElement[] type.
    */
	const { messages } = useIntl()
	return { messages: messages || {} } as { messages: Record<string, string> }
}

export default useMessages
