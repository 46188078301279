import React, { ComponentProps, PropsWithChildren } from 'react'

// assets
import SalonsAndCitiesSearch from '../../components/SalonsAndCitiesSearch/SalonsAndCitiesSearch'

// types
import { SalonsAndCitiesSearchValue } from '../../types/types'
import { WrappedFieldsProps } from '../../types/wrappedFieldsProps'

type SalonsAndCitiesSearchFormFieldProps = PropsWithChildren<
	WrappedFieldsProps<SalonsAndCitiesSearchValue> & Omit<ComponentProps<typeof SalonsAndCitiesSearch>, 'value' | 'onChange'>
>

// component
const SalonsAndCitiesSearchFormField = (props: SalonsAndCitiesSearchFormFieldProps) => {
	const {
		input: { value, onChange },
		...restProps
	} = props

	return <SalonsAndCitiesSearch {...restProps} value={value} onChange={onChange} />
}

export default SalonsAndCitiesSearchFormField
