/* eslint-disable global-require */
import React, { useContext } from 'react'
import { ButtonModel, IconRegularClose } from '@notino/react-styleguide'

// utils
import { AppContext } from '../../../utils/appProvider'

// hooks
import useMessages from '../../../hooks/useMessages'

// styles
import * as SC from './PromoBannerScreenStyles'
import { RESERVATION_BOOKING_MODAL_MOBILE_BREAKPOINT } from '../../../styles/constants'

type Props = {
	onClose?: () => void
	onContinue: () => void
}

const PromoBannerScreen = (props: Props) => {
	const { messages } = useMessages()
	const { assetsPath } = useContext(AppContext)

	const { onContinue, onClose } = props

	// NOTE: this screen is visible only for czech version for now
	const bannerDesktop = `${assetsPath}/${require(`../../../assets/images/reservation-modal/full-pop-up-banner-desktop-cs.jpg`)}`
	const bannerMobile = `${assetsPath}/${require(`../../../assets/images/reservation-modal/full-pop-up-banner-mobile-cs.jpg`)}`
	const promoBannerLink = ''

	const picture = (
		<SC.Picture>
			<source srcSet={bannerDesktop} media={`(min-width: ${RESERVATION_BOOKING_MODAL_MOBILE_BREAKPOINT})`} />
			<source srcSet={bannerMobile} />
			<img src={bannerDesktop} alt={messages['app-promo-banner-alt-text']} />
		</SC.Picture>
	)

	return (
		<SC.PromoBannerScreenWrapper>
			{onClose && (
				<SC.CloseButton type={'button'} onClick={() => onClose()}>
					<IconRegularClose color={'icon.primary'} width={'1.5rem'} height={'1.5rem'} />
				</SC.CloseButton>
			)}

			<SC.MainContentWrapper>
				{promoBannerLink ? (
					<SC.PromoBannerLink href={promoBannerLink} target={'blank'} rel={'noopener noreferrer'}>
						{picture}
					</SC.PromoBannerLink>
				) : (
					picture
				)}
			</SC.MainContentWrapper>

			<SC.PositionedButton buttonStyle={ButtonModel.Styles.primary} onClick={() => onContinue()}>
				{messages['Continue reservation process']}
			</SC.PositionedButton>
		</SC.PromoBannerScreenWrapper>
	)
}

export default PromoBannerScreen
