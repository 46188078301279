import React, { ComponentProps, useContext, useEffect, useRef, useState } from 'react'
import Slider from 'react-slick'
import { useIntl } from 'react-intl'
import { IconRegularChevronLeft, IconRegularChevronRight } from '@notino/react-styleguide'

// types
import { Salon } from '../../../../types/types'

// utils
import { INITIAL_SLIDE_INDEX, SLIDER_SETTINGS, SliderSettings } from './sliderSettings'
import { PAGE_LINKS } from '../../../../utils/helper'
import { MyLocationContext } from '../../../../utils/myLocationProvider'

// components
import SalonSliderItem from './SalonSliderItem'
import SalonSliderItemPlaceholder from './SalonSliderItemPlaceholder'
import ErrorScreen from '../../../../atoms/ErrorScreen/ErrorScreen'
import Empty from '../../../../atoms/Empty/Empty'

// styles
import * as SC from './SalonsSliderStyles'
import { HomePageSectionTitle } from '../../HomePageStyles'

// hooks
import useMessages from '../../../../hooks/useMessages'

type Props = {
	title: string
	salons: (Salon & { imageLoading?: ComponentProps<typeof SalonSliderItem>['imageLoading'] })[]
	isLoadingError?: boolean
	isLoadingData?: boolean
	errorScreen?: ComponentProps<typeof ErrorScreen>
	imageLoading?: ComponentProps<typeof SalonSliderItem>['imageLoading']
}
const SalonsSlider = (props: Props) => {
	const { title, salons, isLoadingData = false, isLoadingError = false, errorScreen = {}, imageLoading } = props

	const { locale } = useIntl()
	const { messages } = useMessages()
	const { myLocation } = useContext(MyLocationContext)

	const [currentIndex, setCurrentIndex] = useState(INITIAL_SLIDE_INDEX)
	const [isPrevButtonDisabledForCurrentScreenSize, setIsPrevButtonDisabledForCurrentScreenSize] = useState(false)
	const [isNextButtonDisabledForCurrentScreenSize, setIsNextButtonDisabledForCurrentScreenSize] = useState(false)

	/**
	 * Due to positioning issues with the react-slick slider when using the responsive object,
	 * we need to manually determine the responsive settings instead of relying on the react-slick responsive object.
	 */
	const [sliderSettings, setSliderSettings] = useState<SliderSettings>({ ...SLIDER_SETTINGS, responsive: [] })

	/**
	 * The slider needs to determine the client's resolution to decide how many slides to render.
	 * Loading skeletons are rendered during the initialization process.
	 * Without this loading step, a flicker would be visible during the initial render
	 */
	const [isLoadingSlider, setIsLoadingSlider] = useState(true)
	const [isLoadingSliderSettings, setIsLoadingSliderSettings] = useState(true)

	const sliderRef = useRef<Slider | null>(null)

	const handleBeforeChange = (_currentSlide: number, nextSlide: number) => {
		setCurrentIndex(nextSlide)
	}

	const isLoading = isLoadingSlider || isLoadingData || isLoadingSliderSettings
	const isError = !isLoading && isLoadingError
	const isEmpty = !isLoading && !isError && salons.length === 0

	const disabledPrevButton = isPrevButtonDisabledForCurrentScreenSize || isLoading
	const disabledNextButton = isNextButtonDisabledForCurrentScreenSize || isLoading

	useEffect(() => {
		/**
		 * Due to positioning issues with the react-slick slider when using the responsive object,
		 * we need to manually determine the responsive settings instead of relying on the react-slick responsive object.
		 */
		const handleResize = () => {
			const highestBreakpoint = SLIDER_SETTINGS.responsive[0].breakpoint

			if (window.innerWidth >= highestBreakpoint) {
				setIsPrevButtonDisabledForCurrentScreenSize(currentIndex === 0 || salons.length <= SLIDER_SETTINGS.slidesToShow)
				setIsNextButtonDisabledForCurrentScreenSize(salons.length <= currentIndex + SLIDER_SETTINGS.slidesToShow)
				setSliderSettings((prev) => ({ ...prev, slidesToScroll: SLIDER_SETTINGS.slidesToScroll, slidesToShow: SLIDER_SETTINGS.slidesToShow }))
			} else {
				SLIDER_SETTINGS.responsive.forEach(({ breakpoint, settings }) => {
					if (window.innerWidth <= breakpoint) {
						setIsPrevButtonDisabledForCurrentScreenSize(currentIndex === 0 || salons.length <= settings.slidesToShow)
						setIsNextButtonDisabledForCurrentScreenSize(salons.length <= currentIndex + settings.slidesToShow)
						setSliderSettings((prev) => ({ ...prev, ...settings }))
					}
				})
			}
			setIsLoadingSliderSettings(false)
		}
		window.addEventListener('resize', handleResize)
		handleResize()
		return () => window.removeEventListener('resize', handleResize)
	}, [currentIndex, salons.length])

	const emptyButtonHref = PAGE_LINKS['/salons'](locale, { ...myLocation })

	return (
		<div>
			<SC.SalonsSliderHeading>
				<HomePageSectionTitle $marginBottom={'0'}>{title}</HomePageSectionTitle>
				{!isEmpty && !isError && (
					<SC.ArrowsContainer>
						<SC.ArrowButton
							type={'button'}
							onClick={() => {
								sliderRef.current?.slickPrev()
							}}
							disabled={disabledPrevButton}
						>
							<IconRegularChevronLeft color={disabledPrevButton ? 'icon.disabled' : 'icon.primary'} />
						</SC.ArrowButton>
						<SC.ArrowButton
							type={'button'}
							onClick={() => {
								sliderRef.current?.slickNext()
							}}
							disabled={disabledNextButton}
						>
							<IconRegularChevronRight color={disabledNextButton ? 'icon.disabled' : 'icon.primary'} />
						</SC.ArrowButton>
					</SC.ArrowsContainer>
				)}
			</SC.SalonsSliderHeading>

			<SC.SalonsSliderContainer>
				{isLoading && (
					<>
						{/** Skeletons are shown during the loading */}
						<SC.PlaceholderContainer>
							{Array.from({ length: 4 }).map((_, index) => {
								return (
									<SC.PlaceholderWrapper key={index}>
										<SC.SlideWrapper>
											<SalonSliderItemPlaceholder />
										</SC.SlideWrapper>
									</SC.PlaceholderWrapper>
								)
							})}
						</SC.PlaceholderContainer>
						{/**
						 * Server-side rendered content to ensure search engine bots can see the salons fetched on the server while the client-side is still loading.
						 * It's hidden for the user with css, but visible for bots in the DOM.
						 * Once the client-side is fully loaded, this content will be removed from the DOM as well.
						 */}
						<SC.SSRContent>
							{salons.map((salon) => {
								return (
									<SC.SlideWrapper key={salon.id}>
										<SalonSliderItem salon={salon} />
									</SC.SlideWrapper>
								)
							})}
						</SC.SSRContent>
					</>
				)}

				{/**
				 * Hacky solution to prevent layout shifts after the loading is done.
				 * We determine container's height based on the loading skeleton height.
				 * So when there is an error or an empty state after the loading is done, the container will remain the same height as loading skeletons and screen doesn't jump.
				 * Skeleton is present in the DOM to determine container height, but hidden with opacity in the CSS.
				 * */}
				{(isError || isEmpty) && (
					<SC.PlaceholderContainer>
						<SC.PlaceholderWrapper $isEmptyScreen>
							<SC.SlideWrapper>
								<SalonSliderItemPlaceholder />
							</SC.SlideWrapper>
						</SC.PlaceholderWrapper>
						<SC.EmptyScreenContainer>
							{isError && (
								<ErrorScreen
									title={messages['Couldn’t load salons. Please try again.']}
									maxWidth={'100%'}
									minHeight={'fit-content'}
									{...errorScreen}
								/>
							)}
							{isEmpty && (
								<Empty
									maxWidth={'100%'}
									minHeight={'fit-content'}
									title={messages['We couldn’t find any recommended salons for you.']}
									button={{ label: messages['Explore salons'], href: emptyButtonHref }}
								/>
							)}
						</SC.EmptyScreenContainer>
					</SC.PlaceholderContainer>
				)}

				{/**
				 * Slider is rendered after the loading of settings is done.
				 */}
				{!isError && !isEmpty && !isLoadingSliderSettings && (
					<SC.SlickSliderContainer $isLoadingData={isLoading} $itemsCount={salons.length}>
						<Slider
							{...sliderSettings}
							responsive={undefined}
							ref={(slider) => {
								sliderRef.current = slider
							}}
							beforeChange={handleBeforeChange}
							onInit={() => setIsLoadingSlider(false)}
						>
							{salons.map((salon) => {
								return (
									<SC.SlideWrapper key={salon.id}>
										<SalonSliderItem salon={salon} imageLoading={salon.imageLoading || imageLoading} />
									</SC.SlideWrapper>
								)
							})}
						</Slider>
					</SC.SlickSliderContainer>
				)}
			</SC.SalonsSliderContainer>
		</div>
	)
}

export default SalonsSlider
