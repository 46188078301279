import styled from 'styled-components'
import { SALONS_PAGE_BANNER_SETTINGS } from '../../../../utils/enums'
import { BREAKPOINTS, CONTAINER_X_PADDING } from '../../../../styles/constants'
import { NotinoBodyLarge } from '../../../../styles/helpers'

const { DESKTOP_BANNER_WIDTH } = SALONS_PAGE_BANNER_SETTINGS

export const SalonsPageBannerContainer = styled.div`
	position: relative;
	margin-left: -${CONTAINER_X_PADDING}px;
	margin-right: -${CONTAINER_X_PADDING}px;

	@media (min-width: ${BREAKPOINTS.lg}) {
		margin-left: 0;
		margin-right: 0;
	}
`

export const SliderWrapper = styled.div`
	margin: 0px auto;
	width: 100%;

	.salons-page-banner {
		width: 100%;
		position: relative;
		max-width: ${DESKTOP_BANNER_WIDTH}px;
		z-index: 3;
	}

	.slick-arrow {
		position: absolute;
		top: 50%;
		transform: translate(0px, -50%);
		cursor: pointer;
		color: transparent;
		overflow: hidden;
		outline: 0px;
		border: none;
		width: 2rem;
		height: 2rem;
		z-index: 2;
		display: none;

		&.slick-prev {
			left: 0px;

			svg:last-child {
				left: -0.125rem;
			}
		}

		&.slick-next {
			right: 0px;

			svg:last-child {
				right: -0.125rem;
			}
		}

		&::before {
			display: none;
		}

		@media (min-width: ${BREAKPOINTS.lg}) {
			display: block;
			z-index: 5;
		}
	}
`

export const ModalText = styled.p`
	${NotinoBodyLarge};
	overflow: hidden;
	white-space: pre-wrap;
	margin-top: 24px;
`
