import { IDropdownOption } from '@notino/react-styleguide'
import { SalonsListPageQueryType } from '../types/types'
import { SORTING_OPTION } from '../utils/enums'
import useMessages from './useMessages'

const useSortingOptions = (query: SalonsListPageQueryType): [IDropdownOption, IDropdownOption[]] => {
	const { messages } = useMessages()

	const sortingOptions: IDropdownOption[] = [
		{ id: SORTING_OPTION.RECOMMENDED, label: messages.Recommended },
		{ id: SORTING_OPTION.CLOSEST, label: messages['The closest'] },
		{ id: SORTING_OPTION.BEST_RATED, label: messages['Best rated'] },
		{ id: SORTING_OPTION.CHEAPEST, label: messages['The cheapest'] },
		{ id: SORTING_OPTION.MOST_EXPENSIVE, label: messages['The most expensive'] }
	]

	const currentSortingOption = sortingOptions.find((opt) => opt.id === query.orderBy) || sortingOptions[0]

	return [currentSortingOption, sortingOptions]
}

export default useSortingOptions
