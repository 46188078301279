import React, { ComponentProps, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Button, ButtonModel, IconRegularCheckmark } from '@notino/react-styleguide'
import { useForm } from 'react-hook-form'
import dayjs from 'dayjs'
import { FormattedMessage, useIntl } from 'react-intl'
import debounce from 'lodash.debounce'
import axios from 'axios'

// hooks
import useMessages from '../../../hooks/useMessages'

// styles
import * as SC from './Step2EmployeeAndDateSelectionStyles'

// components
import ReservationBookingModalLayout from '../ReservationBookingModalLayout/ReservationBookingModalLayout'
import HookFormField from '../../../formFields/HookFormField'
import DatePickerFormField from '../../../formFields/DatePickerFormField/DatePickerFormField'
import HeaderContent from '../HeaderContent/HeaderContent'
import ResourceCard from '../../ResourceCard/ResourceCard'
import CheckboxGroupCustomFormField from '../../../formFields/CheckboxGroupCustomFormField/CheckboxGroupCustomFormField'
import RenderPrice from '../../RenderPrice/RenderPrice'
import RenderDuration from '../../RenderDuration/RenderDuration'
import FetchResult from '../../FetchResult/FetchResult'
import TimeSlotOptionsSkeleton from '../TimeSlotOptionsSkeleton/TimeSlotOptionsSkeleton'
import Empty from '../../../atoms/Empty/Empty'
import collapseMotion from './collapseMotion'

// types
import {
	AllowedReservationTimeSlot,
	GetCalendarEventsReservationAvailableParams,
	GetCalendarEventsReservationAvailableResponse,
	ReservationBookingEmployeeAndDateSelectionForm,
	ReservationBookingEmployeeAndDateSelectionSubmittedForm,
	ReservationBookingSelectedFormValues,
	SalonServiceResponse,
	ServiceCategoryParameterValueData,
	TimeSlotOption
} from '../../../types/types'
import { CheckboxGroupCustomFormFieldProps } from '../../../formFields/CheckboxGroupCustomFormField/types'
import { OnChangeViewArgs, SelectedViewDates } from '../../../atoms/DatePicker/types'
import { DatePickerFormFieldProps } from '../../../formFields/DatePickerFormField/types'

// utils
import {
	ABORT_KEYS,
	CALENDAR_VIEW_CHANGE_DEBOUNCE_TIME_MS,
	DEFAULT_DATE_FORMAT,
	FORM,
	MSG_TYPE,
	RESERVATION_BOOKING_MODAL_STEP,
	RESERVATIONS_TIME_SLOTS
} from '../../../utils/enums'
import { ERROR_MESSAGES, isEnumValue, showNotifications, TIME_SLOTS_TRANSLATIONS } from '../../../utils/helper'
import { AppContext } from '../../../utils/appProvider'
import { abortControllers } from '../../../utils/request'
import {
	ANYONE_AVAILABLE_OPTION_VALUE,
	AvailableDatesStateData,
	EmployeeOption,
	getAnyoneAvailableOption,
	getCalendarGridKey,
	getEmployeeAndDateSelectionInitFormData,
	getEmployeeOption,
	getSelectedTimeSlotOption,
	getSelectedTimeSlotValue,
	getTimeSlotData,
	parseCalendarGridKey,
	scrollElementIntoView,
	TIME_SLOT_PERIODS
} from '../reservationBookingModalHelpers'
import { getFullMonthGridDatesFromSelectedDates, getSelectedViewDates } from '../../../atoms/DatePicker/DatePicker'
import { pushToDataLayer } from '../../../utils/dataLayer'
import { getModalReservationCalendarConfirmEvent } from '../../../utils/dataLayerEvents'

// assets
import UserIcon from '../../../assets/icons/UserIcon'

type Props = PropsWithChildren<{
	salonID: string
	selectedFormValues: ReservationBookingSelectedFormValues
	selectedIndustryID: string | undefined
	selectedServiceData: SalonServiceResponse['service']
	selectedServiceCategoryParameterValueData: ServiceCategoryParameterValueData | undefined
	initAvResTimeSlotDayPart: RESERVATIONS_TIME_SLOTS | null
	headerImage: string | undefined
	onClose: () => void
	onBackButtonClick?: () => void
	handleSubmitEmployeeAndDateSelectionForm: (values: ReservationBookingEmployeeAndDateSelectionSubmittedForm) => void
}>

const EMPLOYEE_COLLAPSE_KEY = 'EMPLOYEE_COLLAPSE_KEY'

const TIME_SLOT_EMPLOYEE_WRAPPER_ID = 'TIME_SLOT_EMPLOYEE_WRAPPER_ID'

const employeeOptionRender = (option: EmployeeOption, isChecked: boolean, isDisabled: boolean, isTimeSlotEmployee: boolean) => {
	return (
		<SC.EmployeeOptionWrapper $isDisabled={isDisabled} $isTimeSlotEmployee={isTimeSlotEmployee}>
			<ResourceCard
				extraContent={isChecked ? <IconRegularCheckmark /> : null}
				title={option.label}
				avatarUrl={option.extra?.avatar}
				customIcon={<UserIcon />}
				infoContent={
					<SC.PriceAndDurationWrapper>
						<RenderPrice from={option.extra?.priceAndDurationData?.priceFrom} to={option.extra?.priceAndDurationData?.priceTo} />
						<SC.DurationWrapper>
							<SC.ClockIconWrapper color={'icon.secondary'} />
							<RenderDuration from={option.extra?.priceAndDurationData?.durationFrom} to={option.extra?.priceAndDurationData?.durationTo} />
						</SC.DurationWrapper>
					</SC.PriceAndDurationWrapper>
				}
			/>
		</SC.EmployeeOptionWrapper>
	)
}

const DEFAULT_CALENDAR_VIEW: DatePickerFormFieldProps['defaultView'] = 'weekly'

const Step2EmployeeAndDateSelection = (props: Props) => {
	const {
		onClose,
		onBackButtonClick,
		handleSubmitEmployeeAndDateSelectionForm,
		initAvResTimeSlotDayPart,
		salonID,
		selectedFormValues,
		selectedIndustryID,
		selectedServiceData,
		selectedServiceCategoryParameterValueData,
		headerImage
	} = props

	const { formatDate } = useIntl()
	const { messages } = useMessages()
	const { apiBrowser } = useContext(AppContext)

	const submittedFormData = getEmployeeAndDateSelectionInitFormData(selectedFormValues[RESERVATION_BOOKING_MODAL_STEP.EMPLOYEE_AND_DATE_SELECTION])

	const dateValue = dayjs(submittedFormData.date)
	const dateValueString = dateValue.format(DEFAULT_DATE_FORMAT)

	const submittedValueCalendarViewSelectedDays = getSelectedViewDates(dateValue, DEFAULT_CALENDAR_VIEW === 'weekly')
	const submittedValueCalendarViewGridDates = getFullMonthGridDatesFromSelectedDates(submittedValueCalendarViewSelectedDays, dateValue)
	const submittedValueCalendarViewGridKey = getCalendarGridKey(
		submittedValueCalendarViewGridDates.startDate,
		submittedValueCalendarViewGridDates.endDate,
		submittedFormData.employeeID[0]
	)

	const [selectedCalendarViewGrid, setSelectedCalendarViewGrid] = useState<SelectedViewDates>(submittedValueCalendarViewGridDates)
	const [availableDatesData, setAvailableDatesData] = useState<AvailableDatesStateData>({ [submittedValueCalendarViewGridKey]: null })
	const [firstAvailableDate, setFirstAvailableDate] = useState<string | null>(null)

	const [employeeCollapseActiveKeys, setEmployeeCollapseActiveKeys] = useState<React.Key[]>([])
	const [timeSlotsCollapseActiveKeys, setTimeSlotsCollapseActiveKeys] = useState<AllowedReservationTimeSlot[]>(
		isEnumValue(initAvResTimeSlotDayPart, RESERVATIONS_TIME_SLOTS) && initAvResTimeSlotDayPart !== RESERVATIONS_TIME_SLOTS.ANY
			? [initAvResTimeSlotDayPart]
			: []
	)

	const [isLoading, setIsLoading] = useState(true)
	const [loadingError, setLoadingError] = useState(false)
	const [tryAgain, setTryAgain] = useState(false)

	const isInitialTimeSlotRequest = useRef(true)
	const employeeCollapseRef = useRef<HTMLDivElement | null>(null)

	const singleEmployee = selectedServiceData.employees.length === 1

	const { control, handleSubmit, watch, setValue } = useForm<ReservationBookingEmployeeAndDateSelectionForm>({
		defaultValues: submittedFormData,
		values: submittedFormData
	})

	const formValues = watch()
	const {
		date,
		employeeID: employeeIDValue,
		'timeSlot-MORNING': timeSlotMorning,
		'timeSlot-AFTERNOON': timeSlotAfternoon,
		'timeSlot-EVENING': timeSlotEvening,
		timeSlotEmployee
	} = formValues
	const [employeeID] = employeeIDValue

	const currentCalendarViewGridKey = getCalendarGridKey(selectedCalendarViewGrid.startDate, selectedCalendarViewGrid.endDate, employeeID)

	const availableDatesForDatePicker = useMemo<DatePickerFormFieldProps['availableDates']>(
		() =>
			(availableDatesData[currentCalendarViewGridKey] || []).map((item) => ({
				date: item.date,
				available: !!item.items.length
			})),
		[availableDatesData, currentCalendarViewGridKey]
	)

	const currentValueCalendarViewGridDates = getFullMonthGridDatesFromSelectedDates({ startDate: date, endDate: date }, date)
	const currentValueCalendarViewGridKey = getCalendarGridKey(
		currentValueCalendarViewGridDates.startDate,
		currentValueCalendarViewGridDates.endDate,
		employeeID
	)

	useEffect(() => {
		;(async () => {
			// no need to fetch data that already exits
			if (availableDatesData[currentCalendarViewGridKey]) {
				return
			}

			// abort previous requests in case the new one is called
			const { AVAILABLE_SLOT_REQUEST_1_ABORT_KEY, AVAILABLE_SLOT_REQUEST_2_ABORT_KEY } = ABORT_KEYS

			abortControllers.httpGet.get(AVAILABLE_SLOT_REQUEST_1_ABORT_KEY)?.abort()
			abortControllers.httpGet.get(AVAILABLE_SLOT_REQUEST_2_ABORT_KEY)?.abort()

			setIsLoading(true)
			setLoadingError(false)
			// BE allows maximum range of 31 days, so we need to split it to 2 requests if the range is higher
			const requests: Promise<GetCalendarEventsReservationAvailableResponse | undefined>[] = []

			const commonRequestsParams: Omit<GetCalendarEventsReservationAvailableParams, 'dateFrom' | 'dateTo'> = {
				serviceID: selectedServiceData.id,
				employeeID: employeeID === ANYONE_AVAILABLE_OPTION_VALUE ? undefined : employeeID,
				serviceCategoryParameterValueID: selectedServiceCategoryParameterValueData?.id
			}

			// BE doesn't allow to send dateFrom that is earlier than today
			// BE doesn't allow to send dateTo eelier than dateFrom
			const dateFromForRequest = selectedCalendarViewGrid.startDate.isBefore(dayjs(), 'date') ? dayjs() : selectedCalendarViewGrid.startDate
			const dateToForRequest = selectedCalendarViewGrid.endDate.isBefore(dateFromForRequest, 'date')
				? dateFromForRequest
				: selectedCalendarViewGrid.endDate

			if (dateToForRequest.diff(dateFromForRequest, 'days') > 31) {
				requests.push(
					apiBrowser.b2c.getCalendarEventsReservationAvailableParamsSlots(
						salonID,
						{
							...commonRequestsParams,
							dateFrom: dateFromForRequest.format(DEFAULT_DATE_FORMAT),
							dateTo: dateFromForRequest.add(31, 'days').format(DEFAULT_DATE_FORMAT)
						},
						{ allowAbort: true, abortSignalKey: AVAILABLE_SLOT_REQUEST_1_ABORT_KEY }
					)
				)
				requests.push(
					apiBrowser.b2c.getCalendarEventsReservationAvailableParamsSlots(
						salonID,
						{
							...commonRequestsParams,
							dateFrom: dateFromForRequest.add(32, 'days').format(DEFAULT_DATE_FORMAT),
							dateTo: dateToForRequest.format(DEFAULT_DATE_FORMAT)
						},
						{ allowAbort: true, abortSignalKey: AVAILABLE_SLOT_REQUEST_2_ABORT_KEY }
					)
				)
			} else {
				requests.push(
					apiBrowser.b2c.getCalendarEventsReservationAvailableParamsSlots(
						salonID,
						{
							...commonRequestsParams,
							dateFrom: dateFromForRequest.format(DEFAULT_DATE_FORMAT),
							dateTo: dateToForRequest.format(DEFAULT_DATE_FORMAT)
						},
						{ allowAbort: true, abortSignalKey: AVAILABLE_SLOT_REQUEST_1_ABORT_KEY }
					)
				)
			}

			try {
				const response = await Promise.all(requests)
				const filteredResponse = response.reduce<GetCalendarEventsReservationAvailableResponse[]>((acc, cv) => {
					return cv ? [...acc, cv] : acc
				}, [])

				const mergedResponseData = filteredResponse.reduce<GetCalendarEventsReservationAvailableResponse['availableReservations']>((acc, cv) => {
					return [...acc, ...cv.availableReservations]
				}, [])

				setAvailableDatesData((current) => ({
					...current,
					[currentCalendarViewGridKey]: mergedResponseData
				}))

				// find the closest available date when the component is first time initialized and there are no available slots for the initially selected date
				if (isInitialTimeSlotRequest.current) {
					if (mergedResponseData.find((item) => dayjs(item.date).isSame(dayjs(), 'date') && !item.items.length)) {
						const firstAvailableDateResponse = await apiBrowser.b2c.getCalendarEventsReservationFirstAvailableDate(salonID, {
							dateFrom: dateValueString,
							...commonRequestsParams
						})

						// no need to show error state when this request fails, because it is only nice to have feature
						if (firstAvailableDateResponse?.firstAvailableDate) {
							const newFirstAvailableDate = dayjs(firstAvailableDateResponse.firstAvailableDate)

							setValue('date', dayjs(newFirstAvailableDate))

							// if new firstAvailableDate is not in the range we fetched for the first time, we want to continue showing loading state
							// calendar view will be changed, which will re-trigger this useEffect a new data will be fetched
							if (!newFirstAvailableDate.isBetween(selectedCalendarViewGrid.startDate, selectedCalendarViewGrid.endDate, 'date', '[]')) {
								isInitialTimeSlotRequest.current = false
								return
							}
						}
					}

					isInitialTimeSlotRequest.current = false
				}
				setIsLoading(false)
			} catch (error) {
				// set error, but only if it is not caused by cancel token
				if (axios.isAxiosError(error) && error.code !== 'ERR_CANCELED') {
					setLoadingError(true)
					setIsLoading(false)
				}
			}
		})()
	}, [
		dateValueString,
		selectedCalendarViewGrid.startDate,
		selectedCalendarViewGrid.endDate,
		selectedServiceData.id,
		salonID,
		employeeID,
		setValue,
		selectedServiceCategoryParameterValueData?.id,
		apiBrowser,
		availableDatesData,
		currentCalendarViewGridKey,
		// tryAgain is just a listener that forces to re-run use effect when request fails
		tryAgain
	])

	// Button from Notino style-guide library doesn't recognize "form" as a prop, however, when passed to the component, it works fine
	const unknownButtonProps = {
		form: FORM.RESERVATION_BOOKING_MODAL_EMPLOYEE_AND_DATE_SELECTION
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} as any

	const { timeSlotOptions, timeSlotDataMap } = useMemo(() => {
		let gridKeyData: AvailableDatesStateData[number] = availableDatesData[currentValueCalendarViewGridKey]

		if (!gridKeyData) {
			Object.entries(availableDatesData).forEach(([key, data]) => {
				const { startDate, endDate, employeeID: employeeIdKey } = parseCalendarGridKey(key)
				if (date.isBetween(startDate, endDate, 'date', '[]') && employeeIdKey === employeeID) {
					gridKeyData = data
				}
			})
		}

		return getTimeSlotData(gridKeyData || [], date)
	}, [availableDatesData, currentValueCalendarViewGridKey, date, employeeID])

	const selectedTimeSlotOption = getSelectedTimeSlotOption([timeSlotMorning, timeSlotAfternoon, timeSlotEvening])
	const selectedTimeSlotOptionData = selectedTimeSlotOption ? timeSlotDataMap[selectedTimeSlotOption.value] : selectedTimeSlotOption
	const selectedTimeSlotValue = getSelectedTimeSlotValue(selectedTimeSlotOptionData, timeSlotEmployee)

	const disabledConfirmButton = !date || !employeeID || !selectedTimeSlotValue

	useEffect(() => {
		const newActiveKeys: AllowedReservationTimeSlot[] = []

		if (initAvResTimeSlotDayPart && initAvResTimeSlotDayPart !== RESERVATIONS_TIME_SLOTS.ANY) {
			// select day period keys based on user filters in salons category page
			newActiveKeys.push(initAvResTimeSlotDayPart)
		} else {
			// or open ones that have some data
			Object.entries(timeSlotOptions).forEach(([dayPeriod, options]) => {
				if (options.length) {
					newActiveKeys.push(dayPeriod as AllowedReservationTimeSlot)
				}
			})
		}
		setTimeSlotsCollapseActiveKeys(newActiveKeys)
	}, [timeSlotOptions, initAvResTimeSlotDayPart])

	const anyoneAvailableOption = getAnyoneAvailableOption(selectedServiceData, selectedServiceCategoryParameterValueData)

	const getEmployeeOptions = (selectedEmployeeIDs?: string[]) => {
		const options: EmployeeOption[] = []

		if (!selectedEmployeeIDs) {
			options.push(anyoneAvailableOption)
		}

		selectedServiceData.employees.forEach((employee) => {
			if (selectedEmployeeIDs && !selectedEmployeeIDs.includes(employee.id)) {
				return
			}

			options.push(getEmployeeOption(employee, selectedServiceCategoryParameterValueData))
		})

		return options
	}

	const timeSlotDayPeriods = TIME_SLOT_PERIODS(TIME_SLOTS_TRANSLATIONS())

	const employeeOptions = getEmployeeOptions(singleEmployee ? [selectedServiceData.employees[0].id] : undefined)

	const selectedEmployeeData =
		employeeOptions.find((employeeOption) => {
			const employeeIdToCompare = employeeID
			return employeeIdToCompare === employeeOption.value
		}) || anyoneAvailableOption

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const onCalendarViewChange = useCallback(
		debounce((args: OnChangeViewArgs) => {
			setSelectedCalendarViewGrid(args.selectedViewMonthGridDates)
		}, CALENDAR_VIEW_CHANGE_DEBOUNCE_TIME_MS),
		[]
	)

	const afterDatePickerValueChange: DatePickerFormFieldProps['afterChange'] = async (newDate, dateCellData) => {
		setFirstAvailableDate(null)

		if (!dateCellData.isAvailable && !dateCellData.isPast) {
			setIsLoading(true)
			const response = await apiBrowser.b2c.getCalendarEventsReservationFirstAvailableDate(salonID, {
				dateFrom: dayjs(newDate).format(DEFAULT_DATE_FORMAT),
				serviceID: selectedServiceData.id,
				serviceCategoryParameterValueID: selectedServiceCategoryParameterValueData?.id
			})

			if (response?.firstAvailableDate) {
				setFirstAvailableDate(response.firstAvailableDate)
			}
			setIsLoading(false)
		}

		// reset time slot employee before every change
		setValue('timeSlotEmployee', [])

		// reset time slot values whenever date changes
		timeSlotDayPeriods.forEach((period) => {
			setValue(period.formName, [])
		})
	}

	const afterTimeSlotOptionValueChange: CheckboxGroupCustomFormFieldProps<TimeSlotOption[], TimeSlotOption>['afterChange'] = (_value, option) => {
		// reset time slot employee before every change
		setValue('timeSlotEmployee', [])

		// reset all time slot values expect selected
		timeSlotDayPeriods.forEach((period) => {
			if (period.value !== option.extra?.dayPeriod) {
				setValue(period.formName, [])
			}
		})

		// scroll to employee selection for the time slot in case
		// this happens only when there are multiple slots with the same start time
		if ((selectedTimeSlotOptionData?.length || 0) > 1) {
			// a slight delay, so we are sure element is mounted in the DOM
			setTimeout(() => {
				const element = document.getElementById(TIME_SLOT_EMPLOYEE_WRAPPER_ID)
				if (element) {
					scrollElementIntoView(element)
				}
			}, 100)
		}
	}

	const afterEmployeeChange: CheckboxGroupCustomFormFieldProps<string[], EmployeeOption>['afterChange'] = () => {
		setEmployeeCollapseActiveKeys([])

		// reset time slot employee before every change
		setValue('timeSlotEmployee', [])

		// reset all time slot values expect selected
		timeSlotDayPeriods.forEach((period) => {
			setValue(period.formName, [])
		})
	}

	const handleSubmitForm = (values: ReservationBookingEmployeeAndDateSelectionForm) => {
		if (!selectedTimeSlotValue) {
			// The application shouldn't be able to reach this state
			// But to avoid having just a return with nothing happening, an error message is displayed here as a fallback
			showNotifications([{ type: MSG_TYPE.ERROR, message: ERROR_MESSAGES().GENERAL_ERROR }])
			return
		}

		const submitDataEmployeeID = values.employeeID[0] !== ANYONE_AVAILABLE_OPTION_VALUE ? values.employeeID[0] : null

		const submittedData: ReservationBookingEmployeeAndDateSelectionSubmittedForm = {
			employeeID: submitDataEmployeeID,
			date: values.date.format(DEFAULT_DATE_FORMAT),
			timeSlot: selectedTimeSlotValue
		}

		pushToDataLayer(
			getModalReservationCalendarConfirmEvent({
				category: selectedIndustryID,
				subcategory: selectedServiceData.category.child?.id,
				type: selectedServiceData.category.child.child?.id,
				subtype: selectedServiceCategoryParameterValueData?.categoryParameterValueID,
				employee_id: submitDataEmployeeID || selectedTimeSlotValue.employeeID,
				date: dayjs(date).format(DEFAULT_DATE_FORMAT),
				slot: `${selectedTimeSlotValue.timeFrom} - ${selectedTimeSlotValue.timeTo}`
			})
		)

		handleSubmitEmployeeAndDateSelectionForm(submittedData)
	}

	return (
		<ReservationBookingModalLayout
			title={messages.Date}
			headerImage={headerImage}
			onBackButtonClick={onBackButtonClick}
			onClose={onClose}
			headerContent={
				<HeaderContent
					title={selectedServiceData.category.child.child?.name ?? ''}
					description={
						selectedServiceCategoryParameterValueData?.value && selectedServiceData.serviceCategoryParameter?.name
							? `${selectedServiceData.serviceCategoryParameter.name}: ${selectedServiceCategoryParameterValueData.value}`
							: selectedServiceCategoryParameterValueData?.value
					}
				/>
			}
			footerContent={
				<SC.FooterWrapper>
					<SC.DateTimeInfo>
						<SC.SelectedDate>{formatDate(dayjs(date).toDate(), { weekday: 'long', day: '2-digit', month: '2-digit' })}</SC.SelectedDate>
						<SC.SelectedTimeSlot>
							{selectedTimeSlotValue ? (
								<>
									{selectedTimeSlotValue.timeFrom} - {selectedTimeSlotValue.timeTo}
								</>
							) : null}
						</SC.SelectedTimeSlot>
					</SC.DateTimeInfo>
					<Button {...unknownButtonProps} type={'submit'} buttonStyle={ButtonModel.Styles.primary} disabled={disabledConfirmButton}>
						{messages.Confirm}
					</Button>
				</SC.FooterWrapper>
			}
		>
			<FetchResult isError={loadingError} tryAgainButton={{ onClick: () => setTryAgain((current) => !current) }}>
				<SC.Form id={FORM.RESERVATION_BOOKING_MODAL_EMPLOYEE_AND_DATE_SELECTION} onSubmit={handleSubmit(handleSubmitForm)}>
					<SC.EmployeeCollapseWrapper
						ref={employeeCollapseRef}
						activeKey={employeeCollapseActiveKeys}
						openMotion={collapseMotion}
						$collapsible={!singleEmployee}
						onChange={(newActiveKeys) => {
							setEmployeeCollapseActiveKeys(Array.isArray(newActiveKeys) ? newActiveKeys : [newActiveKeys])
						}}
						collapsible={singleEmployee ? 'disabled' : 'header'}
						items={[
							{
								key: EMPLOYEE_COLLAPSE_KEY,
								label: (
									<SC.CardWrapper $collapsible={!singleEmployee}>
										<ResourceCard
											customIcon={<UserIcon />}
											title={selectedEmployeeData.label}
											avatarUrl={selectedEmployeeData.extra?.avatar}
											infoContent={
												<SC.PriceAndDurationWrapper>
													<RenderPrice
														from={selectedEmployeeData.extra?.priceAndDurationData?.priceFrom}
														to={selectedEmployeeData.extra?.priceAndDurationData?.priceTo}
													/>
													<SC.DurationWrapper>
														<SC.ClockIconWrapper color={'icon.secondary'} />
														<RenderDuration
															from={selectedEmployeeData.extra?.priceAndDurationData?.durationFrom}
															to={selectedEmployeeData.extra?.priceAndDurationData?.durationTo}
														/>
													</SC.DurationWrapper>
												</SC.PriceAndDurationWrapper>
											}
											extraContent={
												singleEmployee ? null : (
													<SC.ChevronIcon
														$isActive={!!employeeCollapseActiveKeys.length}
														color={'icon.primary'}
														width={'1.5rem'}
														height={'1.5rem'}
													/>
												)
											}
										/>
									</SC.CardWrapper>
								),
								children: (
									<HookFormField
										control={control}
										name={'employeeID'}
										disabled={isLoading}
										component={CheckboxGroupCustomFormField<string[], EmployeeOption>}
										options={employeeOptions}
										optionRender={(option: EmployeeOption, isChecked: boolean, isDisabled: boolean) =>
											employeeOptionRender(option, isChecked, isDisabled, false)
										}
										afterChange={afterEmployeeChange}
										alignment={'vertical'}
										singleSelect
										gap={'0'}
										allowUnselect={false}
									/>
								)
							}
						]}
					/>
					<HookFormField
						control={control}
						name={'date'}
						component={DatePickerFormField}
						afterChange={afterDatePickerValueChange}
						size={'small'}
						availableDates={availableDatesForDatePicker}
						onChangeView={onCalendarViewChange}
						disabled={isLoading}
						isLoading={isLoading}
						defaultView={DEFAULT_CALENDAR_VIEW}
					/>
					<SC.Divider />
					{(() => {
						if (isLoading) {
							return <TimeSlotOptionsSkeleton />
						}

						const areTimeSlotsEmpty = Object.values(timeSlotOptions).every((options) => !options.length)

						if (areTimeSlotsEmpty) {
							const isFirstAvailableDateEmptyState = firstAvailableDate && !dayjs(firstAvailableDate).isSameOrBefore(date)
							const isEmployeeEmptyState = employeeID && employeeID !== ANYONE_AVAILABLE_OPTION_VALUE

							let emptyProps: ComponentProps<typeof Empty> = {
								title: messages['No available time slots'],
								label: messages['There are currently no available time slots']
							}

							if (isFirstAvailableDateEmptyState) {
								emptyProps = {
									...emptyProps,
									title: messages['No available time slots on this day'],
									label: (
										<FormattedMessage
											id='Next available time slot is on <button>{ date }</button>'
											defaultMessage='Next available time slot is on <button>{ date }</button>'
											values={{
												date: formatDate(dayjs(firstAvailableDate).toDate(), { dateStyle: 'medium' }),
												button: (chunks) => (
													<SC.EmptyStateLinkButton
														type={'button'}
														onClick={() => {
															// there is a weird screen flick when it jumps immediately from empty state to the open collapse with time slots, so there's fake loading for a few hundred ms, so it feels more natural for the user
															setIsLoading(true)
															setTimeout(() => {
																setValue('date', dayjs(firstAvailableDate))
																setIsLoading(false)
															}, 200)
														}}
													>
														{chunks}
													</SC.EmptyStateLinkButton>
												)
											}}
										/>
									)
								}
							} else if (isEmployeeEmptyState) {
								emptyProps = {
									...emptyProps,
									title: messages['No available time slots on this day'],
									label: messages['There are currently no available time slots for this employee. Please select another employee.'],
									button: {
										label: messages['Change employee'],
										onClick: () => {
											setEmployeeCollapseActiveKeys([EMPLOYEE_COLLAPSE_KEY])

											if (employeeCollapseRef.current) {
												scrollElementIntoView(employeeCollapseRef.current)
											}
										}
									}
								}
							}

							return <Empty minHeight={'200px'} {...emptyProps} />
						}

						return (
							<SC.TimeSlotsCollapseWrapper
								openMotion={collapseMotion}
								expandIcon={(expandProps: { isActive?: boolean }) => (
									<SC.ChevronIcon $isActive={expandProps.isActive} color={'icon.tertiary'} width={'1rem'} height={'1rem'} />
								)}
								activeKey={timeSlotsCollapseActiveKeys}
								onChange={(newActiveKey) => {
									setTimeSlotsCollapseActiveKeys(
										(Array.isArray(newActiveKey) ? newActiveKey : [newActiveKey]) as AllowedReservationTimeSlot[]
									)
								}}
								items={timeSlotDayPeriods.map((period) => {
									const options = timeSlotOptions[period.value]
									const timeSlotEmployees = selectedTimeSlotOptionData || []
									const isSelectedOptionFromThisPeriod =
										selectedTimeSlotOption?.extra?.dayPeriod && selectedTimeSlotOption?.extra?.dayPeriod === period.value

									return {
										key: period.value,
										label: period.label,
										children: !options.length ? (
											<SC.DayPeriodEmptyState>{messages['No time slots available']}</SC.DayPeriodEmptyState>
										) : (
											<SC.CollapseContent>
												<HookFormField
													control={control}
													name={period.formName}
													singleSelect
													options={options}
													optionAsValue
													afterChange={afterTimeSlotOptionValueChange}
													component={CheckboxGroupCustomFormField<TimeSlotOption[], TimeSlotOption>}
												/>
												{isSelectedOptionFromThisPeriod &&
													timeSlotEmployees.length > 1 &&
													(() => {
														const timeSlotEmployeeIDs =
															timeSlotEmployees.length > 1 ? timeSlotEmployees.map((slot) => slot.employeeID) : []
														const timeSlotEmployeeOptions = getEmployeeOptions(timeSlotEmployeeIDs)

														return (
															<SC.TimeSlotEmployeeWrapper id={TIME_SLOT_EMPLOYEE_WRAPPER_ID}>
																<SC.EmployeeTitle>{messages['Select the specialist']}</SC.EmployeeTitle>
																<SC.EmployeeDescription>
																	{messages['You can choose from several specialists at this time']}
																</SC.EmployeeDescription>
																<HookFormField
																	control={control}
																	name={'timeSlotEmployee'}
																	alignment={'vertical'}
																	gap={'0'}
																	singleSelect
																	allowUnselect={false}
																	options={timeSlotEmployeeOptions}
																	component={CheckboxGroupCustomFormField<string[], EmployeeOption>}
																	optionRender={(option: EmployeeOption, isChecked: boolean, isDisabled: boolean) =>
																		employeeOptionRender(option, isChecked, isDisabled, true)
																	}
																/>
															</SC.TimeSlotEmployeeWrapper>
														)
													})()}
											</SC.CollapseContent>
										)
									}
								})}
							/>
						)
					})()}
				</SC.Form>
			</FetchResult>
		</ReservationBookingModalLayout>
	)
}

export default Step2EmployeeAndDateSelection
