import { Settings } from 'react-slick'
import { BREAKPOINTS_INTS } from '../../../../styles/constants'

export const INITIAL_SLIDE_INDEX = 0

export type SliderSettings = Omit<Settings, 'responsive' | 'slidesToShow' | 'slidesToScroll'> & {
	slidesToShow: number
	slidesToScroll: number
	responsive: Array<{ breakpoint: number; settings: Omit<SliderSettings, 'responsive'> }>
}

export const SLIDER_SETTINGS: SliderSettings = {
	infinite: false,
	accessibility: false,
	speed: 500,
	responsive: [
		{
			breakpoint: BREAKPOINTS_INTS.xl,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 3
			}
		},
		{
			breakpoint: BREAKPOINTS_INTS.lg,
			settings: {
				slidesToShow: 3.1,
				slidesToScroll: 3
			}
		},
		{
			breakpoint: BREAKPOINTS_INTS.md,
			settings: {
				slidesToShow: 2.1,
				slidesToScroll: 2
			}
		},
		{
			breakpoint: BREAKPOINTS_INTS.sm,
			settings: {
				slidesToShow: 1.1,
				slidesToScroll: 1
			}
		}
	].sort((a, b) => b.breakpoint - a.breakpoint),
	slidesToShow: 4,
	slidesToScroll: 4,
	autoplay: false,
	arrows: false,
	draggable: true,
	waitForAnimate: false,
	initialSlide: INITIAL_SLIDE_INDEX
}
