import React, { useEffect, useRef, useState } from 'react'
import RcSelect, { BaseSelectRef, SelectProps as RcSelectProps } from 'rc-select'
import { InputRef } from 'rc-input'
import { IconRegularChevronDown } from '@notino/react-styleguide'

// types
import { BaseSelectOption, SelectOptionValue, SelectProps, SelectValueType } from './types'

// styles
import * as SC from './SelectStyles'
import { BREAKPOINTS_INTS } from '../../styles/constants'

// assets
import CloseIcon from '../../assets/icons/CloseIcon'
import CheckIcon from '../../assets/icons/CheckIcon'

// components
import Drawer from '../../components/Drawer/Drawer'

// hooks
import useIsMobile from '../../hooks/useIsMobile'
import useMessages from '../../hooks/useMessages'

// utils
import { transformToLowerCaseWithoutAccent } from '../../utils/helper'

const getIsSelected = <ValuePropsType extends SelectValueType = string, OptionPropsType extends BaseSelectOption = BaseSelectOption>(
	value: ValuePropsType,
	option: OptionPropsType
) => {
	if (!value) {
		return false
	}

	if (typeof value === 'string' || typeof value === 'number') {
		return value === option.value
	}

	if ('value' in value) {
		return value.value === option.value
	}

	if (Array.isArray(value)) {
		return !!value.find((val) => {
			if (typeof val === 'string' || typeof val === 'number') {
				return val === option.value
			}

			if ('value' in val) {
				return val.value === option.value
			}
			return false
		})
	}

	return false
}

const Select = <ValuePropsType extends SelectValueType = string, OptionPropsType extends BaseSelectOption = BaseSelectOption>(
	props: SelectProps<ValuePropsType, OptionPropsType>
) => {
	const { messages } = useMessages()

	const {
		selectStyle = 'default',
		prefixIcon,
		className,
		showSearch = false,
		suffixIcon = <IconRegularChevronDown width='20px' height='20px' color={'icon.tertiary'} />,
		allowClear,
		placeholder,
		mobileSearchPlaceholder = placeholder || messages.Search,
		listHeight = 300,
		menuItemSelectedIcon = <CheckIcon />,
		optionRender,
		options: optionsProps,
		optionFilterProp = 'label',
		filterOption = false,
		onChange,
		value,
		disabled,
		dropdownStyle,
		dropdownMatchSelectWidth,
		onSelect,
		onDeselect,
		onFocus,
		initialLoading = false,
		isRefetching = false,
		invalid = false,
		emptyContent,
		notFoundContent,
		labelInValue,
		onSearch,
		onDropdownVisibleChange,
		onClearSearch,
		labelRender,
		getPopupContainer
	} = props

	const { emptyIcon, emptyTitle = messages.Search, emptyLabel = messages['Enter the term you want to search for.'] } = emptyContent || {}

	const [isOpen, setIsOpen] = useState(false)
	const [isDrawerOpen, setIsDrawerOpen] = useState(false)
	const [searchValue, setSearchValue] = useState('')
	// highlighted index for keyboard navigation in mobile menu
	const [highlightedIndex, setHighlightedIndex] = useState(0)

	const selectRef = useRef<BaseSelectRef | null>(null)
	const listBoxRef = useRef<HTMLDivElement | null>(null)
	const searchInputRef = useRef<InputRef>(null)

	const isMobile = useIsMobile(BREAKPOINTS_INTS.sm)

	const {
		notFoundIcon,
		notFoundTitle = showSearch && searchValue ? messages['No results found. Try using a different term.'] : messages['Unfortunately, no data was found'],
		notFoundLabel = showSearch && searchValue ? messages['Oops, we didn’t find anything that matches your search.'] : null
	} = notFoundContent || {}

	useEffect(() => {
		if (!isMobile && isDrawerOpen) {
			setIsDrawerOpen(false)
		}
	}, [isMobile, isDrawerOpen])

	const handleFilterOption: SelectProps<ValuePropsType, OptionPropsType>['filterOption'] = (inputValue, option) => {
		if (typeof filterOption === 'function') {
			return filterOption(inputValue, option)
		}

		const compareValue = optionFilterProp === 'label' ? option?.label : option?.value

		return !!transformToLowerCaseWithoutAccent(compareValue)?.includes(transformToLowerCaseWithoutAccent(inputValue))
	}

	const options =
		(showSearch && filterOption && isMobile && searchValue
			? optionsProps?.filter((option) => {
					return handleFilterOption(searchValue, option)
				})
			: optionsProps) || []

	const handleSetHighlightedIndex = () => {
		// set highlighted index to first selected value or first value in the list
		let firstValueIndex = 0
		let valueToFind: ValuePropsType | null | undefined = value

		if (Array.isArray(value)) {
			valueToFind = value[0] as ValuePropsType
		}

		if (valueToFind) {
			firstValueIndex =
				options.findIndex((option) => {
					if (typeof valueToFind === 'object' && 'value' in valueToFind) {
						return option.value === valueToFind.value
					}
					return option.value === value
				}) ?? 0
		}

		setHighlightedIndex(firstValueIndex)
	}

	const handleOnDropdownVisibleChange: RcSelectProps<ValuePropsType, OptionPropsType>['onDropdownVisibleChange'] = (open) => {
		if (!isMobile) {
			// logic for desktop version of dropdown
			if (onDropdownVisibleChange) {
				onDropdownVisibleChange(open)
			}
			if (!open) {
				setSearchValue('')
			}
		}

		if (!isDrawerOpen) {
			setIsOpen(open)
			// logic for mobile version of dropdown
			if (isMobile) {
				setIsDrawerOpen(true)
				// delay focus with setTimeout, so the refs elements are mounted in the DOM
				setTimeout(() => {
					// loose focus from the original select element
					selectRef.current?.blur()
					// focus on search bar if exist or on the first option in the list
					if (showSearch && searchInputRef) {
						searchInputRef.current?.focus()
					} else {
						listBoxRef.current?.focus()
						handleSetHighlightedIndex()
					}
				}, 0)
			}
		}
	}

	const handleChangeDesktop: RcSelectProps<ValuePropsType, OptionPropsType>['onChange'] = (newValue, option) => {
		// When allowClear is enabled, Antd triggers the onChange callback with an undefined value upon clearing.
		// This behavior poses an issue in hook-form-fields, which doesn't recognize undefined as a valid value, resulting in the default value being set instead.
		// More details: https://github.com/orgs/react-hook-form/discussions/5858
		if (onChange) {
			onChange(newValue === undefined ? (null as ValuePropsType) : newValue, option)
		}
	}

	const handleChangeMobile = (isSelected: boolean, currentOption: OptionPropsType) => {
		if (!onChange) {
			return
		}

		let newValue: ValuePropsType | null | undefined = null
		let newOptions: OptionPropsType | OptionPropsType[] = currentOption

		if (Array.isArray(value)) {
			newValue = [...value].reduce((acc) => {
				if (isSelected) {
					return [...acc]
				}

				return [...acc, labelInValue ? { value: currentOption.value, label: currentOption.label } : value]
			}, [])

			newOptions = Array.isArray(newValue)
				? newValue.reduce<OptionPropsType[]>((acc, val) => {
						const option = options.find((opt) => (typeof val === 'object' ? val.value === opt.value : val === opt.value))
						return option ? [...acc, option] : acc
					}, [])
				: []
		}

		if (value && labelInValue) {
			newValue = { value: currentOption.value, label: currentOption.label } as ValuePropsType
		} else {
			newValue = currentOption.value as ValuePropsType
		}
		onChange(newValue ?? null, newOptions)
		setIsDrawerOpen(false)
		setIsOpen(false)
	}

	const handleOnSelectAndDeselect = (option: OptionPropsType, callback: (value: SelectOptionValue<ValuePropsType>, option: OptionPropsType) => void) => {
		return callback((labelInValue ? { value: option.value, label: option.label } : option.value) as SelectOptionValue<ValuePropsType>, option)
	}

	const allowClearWrap = () => {
		if (typeof allowClear === 'object' && allowClear.clearIcon) {
			return allowClear
		}

		if (allowClear) {
			return {
				clearIcon: <CloseIcon />
			}
		}

		return false
	}

	const isInitInitEmptyState = showSearch && !filterOption && !options.length && !searchValue

	const customDropdownStyle =
		isMobile || isInitInitEmptyState
			? {
					minHeight: 0,
					display: 'none'
				}
			: {
					minHeight: 44
				}

	const handleSearch = (newSearchValue: string) => {
		if (onSearch) {
			onSearch(newSearchValue)
		}

		setSearchValue(newSearchValue)
	}

	const handleKeyDownOnMobile = (event: React.KeyboardEvent, isSelected: boolean) => {
		// handles keyboard navigation for mobile menu
		if (!isDrawerOpen) return

		switch (event.key) {
			case 'ArrowDown':
				setHighlightedIndex((prevIndex) => (prevIndex + 1) % options.length)
				break
			case 'ArrowUp':
				setHighlightedIndex((prevIndex) => (prevIndex - 1 + options.length) % options.length)
				break
			case 'Enter':
			case ' ':
				if (options[highlightedIndex] && onChange) {
					handleChangeMobile(isSelected, options[highlightedIndex])
				}
				break
			case 'Escape':
				setIsOpen(false)
				setIsDrawerOpen(false)
				break
			default:
				break
		}
	}

	const onMobileMenuClose = () => {
		// set menu states to false
		setIsOpen(false)
		setIsDrawerOpen(false)
		// refocus to select input after close
		selectRef.current?.focus()
	}

	const afterMobileMenuOpenChange = (open: boolean) => {
		if (!open) {
			setSearchValue('')
			setHighlightedIndex(-1)
		}
		if (onDropdownVisibleChange) {
			onDropdownVisibleChange(open)
		}
	}

	const onMobileMenuItemClick = (option: OptionPropsType, isSelected: boolean) => {
		if (isSelected && onDeselect) {
			handleOnSelectAndDeselect(option, onDeselect)
		}

		if (!isSelected && onSelect) {
			handleOnSelectAndDeselect(option, onSelect)
		}

		if (onChange) {
			handleChangeMobile(isSelected, option)
		}
	}

	const labelRenderWrap: RcSelectProps<ValuePropsType, OptionPropsType>['labelRender'] = labelRender
		? (labelRenderProps) => {
				const valueOption = options.find((option) => option.value === labelRenderProps.value)
				if (valueOption) {
					return labelRender(valueOption)
				}

				return undefined
			}
		: undefined

	const desktopNotFoundContent = () => {
		if (showSearch) {
			return initialLoading || isRefetching ? <div /> : null
		}

		return <div />
	}

	return (
		<>
			<SC.SelectWrapper>
				{prefixIcon && <SC.PrefixIconWrapper $isDisabled={disabled}>{prefixIcon}</SC.PrefixIconWrapper>}
				<RcSelect<ValuePropsType, OptionPropsType>
					ref={selectRef}
					// prevent to have empty string as value, because empty string prevents to show placeholder
					value={value || null}
					className={`${prefixIcon ? 'rc-select-prefix-icon' : ''} ${selectStyle === 'no-borders' ? 'rc-select-no-borders' : ''} ${invalid ? 'rc-select-invalid' : ''} ${className}`}
					showSearch={showSearch}
					suffixIcon={suffixIcon}
					allowClear={allowClearWrap()}
					placeholder={placeholder}
					listHeight={listHeight}
					menuItemSelectedIcon={menuItemSelectedIcon}
					labelInValue={labelInValue}
					optionRender={
						optionRender
							? (option) => {
									return optionRender(option.data)
								}
							: undefined
					}
					options={options}
					labelRender={labelRenderWrap}
					disabled={disabled}
					filterOption={filterOption === false ? false : handleFilterOption}
					optionFilterProp={optionFilterProp}
					dropdownStyle={{ ...customDropdownStyle, ...(dropdownStyle || {}) }}
					dropdownMatchSelectWidth={dropdownMatchSelectWidth}
					onSelect={(_, option) => {
						if (onSelect) {
							handleOnSelectAndDeselect(option, onSelect)
						}
					}}
					onDeselect={(_, option) => {
						if (onDeselect) {
							handleOnSelectAndDeselect(option, onDeselect)
						}
					}}
					onFocus={onFocus}
					dropdownRender={(menu) =>
						isMobile ? (
							<div />
						) : (
							<SC.StyledFetchResults
								initialLoading={initialLoading}
								isRefetching={isRefetching}
								isError={invalid}
								empty={!options.length && !initialLoading && !isRefetching}
								emptyIcon={isInitInitEmptyState ? emptyIcon : notFoundIcon}
								emptyTitle={isInitInitEmptyState ? emptyTitle : notFoundTitle}
								emptyLabel={isInitInitEmptyState ? emptyLabel : notFoundLabel}
							>
								{menu}
							</SC.StyledFetchResults>
						)
					}
					open={isOpen}
					onDropdownVisibleChange={handleOnDropdownVisibleChange}
					onChange={isMobile ? undefined : handleChangeDesktop}
					onClear={() => {
						if (isMobile && onChange) {
							onChange(null)
						}
					}}
					onSearch={showSearch ? handleSearch : undefined}
					// not found content is handled in dropdownRender callback with FetchResult component
					// empty <div /> must be returned, otherwise RcSelect would show some default, or doesn't render dropdown at all when null is passed
					// notFoundContent={<div />}
					notFoundContent={desktopNotFoundContent()}
					getPopupContainer={getPopupContainer}
				/>
			</SC.SelectWrapper>
			{isMobile && (
				<Drawer
					open={isOpen}
					mask
					maskClosable
					keyboard
					afterOpenChange={afterMobileMenuOpenChange}
					title={
						showSearch ? (
							<SC.MobileSearchWrapper>
								<SC.MobileSearchInput
									ref={searchInputRef}
									inputMode={'search'}
									value={searchValue ?? null}
									onChange={(e) => handleSearch(e.target.value)}
									onClear={() => {
										if (onClearSearch) {
											onClearSearch()
										}
										handleSearch('')
									}}
									placeholder={mobileSearchPlaceholder}
									onKeyDown={(e) => {
										if (e.key === 'ArrowDown') {
											listBoxRef.current?.focus()
											setHighlightedIndex(0)
										}
									}}
								/>
							</SC.MobileSearchWrapper>
						) : (
							placeholder
						)
					}
					onClose={onMobileMenuClose}
					destroyOnClose
				>
					<SC.MobileListWrapper>
						<SC.StyledFetchResults
							initialLoading={initialLoading}
							isRefetching={isRefetching}
							isError={invalid}
							empty={!options.length && !initialLoading && !isRefetching}
							emptyIcon={isInitInitEmptyState ? emptyIcon : notFoundIcon}
							emptyTitle={isInitInitEmptyState ? emptyTitle : notFoundTitle}
							emptyLabel={isInitInitEmptyState ? emptyLabel : notFoundLabel}
						>
							<SC.MobileListBox role={'listbox'} tabIndex={0} ref={listBoxRef} onKeyDown={(e) => handleKeyDownOnMobile(e, false)}>
								{options.map((option, index) => {
									const isSelected = value ? getIsSelected<ValuePropsType, OptionPropsType>(value, option) : false
									const isHighlighted = index === highlightedIndex

									return (
										<SC.MobileListBoxItem
											key={option.key}
											$isSelected={isSelected}
											$isHighlighted={isHighlighted}
											aria-label={option.label}
											aria-selected={isSelected}
											role={'option'}
											// Set the highlighted index on onMouseEnter so that when the user hovers over an element with the mouse and then continues navigating with the arrow keys, the navigation will resume from the hovered item's position.
											onMouseEnter={() => setHighlightedIndex(index)}
											onClick={() => onMobileMenuItemClick(option, isSelected)}
										>
											<SC.MobileMenuItemText>{optionRender ? optionRender(option) : option.label}</SC.MobileMenuItemText>
											{isSelected && <SC.MobileMenuItemIconWrapper>{menuItemSelectedIcon}</SC.MobileMenuItemIconWrapper>}
										</SC.MobileListBoxItem>
									)
								})}
							</SC.MobileListBox>
						</SC.StyledFetchResults>
					</SC.MobileListWrapper>
				</Drawer>
			)}
		</>
	)
}

export default Select
