import React, { useState } from 'react'
import { ButtonModel } from '@notino/react-styleguide'
import { useIntl } from 'react-intl'
import useMessages from '../../../../hooks/useMessages'
import { ConfigResponse, SalonDetail } from '../../../../types/types'
import * as SC from './SalonAboutUsMobileStyles'
import InfoIcon from '../../../../assets/icons/InfoIcon'
import CosmeticsIcon from '../../../../assets/icons/CosmeticsIcon'
import MapPinIcon from '../../../../assets/icons/MapPinIcon'
import NavigationIcon from '../../../../assets/icons/NavigationIcon'
import GoogleMaps from '../../../../components/GoogleMaps/GoogleMaps'
import ParkingIcon from '../../../../assets/icons/ParkingIcon'
import MailIcon from '../../../../assets/icons/MailIcon'
import PhoneIcon from '../../../../assets/icons/PhoneIcon'
import { getGoogleMapsLink, getPhoneNumber } from '../../../../utils/helper'
import SocialIcons from '../SocialIcons/SocialIcons'
import CreditCardIcon from '../../../../assets/icons/CreditCardIcon'
import PaymentDetails from '../PaymentDetails/PaymentDetails'
import SalonPricelists from '../SalonPricelists/SalonPricelists'
import FileIcon from '../../../../assets/icons/FileIcon'
import TeamMobile from '../TeamMobile/TeamMobile'
import UserIcon from '../../../../assets/icons/UserIcon'
import useCheckClamping from '../../../../hooks/useCheckClamping'
import { SOCIAL_LINK_VARIANT } from '../SocialIcons/SocialIconsStyles'
import ElementsSlider from '../../../../components/ElementsSlider/ElementsSlider'
import { MAP } from '../../../../utils/enums'

type Props = {
	salonData: SalonDetail
	countriesData: ConfigResponse['rolloutCountries']
	hideFirstSectionHeading?: boolean
	hideTeamSection?: boolean
	shouldClampDescription?: boolean
}

const SalonAboutUsMobile = (props: Props) => {
	const { messages } = useMessages()
	const { locale } = useIntl()

	const { salonData, countriesData, hideFirstSectionHeading, hideTeamSection, shouldClampDescription } = props

	const { clampedElementRef, isElementClamped } = useCheckClamping()
	const [isDescriptionClamped, setIsDescriptionClamped] = useState(shouldClampDescription)

	const cosmetics = salonData.cosmetics && salonData.cosmetics.length > 0 ? salonData.cosmetics : null
	const languages = salonData.languages && salonData.languages.length > 0 ? salonData.languages : null
	const employees = salonData.employees && salonData.employees.length > 0 ? salonData.employees : null

	const googleMapLink = getGoogleMapsLink({ lat: salonData.address?.latitude, lon: salonData.address?.longitude })

	const phone = salonData.phones.length > 0 && countriesData.length > 0 ? getPhoneNumber(salonData.phones[0], countriesData) : undefined
	const phoneHref = phone ? `tel:${phone}` : undefined

	const socialLinks =
		salonData.socialLinkWebPage ||
		salonData.socialLinkFB ||
		salonData.socialLinkInstagram ||
		salonData.socialLinkPinterest ||
		salonData.socialLinkYoutube ||
		salonData.socialLinkTikTok

	const lng = salonData.address?.longitude
	const lat = salonData.address?.latitude

	let mapCenter: google.maps.LatLngLiteral = MAP.locations[locale as keyof typeof MAP.locations] || MAP.defaultLocation
	const markers = []

	if (lng !== undefined && lat !== undefined) {
		mapCenter = { lat, lng }
		markers.push({
			id: salonData.id,
			position: { lat, lng },
			extra: {}
		})
	}

	return (
		<div>
			{/* team */}
			{!hideTeamSection && employees && (
				<SC.Section $paddingTop={0}>
					<SC.SectionHeading>
						<UserIcon />
						{messages.Team}
					</SC.SectionHeading>
					<TeamMobile salonData={salonData} />
				</SC.Section>
			)}

			{/* about us text */}
			{salonData.aboutUsFirst && (
				<SC.Section $paddingTop={hideTeamSection ? 0 : undefined}>
					{!hideFirstSectionHeading && (
						<SC.SectionHeading>
							<InfoIcon />
							{messages['About us']}
						</SC.SectionHeading>
					)}
					<div>
						<SC.AboutUsText ref={clampedElementRef} $isClamped={isDescriptionClamped}>
							{salonData.aboutUsFirst}
						</SC.AboutUsText>
						{/* NOTE: we need 2 state variables - first is for manual enabling/disabling of clamping, second is for checking if the element is being automatically clamped */}
						{isDescriptionClamped && isElementClamped && (
							<SC.AboutUsShowMoreButton type='button' onClick={() => setIsDescriptionClamped(false)}>
								{messages['Show more']}
							</SC.AboutUsShowMoreButton>
						)}
					</div>
				</SC.Section>
			)}

			{/* cosmetics */}
			{cosmetics && (
				<SC.Section>
					<SC.SectionHeading>
						<CosmeticsIcon />
						{messages['What cosmetics do we use?']}
					</SC.SectionHeading>
					<ElementsSlider gap={'28px'} fading={false}>
						{cosmetics.map((cosmetic) => (
							<SC.EnumsItem key={cosmetic.id}>
								{cosmetic.image?.resizedImages.thumbnail ? (
									<img loading='lazy' alt={cosmetic.name} src={cosmetic.image.resizedImages.thumbnail} />
								) : (
									<span>{cosmetic.name}</span>
								)}
							</SC.EnumsItem>
						))}
					</ElementsSlider>
				</SC.Section>
			)}

			{/* languages */}
			{languages && (
				<SC.Section>
					<SC.SectionHeading>
						<CosmeticsIcon />
						{messages['We speak']}
					</SC.SectionHeading>
					<ElementsSlider gap={'28px'} fading={false}>
						{languages.map((language) => (
							<SC.LanguageContainer key={language.id}>
								{language.flag?.resizedImages?.thumbnail && (
									<SC.LanguageFlag>
										<img width={24} height={16} loading='lazy' alt={language.name} src={language.flag.resizedImages.thumbnail} />
									</SC.LanguageFlag>
								)}
								<SC.LanguageTitle>{language.name}</SC.LanguageTitle>
							</SC.LanguageContainer>
						))}
					</ElementsSlider>
				</SC.Section>
			)}

			{/* address */}
			{salonData.address && (
				<SC.Section>
					<SC.SectionHeading>
						<MapPinIcon />
						{messages.Address}
					</SC.SectionHeading>
					<SC.MapContainer>
						<GoogleMaps defaultCenter={mapCenter} defaultSelectedMarkerID={salonData.id} markers={markers} />
					</SC.MapContainer>
					{salonData.formattedAddress && <SC.Address>{salonData.formattedAddress}</SC.Address>}
					{salonData.address?.description && <SC.AddressDescription>{salonData.address?.description}</SC.AddressDescription>}
					{googleMapLink && (
						<SC.LinkButton href={googleMapLink} target='_blank' buttonStyle={ButtonModel.Styles.secondary}>
							<NavigationIcon />
							{messages.Navigate}
						</SC.LinkButton>
					)}
				</SC.Section>
			)}

			{/* parking */}
			{salonData.parkingNote && (
				<SC.Section>
					<SC.SectionHeading>
						<ParkingIcon />
						{messages.Parking}
					</SC.SectionHeading>
					<SC.ParkingDescription>{salonData.parkingNote}</SC.ParkingDescription>
				</SC.Section>
			)}

			{/* email */}
			{salonData.email && (
				<SC.Section>
					<SC.SectionHeading $marginBottom={0}>
						<MailIcon />
						<SC.MailLink href={`mailto:${salonData.email}`}>{salonData.email}</SC.MailLink>
					</SC.SectionHeading>
				</SC.Section>
			)}

			{/* phone */}
			{phone && phoneHref && (
				<SC.Section>
					<SC.SectionHeading $marginBottom={0}>
						<PhoneIcon />
						<SC.MailLink href={phoneHref}>{phone}</SC.MailLink>
					</SC.SectionHeading>
				</SC.Section>
			)}

			{/* social icons */}
			{socialLinks && (
				<SC.Section>
					<SocialIcons salonData={salonData} variant={SOCIAL_LINK_VARIANT.DARK} />
				</SC.Section>
			)}

			{/* payment info */}
			{(salonData.payByCard || salonData.payByCash || salonData.otherPaymentMethods) && (
				<SC.Section>
					<SC.SectionHeading>
						<CreditCardIcon />
						{messages['Payment information']}
					</SC.SectionHeading>
					<PaymentDetails salonData={salonData} />
				</SC.Section>
			)}

			{/* price lists */}
			{salonData.pricelists && (
				<SC.Section>
					<SC.SectionHeading>
						<FileIcon />
						{messages['Price list']}
					</SC.SectionHeading>
					<SalonPricelists pricelists={salonData.pricelists} hideHeading />
				</SC.Section>
			)}
		</div>
	)
}

export default SalonAboutUsMobile
