/* eslint-disable global-require */
import React, { useContext, useEffect, useState } from 'react'
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'

// styles
import * as SC from './GalleryMobileStyles'
import { SALON_MOBILE_PROMO_BANNER_CLASSNAME } from '../../../../styles/constants'

// types
import { Image as ImageType } from '../../../../types/types'

// utils
import { AppContext } from '../../../../utils/appProvider'

type Props = {
	images: ImageType[]
	allowedReservations: boolean
}

const GalleryMobile = (props: Props) => {
	const { images, allowedReservations } = props

	const { assetsPath } = useContext(AppContext)

	const [isLoading, setIsLoading] = useState(true)

	const placeholder = `${assetsPath}/${require('../../../../assets/images/basic-salon-placeholder.jpg')}`
	const placeholderSmall = `${assetsPath}/${require('../../../../assets/images/basic-salon-placeholder-small.jpg')}`

	// NOTE: this screen is visible only for czech version for now
	const promoBannerMobile = `${assetsPath}/${require('../../../../assets/images/salon-detail/gallery-banner-mobile-cs.jpg')}`
	let promoBannerLink: string | undefined

	const sliderImages: ReactImageGalleryItem[] = images?.map((image, index) => {
		return {
			original: image.resizedImages.small,
			loading: 'lazy',
			originalAlt: `Salon gallery image ${index}`,
			srcSet: `
                ${image.resizedImages.small} 480w,
                ${image.resizedImages.medium} 1080w,
                ${image.resizedImages.large} 1500w
            `,
			sizes: `
                (max-width: 480px) 480px,
                (max-width: 1080px) 1080px,
                1500px
            `
		}
	})

	// add placeholder if salon doesn't have any images
	if (sliderImages.length <= 0) {
		sliderImages.push({
			original: placeholder
		})
	}

	/** Display only when reservations are allowed */
	if (allowedReservations && sliderImages.length > 2) {
		sliderImages.splice(2, 0, {
			originalClass: promoBannerLink ? SALON_MOBILE_PROMO_BANNER_CLASSNAME : undefined,
			original: promoBannerMobile
		})
	}

	const handleImageClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
		const target = e.target as HTMLDivElement

		if (target.parentElement?.classList.contains(SALON_MOBILE_PROMO_BANNER_CLASSNAME)) {
			window.open(promoBannerLink, '_blank', 'noopener,noreferrer')
		}
	}

	useEffect(() => {
		const checkImageLoaded = () => {
			const img = new Image()
			img.src = placeholderSmall
			img.onload = () => setIsLoading(false)
			img.onerror = () => setIsLoading(false)
		}

		checkImageLoaded()
	}, [placeholderSmall])

	return (
		<SC.GalleryMobileContainer>
			{isLoading ? (
				<SC.SingleImage src={placeholderSmall} alt={''} loading={'eager'} />
			) : (
				<ImageGallery
					lazyLoad={true}
					items={sliderImages}
					showNav={false}
					showPlayButton={false}
					showThumbnails={false}
					showFullscreenButton={false}
					onClick={promoBannerLink ? handleImageClick : undefined}
					showBullets
				/>
			)}
		</SC.GalleryMobileContainer>
	)
}

export default GalleryMobile
