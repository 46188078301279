import styled, { css } from 'styled-components'
import { BREAKPOINTS, RESERVATION_BOOKING_MODAL_MOBILE_BREAKPOINT, SALON_PAGE_MOBILE_BREAKPOINT } from '../../styles/constants'
import ErrorScreen from '../../atoms/ErrorScreen/ErrorScreen'
import { RESERVATION_BOOKING_MODAL_Z_INDEXES } from './reservationBookingModalHelpers'

const NOTINO_MODAL_DESKTOP_MARGIN = 5 // in rem

// NOTE: we need to override styles on Notino modal for which no better approach was found
export const ModalWrapper = styled.div<{ $isSummaryStep: boolean }>`
	z-index: ${RESERVATION_BOOKING_MODAL_Z_INDEXES.MODAL_WRAP};
	position: relative;

	> div > div {
		overflow-y: auto;
	}

	& div[role='dialog'] {
		position: relative;

		& > button {
			display: none;
		}

		> div:first-of-type {
			height: 100%;
			max-height: initial;

			> div:first-of-type {
				height: 100%;

				> div:first-of-type {
					height: 100%;
				}
			}
		}

		@media (min-width: ${RESERVATION_BOOKING_MODAL_MOBILE_BREAKPOINT}) {
			max-width: 500px;
			max-height: 720px;
			min-height: 400px;
			margin: ${NOTINO_MODAL_DESKTOP_MARGIN}rem auto;
			height: calc(100% - ${NOTINO_MODAL_DESKTOP_MARGIN * 2}rem);
			border-radius: 4px;
			overflow: hidden;
		}

		@media (min-width: ${RESERVATION_BOOKING_MODAL_MOBILE_BREAKPOINT} && max-height: ${BREAKPOINTS.md}) {
			margin: ${NOTINO_MODAL_DESKTOP_MARGIN / 2}rem auto;
			height: calc(100% - ${NOTINO_MODAL_DESKTOP_MARGIN}rem);
		}

		${({ $isSummaryStep }) =>
			$isSummaryStep &&
			css`
				@media (min-width: ${SALON_PAGE_MOBILE_BREAKPOINT}) {
					max-width: 920px;
				}
			`}
	}
`

export const Content = styled.div`
	height: 100%;
`

export const StyledError = styled(ErrorScreen)`
	height: 100%;
`
