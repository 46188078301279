import React, { ReactNode } from 'react'
import { IntlShape } from 'react-intl'
import { getIntl } from './intl'

export type CategorySeoData = {
	metaTitle: string
	metaDescription: string
	metaKeywords: string
	categoryDescription: ReactNode
	categoryDescriptionTitle: string
}

type CategoriesSeoData = {
	[key: string]: CategorySeoData
}

const getCategoryDescription = (intl: IntlShape, messageId: string) =>
	intl.formatMessage({ id: messageId }, { p: (chunks) => <p>{chunks}</p>, strong: (chunks) => <strong>{chunks}</strong> })

export const getCategorySeoData = (categoryID: string, categoryName: string) => {
	const intl = getIntl()

	const fallbackMetaTitle = intl.formatMessage(
		{
			id: 'SEO - Category page - fallback meta title',
			defaultMessage: '{ categoryName } | Notino Partner'
		},
		{
			categoryName
		}
	)
	const fallbackMetaDescription = intl.formatMessage({ id: 'SEO - Category page - fallback meta description' })
	const fallbackMetaKeywords = intl.formatMessage({ id: 'SEO - Category page - fallback meta keywords' })
	const fallbackCategoryDescription = getCategoryDescription(intl, 'SEO - Category page - fallback category description')
	const fallbackCategoryDescriptionTitle = intl.formatMessage({ id: 'SEO - Category page - fallback category description title' })

	const data: CategoriesSeoData = {
		'00000000-0000-0000-0000-000000000001': {
			// categoryName: "Women's hairdressing",
			metaTitle: intl.formatMessage(
				{ id: 'SEO - Category page - Womens hairdressing - meta title' },
				{
					categoryName
				}
			),
			metaDescription: intl.formatMessage({ id: 'SEO - Category page - Womens hairdressing - meta description' }),
			metaKeywords: intl.formatMessage({ id: 'SEO - Category page - Womens hairdressing - meta keywords' }),
			categoryDescription: getCategoryDescription(intl, 'SEO - Category page - Womens hairdressing - category description'),
			categoryDescriptionTitle: intl.formatMessage({ id: 'SEO - Category page - Womens hairdressing - category description title' })
		},
		'00000000-0000-0000-0000-000000000002': {
			// categoryName: "Men's hairdressing",
			metaTitle: intl.formatMessage(
				{ id: 'SEO - Category page - Mens hairdressing - meta title' },
				{
					categoryName
				}
			),
			metaDescription: intl.formatMessage({ id: 'SEO - Category page - Mens hairdressing - meta description' }),
			metaKeywords: intl.formatMessage({ id: 'SEO - Category page - Mens hairdressing - meta keywords' }),
			categoryDescription: getCategoryDescription(intl, 'SEO - Category page - Mens hairdressing - category description'),
			categoryDescriptionTitle: intl.formatMessage({ id: 'SEO - Category page - Mens hairdressing - category description title' })
		},
		'00000000-0000-0000-0000-000000000003': {
			// categoryName: "Children's hairdressing",
			metaTitle: intl.formatMessage(
				{ id: 'SEO - Category page - Childrens hairdressing - meta title' },
				{
					categoryName
				}
			),
			metaDescription: intl.formatMessage({ id: 'SEO - Category page - Childrens hairdressing - meta description' }),
			metaKeywords: intl.formatMessage({ id: 'SEO - Category page - Childrens hairdressing - meta keywords' }),
			categoryDescription: getCategoryDescription(intl, 'SEO - Category page - Childrens hairdressing - category description'),
			categoryDescriptionTitle: intl.formatMessage({ id: 'SEO - Category page - Childrens hairdressing - category description title' })
		},
		'00000000-0000-0000-0000-000000000004': {
			// categoryName: 'Barber shop',
			metaTitle: intl.formatMessage(
				{ id: 'SEO - Category page - Barber shop - meta title' },
				{
					categoryName
				}
			),
			metaDescription: intl.formatMessage({ id: 'SEO - Category page - Barber shop - meta description' }),
			metaKeywords: intl.formatMessage({ id: 'SEO - Category page - Barber shop - meta keywords' }),
			categoryDescription: getCategoryDescription(intl, 'SEO - Category page - Barber shop - category description'),
			categoryDescriptionTitle: intl.formatMessage({ id: 'SEO - Category page - Barber shop - category description title' })
		},
		'00000000-0000-0000-0000-000000000005': {
			// categoryName: 'Cosmetic services',
			metaTitle: intl.formatMessage(
				{ id: 'SEO - Category page - Cosmetic services - meta title' },
				{
					categoryName
				}
			),
			metaDescription: intl.formatMessage({ id: 'SEO - Category page - Cosmetic services - meta description' }),
			metaKeywords: intl.formatMessage({ id: 'SEO - Category page - Cosmetic services - meta keywords' }),
			categoryDescription: getCategoryDescription(intl, 'SEO - Category page - Cosmetic services - category description'),
			categoryDescriptionTitle: intl.formatMessage({ id: 'SEO - Category page - Cosmetic services - category description title' })
		},
		'00000000-0000-0000-0000-000000000006': {
			// categoryName: 'Massage & physiotherapy',
			metaTitle: intl.formatMessage(
				{ id: 'SEO - Category page - Massage and physiotherapy - meta title' },
				{
					categoryName
				}
			),
			metaDescription: intl.formatMessage({ id: 'SEO - Category page - Massage and physiotherapy - meta description' }),
			metaKeywords: intl.formatMessage({ id: 'SEO - Category page - Massage and physiotherapy - meta keywords' }),
			categoryDescription: getCategoryDescription(intl, 'SEO - Category page - Massage and physiotherapy - category description'),
			categoryDescriptionTitle: intl.formatMessage({ id: 'SEO - Category page - Massage and physiotherapy - category description title' })
		},
		'00000000-0000-0000-0000-000000000007': {
			// categoryName: 'Aesthetic services',
			metaTitle: intl.formatMessage(
				{ id: 'SEO - Category page - Aesthetic services - meta title' },
				{
					categoryName
				}
			),
			metaDescription: intl.formatMessage({ id: 'SEO - Category page - Aesthetic services - meta description' }),
			metaKeywords: intl.formatMessage({ id: 'SEO - Category page - Aesthetic services - meta keywords' }),
			categoryDescription: getCategoryDescription(intl, 'SEO - Category page - Aesthetic services - category description'),
			categoryDescriptionTitle: intl.formatMessage({ id: 'SEO - Category page - Aesthetic services - category description title' })
		},
		'00000000-0000-0000-0000-000000000008': {
			// categoryName: 'Manicure & pedicure',
			metaTitle: intl.formatMessage(
				{ id: 'SEO - Category page - Manicure and pedicure - meta title' },
				{
					categoryName
				}
			),
			metaDescription: intl.formatMessage({ id: 'SEO - Category page - Manicure and pedicure - meta description' }),
			metaKeywords: intl.formatMessage({ id: 'SEO - Category page - Manicure and pedicure - meta keywords' }),
			categoryDescription: getCategoryDescription(intl, 'SEO - Category page - Manicure and pedicure - category description'),
			categoryDescriptionTitle: intl.formatMessage({ id: 'SEO - Category page - Manicure and pedicure - category description title' })
		},
		'00000000-0000-0000-0000-000000000009': {
			// categoryName: 'Pet salons',
			metaTitle: intl.formatMessage(
				{ id: 'SEO - Category page - Pet salons - meta title' },
				{
					categoryName
				}
			),
			metaDescription: intl.formatMessage({ id: 'SEO - Category page - Pet salons - meta description' }),
			metaKeywords: intl.formatMessage({ id: 'SEO - Category page - Pet salons - meta keywords' }),
			categoryDescription: getCategoryDescription(intl, 'SEO - Category page - Pet salons - category description'),
			categoryDescriptionTitle: intl.formatMessage({ id: 'SEO - Category page - Pet salons - category description title' })
		},
		'2950610d-c9ec-484d-8c33-115a52e6bd42': {
			// categoryName: 'Tanning salons & sunbeds',
			metaTitle: intl.formatMessage(
				{ id: 'SEO - Category page - Tanning salons and sunbeds - meta title' },
				{
					categoryName
				}
			),
			metaDescription: intl.formatMessage({ id: 'SEO - Category page - Tanning salons and sunbeds - meta description' }),
			metaKeywords: intl.formatMessage({ id: 'SEO - Category page - Tanning salons and sunbeds - meta keywords' }),
			categoryDescription: getCategoryDescription(intl, 'SEO - Category page - Tanning salons and sunbeds - category description'),
			categoryDescriptionTitle: intl.formatMessage({ id: 'SEO - Category page - Tanning salons and sunbeds - category description title' })
		},
		'8cb2ed99-a83f-45cc-8c50-7c47eec7a5a2': {
			// categoryName: 'Tattoos & piercings',
			metaTitle: intl.formatMessage(
				{ id: 'SEO - Category page - Tattoos and piercings - meta title' },
				{
					categoryName
				}
			),
			metaDescription: intl.formatMessage({ id: 'SEO - Category page - Tattoos and piercings - meta description' }),
			metaKeywords: intl.formatMessage({ id: 'SEO - Category page - Tattoos and piercings - meta keywords' }),
			categoryDescription: getCategoryDescription(intl, 'SEO - Category page - Tattoos and piercings - category description'),
			categoryDescriptionTitle: intl.formatMessage({ id: 'SEO - Category page - Tattoos and piercings - category description title' })
		},
		'cbf2934d-49ad-409e-a283-ac4a8b060eca': {
			// categoryName: 'Aesthetic medicine',
			metaTitle: intl.formatMessage(
				{ id: 'SEO - Category page - Aesthetic medicine - meta title' },
				{
					categoryName
				}
			),
			metaDescription: intl.formatMessage({ id: 'SEO - Category page - Aesthetic medicine - meta description' }),
			metaKeywords: intl.formatMessage({ id: 'SEO - Category page - Aesthetic medicine - meta keywords' }),
			categoryDescription: getCategoryDescription(intl, 'SEO - Category page - Aesthetic medicine - category description'),
			categoryDescriptionTitle: intl.formatMessage({ id: 'SEO - Category page - Aesthetic medicine - category description title' })
		}
	}

	const foundCategoryData = data[categoryID]

	return foundCategoryData
		? {
				metaTitle: foundCategoryData.metaTitle,
				metaDescription: foundCategoryData.metaDescription,
				metaKeywords: foundCategoryData.metaKeywords,
				categoryDescription: foundCategoryData.categoryDescription,
				categoryDescriptionTitle: foundCategoryData.categoryDescriptionTitle
			}
		: {
				metaTitle: fallbackMetaTitle,
				metaDescription: fallbackMetaDescription,
				metaKeywords: fallbackMetaKeywords,
				categoryDescription: fallbackCategoryDescription,
				categoryDescriptionTitle: fallbackCategoryDescriptionTitle
			}
}
