import styled, { css } from 'styled-components'
import { theme } from '@notino/react-styleguide'
import { NotinoBodyLarge, NotinoBodyMedium, NotinoBodySmall } from '../../styles/helpers'
import { DateTimeSlotPickerProps } from '../DateTimeSlotPicker/types'

type SizeType = NonNullable<DateTimeSlotPickerProps['size']>

export const Wrapper = styled.div``

export const Label = styled.span`
	${NotinoBodyLarge};
	display: inline-block;
	margin-bottom: 16px;
`

export const RadioButtonsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`

export const RadioButtonLabel = styled.label<{ $disabled?: boolean }>`
	position: relative;
	cursor: ${({ $disabled }) => ($disabled ? 'default' : 'pointer')};
`

export const CustomLabel = styled.div<{ $size: SizeType }>`
	${({ $size }) => css`
		background: #f5f5f5;
		min-height: 48px;
		padding: ${$size === 'small' ? '5px 10px' : '10px 15px'};
		border: 1px solid transparent;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: center;
		transition: all 0.3s ease;
		outline: 2px solid transparent;
	`}
`

export const LabelTitle = styled.span<{ $size: SizeType }>`
	${({ $size }) => css`
		${$size === 'default' ? NotinoBodyLarge : NotinoBodyMedium};
	`}
`

export const LabelDescription = styled.span`
	color: #404040;
	${NotinoBodySmall};
`

export const RadioButton = styled.input`
	position: absolute;
	left: 0;
	top: 0;
	width: 0;
	height: 0;
	overflow: hidden;
	opacity: 0;

	&:checked ~ ${CustomLabel} {
		background-color: ${theme.color.background.primary};
		border-color: ${theme.color.common.black};
	}

	&:not(:disabled) {
		&:focus-visible ~ ${CustomLabel} {
			outline-color: ${theme.color.common.black};
		}
	}

	&:disabled {
		&:checked {
			& ~ ${CustomLabel} {
				border-color: #9f9f9f;
			}
		}

		& ~ ${CustomLabel} {
			${LabelTitle},
			${LabelDescription} {
				color: #9f9f9f;
			}
		}
	}
`
