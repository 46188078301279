export enum INFO_LINE_TYPE {
	INFO = 'INFO',
	WARNING = 'WARNING',
	SUCCESS = 'SUCCESS',
	ERROR = 'ERROR'
}

export type InfoLineProps = {
	type?: INFO_LINE_TYPE
	title?: React.ReactNode
	message: React.ReactNode
	onClose?: () => void
}
