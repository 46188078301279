import { theme } from '@notino/react-styleguide'
import styled from 'styled-components'

// utils
import { CONTAINER_X_PADDING } from '../../styles/constants'

export const ElementsSliderWrapper = styled.div`
	position: relative;
	margin-left: -${CONTAINER_X_PADDING}px;
	margin-right: -${CONTAINER_X_PADDING}px;
`

export const ElementsSlider = styled.div<{ $overflowX: 'auto' | 'scroll' }>`
	scroll-behavior: smooth;
	overflow-x: ${({ $overflowX }) => $overflowX};

	@media (hover: none) and (pointer: coarse) {
		/* Hides scrollbar on touch devices */
		scrollbar-width: none; /* Firefox */
		-ms-overflow-style: none; /* IE and Edge */
		&::-webkit-scrollbar {
			display: none; /* Chrome, Safari and Opera */
		}
	}
`

export const ElementsSliderContent = styled.div<{ $gap: string }>`
	display: inline-flex;
	gap: ${({ $gap }) => $gap};
	padding-bottom: 4px;
	padding-left: ${CONTAINER_X_PADDING}px;
	padding-right: ${CONTAINER_X_PADDING}px;
`

export const FadeLeft = styled.div`
	position: absolute;
	top: 0px;
	bottom: 0px;
	left: 0px;
	z-index: 1;
	width: 50px;
	background: linear-gradient(to left, transparent, ${theme.color.background.primary} 100%);
	pointer-events: none;
`

export const FadeRight = styled.div`
	position: absolute;
	top: 0px;
	bottom: 0px;
	right: 0px;
	width: 50px;
	z-index: 1;
	background: linear-gradient(to right, transparent, ${theme.color.background.primary} 100%);
	pointer-events: none;
`
