/* eslint-disable global-require */
import React, { useContext, useEffect, useState } from 'react'
import { ButtonModel, Modal, ModalModel } from '@notino/react-styleguide'
import ImageIcon from '../../../../assets/icons/ImageIcon'
import { Image as ImageType } from '../../../../types/types'
import * as SC from './GalleryDesktopStyles'
import useMessages from '../../../../hooks/useMessages'
import { AppContext } from '../../../../utils/appProvider'
import { CONTAINER_X_PADDING, MAX_WIDTHS } from '../../../../styles/constants'

// constants
const MAX_PREVIEW_IMAGES_COUNT = 5

type SalonGalleryProps = {
	images: ImageType[]
	allowedReservations: boolean
}

const GalleryDesktop = (props: SalonGalleryProps) => {
	const { images, allowedReservations } = props

	const { assetsPath } = useContext(AppContext)
	const { messages } = useMessages()

	const [isLoading, setIsLoading] = useState(true)
	const [photoIndex, setPhotoIndex] = useState<number>(-1)
	const modalOpen = photoIndex > -1

	const PROMO_BANNERS_COUNT = allowedReservations ? 1 : 0

	const placeholder = `${assetsPath}/${require('../../../../assets/images/basic-salon-placeholder.jpg')}`
	const placeholderSmall = `${assetsPath}/${require('../../../../assets/images/basic-salon-placeholder-small.jpg')}`

	// handling arrow keys to navigate between images when modal is open
	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			if (event.key === 'ArrowLeft') setPhotoIndex((prev) => (prev > 0 ? prev - 1 : 0))
			if (event.key === 'ArrowRight') setPhotoIndex((prev) => (prev < images.length - 1 ? prev + 1 : prev))
		}
		if (modalOpen) {
			window.addEventListener('keydown', handleKeyDown)
		}
		return () => {
			return window.removeEventListener('keydown', handleKeyDown)
		}
	}, [modalOpen, images.length])

	useEffect(() => {
		const checkImageLoaded = () => {
			const img = new Image()
			img.src = placeholderSmall
			img.onload = () => setIsLoading(false)
			img.onerror = () => setIsLoading(false)
		}

		checkImageLoaded()
	}, [placeholderSmall])

	// NOTE: this screen is visible only for czech version for now
	const promoBannerDesktop = `${assetsPath}/${require('../../../../assets/images/salon-detail/gallery-banner-desktop-cs.jpg')}`
	let promoBannerLink: string | undefined

	const gridImages: React.ReactNode[] = []

	if (images.length === 0) {
		gridImages.push(
			<div key={'placeholder-image'}>
				<SC.GalleryImage $clickable src={placeholder} alt={messages.salon} />
			</div>
		)
	} else {
		const sliceIndex = MAX_PREVIEW_IMAGES_COUNT - PROMO_BANNERS_COUNT
		const containerWidth = MAX_WIDTHS.PAGE_DEFAULT - 2 * CONTAINER_X_PADDING

		images.slice(0, sliceIndex).forEach((image, index) => {
			gridImages.push(
				// NOTE: the following rules are aimed at improving accessibility, but here they are not needed since we have button to open the lightbox
				// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
				<div key={image.id} onClick={() => setPhotoIndex(index)}>
					<SC.GalleryImage
						$clickable
						loading={index === 0 ? 'eager' : 'lazy'}
						src={index === 0 ? image.resizedImages.large : image.resizedImages.medium}
						alt={messages.salon}
						srcSet={
							index === 0
								? `
							${image.resizedImages.medium} 1080w,
							${image.resizedImages.large} 1500w
            			`
								: `
							${image.resizedImages.small} 480w,
							${image.resizedImages.medium} 1080w,
            			`
						}
						sizes={
							index === 0
								? `(max-width: ${containerWidth}}px) 50vw, ${containerWidth / 2}px`
								: `(max-width: ${containerWidth}px) 25vw, ${containerWidth / 4}px`
						}
					/>
				</div>
			)
		})

		/** Display only when reservations are allowed */
		if (allowedReservations && gridImages.length > 2) {
			const promoBannerImage = (
				<SC.GalleryImage $clickable={!!promoBannerLink} loading='lazy' src={promoBannerDesktop} alt={messages['app-promo-banner-alt-text']} />
			)

			const promoBanner = (
				<div key={'promo-banner'}>
					{promoBannerLink ? (
						<SC.PromoBannerLink href={promoBannerLink} target={'blank'} rel={'noopener noreferrer'}>
							{promoBannerImage}
						</SC.PromoBannerLink>
					) : (
						promoBannerImage
					)}
				</div>
			)

			gridImages.splice(2, 0, promoBanner)
		}
	}

	const loadingImages = gridImages.map((_, index) => (
		<div key={index}>
			<SC.GalleryImage src={placeholderSmall} alt={''} loading={'eager'} />
		</div>
	))

	return (
		<>
			{/* grid */}
			<SC.ImageGridWrapper>
				{(() => {
					if (isLoading) {
						return <SC.ImageGrid $numberOfImages={loadingImages.length}>{loadingImages}</SC.ImageGrid>
					}

					return (
						<>
							{/* loading images */}
							<SC.ImageGrid $numberOfImages={gridImages.length}>{gridImages}</SC.ImageGrid>

							{gridImages.length > 1 && (
								<SC.LightboxOpenButton
									buttonSize={ButtonModel.Sizes.xSmall}
									buttonStyle={ButtonModel.Styles.primary}
									onClick={() => setPhotoIndex(0)}
								>
									<ImageIcon />
									<span>{messages['Show all images']}</span>
								</SC.LightboxOpenButton>
							)}
						</>
					)
				})()}
			</SC.ImageGridWrapper>

			{modalOpen && (
				<SC.ModalWrapper>
					<Modal
						withFocusTrap={modalOpen}
						show={modalOpen}
						// NOTE: when color is different than black, it shows hydration error for some reason:
						// hook.js:608 Warning: Prop `color` did not match. Server: "#000000" Client: "#dc0069" Error Component Stack
						closeIconColor={'icon.primary'}
						onClose={() => setPhotoIndex(-1)}
						size={ModalModel.Sizes.s1200}
					>
						<SC.MainImageWrapper>
							<SC.ModalImage src={images?.[photoIndex]?.resizedImages?.large || ''} alt={messages.salon} />
						</SC.MainImageWrapper>
						<SC.ThumbnailButtonsContainer>
							{images.map((image, index) => {
								return (
									// NOTE: opening image onMouseOver implemented to match behavior on Notino website, onClick used to open image when pressing enter when button focused
									<SC.ThumbnailButton key={index} onMouseOver={() => setPhotoIndex(index)} onClick={() => setPhotoIndex(index)}>
										<SC.ModalImage src={image.resizedImages?.thumbnail || ''} alt={messages.salon} />
									</SC.ThumbnailButton>
								)
							})}
						</SC.ThumbnailButtonsContainer>
					</Modal>
				</SC.ModalWrapper>
			)}
		</>
	)
}

export default GalleryDesktop
