import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Redirect } from '@notino/react-toolkit/renderer/fragment/contexts/HttpContext'

// types
import { AvailableLanguagesType, ContextProps } from '../types/types'

// pats
import Paths from './paths'

// components
import AuthRoute from './AuthRoute'
import HomePageRoute from './HomePageRoute'
import SalonRoute from './SalonRoute'
import SalonsRoute from './SalonsRoute'
import SalonsCategoryRoute from './SalonsCategoryRoute'
// NOTE: Temporary hide reservations history section - https://goodrequest.atlassian.net/browse/NOT-10384 (look up this comment in the app to show the section)
/* import MyReservationsRoute from './MyReservationsRoute'
import MyReservationRoute from './MyReservationRoute' */
import AuthCompletePage from '../pages/AuthComplete/AuthCompletePage'

// utils
import { AVAILABLE_LANGUAGES } from '../appDefaults'

const AppRoutes: React.FC<React.PropsWithChildren<ContextProps>> = (props) => {
	const { locale } = useIntl() as { locale: AvailableLanguagesType }

	// if header included unsupported language, return null
	if (!AVAILABLE_LANGUAGES.includes(locale)) {
		return null
	}

	return (
		<Routes>
			{/* redirect */}
			<Route path='/' element={<Redirect to={Paths[locale]['/salons/homepage']} />} />

			{/* home page */}
			<Route path={Paths[locale]['/salons/homepage']} element={<HomePageRoute {...props} />} />

			{/* salons list */}
			<Route path={Paths[locale]['/salons']} element={<SalonsRoute {...props} />} />
			<Route path={Paths[locale]['/salons/services/:categorySlug']} element={<SalonsCategoryRoute {...props} />} />

			{/* salon detail */}
			<Route path={Paths[locale]['/salons/:salonSlug']} element={<SalonRoute {...props} />} />

			{/* my reservations */}
			{/* // NOTE: Temporary hide reservations history section - https://goodrequest.atlassian.net/browse/NOT-10384 (look up this comment in the app to show the section)  */}
			{/* <Route element={<AuthRoute />}>
				<Route path={Paths[locale]['/salons/my-reservations']} element={<MyReservationsRoute {...props} />} />
				<Route path={Paths[locale]['/salons/my-reservations/:reservationID']} element={<MyReservationRoute {...props} />} />
			</Route> */}

			{/* auth complete - handle redirects from Notino IDP */}
			<Route element={<AuthRoute />}>
				<Route path={Paths[locale]['/salons/auth-complete']} element={<AuthCompletePage {...props} />} />
			</Route>
		</Routes>
	)
}

export default AppRoutes
