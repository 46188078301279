import styled from 'styled-components'
import { theme } from '@notino/react-styleguide'

// styles
import { BREAKPOINTS } from '../../../../styles/constants'
import { NotinoLabelSmall400, NotinoTitleMedium, resetButtonStyles } from '../../../../styles/helpers'

export const ModalWrapper = styled.div`
	& div[role='dialog'] {
		position: relative;

		& > button {
			display: none;
		}

		& > div {
			max-height: 100vh;
		}

		@media (min-width: ${BREAKPOINTS.sm}) {
			& > div {
				max-height: 85vh;
			}
		}
	}
`

export const ModalContent = styled.div`
	padding: 20px;
	height: 100%;

	@media (min-width: ${BREAKPOINTS.sm}) {
		height: auto;
	}
`

export const Form = styled.form`
	display: flex;
	flex-direction: column;
	height: 100%;
	position: relative;

	@media (min-width: ${BREAKPOINTS.sm}) {
		height: auto;
	}
`

export const Header = styled.header`
	display: flex;
	justify-content: space-between;
	gap: 16px;
	margin-bottom: 20px;
`

export const HeaderTitle = styled.div`
	${NotinoTitleMedium};
`

export const CloseButton = styled.button`
	${resetButtonStyles};
`

export const Main = styled.main`
	display: flex;
	flex-direction: column;
	gap: 24px;
	margin-bottom: 40px;
	flex: 1;
`

export const PartialRatingsWrapper = styled.div`
	max-width: 350px;
`

export const Footer = styled.footer`
	display: flex;
	flex-direction: column;
	gap: 12px;
	position: sticky;
	bottom: 0;
	margin: auto -20px -20px;
	background: ${theme.color.background.primary};
	padding: 20px;
`

export const AgreementDescription = styled.p`
	color: ${theme.color.text.primary};
	${NotinoLabelSmall400};
	margin-bottom: 0;
`

export const AgreementLink = styled.a`
	color: ${theme.color.text.primary};
	text-decoration: underline;

	&:hover {
		text-decoration: none;
	}
`
