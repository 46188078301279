import styled from 'styled-components'
import { theme } from '@notino/react-styleguide'
import { NotinoBodyMedium, NotinoLabelRegular } from '../../styles/helpers'

export const ResourceCardWrapper = styled.div`
	display: flex;
	position: relative;
	align-items: center;
	gap: 10px;
	svg {
		color: ${theme.color.common.black};
	}
`
export const Title = styled.div`
	${NotinoLabelRegular};
	color: ${theme.color.text.primary};
`

export const Description = styled.div`
	${NotinoBodyMedium};
	color: ${theme.color.common.gray30};
`

export const Avatar = styled.div`
	height: 48px;
	width: 48px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	border-radius: 50%;
	flex-shrink: 0;
	background: ${theme.color.common.gray90};
`

export const AvatarImg = styled.img`
	width: 100%;
	height: 100%;
	border-radius: 50%;
	object-fit: cover;
`

export const ExtraContentWrapper = styled.div`
	margin-left: auto;
`
