import styled from 'styled-components'
import { IconRegularClock, theme } from '@notino/react-styleguide'

// styles
import { NotinoLabelRegular400, resetButtonStyles } from '../../../styles/helpers'
import { RESERVATION_BOOKING_MODAL_MOBILE_BREAKPOINT } from '../../../styles/constants'

export const Form = styled.form``

export const AgreementDescription = styled.span`
	color: ${theme.color.text.primary};
	${NotinoLabelRegular400};
`

export const AllowMarketingCheckboxWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 8px;
`

export const AgreementLink = styled.a`
	color: ${theme.color.text.primary};
	text-decoration: underline;

	&:hover {
		text-decoration: none;
	}
`

export const Divider = styled.hr<{ $spacing?: number }>`
	border: none;
	border-bottom: 1px solid ${theme.color.border.divider};
	margin: ${({ $spacing }) => ($spacing ? `${$spacing}px 0` : '0px')};
`

export const FormSectionTitle = styled.div`
	color: ${theme.color.text.primary};
	font-size: 1.25rem;
	line-height: 1.5rem;
	font-weight: 600;
	margin-bottom: 10px;
`

export const FormSectionWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
`

export const NameFormSection = styled.div`
	display: flex;
	gap: 10px;
	flex-direction: column;

	@media (min-width: ${RESERVATION_BOOKING_MODAL_MOBILE_BREAKPOINT}) {
		flex-direction: row;

		& > * {
			flex: 1 1 50%;
		}
	}
`

export const DateWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
	color: ${theme.color.text.secondary};
`

export const Date = styled.span`
	${NotinoLabelRegular400};
	color: ${theme.color.text.primary};
`

export const Time = styled.span`
	${NotinoLabelRegular400};
	color: ${theme.color.text.secondary};
`

export const CardFlexWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4px;
	margin: -20px -20px 16px -20px;

	@media (min-width: ${RESERVATION_BOOKING_MODAL_MOBILE_BREAKPOINT}) {
		margin: -24px -24px 16px -24px;
	}
`

export const CardWrapperEmployee = styled.div`
	background: ${theme.color.background.tertiary};
	padding: 16px 20px;
`

export const CardWrapperDate = styled.div`
	background: ${theme.color.background.tertiary};
	padding: 16px 20px;
`

export const DurationWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 4px;
`

export const ClockIconWrapper = styled(IconRegularClock)`
	width: 16px;
	height: 16px;
`

export const AuthLink = styled.button`
	${resetButtonStyles};
	white-space: nowrap;
	color: ${theme.color.text.highlight};
	text-decoration: underline;
	gap: 8px;
`
