import React, { useEffect } from 'react'
import { Head } from '@notino/react-toolkit/exports/Head'
import { useIntl } from 'react-intl'

// utils
import { shopsConfig } from '../../../../appDefaults'
import { PAGE_LINKS } from '../../../../utils/helper'
import { ENVIRONMENTS } from '../../../../utils/enums'

// hooks
import useMessages from '../../../../hooks/useMessages'

// types
import { HeroSectionData } from '../../../../types/types'

type Props = {
	heroSectionData: HeroSectionData
}

const HomepageHead = (props: Props) => {
	const { heroSectionData } = props
	const { desktopBg: heroDesktopBg, mobileBg: mobileDesktopBg } = heroSectionData

	const { locale } = useIntl()
	const { messages } = useMessages()

	const defaultShop = shopsConfig[0]

	const currentShop = shopsConfig.find((shop) => shop.locale === locale)

	const currentShopSalonsPath = currentShop ? PAGE_LINKS['/salons/homepage'](currentShop.locale) : null

	const pageTitle = messages['SEO - Homepage - meta title']
	const description = messages['SEO - Homepage - meta description']
	const keywords = messages['SEO - Homepage - meta keywords']

	// use effect needed to see title in local development
	useEffect(() => {
		if (process.env.NODE_ENV === ENVIRONMENTS.development) {
			document.title = pageTitle
		}
	}, [pageTitle])

	// canonical href
	const canonicalHref = currentShopSalonsPath

	// TODO: dorobit preload

	// alternate hrefs
	const alternateLinks = shopsConfig
		.map((shop) => {
			return {
				href: `${shop?.url}${PAGE_LINKS['/salons/homepage'](shop.locale)}`,
				lang: shop.lang
			}
		})
		.filter(Boolean)

	// x-default href
	const xDefaultHref = `${defaultShop.url}${PAGE_LINKS['/salons/homepage'](defaultShop.locale)}`

	// NOTE: defining children as array and using .flat() on it otherwise <Head> doesn't work properly
	// SIDENOTE: each item has to have unique 'key' because <Head/> iterates over the array
	const children = [
		// charset
		<meta key='charset' charSet='utf-8' />,

		// title
		<title key='title'>{pageTitle}</title>,

		// description
		<meta key='description' name='description' content={description} />,

		// keywords
		<meta key='keywords' name='keywords' content={keywords} />,

		// canonical
		canonicalHref && <link key='canonical' rel='canonical' href={canonicalHref} />,
		// alternates
		alternateLinks.map((link, id) => {
			return <link key={`alternate-${id}`} rel='alternate' href={link?.href} hrefLang={link?.lang} />
		}),
		// x-default
		xDefaultHref && <link key='x-default' rel='alternate' href={xDefaultHref} hrefLang='x-default' />,

		<link
			key={'hero-bg-preload'}
			rel='preload'
			as='image'
			href={heroDesktopBg}
			imageSrcSet={`${mobileDesktopBg} 960w, ${heroDesktopBg} 2650w`}
			imageSizes='(min-width: 960px) 2650px, 960px'
		/>
	]
		.flat()
		.filter(Boolean)

	return <Head>{children}</Head>
}

export default HomepageHead
