import styled from 'styled-components'

// styles
import { CONTAINER_X_PADDING, MAX_WIDTHS, PAGE_BOTTOM_PADDING, PAGE_TOP_PADDING } from '../../styles/constants'

// eslint-disable-next-line import/prefer-default-export
export const DefaultContainer = styled.div`
	width: 100%;
	max-width: ${MAX_WIDTHS.PAGE_DEFAULT}px;
	padding: ${PAGE_TOP_PADDING}px ${CONTAINER_X_PADDING}px ${PAGE_BOTTOM_PADDING}px ${CONTAINER_X_PADDING}px;
	margin: 0 auto;
`
