import styled, { css } from 'styled-components'
import { theme } from '@notino/react-styleguide'
import CheckboxAtom from '../../atoms/Checkbox/Checkbox'

// utils
import { NotinoLabelRegular400, NotinoLabelRegular500 } from '../../styles/helpers'

export const CheckboxGroupHeader = styled.div`
	height: 48px;
	display: flex;
	align-items: center;
	${NotinoLabelRegular500};
`

export const Checkbox = styled(CheckboxAtom)`
	min-height: 36px;
`

export const CheckboxListWrapper = styled.fieldset`
	display: flex;
	flex-direction: column;
	gap: 12px;
	border: none;
`

export const CheckboxLabel = styled.span`
	display: inline-flex;
	width: 100%;
	align-items: center;
`

export const CheckboxLabelTitle = styled.span<{ $checked?: boolean }>`
	${({ $checked }) =>
		$checked
			? css`
					color: ${theme.color.text.primary};
					${NotinoLabelRegular500};
				`
			: css`
					color: ${theme.color.text.secondary};
					${NotinoLabelRegular400}
				`};
`

export const CheckboxLabelDescription = styled.span`
	margin-left: auto;
	text-align: right;
	color: ${theme.color.text.tertiary};
	${NotinoLabelRegular400};
`

export const CheckboxIcon = styled.span`
	display: inline-block;
	margin-right: 8px;
`
