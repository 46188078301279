import React from 'react'
import { ButtonModel, IconRegularClock, IconRegularClose, Modal, ModalModel } from '@notino/react-styleguide'
import * as SC from './SalonServiceDetailStyles'
import RenderDuration from '../RenderDuration/RenderDuration'
import RenderPrice from '../RenderPrice/RenderPrice'
import useMessages from '../../hooks/useMessages'
import { ServiceCardCallbackData, ServiceDetailData } from '../../types/types'

type Props = {
	onClose: () => void
	phoneNumber: string
	serviceData: ServiceDetailData
	isReservationBookingModal?: boolean
	onServiceCardButtonClick: (data: ServiceCardCallbackData) => void
}

const SalonServiceDetail = (props: Props) => {
	const { messages } = useMessages()

	const { onClose, serviceData, phoneNumber, onServiceCardButtonClick, isReservationBookingModal = false } = props

	const { durationFrom, durationTo, priceFrom, priceTo } = serviceData.service.rangePriceAndDurationData
	const { availableForReservation, id: serviceID, serviceCategoryParameter } = serviceData.service
	const description = serviceData.service.description || serviceData.category.description

	const content = (
		<SC.ScrollContainer $isReservationBookingModal={isReservationBookingModal}>
			<SC.ContentWrapper>
				<SC.CloseButton>
					<IconRegularClose color={'icon.primary'} width={'1.5rem'} height={'1.5rem'} onClick={onClose} />
				</SC.CloseButton>

				<SC.ServiceCategoryTitle>{serviceData.category.name}</SC.ServiceCategoryTitle>
				<SC.ServicePriceAndDurationWrapper>
					<SC.PriceAndDurationContainer>
						{durationFrom !== undefined && !durationFrom !== null && (
							<SC.ServiceDuration>
								<SC.DurationIcon>
									<IconRegularClock color={'icon.primary'} />
								</SC.DurationIcon>
								<SC.DurationText>
									<RenderDuration from={durationFrom} to={durationTo} />
								</SC.DurationText>
							</SC.ServiceDuration>
						)}
						{priceFrom !== undefined && !priceFrom !== null && (
							<SC.ServicePrice>
								<RenderPrice from={priceFrom} to={priceTo} />
							</SC.ServicePrice>
						)}
					</SC.PriceAndDurationContainer>
				</SC.ServicePriceAndDurationWrapper>
				{description && <SC.ServiceDescription>{description}</SC.ServiceDescription>}

				<SC.ButtonsWrapper>
					{availableForReservation ? (
						<SC.BookOnlineButton
							buttonStyle={ButtonModel.Styles.primary}
							fullWidth={true}
							onClick={(e) => {
								e.stopPropagation()
								onServiceCardButtonClick({
									industryID: serviceData.industryID,
									serviceID,
									serviceCategoryParameterID: serviceCategoryParameter?.id ?? null,
									gaSpecificData: { secondLevelCategoryID: serviceData.secondLevelCategoryID, serviceCategoryID: serviceData.category.id }
								})
								onClose()
							}}
						>
							{messages['Book online']}
						</SC.BookOnlineButton>
					) : (
						<SC.BookOnlineButton
							buttonStyle={ButtonModel.Styles.primary}
							fullWidth={true}
							onClick={(e) => {
								e.stopPropagation()
								// Create a URL for the phone call
								const phoneUrl = `tel:${phoneNumber}`
								// Attempt to make the call
								try {
									window.location.href = phoneUrl
								} catch (error) {
									// eslint-disable-next-line no-console
									console.error('Error making phone call:', error)
								}
							}}
						>
							{messages.Call}
						</SC.BookOnlineButton>
					)}
				</SC.ButtonsWrapper>
			</SC.ContentWrapper>
		</SC.ScrollContainer>
	)

	if (isReservationBookingModal) {
		return (
			<SC.ReservationModalScreenWrapper>
				<SC.ReservationModalScreen>{content}</SC.ReservationModalScreen>´
			</SC.ReservationModalScreenWrapper>
		)
	}

	return (
		<SC.ModalWrapper $isReservationBookingModal={isReservationBookingModal}>
			<Modal fullscreenOnMobile breakpoint={ModalModel.Breakpoints.md} show onClose={onClose} noBorders>
				{content}
			</Modal>
		</SC.ModalWrapper>
	)
}

export default SalonServiceDetail
