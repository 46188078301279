import React, { useContext, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { IconRegularPin } from '@notino/react-styleguide'

// styles
import * as SC from './SalonsBookingFormStyles'
import { HomePageContainer } from '../../HomePageStyles'
import { SALONS_PAGE_MOBILE_BREAKPOINT_INT } from '../../../../styles/constants'

// hooks
import useMessages from '../../../../hooks/useMessages'
import useIsMobile from '../../../../hooks/useIsMobile'

// types
import { BookingFormType, CategoriesResponse, Category, ConfigResponse, SalonsCategoryPageServerQueryType } from '../../../../types/types'
import { BaseSelectOption, SelectProps } from '../../../../atoms/Select/types'

// utils
import { DEFAULT_DATE_FORMAT, FORM, RESERVATIONS_TIME_SLOTS, SEARCH_FIELD_OPTION_TYPE, SESSION_STORAGE_KEYS } from '../../../../utils/enums'
import { BOOKING_FORM_Z_INDEXES, PAGE_LINKS } from '../../../../utils/helper'
import { getBookingFormSubmitEvent, getClickToSearchBookingEvent } from '../../../../utils/dataLayerEvents'
import { pushToDataLayer } from '../../../../utils/dataLayer'
import { GET_MY_LOCATION_OPTION } from '../../../../components/SalonsAndCitiesSearch/SalonsAndCitiesSearch'
import { MyLocationContext } from '../../../../utils/myLocationProvider'

// components
import HookFormField from '../../../../formFields/HookFormField'
import SalonsAndCitiesSearchFormField from '../../../../formFields/SalonsAndCitiesSearchFormField/SalonsAndCitiesSearchFormField'
import DateTimeSlotPickerBookingFormField from '../../../../formFields/DateTimeSlotPickerBookingFormField/DateTimeSlotPickerBookingFormField'
import SelectFormField from '../../../../formFields/SelectFormField/SelectFormField'

// assets
import CategoryIcon from '../../../../assets/icons/CategoryIcon'
import ScissorsIcon from '../../../../assets/icons/ScissorsIcon'

type SalonsBookingFormProps = {
	categoriesData: CategoriesResponse
	configData: ConfigResponse
}

type IndustryValue = string
type IndustryOption = BaseSelectOption<{ image?: string }>

const IndustryOptionRender: SelectProps<IndustryValue, IndustryOption>['optionRender'] = (option) => {
	return (
		<SC.IndustryOptionWrapper>
			<SC.IndustryOptionImage $image={option.extra?.image} />
			<SC.IndustryOptionText>{option.label}</SC.IndustryOptionText>
		</SC.IndustryOptionWrapper>
	)
}

const getIndustryAndCategoriesOptions = (categories: Category[], selectedIndustryID: string | undefined) => {
	const industryOptions: IndustryOption[] = []
	const categoryOptions: BaseSelectOption[] = []

	categories.forEach((industry) => {
		const industryOption: IndustryOption = {
			key: industry.id,
			value: industry.id,
			label: industry.name || industry.id,
			extra: {
				image: industry.image?.resizedImages.thumbnail
			}
		}

		industryOptions.push(industryOption)

		if (industry.id === selectedIndustryID) {
			industry.children.forEach((category) => {
				categoryOptions.push({
					key: category.id,
					value: category.id,
					label: category.name || category.id
				})
			})
		}
	})

	return { industryOptions, categoryOptions }
}

// component
const SalonsBookingForm = (props: SalonsBookingFormProps) => {
	const { categoriesData, configData } = props

	const { categories } = categoriesData
	const { defaultCategoryID: defaultIndustryID, maxAvResTimeSlotDateRange } = configData

	const { messages } = useMessages()
	const { locale } = useIntl()
	const isMobile = useIsMobile(SALONS_PAGE_MOBILE_BREAKPOINT_INT)

	const { myLocation, isLoadingMyLocation } = useContext(MyLocationContext)

	const [isLoadingSearchValue, setIsLoadingSearchValue] = useState(true)

	const defaultCity = configData.rolloutCountries.find((country) => country.languageCode === locale)?.capitalCity

	const { control, handleSubmit, watch, setValue } = useForm<BookingFormType>({
		defaultValues: {
			city: {
				key: defaultCity?.googlePlaceID ?? '',
				value: defaultCity?.googlePlaceID ?? '',
				label: defaultCity?.name ?? '',
				extra: {
					type: SEARCH_FIELD_OPTION_TYPE.CITY,
					latitude: defaultCity?.location?.latitude,
					longitude: defaultCity?.location?.longitude
				}
			},
			availableReservations: {
				timeSlot: RESERVATIONS_TIME_SLOTS.ANY,
				dateFrom: undefined,
				dateTo: undefined
			},
			categoryID: '',
			industryID: defaultIndustryID || ''
		}
	})

	useEffect(() => {
		;(async () => {
			if (isLoadingMyLocation) {
				return
			}

			if (myLocation) {
				const defaultCityValue = GET_MY_LOCATION_OPTION({
					latitude: myLocation.latMy,
					longitude: myLocation.lonMy
				})
				setValue('city', defaultCityValue)
			}
			setIsLoadingSearchValue(false)
		})()
	}, [setValue, myLocation, isLoadingMyLocation])

	const selectedIndustryID = watch('industryID')

	const { industryOptions, categoryOptions } = getIndustryAndCategoriesOptions(categories, selectedIndustryID)

	const getLocationParams = (city: BookingFormType['city']) => {
		const MY_LOCATION_OPTION = GET_MY_LOCATION_OPTION()
		const isUsingMyPosition = city?.value === MY_LOCATION_OPTION.value

		let lat: number | undefined
		let lon: number | undefined
		let latMy: number | undefined
		let lonMy: number | undefined
		let googlePlaceID: string | undefined

		if (isUsingMyPosition || myLocation) {
			latMy = myLocation?.latMy || city?.extra?.latitude
			lonMy = myLocation?.lonMy || city?.extra?.longitude
		}

		if (!isUsingMyPosition) {
			lat = city?.extra?.latitude
			lon = city?.extra?.longitude
			googlePlaceID = city?.value
		}

		return { lat, lon, latMy, lonMy, googlePlaceID }
	}

	const isFormStartInteractionEventSent = useRef(false)
	const formInteractionStartEventTimestamp = useRef<number | null>(null)
	const sendFormStartInteractionEvent = () => {
		// NOTE: We are attaching this event on focus on each input.
		// Alternative approach would be checking form state/form dirty fields,
		// but that would be more complicated because some form fields (city input)
		// might be re-initialized on first mount (when user has geo location enabled),
		// which causes the form to be dirty even if the user didn't interact with it.
		// Using onFocus is much safer - we are sure that the user interacted with the form.
		if (isFormStartInteractionEventSent.current) return
		pushToDataLayer(getClickToSearchBookingEvent({ isMobile }))

		isFormStartInteractionEventSent.current = true
		formInteractionStartEventTimestamp.current = Date.now()
	}

	const handleSubmitFilter = async (formValues: BookingFormType) => {
		// google analytics
		const formInteractionDuration = Date.now() - (formInteractionStartEventTimestamp.current ?? Date.now())
		const event = getBookingFormSubmitEvent({
			timing: formInteractionDuration,
			searchTerm: formValues.city?.label ?? '',
			dateFrom: formValues.availableReservations.dateFrom,
			dateTo: formValues.availableReservations.dateTo,
			timeSlot: formValues.availableReservations.timeSlot,
			category: formValues.industryID,
			subcategory: formValues.categoryID
		})
		pushToDataLayer(event)

		let queryParams: Partial<SalonsCategoryPageServerQueryType> = { hasAvailableReservationSystem: true }

		if (formValues.city) {
			const locationParams = getLocationParams(formValues.city)

			queryParams = {
				...queryParams,
				...locationParams
			}
		}

		if (formValues.categoryID) {
			queryParams = {
				...queryParams,
				categoryIDs: [formValues.categoryID]
			}
		}

		if (formValues.availableReservations.dateFrom) {
			queryParams = {
				...queryParams,
				avResTimeSlotDateFrom: formValues.availableReservations.dateFrom.format(DEFAULT_DATE_FORMAT)
			}

			if (formValues.availableReservations.dateTo) {
				queryParams = {
					...queryParams,
					avResTimeSlotDateTo: formValues.availableReservations.dateTo.format(DEFAULT_DATE_FORMAT)
				}
			}

			if (formValues.availableReservations.timeSlot !== RESERVATIONS_TIME_SLOTS.ANY) {
				queryParams = {
					...queryParams,
					avResTimeSlotDayPart: formValues.availableReservations.timeSlot
				}
			}
		}

		// we need to set IS_SALONS_SEARCH attribute to session storage, so we can send a correct Google Analytics event when the new page is rendered
		// check getViewItemListEvent function for more info
		sessionStorage.setItem(SESSION_STORAGE_KEYS.IS_SALONS_SEARCH, 'true')

		const slugName = categories.find((category) => category.id === formValues.industryID)?.nameSlug ?? ''
		window.location.href = PAGE_LINKS['/salons/services/:categorySlug'](locale, slugName, queryParams)
	}

	const onSelectIndustry: SelectProps<IndustryValue, IndustryOption>['onSelect'] = (newValue) => {
		if (newValue !== selectedIndustryID) {
			setValue('categoryID', '')
		}
	}

	return (
		<HomePageContainer>
			<SC.SalonsBookingForm onSubmit={handleSubmit(handleSubmitFilter)} id={FORM.BOOKING_FORM}>
				<SC.FormFields>
					<HookFormField
						control={control}
						name={'city'}
						component={SalonsAndCitiesSearchFormField}
						selectStyle={'no-borders'}
						placeholder={`${messages['Search for a city']}...`}
						allowClear={false}
						suffixIcon={null}
						prefixIcon={<IconRegularPin />}
						limits={{ limitSalons: 0, limitCategories: 0, limitCities: 8 }}
						showMyLocationOption
						emptyContent={{
							emptyTitle: messages['Search city'],
							emptyLabel: messages['Enter the name of the city you are looking for.']
						}}
						onFocus={sendFormStartInteractionEvent}
						isLoadingValue={isLoadingSearchValue}
						dropdownStyle={{ zIndex: BOOKING_FORM_Z_INDEXES.DROPDOWN }}
					/>
					<HookFormField
						control={control}
						name={'availableReservations'}
						component={DateTimeSlotPickerBookingFormField}
						defaultValue={'now'}
						maxRange={maxAvResTimeSlotDateRange}
						onFocus={sendFormStartInteractionEvent}
					/>
					<HookFormField
						control={control}
						name={'industryID'}
						component={SelectFormField<IndustryValue, IndustryOption>}
						selectStyle={'no-borders'}
						prefixIcon={<CategoryIcon />}
						placeholder={`${messages['Select industry']}...`}
						allowClear={false}
						optionRender={IndustryOptionRender}
						options={industryOptions}
						listHeight={450}
						onSelect={onSelectIndustry}
						onFocus={sendFormStartInteractionEvent}
						dropdownStyle={{ zIndex: BOOKING_FORM_Z_INDEXES.DROPDOWN }}
					/>
					<HookFormField
						control={control}
						name={'categoryID'}
						component={SelectFormField}
						selectStyle={'no-borders'}
						prefixIcon={<ScissorsIcon />}
						placeholder={`${messages['Select category']}...`}
						allowClear
						options={categoryOptions}
						listHeight={450}
						onFocus={sendFormStartInteractionEvent}
						dropdownStyle={{ zIndex: BOOKING_FORM_Z_INDEXES.DROPDOWN }}
					/>
				</SC.FormFields>
				<SC.SearchButton type={'submit'} disabled={!selectedIndustryID}>
					{messages.Search}
				</SC.SearchButton>
			</SC.SalonsBookingForm>
		</HomePageContainer>
	)
}

export default SalonsBookingForm
