import styled from 'styled-components'
import { BREAKPOINTS, CONTAINER_X_PADDING } from '../../../../styles/constants'

const DESKTOP_BANNER_WIDTH = 1320 // in px
const DESKTOP_BANNER_HEIGHT = 475 // in px
const MOBILE_BANNER_WIDTH = 728 // in px
const MOBILE_BANNER_HEIGHT = 332 // in px

export const Link = styled.a`
	text-decoration: none;
`

export const Section = styled.section<{ $backgroundImage: string }>`
	width: 100%;
	height: auto;
	background-size: auto 100%;
	background-position: center;

	@media (min-width: ${DESKTOP_BANNER_WIDTH}px) {
		max-height: ${DESKTOP_BANNER_HEIGHT}px;
		background-image: ${({ $backgroundImage }) => `url("${$backgroundImage}")`};
	}
`

export const Picture = styled.picture`
	aspect-ratio: ${MOBILE_BANNER_WIDTH} / ${MOBILE_BANNER_HEIGHT};
	display: block;
	margin: 0 -${CONTAINER_X_PADDING}px;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	@media (min-width: ${BREAKPOINTS.md}) {
		aspect-ratio: ${DESKTOP_BANNER_WIDTH} / ${DESKTOP_BANNER_HEIGHT};
	}

	@media (min-width: ${DESKTOP_BANNER_WIDTH}px) {
		margin: 0;
	}
`
