import React, { PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react'
import debounce from 'lodash.debounce'
import { useIntl } from 'react-intl'
import { IconRegularPin, IconRegularSearch } from '@notino/react-styleguide'

// hooks
import useMessages from '../../hooks/useMessages'
import useIsMobile from '../../hooks/useIsMobile'

// types
import { GetSalonFiltersQueryParams, SalonPageRedirectQueryData, SalonsAndCitiesSearchOption, SalonsAndCitiesSearchValue } from '../../types/types'
import { SelectProps } from '../../atoms/Select/types'

// styles
import * as SC from './SalonsAndCitiesSearchStyles'
import { BREAKPOINTS_INTS } from '../../styles/constants'

// utils
import { PAGE_LINKS } from '../../utils/helper'
import { AppContext } from '../../utils/appProvider'
import { SALONS_SEARCH_DEBOUNCE_TIME_MS, SEARCH_FIELD_OPTION_TYPE, SELECT_OPTION_CLASS_NAME } from '../../utils/enums'
import { MyLocationContext } from '../../utils/myLocationProvider'
import { getIntl } from '../../utils/intl'

// assets
import MapIcon from '../../assets/icons/MapIcon'

// components
import Select from '../../atoms/Select/Select'

type ValueTypeForSelectComponent = string | null

type SalonsAndCitiesSearchProps = PropsWithChildren<
	{
		value: SalonsAndCitiesSearchValue
		onChange?: (value: SalonsAndCitiesSearchValue) => void
		afterChange?: (value: SalonsAndCitiesSearchValue) => void
		onDropdownVisibleChange?: (open: boolean) => void
		prefixIcon?: React.ReactNode
		suffixIcon?: React.ReactNode
		emptyContent?: {
			emptyIcon?: React.ReactNode
			emptyTitle: React.ReactNode
			emptyLabel?: React.ReactNode
		}
		notFoundContent?: {
			notFoundIcon?: React.ReactNode
			notFoundTitle?: React.ReactNode
			notFoundLabel?: React.ReactNode
		}
		limits?: {
			limitSalons?: GetSalonFiltersQueryParams['limitSalons']
			limitCities?: GetSalonFiltersQueryParams['limitCities']
			limitCategories?: GetSalonFiltersQueryParams['limitCategories']
		}
		showMyLocationOption?: boolean
		redirectQueryData?: SalonPageRedirectQueryData
		isLoadingValue?: boolean
		onFocus?: () => void
		dropdownStyle?: React.CSSProperties
	} & Pick<SelectProps, 'allowClear' | 'placeholder' | 'selectStyle'>
>

const SEARCH_FIELD_MY_LOCATION_VALUE = 'SEARCH_FIELD_MY_LOCATION_VALUE'

type MyLocationOptionArgs = { latitude?: number; longitude?: number }

export const GET_MY_LOCATION_OPTION = (args?: MyLocationOptionArgs): SalonsAndCitiesSearchOption => {
	const intl = getIntl()
	const { latitude, longitude } = args || {}

	return {
		key: SEARCH_FIELD_MY_LOCATION_VALUE,
		value: SEARCH_FIELD_MY_LOCATION_VALUE,
		label: intl.formatMessage({ id: 'My location', defaultMessage: 'My location' }),
		className: SELECT_OPTION_CLASS_NAME.BOTTOM_DIVIDER,
		extra: {
			type: SEARCH_FIELD_OPTION_TYPE.CITY,
			latitude,
			longitude
		}
	}
}

const optionRender = (optionProps: SalonsAndCitiesSearchOption, locale: string) => {
	const isMyLocationOption = optionProps.value === SEARCH_FIELD_MY_LOCATION_VALUE

	return (
		<>
			{optionProps.extra?.type === SEARCH_FIELD_OPTION_TYPE.CITY && (
				<SC.OptionContent $myLocationOption={isMyLocationOption}>
					<SC.OptionIconWrapper>
						{isMyLocationOption ? <IconRegularPin width='14' height='14' color='icon.highlight' /> : <MapIcon />}
					</SC.OptionIconWrapper>
					<span>{optionProps.label}</span>
				</SC.OptionContent>
			)}
			{optionProps.extra?.type === SEARCH_FIELD_OPTION_TYPE.SALON && (
				<SC.OptionLink href={PAGE_LINKS['/salons/:salonSlug'](locale, optionProps.extra.seoSlugName ?? '')}>
					<div>
						<SC.OptionIconWrapper>
							<IconRegularPin width='14' height='14' color={'icon.secondary'} />
						</SC.OptionIconWrapper>
						<SC.SalonName>{optionProps.label}</SC.SalonName>
					</div>
					<SC.OptionAddress>{optionProps.extra?.address}</SC.OptionAddress>
				</SC.OptionLink>
			)}
		</>
	)
}

// component
const SalonsAndCitiesSearch = (props: SalonsAndCitiesSearchProps) => {
	const { locale } = useIntl()
	const { messages } = useMessages()

	const {
		onChange,
		value,
		limits,
		allowClear = true,
		placeholder = `${messages['Search for a city or a salon']}...`,
		prefixIcon,
		suffixIcon = <IconRegularSearch />,
		emptyContent,
		notFoundContent,
		showMyLocationOption = false,
		onFocus,
		redirectQueryData,
		selectStyle,
		isLoadingValue,
		dropdownStyle,
		onDropdownVisibleChange
	} = props

	const { limitCategories, limitSalons, limitCities } = limits || {}

	const [loadedOptions, setLoadedOptions] = useState<SalonsAndCitiesSearchOption[]>([])
	const [isLoading, setIsLoading] = useState(false)

	const { myLocation, askForGeolocationPermissions } = useContext(MyLocationContext)

	const isMobile = useIsMobile(BREAKPOINTS_INTS.sm)

	const { apiBrowser } = useContext(AppContext)

	const MY_LOCATION_OPTION = GET_MY_LOCATION_OPTION()

	const resetLoadedOptions = () => {
		setLoadedOptions([])
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const loadOptionsDebounced = useCallback(
		debounce((inputValue: string) => {
			if (!inputValue) {
				return
			}

			setIsLoading(true)

			apiBrowser.b2c
				.getSalonsFilterData({ search: inputValue, limitSalons, limitCategories, limitCities, latMy: myLocation?.latMy, lonMy: myLocation?.lonMy })
				.then((salonsData) => {
					let newOptions: SalonsAndCitiesSearchOption[] = []

					const citiesOptions: SalonsAndCitiesSearchOption[] = salonsData.cities.map((city) => ({
						key: city.placeID,
						value: city.placeID,
						label: city.name,
						extra: {
							type: SEARCH_FIELD_OPTION_TYPE.CITY,
							longitude: city.longitude,
							latitude: city.latitude
						}
					}))
					const salonsOptions: SalonsAndCitiesSearchOption[] = salonsData.salons.map((salon) => ({
						key: salon.id,
						value: salon.id,
						label: salon.name ?? salon.id,
						extra: {
							type: SEARCH_FIELD_OPTION_TYPE.SALON,
							address: `${salon.address?.street}, ${salon.address?.city}`,
							seoSlugName: salon.seoSlugName,
							image: salon.coverImage?.resizedImages.thumbnail,
							distance: salon.distance
						}
					}))

					newOptions = [...citiesOptions, ...salonsOptions]

					setLoadedOptions(newOptions)
				})
				.catch(() => setLoadedOptions([]))
				.finally(() => {
					setIsLoading(false)
				})
		}, SALONS_SEARCH_DEBOUNCE_TIME_MS),
		[myLocation?.latMy, myLocation?.lonMy, limitCategories, limitCategories, limitSalons]
	)

	useEffect(() => {
		return () => {
			loadOptionsDebounced.cancel()
			setIsLoading(false)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleChange: SelectProps<ValueTypeForSelectComponent, SalonsAndCitiesSearchOption>['onChange'] = async (_, option) => {
		let newOption = Array.isArray(option) ? option[0] : option

		if (newOption) {
			// user choose "my location" value in select
			if (newOption.value === MY_LOCATION_OPTION.value) {
				if (!myLocation) {
					const position = await askForGeolocationPermissions()

					if (!position) {
						return
					}

					newOption = GET_MY_LOCATION_OPTION({ latitude: position.latMy, longitude: position.lonMy })
				}
			}

			if (onChange) {
				onChange(newOption)
			}
		} else {
			resetLoadedOptions()
			if (onChange) {
				onChange(null)
			}
		}

		setIsLoading(false)

		if (newOption?.extra?.type === SEARCH_FIELD_OPTION_TYPE.SALON) {
			// redirect to salon detail page
			const salonDetailPageHref = PAGE_LINKS['/salons/:salonSlug'](locale, newOption.extra.seoSlugName ?? '', redirectQueryData)
			window.location.href = salonDetailPageHref
		}
	}

	// reset loaded options to default
	// because mobile version has slide up animation, we need to have different approaches for both scenarios
	const onDropdownVisibleChangeWrap: SelectProps<ValueTypeForSelectComponent, SalonsAndCitiesSearchOption>['onDropdownVisibleChange'] = (open) => {
		if (onDropdownVisibleChange) {
			onDropdownVisibleChange(open)
		}
		if (open) {
			if (!isMobile) {
				resetLoadedOptions()
			}
		} else if (isMobile) {
			resetLoadedOptions()
		}
	}

	const getDefaultOptions = () => {
		const defaultOptions: SalonsAndCitiesSearchOption[] = []

		// "MY_LOCATION_OPTION" is a special option and is always first when is allowed to be seen
		if (showMyLocationOption) {
			defaultOptions.push(MY_LOCATION_OPTION)
		}

		// In case user has selected value, it should be on top of other normal options
		if (value && value.value !== MY_LOCATION_OPTION.value) {
			defaultOptions.push({ ...value })
		}
		return defaultOptions
	}

	const options = loadedOptions.reduce<SalonsAndCitiesSearchOption[]>(
		(finalOptions, option) => {
			// option, that belongs to selected value must be filtered, because this already exist in default options
			if (option.value !== value?.value) {
				return [...finalOptions, option]
			}
			return finalOptions
		},
		[...getDefaultOptions()]
	)

	return (
		<Select<ValueTypeForSelectComponent, SalonsAndCitiesSearchOption>
			selectStyle={selectStyle}
			value={isLoadingValue ? null : value?.value} // null -> show placeholder while loading
			onChange={handleChange}
			allowClear={allowClear}
			showSearch
			filterOption={false}
			placeholder={isLoadingValue ? messages.Loading : placeholder}
			onSearch={(searchedValue) => {
				setIsLoading(!!searchedValue)
				loadOptionsDebounced(searchedValue)
			}}
			options={options}
			onDropdownVisibleChange={onDropdownVisibleChangeWrap}
			suffixIcon={suffixIcon}
			optionRender={(option) => optionRender(option, locale)}
			prefixIcon={prefixIcon}
			emptyContent={emptyContent}
			notFoundContent={notFoundContent}
			isRefetching={isLoading}
			onClearSearch={resetLoadedOptions}
			disabled={isLoadingValue}
			onFocus={onFocus}
			dropdownStyle={dropdownStyle}
		/>
	)
}

export default SalonsAndCitiesSearch
