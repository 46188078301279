import { styled, theme } from '@notino/react-styleguide'

// styles
import { BREAKPOINTS } from '../../../../styles/constants'
import { NotinoLabelSmall400 } from '../../../../styles/helpers'

export const RatingsList = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`

export const RatingWrapper = styled.div`
	display: flex;
	gap: 10px;
	align-items: center;
`

export const ProgressLineWithCount = styled.div`
	display: flex;
	align-items: center;
	flex: 1;
`

export const Count = styled.span`
	${NotinoLabelSmall400};
	display: inline-block;
	margin-left: 10px;
	flex-shrink: 0;
`

export const SalonReviewsContainer = styled.div`
	width: 50%;

	@media (max-width: ${BREAKPOINTS.md}) {
		width: 100%;
	}
`

export const ProgressBarContainer = styled.div`
	width: 100%;
	height: 8px;
	background-color: ${theme.color.background.tertiary};
`

export const Progress = styled.div<{ $progress: number }>`
	width: ${({ $progress }) => $progress}%;
	height: 100%;
	background-color: ${theme.color.background.inverse};
`
