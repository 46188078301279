import { css, styled, theme } from '@notino/react-styleguide'
import { Rating } from '@smastrom/react-rating'
import '@smastrom/react-rating/style.css'

// styles
import { NotinoLabelSmall400, truncate } from '../../styles/helpers'

export const ProgressBarContainer = styled.div`
	min-height: 1rem;
	display: flex;
	align-items: center;
`

export const ProgressBar = styled.div<{ $width: string; $height: string }>`
	display: flex;
	width: ${({ $width }) => $width};
	height: ${({ $height }) => $height};
`

export const Segment = styled.div<{
	$width: string
	$height: string
	$backgroundColor: string
	$marginRight: string
}>`
	width: ${({ $width }) => $width};
	height: ${({ $height }) => $height};
	background-color: ${({ $backgroundColor }) => $backgroundColor};
	margin-right: ${({ $marginRight }) => $marginRight};

	position: relative;
	overflow: hidden;

	&:last-child {
		margin-right: 0;
	}
`

export const FilledPart = styled.div<{ $width: string; $filledColor: string }>`
	width: ${({ $width }) => $width};
	height: 100%;
	background-color: ${({ $filledColor }) => $filledColor};
	position: absolute;
	top: 0;
	left: 0;
`

export const RatingsList = styled.div`
	display: flex;
	gap: 10px;
`

export const LinesCol = styled.div<{ $gap: string }>`
	min-width: 20px;
	display: flex;
	flex-direction: column;
	gap: ${({ $gap }) => $gap};
	flex: 1;
`

export const LabelsCol = styled.div<{ $gap: string }>`
	display: flex;
	flex-shrink: 0;
	flex-direction: column;
	gap: ${({ $gap }) => $gap};
`

export const RatingWrapper = styled.div`
	display: flex;
	gap: 10px;
	align-items: center;
`

export const Rate = styled(Rating)<{ $space: number; $width: string; $height: string; $filledColor: string; $backgroundColor: string }>`
	${({ $space, $width, $height, $filledColor, $backgroundColor }) => css`
		gap: ${$space}px;
		flex-shrink: 0;
		width: ${$width};
		min-height: 1rem;
		display: flex;
		align-items: center;

		&.rr--focus-reset {
			outline: 2px solid ${theme.color.text.primary};
		}

		.rr--box {
			width: 20%;
			height: ${$height};
			transition: background-color 0.3s ease;

			&.rr--on {
				background-color: ${$filledColor};
			}

			&.rr--off {
				background-color: ${$backgroundColor};
			}

			&:focus-visible {
				outline-offset: 1px;
				outline: 2px solid ${theme.color.text.primary};
			}

			svg {
				display: none;
			}
		}
	`}
`

export const RatingLabel = styled.span`
	${NotinoLabelSmall400};
	${truncate};
	display: inline-block;
	flex-shrink: 0;
	line-height: 1rem;
`
