import React from 'react'
import { toHoursAndMinutes } from '../../utils/helper'

type Props = {
	from?: number
	to?: number
}

const RenderDuration = ({ from, to }: Props) => {
	const fromExists = from !== null && from !== undefined
	const toExists = to !== null && to !== undefined

	if (!fromExists && !toExists) {
		return null
	}

	if (from !== to) {
		return (
			<>
				{fromExists ? toHoursAndMinutes(from) : ''} - {toExists ? toHoursAndMinutes(to) : ''}
			</>
		)
	}

	return toHoursAndMinutes(from || to)
}

export default RenderDuration
