import React, { PropsWithChildren } from 'react'
import { ArrayParam, BooleanParam, NumberParam, NumericArrayParam, StringParam, useQueryParams, withDefault } from 'use-query-params'

// components
import SalonsListPage from '../pages/SalonsListPage/SalonsListPage'

// types
import { ContextProps, SalonsListPageServerQueryType, SalonsListPageSetQueryType } from '../types/types'

// utils
import { SORTING_OPTION } from '../utils/enums'
import { getValidSalonsPageQuery } from '../utils/helper'

type SalonsRouteProps = PropsWithChildren<ContextProps & {}>

const SalonsRoute: React.FC<SalonsRouteProps> = (props) => {
	// TODO: sync with SalonsCategoryRoute - duplicate code

	// NOTE: when adding new parameter, it will be also add it to SalonsListPageQueryType and SalonsCategoryPageQueryConfigType in interfaces
	const [query, setQuery] = useQueryParams({
		page: withDefault(NumberParam, 1),
		openingHoursStatus: StringParam,
		latMy: NumberParam,
		lonMy: NumberParam,
		lat: NumberParam,
		lon: NumberParam,
		googlePlaceID: StringParam,
		orderBy: withDefault(StringParam, SORTING_OPTION.RECOMMENDED),
		categoryIDs: withDefault(ArrayParam, []),
		exactRating: withDefault(NumericArrayParam, []),
		languageIDs: withDefault(ArrayParam, []),
		cosmeticIDs: withDefault(ArrayParam, []),
		hasAvailableReservationSystem: BooleanParam,
		serviceTotalPriceFrom: NumberParam,
		serviceTotalPriceTo: NumberParam,
		avResTimeSlotDayPart: StringParam,
		avResTimeSlotDateFrom: StringParam,
		avResTimeSlotDateTo: StringParam,
		isMapView: BooleanParam
	})

	const validQuery = getValidSalonsPageQuery(query as SalonsListPageServerQueryType)

	return <SalonsListPage query={validQuery} setQuery={setQuery as SalonsListPageSetQueryType} {...props} />
}

export default SalonsRoute
