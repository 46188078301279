import React, { PropsWithChildren } from 'react'

// styles
import * as SC from './HeaderContentStyles'

type Props = PropsWithChildren<{
	title: string
	description?: string
}>

const HeaderContent = (props: Props) => {
	const { title, description } = props

	return (
		<>
			<SC.HeaderContentTitle>{title}</SC.HeaderContentTitle>
			{description && <SC.HeaderContentDescription>{description}</SC.HeaderContentDescription>}
		</>
	)
}

export default HeaderContent
