import styled, { css } from 'styled-components'
import RcInput from 'rc-input'
import { theme } from '@notino/react-styleguide'

// styles
import { NotinoLabelLarge400, resetButtonStyles } from '../../styles/helpers'

const commonInputStyles = css<{ $isPristine?: boolean; $isInvalid?: boolean }>`
	padding: 0 1rem;
	width: 100%;
	${theme.color.border.negative};
	overflow: hidden;
	border: 1px solid ${({ $isInvalid }) => ($isInvalid ? theme.color.border.negative : theme.color.border.default)};
	border-radius: 2px;
	display: inline-flex;
	align-items: center;
	gap: 0.625rem;
	outline: 2px solid transparent;
	outline-offset: -2px;
	transition:
		border 0.3s ease,
		outline 0.3s ease;
	${NotinoLabelLarge400};
	color: ${({ $isPristine }) => ($isPristine ? theme.color.text.tertiary : theme.color.text.primary)};
	height: 44px;
`

const hoverCommonInputStyles = css<{ $isInvalid?: boolean }>`
	&:hover {
		border-color: ${({ $isInvalid }) => ($isInvalid ? theme.color.border.negative : theme.color.common.black)};
	}

	&:focus-within {
		border-color: ${({ $isInvalid }) => ($isInvalid ? theme.color.border.negative : theme.color.common.black)};
		outline-color: ${({ $isInvalid }) => ($isInvalid ? theme.color.border.negative : theme.color.common.black)};
	}
`

const disabledCommonInputStyles = css<{ $isInvalid?: boolean }>`
	background-color: #f5f5f5;
	border-color: ${({ $isInvalid }) => ($isInvalid ? theme.color.border.negative : '#e6e6e6;')};
	color: ${theme.color.text.disabled};
`

export const Input = styled(RcInput)<{ $isPristine?: boolean; $isSearch?: boolean; $isInvalid?: boolean }>`
	// normal input styles
	&.rc-input {
		${commonInputStyles}

		&:not(:disabled) {
			${hoverCommonInputStyles};
		}

		&:disabled {
			${disabledCommonInputStyles};
		}
	}

	// affix input styles
	&.rc-input-affix-wrapper {
		${commonInputStyles}

		&:not(.rc-input-affix-wrapper-disabled) {
			${hoverCommonInputStyles};
		}

		input {
			${NotinoLabelLarge400};
			display: block;
			width: 100%;
			padding: 0;
			border: none;
			outline: none;
			color: ${({ $isPristine }) => ($isPristine ? theme.color.text.tertiary : theme.color.text.primary)};
		}

		.rc-input-suffix {
			flex: 0 0 auto;
			color: ${theme.color.text.tertiary};
			${NotinoLabelLarge400};

			svg {
				color: ${theme.color.text.tertiary};
				display: inline-block;
				width: 1rem;
				height: auto;
			}

			.rc-input-clear-icon {
				width: 1rem;
				height: 1rem;
				font-size: 1rem;
				line-height: 1rem;
				font-weight: 400;
				${resetButtonStyles};
				color: ${theme.color.text.tertiary};
			}
		}

		${({ $isSearch }) =>
			$isSearch &&
			css`
				background-color: #f5f5f5;
				border: none;

				input {
					background-color: #f5f5f5;
				}
			`}

		&.rc-input-affix-wrapper-disabled {
			${disabledCommonInputStyles}

			input {
				color: ${theme.color.text.disabled};
			}

			.rc-input-suffix {
				color: ${theme.color.text.disabled};

				svg {
					color: ${theme.color.text.disabled};
				}
			}
		}
	}
`
