import * as React from 'react'
import { RawIntlProvider, defineMessages, createIntlCache, createIntl } from 'react-intl'
import { useInitialData } from '@notino/react-toolkit/renderer/fragment/useInitialData'

// types
import { ContextProps, IGetInitialDataProps, LocalizationData } from '../types/types'

// intl helpers
import { setIntl } from './intl'

const messages = defineMessages({})

const cache = createIntlCache()

const createIntInstance = (data: LocalizationData) => {
	const intlInstance = createIntl(
		{
			locale: data.locale.substring(0, 2), // we only take the first part of locale, that is 'sk', 'cs', etc.
			messages: data.messages
		},
		cache
	)

	setIntl(intlInstance)

	return intlInstance
}

const LanguageProvider: IGetInitialDataProps<LocalizationData, ContextProps> = ({ children, ...props }) => {
	const { locale } = props // locale here is 'sk-SK', 'cs-CZ', etc.

	const data = useInitialData(LanguageProvider, props) || {
		messages,
		locale
	}

	return <RawIntlProvider value={createIntInstance(data)}>{React.Children.only(children)}</RawIntlProvider>
}

LanguageProvider.identifier = 'LanguageProvider'

LanguageProvider.initDefaultData = async ({ api }) => {
	return api.getTranslations()
}

export default LanguageProvider
