import React, { PropsWithChildren, useEffect, useRef, useState } from 'react'
import { IconRegularChevronLeft, IconRegularChevronRight } from '@notino/react-styleguide'

// hooks
import useMessages from '../../../hooks/useMessages'

// components
import ReservationBookingModalLayout from '../ReservationBookingModalLayout/ReservationBookingModalLayout'
import SalonServiceDetail from '../../SalonServiceDetail/SalonServiceDetail'
import SalonServicesCards from '../../../pages/SalonPage/components/SalonServicesCards/SalonServicesCards'

// types
import { SalonServicesResponse, ServiceCardCallbackData, ServiceDetailData, ServicesTopCategoryData } from '../../../types/types'
import { SelectedIndustryData } from '../reservationBookingModalHelpers'

// hooks
import useScrolledElement from '../../../hooks/useScrolledElement'

// utils
import { isElementOverflownX } from '../../../utils/helper'
import { getModalCategoryClickedEvent } from '../../../utils/dataLayerEvents'
import { pushToDataLayer } from '../../../utils/dataLayer'

// styles
import * as SC from './Step0ServiceSelectionStyles'

type Props = PropsWithChildren<{
	selectedIndustryData: SelectedIndustryData
	fallbackHeaderImage: string | undefined
	salonServicesData: SalonServicesResponse
	onClose: () => void
	onServiceCardButtonClick: (data: ServiceCardCallbackData) => Promise<void>
	phoneNumber: string
}>

const Step0ServiceSelection = (props: Props) => {
	const { onClose, onServiceCardButtonClick, salonServicesData, selectedIndustryData, fallbackHeaderImage, phoneNumber } = props
	const { messages } = useMessages()

	const [selectedIndustry, setSelectedIndustry] = useState<SelectedIndustryData>(selectedIndustryData)
	const selectedTabKey = selectedIndustry.industryID

	const [serviceDetail, setServiceDetail] = useState<ServiceDetailData | null>(null)

	const headerImage = selectedIndustry.image || fallbackHeaderImage

	const scrollElement = useRef<HTMLUListElement | null>(null)
	const { isScrolledLeft, isScrolledRight, scrollLeft, scrollRight } = useScrolledElement(scrollElement.current)
	const [isOverflown, setIsOverflown] = useState(false)

	const layoutWrapperRef = useRef<HTMLDivElement>(null)

	const isServiceDetailVisible = !!serviceDetail

	useEffect(() => {
		const checkOverflow = () => {
			if (scrollElement.current) {
				setIsOverflown(isElementOverflownX(scrollElement.current))
			}
		}

		checkOverflow()
		window.addEventListener('resize', checkOverflow)

		return () => {
			window.removeEventListener('resize', checkOverflow)
		}
	}, [scrollElement])

	useEffect(() => {
		// TODO: dorobit scrollovanie aj na focus stav
		if (scrollElement.current) {
			const tabButton = document.getElementById(selectedIndustry.industryID)
			if (tabButton) {
				if (tabButton) {
					const container = scrollElement.current
					const containerRect = container.getBoundingClientRect()
					const tabRect = tabButton.getBoundingClientRect()

					// Calculate the scroll position
					const tabLeft = tabRect.left - containerRect.left
					const tabRight = tabRect.right - containerRect.left
					const containerWidth = containerRect.width

					// Determine if the tab is out of view
					if (tabLeft < 0 || tabRight > containerWidth) {
						// Calculate the new scroll position to center the tab
						const newScrollLeft = tabButton.offsetLeft - container.clientWidth / 2 + tabButton.clientWidth / 2

						// Ensure the scroll position is within the bounds of the scrollable area
						const maxScrollLeft = container.scrollWidth - container.clientWidth
						const boundedScrollLeft = Math.min(Math.max(newScrollLeft, 0), maxScrollLeft)

						// Scroll the container
						container.scrollTo({
							left: boundedScrollLeft,
							behavior: 'smooth'
						})
					}
				}
			}
		}
	}, [selectedIndustry.industryID])

	const onTabsChange = (newIndustryData: ServicesTopCategoryData) => {
		setSelectedIndustry({ industryID: newIndustryData.id, image: newIndustryData.image.resizedImages.medium })
		const event = getModalCategoryClickedEvent({ category: newIndustryData.id })
		pushToDataLayer(event)
	}

	useEffect(() => {
		/**
		 * Disable interaction with service selection screen when service detail is visible over it
		 * The inert attribute can be added to sections of content that should not be interactive. When an element is inert, it along with all of the element's descendants, including normally interactive elements such as links, buttons, and form controls are disabled because they cannot receive focus or be clicked.
		 * The inert attribute can also be added to elements that should be offscreen or hidden. An inert element, along with its descendants, gets removed from the tab order and accessibility tree.
		 * Full docs: https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/inert
		 */
		if (layoutWrapperRef.current) {
			if (isServiceDetailVisible) {
				layoutWrapperRef.current.setAttribute('inert', 'true')
			} else {
				layoutWrapperRef.current.removeAttribute('inert')
			}
		}
	}, [isServiceDetailVisible])

	return (
		<>
			{serviceDetail && (
				<SalonServiceDetail
					phoneNumber={phoneNumber}
					serviceData={serviceDetail}
					onClose={() => setServiceDetail(null)}
					isReservationBookingModal
					onServiceCardButtonClick={onServiceCardButtonClick}
				/>
			)}

			<SC.LayoutWrapper ref={layoutWrapperRef} $isServiceDetailVisible={isServiceDetailVisible}>
				<ReservationBookingModalLayout
					title={messages.Services}
					headerImage={headerImage}
					onClose={onClose}
					headerContent={
						<SC.TabsContainer>
							{isScrolledLeft && (
								<SC.ScrollLeftButton type='button' onClick={scrollLeft} style={{ zIndex: 10 }}>
									<IconRegularChevronLeft color={'icon.inverse'} />
								</SC.ScrollLeftButton>
							)}
							<SC.Tabs
								role={'tablist'}
								ref={scrollElement}
								$isScrollLeft={isOverflown && isScrolledLeft}
								$isScrollRight={isOverflown && isScrolledRight}
							>
								{salonServicesData.groupedServicesByCategory.map((industry) => {
									if (!industry.category) {
										return null
									}
									const key = industry.category.id
									const isActive = selectedTabKey === key

									return (
										<SC.Tab
											id={key}
											key={key}
											onKeyDown={(e) => {
												if (e.key === 'Enter') {
													if (industry.category) {
														onTabsChange(industry.category)
													}
												}
											}}
										>
											<SC.TabButton
												$isActive={isActive}
												tabIndex={0}
												role={'tab'}
												aria-selected={isActive}
												onClick={() => {
													if (industry.category) {
														onTabsChange(industry.category)
													}
												}}
											>
												{industry.category?.name}
											</SC.TabButton>
										</SC.Tab>
									)
								})}
							</SC.Tabs>
							{isScrolledRight && (
								<SC.ScrollRightButton type='button' onClick={scrollRight} style={{ zIndex: 10 }}>
									<IconRegularChevronRight color={'icon.inverse'} />
								</SC.ScrollRightButton>
							)}
						</SC.TabsContainer>
					}
				>
					<SC.TabsContent>
						{salonServicesData.groupedServicesByCategory.map((industry) => {
							if (!industry.category) {
								return null
							}

							const key = industry.category.id
							const isActive = selectedTabKey === key

							// no need to render inactive tab content
							if (!isActive) {
								return null
							}

							return (
								<SC.TabContent key={key} $isActive={isActive}>
									<SalonServicesCards
										phoneNumber={phoneNumber}
										isReservationBookingModal
										industryData={industry.category}
										onServiceCardButtonClick={onServiceCardButtonClick}
										setServiceDetail={setServiceDetail}
									/>
								</SC.TabContent>
							)
						})}
					</SC.TabsContent>
				</ReservationBookingModalLayout>
			</SC.LayoutWrapper>
		</>
	)
}

export default Step0ServiceSelection
