import styled, { css } from 'styled-components'

// utils
import { theme } from '@notino/react-styleguide'
import { NotinoBodySmall, NotinoLabelRegular400 } from '../../../../styles/helpers'

// components
import FetchResult from '../../../../components/FetchResult/FetchResult'

export const SidebarContentWrapper = styled.div`
	padding-bottom: 20px;
`

export const SidebarForm = styled.form`
	width: 100%;
`

// Header styles
export const HeaderResetButton = styled.button`
	border: none;
	background: transparent;
	padding: 0;
	margin: 0;
	text-decoration: underline;
	${NotinoLabelRegular400};
	color: ${theme.color.text.tertiary};
	cursor: pointer;
`

// Content styles
export const SidebarDetailContent = styled.div`
	padding: 20px;
`

// Price filter
export const PriceFilterWrapper = styled.div`
	margin-bottom: 20px;
`

// Rating filter
export const RatingFilterWrapper = styled.div`
	margin-bottom: 20px;
`

// Rating filter
export const SortingFilterWrapper = styled.div`
	margin-bottom: 20px;
`

// Cosmetics filter
export const FilterSearchWrapper = styled.div`
	position: sticky;
	top: 0;
	background: ${theme.color.background.primary};
	margin: -20px -20px 0 -20px;
	padding: 16px 20px 8px;
	z-index: 2;
`

export const CosmeticsFetchResults = styled(FetchResult)`
	position: relative;
	z-index: 1;
`

// Languages Filter
export const CheckboxFlag = styled.div<{ $backgroundImage?: string }>`
	width: 24px;
	height: 16px;
	position: relative;
	z-index: 1;
	background-color: #e6e6e6;

	${({ $backgroundImage }) =>
		$backgroundImage &&
		css`
			background-color: transparent;

			&::before {
				content: '';
				position: absolute;
				z-index: 2;
				left: 0;
				right: 0;
				height: 20px;
				top: 50%;
				transform: translateY(-50%);
				background-image: url('${$backgroundImage}');
				background-size: contain;
				background-position: center;
				background-repeat: no-repeat;
			}
		`}
`

// Date filter
export const DateFilterFetchResult = styled(FetchResult)`
	padding: 20px;
`

export const DateFilterFooterLabel = styled.span`
	display: block;
	text-align: center;
	${NotinoBodySmall};
	color: ${theme.color.text.primary};
`
