import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useInitialData } from '@notino/react-toolkit/renderer/fragment/useInitialData'
import { useIntl } from 'react-intl'
import axios from 'axios'

// components
import SalonsListPageHead from './components/SalonsListPageHead/SalonsListPageHead'
import SalonsView from '../../components/SalonsView/SalonsView'
import SalonsSearchWrap from './components/SalonsSearchWrap/SalonsSearchWrap'
import SalonsSeoSchema from '../../components/SalonsSeoSchema/SalonsSeoSchema'
import ElementsSlider from '../../components/ElementsSlider/ElementsSlider'
// NOTE: Temporary hide reservations history section - https://goodrequest.atlassian.net/browse/NOT-10384 (look up this comment in the app to show the section)
// import MyReservations from './components/MyReservations/MyReservations'

// types
import {
	GetSalonsDataParams,
	CategoriesResponse,
	ConfigResponse,
	ContextProps,
	IGetInitialDataProps,
	SalonsResponse,
	LoadSalonsFromFiltersParams,
	SalonsListPageQueryType,
	SalonsListPageSetQueryType,
	SalonsListPageServerQueryType,
	CmsSalonsListPageData,
	Currency
	// NOTE: Temporary hide reservations history section - https://goodrequest.atlassian.net/browse/NOT-10384 (look up this comment in the app to show the section)
	/* FutureReservationsResponse,
	PastReservationsResponse */
} from '../../types/types'

// styles
import * as SC from './SalonsListPageStyles'

// utils
import { AppContext } from '../../utils/appProvider'
import { areReservationsAllowed, PAGE_LINKS, isNil, getValidPriceRangeQueryParams } from '../../utils/helper'
import { pushToDataLayer } from '../../utils/dataLayer'
import { getPageViewEvent, getTopLevelCategoryChangeEvent, getViewItemListEvent } from '../../utils/dataLayerEvents'
// NOTE: Temporary hide reservations history section - https://goodrequest.atlassian.net/browse/NOT-10384 (look up this comment in the app to show the section)
// import { FUTURE_RESERVATIONS_STATES, RESERVATIONS_LIMIT_SALONS_PAGE, PAST_RESERVATIONS_STATES } from '../../utils/enums'
import { MyLocationContext } from '../../utils/myLocationProvider'
import { shopsConfig } from '../../appDefaults'

// hooks
import useMessages from '../../hooks/useMessages'
import { ABORT_KEYS } from '../../utils/enums'

// types
type ExtendedContextProps = {
	query: SalonsListPageServerQueryType
	setQuery: SalonsListPageSetQueryType
} & ContextProps

type InitialData = {
	salonsData: SalonsResponse
	categoriesData: CategoriesResponse
	configData: ConfigResponse
	cmsData: CmsSalonsListPageData | undefined
	allowedReservations: boolean
	currency: Currency
	// NOTE: Temporary hide reservations history section - https://goodrequest.atlassian.net/browse/NOT-10384 (look up this comment in the app to show the section)
	/* futureReservationsData: FutureReservationsResponse | null
	pastReservationsData: PastReservationsResponse | null */
}

type SalonsPageProps = InitialData &
	Omit<ExtendedContextProps, 'query'> & {
		query: SalonsListPageQueryType
		setSalonsData: React.Dispatch<React.SetStateAction<SalonsResponse | undefined>>
	}

// page
const SalonsListPage = (props: SalonsPageProps) => {
	const { query, setQuery, salonsData, setSalonsData, categoriesData, configData, allowedReservations, cmsData, currency } = props
	const { salons, pagination } = salonsData

	const { messages } = useMessages()
	const { locale } = useIntl()
	const { apiBrowser } = useContext(AppContext)
	const { myLocation, isLoadingMyLocation } = useContext(MyLocationContext)

	const [initialLocationBasedLoading, setInitialLocationBasedLoading] = useState(isLoadingMyLocation)
	const [areSalonsLoading, setAreSalonsLoading] = useState<boolean>(false)
	const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false)

	const isMountFetchRef = useRef(true)
	const isMyPositionKnownOnMount = useRef(!!myLocation)
	const mergeWithPreviousSalons = useRef(false)

	const defaultCity = configData.rolloutCountries.find((country) => country.languageCode === locale)?.capitalCity

	const refetchSalonsFromLoadMoreBtn = () => {
		setIsButtonLoading(true)

		// set mergeWithPreviousSalons flag to true so the currently showed salons can be merged with newly loaded salons
		mergeWithPreviousSalons.current = true

		setQuery({
			...query,
			page: query.page + 1
		})
	}

	const updateQueryFromFilters = async (params: LoadSalonsFromFiltersParams) => {
		const { orderBy: orderByParam } = params

		const orderBy = orderByParam || query.orderBy

		// update query
		setQuery({
			page: 1,
			orderBy
		})
	}

	const fetchSalons = useCallback(
		async (mergeWithPrevious = false) => {
			try {
				let salonsDataParams: GetSalonsDataParams = {
					openingHoursStatus: query.openingHoursStatus,
					latMy: myLocation?.latMy,
					lonMy: myLocation?.lonMy,
					lat: query.lat,
					lon: query.lon,
					categoryIDs: [], // Salons List does not have category filter
					exactRating: query.exactRating,
					hasAvailableReservationSystem: query.hasAvailableReservationSystem,
					languageIDs: query.languageIDs,
					cosmeticIDs: query.cosmeticIDs,
					orderBy: query.orderBy,
					avResTimeSlotDayPart: query.avResTimeSlotDayPart,
					avResTimeSlotDateFrom: query.avResTimeSlotDateFrom,
					avResTimeSlotDateTo: query.avResTimeSlotDateTo,
					page: query.page
				}

				if (!isNil(query.serviceTotalPriceFrom) || !isNil(query.serviceTotalPriceTo)) {
					salonsDataParams = {
						...salonsDataParams,
						serviceTotalPriceFrom: query.serviceTotalPriceFrom,
						serviceTotalPriceTo: query.serviceTotalPriceTo,
						serviceTotalPriceCurrencyCode: currency.code
					}
				}

				const newSalonsData = await apiBrowser.b2c.getSalonsData(
					{ ...salonsDataParams },
					{ allowAbort: true, abortSignalKey: ABORT_KEYS.SALONS_ABORT_KEY }
				)

				setSalonsData((prevSalons) => {
					if (!prevSalons) {
						return undefined
					}

					if (mergeWithPrevious) {
						return {
							...prevSalons,
							pagination: newSalonsData.pagination,
							salons: [...prevSalons.salons, ...newSalonsData.salons]
						}
					}

					return newSalonsData
				})

				const event = getViewItemListEvent({
					currentPage: query.page || 1,
					currentSortingOptionName: query.orderBy,
					salons: newSalonsData?.salons || []
				})
				pushToDataLayer(event)
			} catch (error) {
				// set error, but only if it is not caused by cancel token
				if (axios.isAxiosError(error) && error.code !== 'ERR_CANCELED') {
					setSalonsData(undefined)
				}
			}
		},
		[
			query.openingHoursStatus,
			query.lat,
			query.lon,
			query.exactRating,
			query.hasAvailableReservationSystem,
			query.languageIDs,
			query.cosmeticIDs,
			query.orderBy,
			query.avResTimeSlotDayPart,
			query.avResTimeSlotDateFrom,
			query.avResTimeSlotDateTo,
			query.serviceTotalPriceFrom,
			query.serviceTotalPriceTo,
			query.page,
			myLocation?.latMy,
			myLocation?.lonMy,
			apiBrowser,
			setSalonsData,
			currency.code
		]
	)

	useEffect(() => {
		/**
		 * Fetch new data whenever some of the dependencies changes after the first data load
		 */
		;(async () => {
			if (!isMountFetchRef.current && !query.isMapView) {
				setAreSalonsLoading(true)
				await fetchSalons(mergeWithPreviousSalons.current)
				setAreSalonsLoading(false)
				setIsButtonLoading(false)
				mergeWithPreviousSalons.current = false
			}
		})()
	}, [fetchSalons, query.isMapView])

	useEffect(() => {
		/**
		 * Salons are already fetched on the server side, so client-side fetching is not always necessary on page load.
		 * However, since we are working with `myLocation`, which is not available on the server, we need to handle location-based fetching.
		 * If the user grants permission to access their location, we want to refetch the data using the user's location.
		 * During this process, we show an initial loading state until the location is resolved, and if needed, fetch new data.
		 * If the user's location is already known from query parameters at page initialization, there is no need to show the initial loading state.
		 * The loading state is displayed over the salons fetched from the server, ensuring that the data is available in the HTML for search engine bots, while preventing a content flash for users.
		 */
		;(async () => {
			if (isLoadingMyLocation || !isMountFetchRef.current || query.isMapView) {
				return
			}

			if (myLocation && !isMyPositionKnownOnMount.current) {
				await fetchSalons()
			}

			setInitialLocationBasedLoading(false)
			isMountFetchRef.current = false
		})()
	}, [isLoadingMyLocation, myLocation, fetchSalons, query.isMapView])

	// GA page view event
	useEffect(() => {
		const pageViewEvent = getPageViewEvent('salons')
		pushToDataLayer(pageViewEvent)
	}, [])

	const handleCategoryButtonClick = (categoryID: string, categorySlug?: string) => {
		// push to dataLayer
		const event = getTopLevelCategoryChangeEvent({ categoryID, categorySlug })
		pushToDataLayer(event)
	}

	const breadcrumbItems = [{ link: '', name: messages['List of salons'] }]

	return (
		<>
			<SalonsSeoSchema breadcrumbItems={[{ link: PAGE_LINKS['/salons/homepage'](locale), name: messages.Salons }, ...breadcrumbItems]} />
			<SalonsListPageHead pagination={pagination} />
			<SC.Container>
				<SC.SalonsListPageWrapper>
					<SC.Grid>
						<SC.SalonsPageBreadcrumbs breadcrumbItems={breadcrumbItems} hasSalonsBreadcrumb />

						{/* // NOTE: Temporary hide reservations history section - https://goodrequest.atlassian.net/browse/NOT-10384 (look up this comment in the app to show the section) */}
						{/* allowedReservations && isAuthorized && <MyReservations futureReservationsData={futureReservationsData} pastReservationsData={pastReservationsData} /> */}

						<SC.MainTitle>{messages.Salons}</SC.MainTitle>

						<SalonsSearchWrap />

						<SC.TopLevelCategoriesSliderWrapper>
							<ElementsSlider fading={false} gap={'24px'}>
								{categoriesData.categories.map((category) => (
									<SC.TopLevelCategoryButton
										type='button'
										href={PAGE_LINKS['/salons/services/:categorySlug'](locale, category.nameSlug ?? '', { ...myLocation })}
										onClick={() => handleCategoryButtonClick(category.id, category.nameSlug)}
										key={category.id}
									>
										<SC.TopLevelCategoryImgWrapper>
											<SC.TopLevelCategoryImg
												src={category.image?.resizedImages.thumbnail}
												alt={category.name || ''}
												width={80}
												height={80}
											/>
										</SC.TopLevelCategoryImgWrapper>
										<SC.TopLevelCategoryTitle>{category.name}</SC.TopLevelCategoryTitle>
									</SC.TopLevelCategoryButton>
								))}
							</ElementsSlider>
						</SC.TopLevelCategoriesSliderWrapper>

						<SalonsView
							salons={salons}
							pagination={pagination}
							query={query}
							setQuery={setQuery}
							loadSalonsFromLoadMoreButton={refetchSalonsFromLoadMoreBtn}
							loadSalonsFromFilters={updateQueryFromFilters}
							initialLoading={initialLocationBasedLoading}
							salonsLoading={areSalonsLoading}
							buttonLoading={isButtonLoading}
							defaultCity={defaultCity}
							allowedReservations={allowedReservations}
							cmsAppPromoData={cmsData?.appPromoData}
						/>
					</SC.Grid>
				</SC.SalonsListPageWrapper>
			</SC.Container>
		</>
	)
}

// page container
const SalonsListPageContainer: IGetInitialDataProps<InitialData | null, ExtendedContextProps> = (props) => {
	const data = useInitialData(SalonsListPageContainer, props)
	const { query: serverQuery, ...restProps } = props

	//  save data to state to allow client-side refetching
	const [salonsData, setSalonsData] = useState<SalonsResponse | undefined>(data?.salonsData || undefined)

	// When working with the user's location, always use values from `MyLocationContext`, not from the query.
	// While the query does include `latMy` and `lonMy`, this is only to ensure the server can fetch the correct data when the user's detected location is passed via query parameters in the URL.
	// Therefore, throughout the application, this typed `query` should be used, where user-related location parameters are omitted to avoid unintentional usage.
	const query: SalonsListPageQueryType = useMemo(() => {
		const resolvedQuery = {
			...props.query,
			...getValidPriceRangeQueryParams(props.query.serviceTotalPriceFrom, props.query.serviceTotalPriceTo /* , filterCountsData?.priceRange */)
		}

		delete resolvedQuery.latMy
		delete resolvedQuery.lonMy

		return resolvedQuery
	}, [props.query])

	// NOTE: needed for react-toolkit SSR
	if (!data || !salonsData) {
		return null
	}

	return <SalonsListPage {...restProps} {...data} query={query} salonsData={salonsData} setSalonsData={setSalonsData} currency={data.currency} />
}

// ssr setup
SalonsListPageContainer.initDefaultData = async ({ api, query, shopId, locale /* , isAuthorized */ }) => {
	try {
		const configData = await api.b2c.getConfigData()

		const localeCode = shopsConfig.find((config) => config.lang === locale)?.locale
		const currencyCode = configData.rolloutCountries.find((country) => country.languageCode === localeCode)?.currencyCode
		const currency = configData.systemCurrencies.find((c) => c.code === currencyCode)

		if (!currency) {
			return null
		}

		const allowedReservations = areReservationsAllowed(configData.allowReservations, shopId)

		let getSalonsDataParams: GetSalonsDataParams = {
			openingHoursStatus: query.openingHoursStatus,
			latMy: query.latMy,
			lonMy: query.lonMy,
			lat: query.lat,
			lon: query.lon,
			categoryIDs: [], // Salons List does not have category filter
			exactRating: query.exactRating,
			languageIDs: query.languageIDs,
			cosmeticIDs: query.categoryIDs,
			hasAvailableReservationSystem: query.hasAvailableReservationSystem,
			orderBy: query.orderBy,
			avResTimeSlotDayPart: query.avResTimeSlotDayPart,
			avResTimeSlotDateFrom: query.avResTimeSlotDateFrom,
			avResTimeSlotDateTo: query.avResTimeSlotDateTo
		}

		if (!isNil(query.serviceTotalPriceFrom) || !isNil(query.serviceTotalPriceTo)) {
			getSalonsDataParams = {
				...getSalonsDataParams,
				serviceTotalPriceFrom: query.serviceTotalPriceFrom,
				serviceTotalPriceTo: query.serviceTotalPriceTo,
				serviceTotalPriceCurrencyCode: currency.code
			}
		}

		const queryWithValidPriceRange = {
			...getSalonsDataParams,
			...getValidPriceRangeQueryParams(query.serviceTotalPriceFrom, query.serviceTotalPriceTo)
		}

		const [categoriesData, salonsData, cmsData] = await Promise.all([
			api.b2c.getCategoriesData(),
			api.b2c.getSalonsData({
				...queryWithValidPriceRange,
				page: query.page
			}),
			api.cms.getSalonsListPageCmsData()
		])

		// NOTE: Temporary hide reservations history section - https://goodrequest.atlassian.net/browse/NOT-10384 (look up this comment in the app to show the section)
		// let futureReservationsData: FutureReservationsResponse | undefined
		// let pastReservationsData: PastReservationsResponse | undefined

		// if (allowedReservations && isAuthorized) {
		// 	try {
		// 		futureReservationsData = await api.b2c.getFutureReservations({
		// 			page: 1,
		// 			limit: RESERVATIONS_LIMIT_SALONS_PAGE,
		// 			reservationStates: FUTURE_RESERVATIONS_STATES
		// 		})

		// 		if (!futureReservationsData || futureReservationsData.pagination.totalCount === 0) {
		// 			pastReservationsData = await api.b2c.getPastReservations({
		// 				page: 1,
		// 				limit: RESERVATIONS_LIMIT_SALONS_PAGE,
		// 				reservationStates: PAST_RESERVATIONS_STATES
		// 			})
		// 		}
		// 	} catch {
		// 		/* empty */
		// 	}
		// }

		return { salonsData, categoriesData, configData, cmsData, allowedReservations, currency /* , futureReservationsData, pastReservationsData */ }
	} catch {
		return null
	}
}

SalonsListPageContainer.identifier = 'SalonsListPage'

export default SalonsListPageContainer
