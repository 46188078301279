import React from 'react'

// components
import TextArea from '../../atoms/TextArea/TextArea'
import FormItem from '../../atoms/FormItem/FormItem'

// types
import { TextAreaFormFieldProps } from './types'

// component
const TextAreaFormField = (props: TextAreaFormFieldProps) => {
	const {
		input: { value, onChange, onBlur, onFocus },
		meta: { error, invalid },
		label,
		htmlFor,
		required,
		...restProps
	} = props

	const invalidStatus = !!(error || invalid)

	return (
		<FormItem htmlFor={htmlFor} label={label} required={required} error={invalidStatus && error ? error : undefined}>
			<TextArea value={value} onChange={(e) => onChange(e.target.value)} onBlur={onBlur} onFocus={onFocus} invalid={invalid} {...restProps} />
		</FormItem>
	)
}

export default TextAreaFormField
