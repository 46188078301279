import { Button, IconRegularPhone, theme } from '@notino/react-styleguide'
import styled, { css } from 'styled-components'
import { multiLineClamp, NotinoLabelLarge, NotinoLabelSmall, NotinoLabelSmall400, truncate } from '../../../../styles/helpers'
import { BREAKPOINTS } from '../../../../styles/constants'

export const IndustryWrapper = styled.div`
	display: grid;
	gap: 16px;
`

export const CardWrapper = styled.div``

export const SecondCategoryTitle = styled.div`
	${NotinoLabelSmall};
	color: ${theme.color.text.secondary};
`

export const SecondCategoryContent = styled.div<{ $isReservationBookingModal: boolean }>`
	display: grid;
	grid-template-columns: 1fr;

	${({ $isReservationBookingModal }) =>
		!$isReservationBookingModal &&
		css`
			@media (min-width: ${BREAKPOINTS.md}) {
				grid-template-columns: repeat(2, 2fr);
				gap: 24px;
			}

			@media (min-width: ${BREAKPOINTS.lg}) {
				grid-template-columns: 1fr;
				gap: 0;
			}

			@media (min-width: ${BREAKPOINTS.xl}) {
				grid-template-columns: repeat(2, 2fr);
				gap: 24px;
			}
		`}
`
export const Divider = styled.div`
	border-top: 1px solid rgba(225, 225, 225, 1);
	width: 100%;
	margin-top: 8px;
	margin-bottom: 8px;
`

export const CategoryItemDivider = styled.div`
	display: block;
	border-top: 1px solid rgba(225, 225, 225, 1);
	width: 100%;
	margin-top: 8px;
	margin-bottom: 8px;
`

export const CategoryItem = styled.div<{ $isOdd: boolean }>`
	display: flex;
	flex-direction: column;
	position: relative;
	min-height: 65px;
	overflow: hidden;

	&:hover > h3 {
		text-decoration: underline;
	}

	&:nth-last-of-type(1) {
		${CategoryItemDivider} {
			display: none;
		}
	}
	@media (min-width: ${BREAKPOINTS.md}) {
		&:nth-last-of-type(2),
		&:nth-last-of-type(1) {
			${CategoryItemDivider} {
				display: none;
			}
		}
	}

	@media (min-width: ${BREAKPOINTS.lg}) {
		&:nth-last-of-type(2) {
			${CategoryItemDivider} {
				display: block;
			}
		}
	}

	@media (min-width: ${BREAKPOINTS.xl}) {
		&:nth-last-of-type(2) {
			${CategoryItemDivider} {
				display: none;
			}
		}
	}
`

export const CategoryItemContainer = styled.div<{ $isClickable?: boolean }>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 16px;
	padding: 8px;
	transition: background-color 0.2s ease-in-out;
	overflow: hidden;

	@media (min-width: ${BREAKPOINTS.sm}) {
		min-height: 92px; // 84px + 8px padding space for hover state background
	}

	${({ $isClickable }) =>
		$isClickable &&
		css`
			&:hover {
				cursor: pointer;
				border-radius: 4px;
				background-color: ${theme.color.background.secondary};
			}
		`}
`

export const CategoryItemInfo = styled.div`
	overflow: hidden;
`

export const ServiceCategoryTitle = styled.div`
	${truncate};
	${NotinoLabelLarge};
	margin-bottom: 4px;
`

export const ServiceDescription = styled.div`
	color: ${theme.color.text.secondary};
	${NotinoLabelSmall400};
	${multiLineClamp(2)}
	max-height: 32px;
	margin-bottom: 4px;
`

export const ServiceCardFooter = styled.div`
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	gap: 8px;
	@media (min-width: ${BREAKPOINTS.sm}) {
		align-items: center;
		flex-direction: row;
		margin-top: auto;
	}
`

export const PriceAndDurationContainer = styled.div`
	display: flex;
	flex-direction: column-reverse;
	gap: 4px;
	flex: 1;

	@media (min-width: ${BREAKPOINTS.sm}) {
		flex-direction: row;
		align-items: center;
		gap: 0;

		&:not(:empty) {
			min-height: 20px;
		}
	}
`

export const ServicePrice = styled.div`
	display: flex;
	align-items: center;
	${NotinoLabelSmall400};
	white-space: nowrap;
`

export const ServiceDuration = styled.div`
	display: flex;
	align-items: center;
	position: relative;
	${NotinoLabelSmall400};

	@media (min-width: ${BREAKPOINTS.sm}) {
		&:not(:last-child) {
			&::after {
				width: 12px;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				text-align: center;
				content: '•';
				color: ${theme.color.text.disabled};
				margin: 0 4px;
			}
		}
	}
`

export const DurationIcon = styled.div`
	margin-right: 5px;
	flex-shrink: 0;

	& > svg {
		width: 12px;
		height: 12px;
	}
`

export const DurationText = styled.div`
	${NotinoLabelSmall400};
	white-space: nowrap;
`

export const BookOnlineButton = styled(Button)`
	margin-left: auto;
	flex-shrink: 0;
`

export const PhoneText = styled.div`
	font-size: 0.75rem;
	font-weight: 500;
	flex-shrink: 0;
	color: ${theme.color.text.secondary};
`

export const PhoneIcon = styled(IconRegularPhone)`
	flex-shrink: 0;
	width: 16px;
	height: 16px;
`

export const PhoneDividerWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
	margin-top: 20px;
	margin-bottom: 20px;
`

export const InfoLineWrapper = styled.div`
	margin-bottom: 20px;
`
