import styled from 'styled-components'
import { Ratings, theme } from '@notino/react-styleguide'
import { BREAKPOINTS } from '../../../../styles/constants'
import { NotinoBodyMedium, NotinoLabelLarge, NotinoLabelRegular, NotinoLabelSmall, NotinoLabelSmall400, NotinoTitleDisplay } from '../../../../styles/helpers'

export const SalonReviewsContainer = styled.div`
	@media (min-width: ${BREAKPOINTS.sm}) {
		margin-top: 60px;
	}
`

export const ReviewsHeader = styled.div`
	display: flex;
	flex-direction: column;
	gap: 30px;

	@media (min-width: ${BREAKPOINTS.sm}) {
		flex-direction: row;
		gap: 15px;
	}
`

export const HeaderLeftColumn = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 8px;
	flex-wrap: wrap;

	@media (min-width: ${BREAKPOINTS.sm}) {
		display: block;
		flex-shrink: 0;
	}
`

export const HeaderRightColumn = styled.div`
	flex: 1;
`

export const Heading = styled.div`
	display: none;

	@media (min-width: ${BREAKPOINTS.sm}) {
		${NotinoTitleDisplay};
		display: block;
		margin-bottom: 20px;
	}
`

export const AverageRatingDesktopWrapper = styled.div`
	margin-bottom: 20px;
`

export const AverageRatingContainer = styled.div`
	display: flex;
	align-items: flex-end;
	gap: 8px;
	height: 2.25rem;

	@media (min-width: ${BREAKPOINTS.sm}) {
		margin-bottom: 20px;
	}
`

export const AverageRatingValue = styled.span`
	${NotinoTitleDisplay};
`

export const AverageRatingStars = styled.div`
	margin-bottom: 8px; // for alignment with value
`

export const TotalRatingsCountContainerMobile = styled.div`
	@media (min-width: ${BREAKPOINTS.sm}) {
		display: none;
	}
`

export const TotalRatingsCountContainerDesktop = styled.div`
	display: none;

	@media (min-width: ${BREAKPOINTS.sm}) {
		height: 2.25rem;
		display: flex;
		align-items: center;
		margin-bottom: 20px;
	}
`

export const TotalRatingsCount = styled.div`
	${NotinoLabelLarge};
	display: flex;
	align-items: center;
	gap: 4px;

	span {
		${NotinoLabelSmall400};
	}
`

export const RatingsContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 30px;

	@media (min-width: ${BREAKPOINTS.sm}) {
		flex-direction: row;
		justify-content: space-between;
		flex-wrap: wrap;
		gap: 40px;
	}
`

export const GroupedRatingsContainer = styled.div`
	flex: 1;

	svg {
		display: block;
	}

	@media (min-width: ${BREAKPOINTS.sm}) {
		min-width: 300px;
		max-width: 500px;
	}
`

export const PartialRatingsContainer = styled.div`
	flex-shrink: 0;
	max-width: 400px;

	@media (min-width: ${BREAKPOINTS.sm}) {
		min-width: 250px;
	}
`

export const AddReviewsButtonDesktop = styled.div`
	display: none;

	@media (min-width: ${BREAKPOINTS.sm}) {
		display: block;
	}
`

export const AddReviewsButtonMobile = styled.div`
	@media (min-width: ${BREAKPOINTS.sm}) {
		display: none;
	}

	svg {
		display: block;
	}
`

export const ReviewsContainer = styled.div`
	margin-top: 20px;
`

export const ReviewContainer = styled.div`
	&:not(:last-child) {
		margin-bottom: 40px;
	}
`

export const Review = styled.div``

export const ReviewMessage = styled.div`
	${NotinoBodyMedium};
`

export const ReviewInfo = styled.div`
	display: flex;
	align-items: start;
	flex-wrap: wrap;
	gap: 10px;
	margin-bottom: 4px;
`

export const ReviewInfoLeftContent = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 5px;
	flex: 1;

	svg {
		display: block;
	}
`

export const StyledRatings = styled(Ratings)`
	margin-right: 5px;

	svg {
		display: block;
	}
`

export const ReviewerName = styled.div`
	${NotinoLabelRegular};
	word-break: break-word;
	margin-right: 5px;
`

export const VerifiedReview = styled.div`
	${NotinoLabelSmall400};
	color: ${theme.color.text.positive};
	display: flex;
	align-items: center;
	gap: 4px;

	svg {
		flex-shrink: 0;
	}
`

export const VerifiedReviewTooltipContent = styled.div`
	text-align: center;
`

export const ReviewDate = styled.div`
	${NotinoBodyMedium};
	white-space: nowrap;
`

export const ReviewsButtonContainer = styled.div`
	display: flex;
	justify-content: center;

	margin-top: 24px;

	@media (min-width: ${BREAKPOINTS.sm}) {
		margin-top: 30px;
	}
`

export const Divider = styled.hr`
	width: 100%;
	border: 0;
	border-bottom: 1px solid ${theme.color.border.divider};
	margin: 40px 0 20px 0;
`

export const ReviewResponse = styled.div`
	border-left: 1px solid ${theme.color.common.gray80};
	padding-left: 16px;
	margin-top: 8px;
`

export const ReviewResponseInfo = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	gap: 10px;
	margin-bottom: 4px;
`

export const SalonName = styled.div`
	${NotinoLabelSmall};
	color: ${theme.color.text.secondary};
	display: flex;
	align-items: center;
	gap: 4px;
	min-height: 1.5rem;
`

export const SalonLogo = styled.img`
	width: 1.5rem !important;
	height: 1.5rem;
	object-fit: contain;
`
export const ReviewResponseDate = styled.div`
	${NotinoBodyMedium};
	white-space: nowrap;
	min-height: 1.5rem;
	display: flex;
	align-items: center;
`

export const ResponseMessage = styled.div`
	${NotinoBodyMedium};
`
