import { Ratings } from '@notino/react-styleguide'
import React from 'react'
import useMessages from '../../../../hooks/useMessages'
import { OpeningHoursData, Ratings as RatingsData } from '../../../../types/types'
import * as SC from './ProfiSalonHeaderStyles'
import { getViewOnMapEvent } from '../../../../utils/dataLayerEvents'
import { pushToDataLayer } from '../../../../utils/dataLayer'

type Props = {
	ratingId: string
	ratingsData?: RatingsData
	name?: string
	address?: string
	logoUrl?: string
	openingHoursData?: OpeningHoursData
	googleMapLink?: string
}

const ProfiSalonHeader = (props: Props) => {
	const { ratingId, ratingsData, name, address, googleMapLink, logoUrl, openingHoursData } = props

	const { messages } = useMessages()

	return (
		<SC.Container>
			<SC.NameAndLogoContainer>
				{logoUrl && (
					<SC.LogoWrapper>
						<SC.Logo loading='lazy' alt={'logo'} src={logoUrl} />
					</SC.LogoWrapper>
				)}
				{name && <SC.Name>{name}</SC.Name>}
			</SC.NameAndLogoContainer>
			<SC.AdditionalInfoContainer>
				{ratingsData && ratingsData.ratesCount > 0 && (
					<SC.RatingsContainer>
						<Ratings
							size={14} // star size 13px
							foreground={'background.highlight'}
							rating={ratingsData.rating}
							numReviews={ratingsData.ratesCount}
							ratingId={ratingId}
						/>
					</SC.RatingsContainer>
				)}
				{openingHoursData && (
					<SC.OpeningStatus>
						<SC.OpeningStatusValue $isOpen={Boolean(openingHoursData.isOpen)}>
							{openingHoursData.isOpen ? messages.Open : messages.Close}
						</SC.OpeningStatusValue>
						{openingHoursData.message && `, ${openingHoursData.message}`}
					</SC.OpeningStatus>
				)}
				{address && (
					<SC.Address>
						{address}
						{googleMapLink && (
							<SC.ShowOnMapLink
								href={googleMapLink}
								target={'_blank'}
								rel={'noopener noreferrer'}
								onClick={() => {
									const event = getViewOnMapEvent({ buttonType: 'text_header' })
									pushToDataLayer(event)
								}}
							>
								{messages['Show on map']}
							</SC.ShowOnMapLink>
						)}
					</SC.Address>
				)}
			</SC.AdditionalInfoContainer>
		</SC.Container>
	)
}

export default ProfiSalonHeader
