import styled, { keyframes } from 'styled-components'

const translateBackground = keyframes`
    from {
        background-position: 100% 0;
    }
    to {
        background-position: 0 0;
    }
`

export const PlaceholderWrapper = styled.div`
	width: 100%;
	border: 1px solid transparent;
`

const PlaceholderBase = styled.div`
	border-radius: 4px;

	background-image: linear-gradient(
		90deg,
		rgb(245, 245, 245),
		rgb(245, 245, 245),
		rgb(245, 245, 245),
		rgb(245, 245, 245),
		rgb(230, 230, 230),
		rgb(245, 245, 245),
		rgb(245, 245, 245),
		rgb(245, 245, 245),
		rgb(245, 245, 245)
	);
	background-size: 300%;
	animation: 2.5s linear 0s infinite normal none running ${translateBackground};
`

export const PlaceholderImage = styled(PlaceholderBase)`
	aspect-ratio: 2;
	margin-bottom: 8px;
`

export const PlaceholderReview = styled(PlaceholderBase)`
	width: 40%;
	height: 1.25rem;
	margin-bottom: 2px;
`

export const PlaceholderTitle = styled(PlaceholderBase)`
	width: 100%;
	height: 1.25rem;
	margin-bottom: 4px;
`

export const PlaceholderAddress = styled(PlaceholderBase)`
	width: 100%;
	height: 1.25rem;
`

export const PlaceholderContent = styled.div`
	min-height: 72px;
`
