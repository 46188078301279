import styled from 'styled-components'
import { theme } from '@notino/react-styleguide'
import { NotinoBodyMedium, NotinoBodySmall, truncate } from '../../styles/helpers'
import { SALON_DETAIL } from '../../styles/constants'

/**
 * When changing the styles here, especially the ones that affects the size of the component, update SalonDetailPlaceholder as well.
 * We need to keep the same size for both components to prevent Layout Shift.
 * @see src/components/SalonDetailPlaceholder/SalonDetailPlaceholderStyles.tsx
 */

export const SalonDetailItem = styled.a`
	color: inherit;
	text-decoration: none;
	position: relative; // relative for absolute rating
	cursor: pointer;
	min-width: 0; // white-space: no-wrap on categories breaks grid layout, this fixes it

	&:hover h2 {
		text-decoration: underline;
	}
`

export const TextContentWrapper = styled.div`
	min-height: ${SALON_DETAIL.TEXT_CONTENT_HEIGHT}px;
`

export const Rating = styled.div`
	display: flex;
	align-items: center;
	gap: 4px;
	color: ${theme.color.text.highlight};
	font-size: ${theme.typography.bodySmall};
	margin-bottom: 2px;
`

export const Title = styled.h2`
	font-size: ${theme.typography.bodyLarge};
	font-weight: 500;
	margin-top: 0; // resetting global notino margin on heading
	margin-bottom: 4px;
	line-height: 20px; // overriding value from theme.typography.bodyLarge to match Figma
	${truncate};
`

export const ImageWrapper = styled.div`
	position: relative;
	aspect-ratio: ${SALON_DETAIL.IMAGE_ASPECT_RATIO};
	margin-bottom: ${SALON_DETAIL.GAP}px;

	& img {
		display: block;
		height: 100%;
		object-fit: cover;
	}
`

export const AvailableRSBadge = styled.div`
	position: absolute;
	background-color: ${theme.color.background.primary};
	border-radius: 20px;
	display: inline-flex;
	gap: 4px;
	padding: 6px 8px;
	${NotinoBodySmall};
	color: ${theme.color.text.primary};
	left: 12px;
	bottom: 12px;
	align-items: center;

	svg {
		width: 16px;
		height: 16px;
		flex-shrink: 0;
	}
`

export const Description = styled.p`
	margin-bottom: 0;
`

export const Address = styled.span`
	${truncate};
	${NotinoBodyMedium};
	display: block;
`

export const OpeningHours = styled.span<{ $isOpen: boolean }>`
	display: flex;
	gap: 4px;
	margin-bottom: 4px;
	${NotinoBodyMedium};
	${truncate};
	color: ${theme.color.text.tertiary};

	& > svg {
		flex-shrink: 0;
		margin-top: 3px;
		color: ${(props) => (props.$isOpen ? theme.color.icon.positive : theme.color.icon.negative)};
		width: 12px;
		height: 12px;
	}
`

export const OpeningHoursText = styled.span`
	${truncate};
`

export const OpeningHoursStatus = styled.span<{ $isOpen: boolean }>`
	color: ${(props) => (props.$isOpen ? theme.color.text.positive : theme.color.text.negative)};
`

export const LogoWrapper = styled.div`
	height: 40px;
	width: 40px;

	float: right;
	margin-left: 8px;

	& > img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
`
