import React from 'react'
import { theme } from '@notino/react-styleguide'

// utils
import { ItemStyles } from '@smastrom/react-rating'

// types
import { RatingFormFieldProps } from './types'

// styles
import * as SC from './RatingFormFIeldStyles'

// components
import FormItem from '../../atoms/FormItem/FormItem'

const FILLED_COLOR = theme.color.icon.highlight
const EMPTY_COLOR = theme.color.background.tertiary
const WIDTH = '160px'

const Star = (
	<svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M7.58794 1.60327C7.39445 1.02115 6.60724 1.02116 6.41375 1.60327L5.26772 5.05114L1.77796 5.12551C1.18877 5.13807 0.945519 5.92102 1.41512 6.29334L4.19659 8.49861L3.18583 11.9924C3.01518 12.5823 3.65206 13.0662 4.13577 12.7142L7.00084 10.6293L9.86592 12.7142C10.3496 13.0662 10.9865 12.5823 10.8159 11.9924L9.8051 8.49861L12.5866 6.29334C13.0562 5.92102 12.8129 5.13807 12.2237 5.12551L8.73397 5.05114L7.58794 1.60327Z'
			fill='currentColor'
		/>
	</svg>
)

const RatingFormField = (props: RatingFormFieldProps) => {
	const {
		input: { value, onChange },
		meta: { error, invalid },
		label,
		htmlFor,
		required,
		readOnly,
		width = WIDTH
	} = props

	const invalidStatus = !!(error || invalid)

	const roundedValue = value ? Math.round(value) : 0

	const itemStyle: ItemStyles = {
		itemShapes: Star
	}

	return (
		<FormItem htmlFor={htmlFor} label={label} required={required} error={invalidStatus && error ? error : undefined}>
			<SC.RatingWrapper>
				<SC.Rate
					$width={width}
					$filledColor={FILLED_COLOR}
					$backgroundColor={EMPTY_COLOR}
					value={roundedValue}
					spaceBetween={'small'}
					spaceInside={'none'}
					onChange={onChange}
					itemStyles={itemStyle}
					readOnly={readOnly}
				/>
				<SC.RatingLabel>{roundedValue.toFixed(1)}</SC.RatingLabel>
			</SC.RatingWrapper>
		</FormItem>
	)
}

export default RatingFormField
