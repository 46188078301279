/* eslint-disable global-require */
import React, { useContext, useEffect } from 'react'
import { Head } from '@notino/react-toolkit/exports/Head'
import { useIntl } from 'react-intl'

// types
import { SalonDetail } from '../../../../types/types'

// utils
import { shopsConfig } from '../../../../appDefaults'
import { PAGE_LINKS, truncateString } from '../../../../utils/helper'
import { AppContext } from '../../../../utils/appProvider'
import { ENVIRONMENTS } from '../../../../utils/enums'

type Props = {
	salonSlug: string
	salon: SalonDetail
	salonServices: string[]
}

const SalonHead = (props: Props) => {
	const { salonSlug, salon, salonServices } = props
	const { locale, formatMessage } = useIntl()
	const { assetsPath } = useContext(AppContext)

	const currentShop = shopsConfig.find((shop) => shop.locale === locale)

	const pageTitle = formatMessage({ id: 'SEO - Salon detail page - meta title' }, { salonName: salon.name, salonCity: salon.address?.city ?? '' })
	const description = formatMessage({ id: 'SEO - Salon detail page - meta description' }, { salonAboutUs: truncateString(salon.aboutUsFirst) })
	const keywords = formatMessage({ id: 'SEO - Salon detail page - meta keywords' }, { salonServices: salonServices.join(', ') })

	const ogTitle = formatMessage({ id: 'SEO - Salon detail page - meta og title' }, { salonName: salon.name, salonCity: salon.address?.city ?? '' })
	const ogDescription = formatMessage({ id: 'SEO - Salon detail page - meta og description' }, { salonAboutUs: truncateString(salon.aboutUsFirst) })

	const salonImage = salon.images[0]?.resizedImages.small || `${assetsPath}/${require('../../../../assets/images/basic-salon-placeholder-small.jpg')}`

	// use effect needed to see title in local development
	useEffect(() => {
		if (process.env.NODE_ENV === ENVIRONMENTS.development) {
			document.title = pageTitle
		}
	}, [pageTitle])

	// NOTE: defining children as array and using .flat() on it otherwise <Head> doesn't work properly
	// SIDENOTE: each item has to have unique 'key' because <Head/> iterates over the array
	const children = [
		// charset
		<meta key='charset' charSet='utf-8' />,

		// title
		<title key='title'>{pageTitle}</title>,

		// description
		<meta key='description' name='description' content={description} />,

		// keywords
		salonServices?.length > 0 && <meta key='keywords' name='keywords' content={keywords} />,

		// canonical and alternate
		currentShop && <link key='canonical' rel='canonical' href={`${currentShop?.url}${PAGE_LINKS['/salons/services/:categorySlug'](locale, salonSlug)}`} />,
		shopsConfig.map((shop, id) => (
			<link
				key={`alternate-${id}`}
				rel='alternate'
				href={`${shop?.url}${PAGE_LINKS['/salons/services/:categorySlug'](shop.locale, salonSlug)}`}
				hrefLang={shop.lang}
			/>
		)),
		<link
			key='x-default'
			rel='alternate'
			href={`${shopsConfig[0].url}${PAGE_LINKS['/salons/services/:categorySlug'](locale, salonSlug)}`}
			hrefLang='x-default'
		/>,

		// OG tags (social networks)
		<meta key='og:title' property='og:title' content={ogTitle} />,
		<meta key='og:description' property='og:description' content={ogDescription} />,
		<meta key='og:url' property='og:url' content={`${currentShop?.url}${PAGE_LINKS['/salons/services/:categorySlug'](locale, salonSlug)}`} />,
		<meta key='og:image' property='og:image' content={salonImage} />
	].flat()

	return <Head>{children}</Head>
}

export default SalonHead
