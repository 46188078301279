import styled from 'styled-components'
import { theme } from '@notino/react-styleguide'
import { NotinoBodyMedium, truncate } from '../../../../styles/helpers'

export const Title = styled.h3`
	font-size: ${theme.typography.bodyLarge};
	font-weight: 500;
	margin-top: 0; // resetting global notino margin on heading
	margin-bottom: 4px;
	line-height: 1.25rem; // overriding value from theme.typography.bodyLarge to match Figma
	${truncate};
`

export const SalonDetailItem = styled.a`
	display: block;
	color: inherit;
	text-decoration: none;
	cursor: pointer;
	min-width: 0; // white-space: no-wrap on categories breaks grid layout, this fixes it
	border: 1px solid transparent;
	height: 100%;
	transition: border-color 0.3s ease;

	&:hover ${Title} {
		text-decoration: underline;
	}

	&:focus,
	&:focus-visible {
		outline: none;
	}

	&:focus-visible {
		border-color: ${theme.color.common.black};
	}
`

export const MainContent = styled.div`
	min-height: 72px;
`

export const Rating = styled.div`
	display: flex;
	align-items: center;
	gap: 4px;
	color: ${theme.color.text.highlight};
	font-size: ${theme.typography.bodySmall};
	margin-bottom: 2px;
`

export const ImageWrapper = styled.div`
	margin-bottom: 8px;
	aspect-ratio: 2;
	position: relative;

	& img {
		height: 100%;
		object-fit: cover;
	}
`

export const Address = styled.span`
	display: block;
	${NotinoBodyMedium};
	${truncate};
`

export const LogoWrapper = styled.div`
	height: 40px;
	width: 40px;
	float: right;
	margin-left: 8px;

	& > img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
`
