import React, { ComponentProps, useState } from 'react'

// components
import SalonsAndCitiesSearch from '../../../../components/SalonsAndCitiesSearch/SalonsAndCitiesSearch'

// types
import { SalonsAndCitiesSearchValue } from '../../../../types/types'

// utils
import { getSalonSelectedFromSearchEvent, getSearchFocusedEvent } from '../../../../utils/dataLayerEvents'
import { SEARCH_FIELD_OPTION_TYPE } from '../../../../utils/enums'
import { pushToDataLayer } from '../../../../utils/dataLayer'

// styles
import * as SC from './SalonsSearchWrapStyles'
import { SALONS_PAGE_MOBILE_BREAKPOINT_INT } from '../../../../styles/constants'

// hooks
import useMessages from '../../../../hooks/useMessages'
import useIsMobile from '../../../../hooks/useIsMobile'

// search for the salons only
const SalonsSearchWrap = () => {
	const { messages } = useMessages()

	const [searchValue, setSearchValue] = useState<SalonsAndCitiesSearchValue>(null)

	const isMobile = useIsMobile(SALONS_PAGE_MOBILE_BREAKPOINT_INT)

	const handleChange: ComponentProps<typeof SalonsAndCitiesSearch>['onChange'] = (value) => {
		if (value?.extra?.type === SEARCH_FIELD_OPTION_TYPE.SALON) {
			const event = getSalonSelectedFromSearchEvent({ inputValue: value?.value ?? '' })
			pushToDataLayer(event)
		}
		setSearchValue(value)
	}

	const onDropdownVisibleChange: ComponentProps<typeof SalonsAndCitiesSearch>['onDropdownVisibleChange'] = (open: boolean) => {
		if (open) {
			const event = getSearchFocusedEvent({ isMobile })
			pushToDataLayer(event)
		}
	}

	return (
		<SC.SalonsSearchWrapper>
			<SalonsAndCitiesSearch
				value={searchValue}
				limits={{ limitSalons: 8, limitCategories: 0, limitCities: 0 }}
				placeholder={`${messages['Find your salon']}...`}
				emptyContent={{
					emptyTitle: messages['Search salon'],
					emptyLabel: messages['Enter the name of the salon you are looking for']
				}}
				onChange={handleChange}
				onDropdownVisibleChange={onDropdownVisibleChange}
			/>
		</SC.SalonsSearchWrapper>
	)
}

export default SalonsSearchWrap
