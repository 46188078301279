import styled, { keyframes } from 'styled-components'
import RcSlider from 'rc-slider'
import { theme } from '@notino/react-styleguide'

const rcSliderTooltipZoomDownIn = keyframes`
	0% {
		transform: scale(0, 0);
		transform-origin: 50% 100%;
		opacity: 0;
	}
	100% {
		transform: scale(1, 1);
		transform-origin: 50% 100%;
	}
`

const rcSliderTooltipZoomDownOut = keyframes`
	0% {
		transform: scale(1, 1);
		transform-origin: 50% 100%;
	}
	100% {
		transform: scale(0, 0);
		transform-origin: 50% 100%;
		opacity: 0;
	}
`
export const Slider = styled(RcSlider)`
	&.rc-slider {
		position: relative;
		width: calc(100% - 20px);
		height: 20px;
		padding: 8px 0;
		margin: 10px;
		touch-action: none;
		box-sizing: border-box;
		-webkit-tap-highlight-color: ${theme.color.common.black};

		* {
			box-sizing: border-box;
			-webkit-tap-highlight-color: ${theme.color.common.black};
		}

		.rc-slider-rail {
			position: absolute;
			height: 4px;
			background-color: #e6e6e6;
			left: -10px;
			right: -10px;
			border-radius: 6px;
		}

		.rc-slider-track,
		.rc-slider-tracks {
			position: absolute;
			height: 4px;
			background-color: ${theme.color.background.inverse};
			border-radius: 6px;
		}

		.rc-slider-track-draggable {
			z-index: 1;
			box-sizing: content-box;
			background-clip: content-box;
			border-top: 5px solid ${theme.color.common.black};
			border-bottom: 5px solid ${theme.color.common.black};
			transform: translateY(-8px);
		}

		.rc-slider-handle {
			position: absolute;
			z-index: 1;
			width: 20px;
			height: 20px;
			margin-top: -8px;
			background-color: ${theme.color.background.inverse};
			border-radius: 50%;
			cursor: grab;
			touch-action: pan-x;

			&:hover {
				border-color: #57c5f7;
			}

			&:focus {
				outline: none;
				box-shadow: none;
			}

			&:focus-visible {
				box-shadow: 0 0 0 2px ${theme.color.common.black};
			}

			&:active {
				cursor: grabbing;
			}
		}

		.rc-slider-mark {
			position: absolute;
			top: 18px;
			left: 0;
			width: 100%;
			font-size: 12px;
		}

		.rc-slider-mark-text {
			position: absolute;
			display: inline-block;
			color: #999;
			text-align: center;
			vertical-align: middle;
			cursor: pointer;
		}

		.rc-slider-mark-text-active {
			color: #666;
		}

		.rc-slider-step {
			position: absolute;
			width: 100%;
			height: 4px;
			background: transparent;
		}

		.rc-slider-dot {
			position: absolute;
			bottom: -2px;
			width: 20px;
			height: 20px;
			vertical-align: middle;
			background-color: ${theme.color.background.inverse};
			border-radius: 50%;
			cursor: pointer;
		}

		.rc-slider-dot-active {
			border-color: #96dbfa;
		}

		.rc-slider-dot-reverse {
			margin-right: -4px;
		}

		.rc-slider-handle-click-focused:focus {
			border-color: #96dbfa;
			box-shadow: unset;
		}

		// disabled styles

		&.rc-slider-disabled {
			.rc-slider-rail {
				background-color: #e9e9e9;
			}

			.rc-slider-track {
				background-color: #ccc;
			}

			.rc-slider-handle,
			.rc-slider-dot {
				background-color: ${theme.color.common.gray70};
				border-color: ${theme.color.border.default};
				box-shadow: none;
				cursor: not-allowed;
			}

			.rc-slider-mark-text,
			.rc-slider-dot {
				cursor: not-allowed !important;
			}
		}

		// vertical styles

		&.rc-slider-vertical {
			width: 14px;
			height: 100%;
			padding: 0 5px;

			.rc-slider-rail {
				width: 4px;
				height: 100%;
			}

			.rc-slider-track {
				bottom: 0;
				left: 5px;
				width: 4px;
			}

			.rc-slider-track-draggable {
				border-top: 0;
				border-bottom: 0;
				border-right: 5px solid rgba(0, 0, 0, 0);
				border-left: 5px solid rgba(0, 0, 0, 0);
				transform: translateX(-8px);
			}

			.rc-slider-handle {
				position: absolute;
				z-index: 1;
				margin-top: 0;
				margin-left: -8px;
				touch-action: pan-y;
			}

			.rc-slider-mark {
				top: 0;
				left: 18px;
				height: 100%;
			}

			.rc-slider-step {
				width: 4px;
				height: 100%;
			}

			.rc-slider-dot {
				margin-left: -2px;
			}
		}
	}

	// tooltip styles

	.rc-slider-tooltip-zoom-down-enter,
	.rc-slider-tooltip-zoom-down-appear {
		display: block !important;
		animation-duration: 0.3s;
		animation-fill-mode: both;
		animation-play-state: paused;
	}

	.rc-slider-tooltip-zoom-down-leave {
		display: block !important;
		animation-duration: 0.3s;
		animation-fill-mode: both;
		animation-play-state: paused;
	}

	.rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active,
	.rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active {
		animation-name: ${rcSliderTooltipZoomDownIn};
		animation-play-state: running;
	}

	.rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
		animation-name: ${rcSliderTooltipZoomDownOut};
		animation-play-state: running;
	}

	.rc-slider-tooltip-zoom-down-enter,
	.rc-slider-tooltip-zoom-down-appear {
		transform: scale(0, 0);
		animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
	}

	.rc-slider-tooltip-zoom-down-leave {
		animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
	}

	.rc-slider-tooltip {
		position: absolute;
		top: -9999px;
		left: -9999px;
		visibility: visible;
		box-sizing: border-box;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	}

	.rc-slider-tooltip * {
		box-sizing: border-box;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	}

	.rc-slider-tooltip-hidden {
		display: none;
	}

	.rc-slider-tooltip-placement-top {
		padding: 4px 0 8px 0;
	}

	.rc-slider-tooltip-inner {
		min-width: 24px;
		height: 24px;
		padding: 6px 2px;
		color: ${theme.color.text.inverse};
		font-size: 12px;
		line-height: 1;
		text-align: center;
		text-decoration: none;
		background-color: #6c6c6c;
		border-radius: 6px;
		box-shadow: 0 0 4px #d9d9d9;
	}

	.rc-slider-tooltip-arrow {
		position: absolute;
		width: 0;
		height: 0;
		border-color: transparent;
		border-style: solid;
	}

	.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
		bottom: 4px;
		left: 50%;
		margin-left: -4px;
		border-width: 4px 4px 0;
		border-top-color: #6c6c6c;
	}
`
