import React from 'react'

import * as SC from './CheckboxGroupCustomFormFieldStyles'
import { CheckboxGroupCustomFormFieldProps } from './types'
import { BaseCheckboxGroupCustomOption, CheckboxGroupCustomValueType } from '../../types/types'

const defaultOptionRender = <OptionPropsType extends BaseCheckboxGroupCustomOption = BaseCheckboxGroupCustomOption>(
	option: OptionPropsType,
	isChecked: boolean
) => {
	return <SC.CheckWrapper $checked={isChecked}>{option.label}</SC.CheckWrapper>
}

const CheckboxGroupCustomFormField = <
	ValuePropsType extends CheckboxGroupCustomValueType = string[],
	OptionPropsType extends BaseCheckboxGroupCustomOption = BaseCheckboxGroupCustomOption
>(
	props: CheckboxGroupCustomFormFieldProps<ValuePropsType, OptionPropsType>
) => {
	const {
		options,
		input: { value, name, onChange },
		singleSelect,
		optionRender,
		afterChange,
		alignment,
		disabled,
		allowUnselect = true,
		optionAsValue = false,
		gap = '8px'
	} = props

	return (
		<SC.CheckGroup $alignment={alignment} $gap={gap}>
			{options.map((option) => {
				const isChecked = !!value.find((v) => {
					if (typeof v === 'object') {
						return v.value === option.value
					}
					return v === option.value
				})
				const isDisabled = !!(option.disabled || disabled)

				return (
					<SC.CheckboxWrapper key={option.key}>
						<SC.Checkbox
							type={'checkbox'}
							id={String(option.value)}
							name={name}
							value={option.value}
							checked={isChecked}
							onChange={() => {
								if (isDisabled) {
									return
								}

								let newValue: ValuePropsType = value

								if (
									!allowUnselect &&
									isChecked &&
									value.length === 1 &&
									(typeof value[0] === 'object' ? value[0].value === option.value : value[0] === option.value)
								) {
									return
								}

								if (isChecked) {
									newValue = (
										Array.isArray(value)
											? value.filter((v) => {
													if (typeof v === 'object') {
														return v.value !== option.value
													}
													return v !== option.value
												})
											: value
									) as ValuePropsType
								} else {
									newValue = (
										singleSelect ? [optionAsValue ? option : option.value] : [...(value || []), optionAsValue ? option : option.value]
									) as ValuePropsType
								}

								onChange(newValue)

								if (afterChange) {
									afterChange(newValue, option)
								}
							}}
						/>
						<SC.Label $checked={isChecked} key={option.value} htmlFor={String(option.value)}>
							{optionRender ? optionRender(option, isChecked, isDisabled) : defaultOptionRender(option, isChecked)}
						</SC.Label>
					</SC.CheckboxWrapper>
				)
			})}
		</SC.CheckGroup>
	)
}

export default CheckboxGroupCustomFormField
