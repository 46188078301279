import styled from 'styled-components'
import { theme } from '@notino/react-styleguide'
import { SALONS_PAGE_MOBILE_BREAKPOINT } from '../../styles/constants'
import { NotinoBodySmall, NotinoLabelRegular, NotinoLabelSmall, resetLinkStyles } from '../../styles/helpers'
import SalonImage from '../SalonImage/SalonImage'

export const GoogleMapContainer = styled.div<{ $mapPadding: number }>`
	aspect-ratio: 16/9;
	position: relative;

	@media (max-width: ${SALONS_PAGE_MOBILE_BREAKPOINT}) {
		aspect-ratio: 0.7;
	}
`

export const SalonDetailWrapper = styled.div`
	max-width: 300px;
`

export const ButtonAndCardsContainer = styled.div<{ $mapPadding: number }>`
	position: absolute;
	bottom: 26px; // little higher than design so the Google logo (and links) are visible
	left: 0;
	right: 0;
`

export const CardsContainer = styled.div<{ $mapPadding: number }>`
	display: flex;
	gap: 12px;
	overflow-x: auto;
	scroll-behavior: smooth;
	padding-left: ${(p) => p.$mapPadding}px;
	padding-right: ${(p) => p.$mapPadding}px;
	margin-top: ${(p) => p.$mapPadding}px;
`

export const SalonCard = styled.a<{ $isSelected: boolean }>`
	${resetLinkStyles};
	background-color: ${theme.color.background.primary};
	display: flex;
	align-items: center;
	gap: 12px;
	flex: 0;
	padding: 12px;
	border-bottom: 2px solid ${(p) => (p.$isSelected ? theme.color.common.magenta50 : 'transparent')};
`

export const SalonCardImageWrapper = styled.div`
	width: 60px;
	height: 60px;
	position: relative;
	flex-shrink: 0;
`

export const SalonCardImage = styled(SalonImage)`
	position: absolute;
	inset: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	display: block;
`

export const SalonCardTextContent = styled.div`
	align-self: flex-start;
`

export const SalonCardSalonName = styled.div`
	${NotinoLabelRegular};
	white-space: nowrap;
	margin-bottom: 2px;
`

export const SalonCardDescription = styled.div`
	${NotinoBodySmall};
	white-space: nowrap;
	color: ${theme.color.text.tertiary};
`

export const Rating = styled.div`
	display: flex;
	align-items: center;
	gap: 2px;
`

export const RatingValue = styled.span`
	${NotinoLabelSmall};
	color: ${theme.color.text.highlight};
`

export const ToggleListButtonMobile = styled.button<{ $mapPadding: number; $bottom: number }>`
	${theme.typography.bodyRegular};
	color: white;
	background: ${theme.color.background.highlight};
	padding: 12px 12px;
	border-radius: 1000px;
	border: none;
	z-index: 10;
	cursor: pointer;
	display: flex;
	align-items: center;
	gap: 8px;
	line-height: 0; // overriding notino styles so the icon is square
	position: absolute;
	right: ${($p) => $p.$mapPadding}px;
	bottom: ${($p) => $p.$bottom}px;

	@media (min-width: ${SALONS_PAGE_MOBILE_BREAKPOINT}) {
		display: none;
	}
`
