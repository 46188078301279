import styled, { css } from 'styled-components'
import { theme } from '@notino/react-styleguide'
import { NotinoLabelSmall400, NotinoTitleDisplay, NotinoTitleExtraExtraLarge, truncate } from '../../../../styles/helpers'
import { BREAKPOINTS, MAX_WIDTHS } from '../../../../styles/constants'
import { HERO_CATEGORIES_COUNT_DESKTOP, HERO_CATEGORIES_COUNT_MOBILE } from '../../utils'

export const HeroSection = styled.section<{ $mobileBg: string; $desktopBg: string }>`
	width: 100%;
	min-height: 372px;
	background-size: cover;
	background-position: center;
	background-image: ${({ $mobileBg }) => `url("${$mobileBg}")`};
	display: flex;
	align-items: center;

	@media (min-width: ${BREAKPOINTS.lg}) {
		background-image: ${({ $desktopBg }) => `url("${$desktopBg}")`};
		min-height: 456px;
	}
`

export const HeroContainer = styled.div`
	width: 100%;
	max-width: ${MAX_WIDTHS.PAGE_DEFAULT}px;
	margin: 0 auto;
	padding: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding: 40px 16px;
	gap: 16px;

	@media (min-width: ${BREAKPOINTS.lg}) {
		padding: 60px 24px 100px 24px;
		gap: 24px;
	}
`

export const HeroMainHeading = styled.h1`
	margin: 0 !important;
	color: ${theme.color.text.primary};
	${NotinoTitleDisplay};
	font-weight: 600;
	max-width: 750px;
	word-break: break-word;

	@media (min-width: ${BREAKPOINTS.lg}) {
		${NotinoTitleExtraExtraLarge}
		font-weight: 600;
	}
`

export const HeroDescription = styled.p`
	margin: 0;
	color: ${theme.color.text.secondary};
	font-size: 1rem;
	line-height: 1.5rem;
	max-width: 750px;
	word-break: break-word;
`

export const CategoryCard = styled.a<{ $hideOnMobile?: boolean; $hideOnDesktop?: boolean }>`
	display: inline-flex;
	align-items: center;
	gap: 4px;
	padding: 4px 8px;
	overflow: hidden;
	text-decoration: none;
	color: ${theme.color.text.primary};
	max-width: 300px;
	background: ${theme.color.background.primary};
	border-radius: 4px;

	${({ $hideOnMobile, $hideOnDesktop }) => {
		if ($hideOnMobile) {
			return css`
				display: none;
			`
		}

		if ($hideOnDesktop) {
			return css`
				@media (min-width: ${BREAKPOINTS.sm}) {
					display: none;
				}
			`
		}

		return null
	}}
`

export const HeroCategoriesContainer = styled.div`
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	gap: 12px;
	max-width: 750px;

	${Array.from({ length: HERO_CATEGORIES_COUNT_DESKTOP - HERO_CATEGORIES_COUNT_MOBILE }, (_, i) => {
		const index = HERO_CATEGORIES_COUNT_MOBILE + i

		return css`
			& > ${CategoryCard}:nth-of-type(${index}) {
				display: none;

				@media (min-width: ${BREAKPOINTS.sm}) {
					display: flex;
				}
			}
		`
	})}

	@media (min-width: ${BREAKPOINTS.lg}) {
		gap: 16px;
		max-width: 850px;
	}
`

export const CategoryImage = styled.img`
	flex-shrink: 0;
	width: 20px !important;
	height: 20px;
	overflow: hidden;
	border-radius: 50%;
	object-fit: cover;
`

export const CategoryTitle = styled.span`
	${truncate};
	${NotinoLabelSmall400};
`
