import React, { ComponentProps } from 'react'
import { IconRegularClose, IconSolidInfo } from '@notino/react-styleguide'

// styles
import * as SC from './InfoLineStyles'

// types
import { INFO_LINE_TYPE, InfoLineProps } from './types'

const getIconColor = (infoLine: INFO_LINE_TYPE): ComponentProps<typeof IconSolidInfo>['color'] => {
	switch (infoLine) {
		case INFO_LINE_TYPE.WARNING:
			return 'icon.tertiary'
		case INFO_LINE_TYPE.SUCCESS:
			return 'icon.positive'
		case INFO_LINE_TYPE.ERROR:
			return 'icon.negative'
		case INFO_LINE_TYPE.INFO:
		default:
			return 'icon.primary'
	}
}

const InfoLine = (props: InfoLineProps) => {
	const { type = INFO_LINE_TYPE.INFO, title, message, onClose } = props

	return (
		<SC.InfoLine $type={type}>
			<SC.IconWrapper>
				<IconSolidInfo color={getIconColor(type)} />
			</SC.IconWrapper>
			<SC.ContentWrapper>
				{title && <SC.Title $type={type}>{title}</SC.Title>}
				<SC.Message>{message}</SC.Message>
			</SC.ContentWrapper>
			{onClose && (
				<SC.CloseButton type={'button'} onClick={onClose}>
					<IconRegularClose color={'icon.tertiary'} />
				</SC.CloseButton>
			)}
		</SC.InfoLine>
	)
}

export default InfoLine
