/* eslint-disable global-require */
import React, { useContext } from 'react'
import { useIntl } from 'react-intl'

// components
import StoreDownloadButtons from '../../../../components/StoreDownloadButtons/StoreDownloadButtons'

// utils
import { AppContext } from '../../../../utils/appProvider'

// styles
import * as SC from './AppBenefitsSectionStyles'
import { HomePageContainer } from '../../HomePageStyles'

// hooks
import useMessages from '../../../../hooks/useMessages'

// types
import { CmsHomepageData } from '../../../../types/types'

type Props = {
	cmsData: CmsHomepageData['appFeatureData'] | undefined
}

const getLocaleSpecificAssets = (locale: string, assetsPath: string, cmsData: Props['cmsData']) => {
	let image1: string
	let image2: string
	let image3: string
	let image4: string
	let image5: string

	switch (locale) {
		case 'ro':
			image1 = `${assetsPath}/${require('../../../../assets/images/homepage/app-benefits-1-ro.jpg')}`
			image2 = `${assetsPath}/${require('../../../../assets/images/homepage/app-benefits-2-ro.jpg')}`
			image3 = `${assetsPath}/${require('../../../../assets/images/homepage/app-benefits-3-ro.jpg')}`
			image4 = `${assetsPath}/${require('../../../../assets/images/homepage/app-benefits-4-ro.jpg')}`
			image5 = `${assetsPath}/${require('../../../../assets/images/homepage/app-benefits-5-ro.jpg')}`
			break
		case 'bg':
			image1 = `${assetsPath}/${require('../../../../assets/images/homepage/app-benefits-1-bg.jpg')}`
			image2 = `${assetsPath}/${require('../../../../assets/images/homepage/app-benefits-2-bg.jpg')}`
			image3 = `${assetsPath}/${require('../../../../assets/images/homepage/app-benefits-3-bg.jpg')}`
			image4 = `${assetsPath}/${require('../../../../assets/images/homepage/app-benefits-4-bg.jpg')}`
			image5 = `${assetsPath}/${require('../../../../assets/images/homepage/app-benefits-5-bg.jpg')}`
			break
		case 'hu':
			image1 = `${assetsPath}/${require('../../../../assets/images/homepage/app-benefits-1-hu.jpg')}`
			image2 = `${assetsPath}/${require('../../../../assets/images/homepage/app-benefits-2-hu.jpg')}`
			image3 = `${assetsPath}/${require('../../../../assets/images/homepage/app-benefits-3-hu.jpg')}`
			image4 = `${assetsPath}/${require('../../../../assets/images/homepage/app-benefits-4-hu.jpg')}`
			image5 = `${assetsPath}/${require('../../../../assets/images/homepage/app-benefits-5-hu.jpg')}`
			break
		case 'sk':
			image1 = `${assetsPath}/${require('../../../../assets/images/homepage/app-benefits-1-sk.jpg')}`
			image2 = `${assetsPath}/${require('../../../../assets/images/homepage/app-benefits-2-sk.jpg')}`
			image3 = `${assetsPath}/${require('../../../../assets/images/homepage/app-benefits-3-sk.jpg')}`
			image4 = `${assetsPath}/${require('../../../../assets/images/homepage/app-benefits-4-sk.jpg')}`
			image5 = `${assetsPath}/${require('../../../../assets/images/homepage/app-benefits-5-sk.jpg')}`
			break
		case 'cs':
		default:
			image1 = `${assetsPath}/${require('../../../../assets/images/homepage/app-benefits-1-cs.jpg')}`
			image2 = `${assetsPath}/${require('../../../../assets/images/homepage/app-benefits-2-cs.jpg')}`
			image3 = `${assetsPath}/${require('../../../../assets/images/homepage/app-benefits-3-cs.jpg')}`
			image4 = `${assetsPath}/${require('../../../../assets/images/homepage/app-benefits-4-cs.jpg')}`
			image5 = `${assetsPath}/${require('../../../../assets/images/homepage/app-benefits-5-cs.jpg')}`
			break
	}

	if (cmsData) {
		if (cmsData?.image1.desktop) {
			image1 = cmsData.image1.desktop
		}

		if (cmsData?.image2.desktop) {
			image2 = cmsData.image2.desktop
		}

		if (cmsData?.image3.desktop) {
			image3 = cmsData.image3.desktop
		}

		if (cmsData?.image4.desktop) {
			image4 = cmsData.image4.desktop
		}

		if (cmsData?.image5.desktop) {
			image5 = cmsData.image5.desktop
		}
	}
	return { image1, image2, image3, image4, image5 }
}

const getImage = (image: string, link?: string, altText?: string) => {
	const imageComponent = <img src={image} alt={altText} loading='lazy' />

	return (
		<SC.ImageContainer>
			{link ? (
				<SC.ImageLink href={link} target={'_blank'} rel={'noopener noreferrer'}>
					{imageComponent}
				</SC.ImageLink>
			) : (
				imageComponent
			)}
		</SC.ImageContainer>
	)
}

// component
const AppBenefitsSection = (props: Props) => {
	const { cmsData } = props

	const { assetsPath } = useContext(AppContext)

	const { locale } = useIntl()
	const { messages } = useMessages()

	const qrCode = `${assetsPath}/${require('../../../../assets/images/app-qr-code.jpg')}`

	const title = cmsData?.title || messages['app-benefits-title']
	const descriptionDesktop = cmsData?.subtitle.desktop || messages['app-benefits-description-desktop']
	const descriptionMobile = cmsData?.subtitle.mobile || messages['app-benefits-description-mobile']

	const { image1, image2, image3, image4, image5 } = getLocaleSpecificAssets(locale, assetsPath, cmsData)

	const image1Alt = cmsData?.image1?.alt || messages['app-benefits-image-1-alt-text']
	const image2Alt = cmsData?.image2?.alt || messages['app-benefits-image-2-alt-text']
	const image3Alt = cmsData?.image3?.alt || messages['app-benefits-image-3-alt-text']
	const image4Alt = cmsData?.image4?.alt || messages['app-benefits-image-4-alt-text']
	const image5Alt = cmsData?.image5?.alt || messages['app-benefits-image-5-alt-text']

	const image1Link = cmsData?.image1?.link
	const image2Link = cmsData?.image2?.link
	const image3Link = cmsData?.image3?.link
	const image4Link = cmsData?.image4?.link
	const image5Link = cmsData?.image5?.link

	return (
		<section>
			<HomePageContainer>
				<SC.InfoContainer>
					<SC.SectionTitle $marginBottom={'16px'} $textAlign={'center'}>
						{title}
					</SC.SectionTitle>
					<SC.SectionDescriptionDesktop $marginBottom={'16px'} $textAlign={'center'}>
						{descriptionDesktop}
					</SC.SectionDescriptionDesktop>
					<SC.SectionDescriptionMobile $marginBottom={'16px'} $textAlign={'center'}>
						{descriptionMobile}
					</SC.SectionDescriptionMobile>
					<SC.DownloadsSectionWrapper>
						<SC.QRCodeImageWrapper>
							<img src={qrCode} width={86} height={86} alt={messages['Download Notino app via QR code']} loading='lazy' />
						</SC.QRCodeImageWrapper>
						<SC.StoreDownloadButtonsWrapper>
							<StoreDownloadButtons />
						</SC.StoreDownloadButtonsWrapper>
					</SC.DownloadsSectionWrapper>
				</SC.InfoContainer>
				<SC.Layout>
					{getImage(image1, image1Link, image1Alt)}
					{getImage(image2, image2Link, image2Alt)}
					{getImage(image3, image3Link, image3Alt)}
					{getImage(image4, image4Link, image4Alt)}
					{getImage(image5, image5Link, image5Alt)}
				</SC.Layout>
			</HomePageContainer>
		</section>
	)
}

export default AppBenefitsSection
