import React from 'react'
import { Ratings } from '@notino/react-styleguide'
import * as SC from './SalonRatingsStyles'

// types
import { Ratings as RatingsData } from '../../../../types/types'

type SalonRatingsProps = {
	ratingsData: RatingsData
}

const SalonRatings = (props: SalonRatingsProps) => {
	const { ratingsData: ratings } = props

	const ratingsList = [
		{
			rating: 5,
			count: ratings.groupedRatings.FIVE,
			percentage: (ratings.groupedRatings.FIVE / ratings.ratesCount) * 100 || 0
		},
		{
			rating: 4,
			count: ratings.groupedRatings.FOUR,
			percentage: (ratings.groupedRatings.FOUR / ratings.ratesCount) * 100 || 0
		},
		{
			rating: 3,
			count: ratings.groupedRatings.THREE,
			percentage: (ratings.groupedRatings.THREE / ratings.ratesCount) * 100 || 0
		},
		{
			rating: 2,
			count: ratings.groupedRatings.TWO,
			percentage: (ratings.groupedRatings.TWO / ratings.ratesCount) * 100 || 0
		},
		{
			rating: 1,
			count: ratings.groupedRatings.ONE,
			percentage: (ratings.groupedRatings.ONE / ratings.ratesCount) * 100 || 0
		}
	]

	return (
		<SC.RatingsList>
			{ratingsList.map((ratingObj) => {
				return (
					<SC.RatingWrapper key={ratingObj.rating}>
						<SC.ProgressLineWithCount>
							<SC.ProgressBarContainer>
								<SC.Progress $progress={ratingObj.percentage} />
							</SC.ProgressBarContainer>
							<SC.Count>{ratingObj.count}</SC.Count>
						</SC.ProgressLineWithCount>
						<Ratings
							style={{ flexShrink: 0, display: 'flex' }}
							foreground={'text.highlight'}
							background={'background.primary'}
							rating={ratingObj.rating}
							ratingId={'rating-salon-detail'}
							size={14}
						/>
					</SC.RatingWrapper>
				)
			})}
		</SC.RatingsList>
	)
}

export default SalonRatings
