import styled, { css } from 'styled-components'
import { RadioButton as NotinoRadioButton, theme } from '@notino/react-styleguide'

// utils
import { NotinoLabelRegular400, NotinoLabelRegular500 } from '../../styles/helpers'
import { InputStyle } from './types'

export const RadioButtonsWrapper = styled.fieldset<{ $inputStyle: InputStyle }>`
	display: flex;
	flex-direction: column;
	border: none;

	${({ $inputStyle }) => {
		if ($inputStyle === 'default') {
			return css`
				gap: 12px;
			`
		}

		if ($inputStyle === 'bordered') {
			return css`
				gap: 16px;
			`
		}

		return null
	}}
`

export const RadioButton = styled(NotinoRadioButton)<{ $inputStyle: InputStyle; $checked: boolean }>`
	display: flex;
	align-items: center;

	// checkbox
	& > *:last-child {
		top: 50%;
		transform: translateY(-50%);
		transition: border-color 0.3s ease;
	}

	input[type='radio']:focus ~ span {
		border-color: ${theme.color.common.black};
	}

	${({ $inputStyle, $checked }) => {
		if ($inputStyle === 'default') {
			return css`
				min-height: 36px;
			`
		}

		if ($inputStyle === 'bordered') {
			return css`
				min-height: 64px;
				border: 1px solid ${theme.color.border.divider};
				border-radius: 4px;
				padding: 20px 20px 20px 46px;
				transition: 0.3s ease;

				${$checked &&
				css`
					border-color: ${theme.color.common.black};
				`}

				&:hover {
					border-color: ${theme.color.common.black};
				}

				// checkbox
				& > *:last-child {
					left: 20px;
				}
			`
		}

		return null
	}}
`

export const RadioButtonLabel = styled.span`
	display: inline-flex;
	width: 100%;
	align-items: center;
`

export const RadioButtonLabelTitle = styled.span<{ $inputStyle: InputStyle; $checked: boolean }>`
	${({ $checked, $inputStyle }) =>
		$checked
			? css`
					color: ${theme.color.text.primary};
					${$inputStyle === 'default' &&
					css`
						${NotinoLabelRegular500};
					`}
					${$inputStyle === 'bordered' &&
					css`
						${NotinoLabelRegular400};
					`}
				`
			: css`
					color: ${theme.color.text.secondary};
					${NotinoLabelRegular400}
				`};
`

export const RadioButtonLabelDescription = styled.span`
	margin-left: auto;
	text-align: right;
	color: ${theme.color.text.tertiary};
	${NotinoLabelRegular400};
`
