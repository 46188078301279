import styled from 'styled-components'
import RcSwitch from 'rc-switch'
import { theme } from '@notino/react-styleguide'

export const Switch = styled(RcSwitch)`
	position: relative;
	display: inline-block;
	box-sizing: border-box;
	width: 34px;
	height: 14px;
	line-height: 14px;
	padding: 0;
	vertical-align: middle;
	border-radius: 999px;
	background-color: ${theme.color.common.gray90};
	cursor: pointer;
	transition: all 0.3s ease;
	border: none;
	outline: 2px solid transparent;
	&::after {
		position: absolute;
		width: 20px;
		height: 20px;
		left: -3px;
		top: 50%;
		transform: translateY(-50%);
		border-radius: 50%;
		background-color: ${theme.color.background.primary};
		content: '';
		cursor: pointer;
		box-shadow:
			0 1px 1px rgba(0, 0, 0, 0.16),
			0 3px 8px rgba(0, 0, 0, 0.15);
		transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
	}

	&:not(:disabled) {
		&:focus-visible {
			outline-color: ${theme.color.common.black};
		}
	}

	.rc-switch-label {
		display: inline-block;
		line-height: 20px;
		font-size: 14px;
		padding-left: 10px;
		vertical-align: middle;
		white-space: normal;
		pointer-events: none;
		user-select: text;
	}

	// checked

	&.rc-switch-checked {
		&::after {
			background-color: ${theme.color.background.inverse};
			box-shadow:
				0 1px 1px 0 rgba(0, 0, 0, 0.16),
				0 3px 8px 0 rgba(0, 0, 0, 0.15);
			left: 17px;
		}
	}

	// disabled

	&.rc-switch-disabled {
		cursor: default;
		background: ${theme.color.background.tertiary};

		&::after {
			background: ${theme.color.common.gray70};
			cursor: default;
		}
	}
`
