import { theme } from '@notino/react-styleguide'
import styled, { css } from 'styled-components'

export const OptionIconWrapper = styled.span`
	margin-right: 8px;
`

export const OptionAddress = styled.div`
	color: ${theme.color.text.tertiary};
	margin-left: 22px;
	margin-top: 4px;
	font-size: 14px;
`

export const SalonName = styled.span``

export const OptionLink = styled.a`
	color: inherit;
	text-decoration: none;
	display: block;

	&:hover ${SalonName} {
		text-decoration: underline;
	}
`

export const OptionContent = styled.div<{ $myLocationOption?: boolean }>`
	display: flex;
	align-items: center;

	${({ $myLocationOption }) =>
		$myLocationOption &&
		css`
			color: ${theme.color.text.highlight};
			position: relative;

			&::after {
				display: block;
				position: absolute;
				content: '';
				top: 0;
				left: 0;
				right: 0;
				bottom: -8px;
				border-bottom: 1px solid red;
			}
		`}
`
