import React from 'react'
import { theme } from '@notino/react-styleguide'

// utils
import { PARTIAL_RATING_OPTIONS } from '../../utils/helper'
import { PARTIAL_RATING } from '../../utils/enums'

// types
import { SalonPartialRatingsProps } from './types'

// styles
import * as SC from './SalonPartialRatingsStyles'

const FILLED_COLOR = theme.color.icon.primary
const EMPTY_COLOR = theme.color.background.tertiary
const WIDTH = '100%'
const HEIGHT = '8px'
const SPACE = 1
const SEGMENTS = 5

const DEFAULT_VALUE = {
	[PARTIAL_RATING.SERVICE]: 0,
	[PARTIAL_RATING.PLACE]: 0,
	[PARTIAL_RATING.COMMUNICATION]: 0,
	[PARTIAL_RATING.GENERAL_IMPRESSION]: 0
}

const SalonPartialRatings = (props: SalonPartialRatingsProps) => {
	const { value, onChange, readOnly, roundReadOnlyValues = false, ratingsGap = '10px' } = props

	const partialRatings = PARTIAL_RATING_OPTIONS()

	const handleChange = (newRating: number, currentRating: PARTIAL_RATING) => {
		if (!onChange) {
			return
		}

		const newValue = partialRatings.reduce<NonNullable<SalonPartialRatingsProps['value']>>((acc, cv) => {
			if (cv.value === currentRating) {
				return { ...acc, [cv.value]: newRating }
			}

			if (value && value[cv.value]) {
				return { ...acc, [cv.value]: value[cv.value] }
			}

			return acc
		}, DEFAULT_VALUE)

		onChange(newValue)
	}

	return (
		<SC.RatingsList>
			<SC.LinesCol $gap={ratingsGap}>
				{partialRatings.map((rating) => {
					const partialValue =
						!readOnly || roundReadOnlyValues
							? Math.round(value?.[rating.value] ?? DEFAULT_VALUE[rating.value])
							: (value?.[rating.value] ?? DEFAULT_VALUE[rating.value])
					const percentage = (partialValue / 5) * 100

					if (readOnly) {
						// Calculate the width of each segment, including space
						const totalSpace = (SEGMENTS - 1) * SPACE
						const segmentWidth = `calc((${WIDTH} - ${totalSpace}px) / ${SEGMENTS})`

						// Calculate the number of fully filled SEGMENTS and partial fill percentage
						const filledSegments = Math.floor((percentage / 100) * SEGMENTS)
						const partialFill = ((percentage / 100) * SEGMENTS - filledSegments) * 100

						return (
							<SC.ProgressBarContainer key={rating.value}>
								<SC.ProgressBar $width={WIDTH} $height={HEIGHT}>
									{Array.from({ length: SEGMENTS }).map((_, index) => {
										const isFilled = index < filledSegments
										const isPartial = index === filledSegments

										return (
											<SC.Segment
												key={index}
												$width={segmentWidth}
												$height={HEIGHT}
												$backgroundColor={isFilled ? FILLED_COLOR : EMPTY_COLOR}
												$marginRight={index < SEGMENTS - 1 ? `${SPACE}px` : '0'}
											>
												{isPartial && <SC.FilledPart $width={`${partialFill}%`} $filledColor={FILLED_COLOR} />}
											</SC.Segment>
										)
									})}
								</SC.ProgressBar>
							</SC.ProgressBarContainer>
						)
					}

					return (
						<SC.Rate
							key={rating.value}
							$width={WIDTH}
							$height={HEIGHT}
							$filledColor={FILLED_COLOR}
							$backgroundColor={EMPTY_COLOR}
							$space={SPACE}
							value={partialValue}
							items={SEGMENTS}
							spaceBetween={'none'}
							spaceInside={'none'}
							onChange={(newRating: number) => handleChange(newRating, rating.value)}
						/>
					)
				})}
			</SC.LinesCol>
			<SC.LabelsCol $gap={ratingsGap}>
				{partialRatings.map((rating) => {
					return <SC.RatingLabel key={rating.value}>{rating.label}</SC.RatingLabel>
				})}
			</SC.LabelsCol>
		</SC.RatingsList>
	)
}

export default SalonPartialRatings
