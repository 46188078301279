/* eslint-disable global-require */
import React, { PropsWithChildren, useContext, useEffect, useRef } from 'react'
import { Button, ButtonModel, IconRegularCalendar, IconRegularCheckmark, IconSolidStar } from '@notino/react-styleguide'
import { useIntl } from 'react-intl'

// hooks
import useMessages from '../../../hooks/useMessages'

// styles
import * as SC from './Step4SummarytStyles'
import { BREAKPOINTS } from '../../../styles/constants'

// components
import RenderPrice from '../../RenderPrice/RenderPrice'

// assets
import ClockIcon from '../../../assets/icons/ClockIcon'
import CloseIcon from '../../../assets/icons/CloseIcon'
import ScissorsIcon from '../../../assets/icons/ScissorsIcon'

// types
import { PostCalendarEventsReservationResponse, ReservationBookingModalCloseType, SalonServiceResponse } from '../../../types/types'

// utils
import { AppContext } from '../../../utils/appProvider'
import { getModalNextReservationEvent, getModalReservationPurchaseEvent } from '../../../utils/dataLayerEvents'
import { pushToDataLayer } from '../../../utils/dataLayer'
import { PAGE_LINKS } from '../../../utils/helper'
import { MyLocationContext } from '../../../utils/myLocationProvider'

type Props = PropsWithChildren<{
	onClose: (closeType: ReservationBookingModalCloseType) => void
	onRedirectToHomepage: () => void
	submittedReservationData: PostCalendarEventsReservationResponse['reservationCalendarEvent']
	selectedServiceData: SalonServiceResponse['service']
}>

const Step4Summary = (props: Props) => {
	const { onClose, onRedirectToHomepage, submittedReservationData, selectedServiceData } = props
	const { messages } = useMessages()
	const { formatDate, locale } = useIntl()
	const { assetsPath } = useContext(AppContext)
	const { myLocation } = useContext(MyLocationContext)

	const { salon, service, noteFromB2CCustomer, employee, customer, visitor, start, end, reservationData, visitorSameAsCustomer } = submittedReservationData
	const salonCoverImageUrl = salon.images?.find((image) => image.isCover)?.resizedImages.thumbnail

	const isDataLayerEventSend = useRef(false)

	useEffect(() => {
		if (isDataLayerEventSend.current) {
			return
		}
		pushToDataLayer(
			getModalReservationPurchaseEvent({
				postReservationData: submittedReservationData,
				industryID: selectedServiceData.category.id,
				secondLevelCategoryID: selectedServiceData.category.child.id,
				serviceCategoryID: selectedServiceData.category.child.child?.id ?? ''
			})
		)
		isDataLayerEventSend.current = true
	}, [submittedReservationData, selectedServiceData.category.id, selectedServiceData.category.child.id, selectedServiceData.category.child.child?.id])

	// NOTE: this screen is visible only for czech version for now
	const bannerDesktop = `${assetsPath}/${require(`../../../assets/images/reservation-modal/summary-page-banner-desktop-cs.jpg`)}`
	const bannerMobile = `${assetsPath}/${require(`../../../assets/images/reservation-modal/summary-page-banner-mobile-cs.jpg`)}`
	const promoBannerLink = 'https://bit.ly/41ckhal' // CZ variant
	/**
	 * @see https://goodrequest.atlassian.net/browse/NOT-11447
	 * Thank you page banner links for each language:
	 * sk - https://bit.ly/3ETXV5X
	 * hu - https://bit.ly/4ifOvAc
	 * bg - https://bit.ly/3XekqZK
	 * ro - https://bit.ly/4hTjuSO
	 */

	const footerButtons = (
		<>
			<Button
				onClick={() => {
					pushToDataLayer(getModalNextReservationEvent())
					onClose(null)
				}}
				fullWidth
				buttonStyle={ButtonModel.Styles.secondary}
			>
				{messages['Next reservation']}
			</Button>
			<SC.LinkButton
				href={PAGE_LINKS['/salons/homepage'](locale, { ...myLocation })}
				onClick={onRedirectToHomepage}
				fullWidth
				buttonStyle={ButtonModel.Styles.primary}
			>
				{messages['Close booking modal']}
			</SC.LinkButton>
		</>
	)

	const picture = (
		<SC.Picture>
			<source srcSet={bannerDesktop} media={`(min-width: ${BREAKPOINTS.lg})`} />
			<source srcSet={bannerMobile} />
			<img src={bannerDesktop} alt={messages['app-promo-banner-alt-text']} />
		</SC.Picture>
	)

	return (
		<SC.SummaryLayoutWrapper>
			<SC.ScrollableContent>
				<SC.SummaryWrapper>
					<SC.SummaryGrid>
						<SC.CloseIconWrapper onClick={() => onClose('button_x')}>
							<CloseIcon />
						</SC.CloseIconWrapper>
						<SC.PromoBanner>
							<SC.PromoBannerContent>
								{promoBannerLink ? (
									<SC.PromoBannerLink href={promoBannerLink} target={'blank'} rel={'noopener noreferrer'}>
										{picture}
									</SC.PromoBannerLink>
								) : (
									picture
								)}
							</SC.PromoBannerContent>
						</SC.PromoBanner>
						<SC.ReservationContent>
							<div>
								<SC.ReservationTitleWrapper>
									<SC.CheckIcon>
										<IconRegularCheckmark color={'icon.inverse'} width={'2.375rem'} height={'2.375rem'} />
									</SC.CheckIcon>
									<SC.ReservationTitle>{messages['The reservation has been successfully created']}</SC.ReservationTitle>
								</SC.ReservationTitleWrapper>
								<SC.ReservationWrapper>
									{salon?.logo && (
										<SC.SalonLogo>
											<img src={salon.logo?.resizedImages.thumbnail} alt='salon logo' />
										</SC.SalonLogo>
									)}
									<SC.SalonInfo>
										{salonCoverImageUrl && (
											<SC.SalonCover>
												<img loading='lazy' src={salonCoverImageUrl} alt='salon cover' />
											</SC.SalonCover>
										)}
										<div>
											{salon?.rating && (
												<SC.Rating>
													<IconSolidStar color={'icon.highlight'} width='12' height='12' style={{ marginBottom: '2px' }} />
													<span>{salon.rating.toFixed(1)}</span>
												</SC.Rating>
											)}
											{salon?.name && <SC.SalonTitle>{salon.name}</SC.SalonTitle>}
											<div>
												{salon?.address && <SC.LineWithIcon>{salon.formattedAddress}</SC.LineWithIcon>}
												{salon?.openingHoursData && (
													<SC.OpeningHours $isOpen={salon.openingHoursData?.isOpen}>
														<ClockIcon />
														<span>
															<SC.OpeningHoursStatus $isOpen={salon.openingHoursData?.isOpen}>
																{salon.openingHoursData.isOpen ? messages.Open : messages.Close}
															</SC.OpeningHoursStatus>
															{salon.openingHoursData.message && ` • ${salon.openingHoursData.message}`}
														</span>
													</SC.OpeningHours>
												)}
											</div>
										</div>
									</SC.SalonInfo>
									<SC.Divider />
									<SC.Service>
										<SC.ServiceValues>
											<SC.ServiceValue>
												<ScissorsIcon />
												<SC.LabelRegular>{service?.name}</SC.LabelRegular>
											</SC.ServiceValue>
											<SC.ServiceValue>
												<IconRegularCalendar />
												<SC.LabelRegular>
													{formatDate(start.date, { dateStyle: 'medium' })} {start.time} - {end.time}
												</SC.LabelRegular>
											</SC.ServiceValue>
											<SC.EmployeeWrapper>
												{employee.image.resizedImages.thumbnail && <SC.EmployeeAvatar src={employee.image.resizedImages.thumbnail} />}
												<SC.LabelRegular>
													{employee.firstName || employee.lastName ? (
														<>
															{employee.firstName} {employee.lastName}
														</>
													) : (
														employee.email
													)}
												</SC.LabelRegular>
											</SC.EmployeeWrapper>
										</SC.ServiceValues>
										<SC.Price>
											<RenderPrice from={reservationData?.priceFrom} to={reservationData?.priceTo} />
										</SC.Price>
									</SC.Service>
									{noteFromB2CCustomer && <SC.Note>{noteFromB2CCustomer}</SC.Note>}
									<SC.Divider />
									<SC.CustomerWrapper>
										{customer && (
											<SC.Customer>
												<SC.CustomerLabel>{messages.Customer}</SC.CustomerLabel>
												<SC.CustomerValue>
													<SC.LabelRegular>
														{customer.firstName} {customer.lastName}
													</SC.LabelRegular>
													<SC.CustomerEmail>{customer.email}</SC.CustomerEmail>
													<SC.CustomerPhone>
														{customer.phonePrefix} {customer.phone}
													</SC.CustomerPhone>
												</SC.CustomerValue>
											</SC.Customer>
										)}
										{!visitorSameAsCustomer && visitor && (
											<SC.Customer>
												<SC.CustomerLabel>{messages.Visitor}</SC.CustomerLabel>
												<SC.LabelRegular>
													{visitor.firstName} {visitor.lastName}
												</SC.LabelRegular>
											</SC.Customer>
										)}
									</SC.CustomerWrapper>
								</SC.ReservationWrapper>
							</div>
							<SC.Footer>{footerButtons}</SC.Footer>
						</SC.ReservationContent>
					</SC.SummaryGrid>
				</SC.SummaryWrapper>
			</SC.ScrollableContent>
			<SC.MobileFooter>{footerButtons}</SC.MobileFooter>
		</SC.SummaryLayoutWrapper>
	)
}

export default Step4Summary
