import React from 'react'

const ScissorsIcon = () => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' viewBox='0 0 20 20'>
			<path
				stroke='currentColor'
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeWidth='1.5'
				d='M5 17.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5zM5 7.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5z'
			/>
			<path stroke='currentColor' strokeLinecap='square' strokeLinejoin='round' strokeWidth='1.5' d='M16.667 3.333l-9.9 9.9M12.058 12.066l4.608 4.6' />
			<path stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='1.5' d='M6.767 6.767L10 10' />
		</svg>
	)
}

export default ScissorsIcon
