import styled from 'styled-components'
import { BREAKPOINTS } from '../../styles/constants'

export const BreadcrumbWrapper = styled.div`
	margin-bottom: 20px;

	@media (min-width: ${BREAKPOINTS.lg}) {
		margin-bottom: 64px;
	}
`
