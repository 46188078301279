import React, { useContext, useEffect } from 'react'
import { theme, ThemeProvider } from '@notino/react-styleguide'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'
import qs from 'query-string'
import { QueryParamProvider } from 'use-query-params'
import styled, { StyleSheetManager } from 'styled-components'
import { StyledTarget } from 'styled-components/dist/types'
import isPropValid from '@emotion/is-prop-valid'
import { APIProvider as GoogleMapApiProvider } from '@vis.gl/react-google-maps'

import dayjs from 'dayjs'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isBetween from 'dayjs/plugin/isBetween'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import 'dayjs/locale/sk'
import 'dayjs/locale/cs'
import 'dayjs/locale/hu'
import 'dayjs/locale/ro'
import 'dayjs/locale/bg'

import Routes from './routes/routes'

// styles
import GlobalStyles, { AppContainer } from './styles/globalStyles'
import 'overlayscrollbars/styles/overlayscrollbars.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

// types
import { ContextProps } from './types/types'

// utils
import LanguageProvider from './utils/languageProvider'
import { DEFAULT_LOCALE, shopsConfig } from './appDefaults'
import { APP_CONTAINER_ID } from './utils/enums'
import MyLocationProvider from './utils/myLocationProvider'
import { AppContext } from './utils/appProvider'

// components
import ToastContainer from './components/ToastContainer/ToastContainer'

dayjs.locale(DEFAULT_LOCALE)
dayjs.extend(localeData)
dayjs.extend(weekday)
dayjs.extend(LocalizedFormat)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.extend(isBetween)

const AppVersion = styled.div`
	display: none;
`

const shouldForwardProp = (propName: string, target: StyledTarget<'web'>) => {
	if (typeof target === 'string') {
		return isPropValid(propName)
	}
	return true
}

const AppLayout: React.FC<React.PropsWithChildren<ContextProps>> = (props) => {
	const { googleMapsApiKey } = useContext(AppContext)

	useEffect(() => {
		const dayjsLocale = shopsConfig.find((config) => config.lang === props.locale)?.locale || DEFAULT_LOCALE
		dayjs.locale(dayjsLocale)
	}, [props.locale])

	return (
		<StyleSheetManager shouldForwardProp={shouldForwardProp}>
			<QueryParamProvider adapter={ReactRouter6Adapter} options={{ searchStringToObject: qs.parse, objectToSearchString: qs.stringify }}>
				<ThemeProvider theme={theme}>
					<GlobalStyles />
					<LanguageProvider {...props}>
						<GoogleMapApiProvider apiKey={googleMapsApiKey}>
							<MyLocationProvider>
								<AppContainer id={APP_CONTAINER_ID}>
									<ToastContainer />
									<Routes {...props} />
								</AppContainer>
							</MyLocationProvider>
						</GoogleMapApiProvider>
					</LanguageProvider>
				</ThemeProvider>
			</QueryParamProvider>
			<AppVersion id='app_version'>{props.appVersion}</AppVersion>
		</StyleSheetManager>
	)
}

export default AppLayout
