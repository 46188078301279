import styled from 'styled-components'
import { theme } from '@notino/react-styleguide'
import { BREAKPOINTS } from '../../../../styles/constants'
import { HomePageSectionDescription, HomePageSectionTitle } from '../../HomePageStyles'
import { multiLineClamp, NotinoLabelRegular400 } from '../../../../styles/helpers'

export const Layout = styled.div`
	display: grid;
	gap: 24px;

	@media (min-width: ${BREAKPOINTS.sm}) {
		gap: 40px;
	}

	@media (min-width: ${BREAKPOINTS.lg}) {
		gap: 80px;
		grid-template-columns: 1fr 2fr;
	}
`

export const InfoCol = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
`

export const ServicesCol = styled.div`
	display: flex;
	justify-content: center;

	@media (min-width: ${BREAKPOINTS.lg}) {
		padding: 40px;
	}
`

export const ServicesGrid = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(92px, 1fr));
	gap: 20px;
	width: 100%;

	@media (min-width: ${BREAKPOINTS.sm}) {
		grid-template-columns: repeat(4, minmax(92px, 1fr));
		gap: 24px;
	}

	@media (min-width: ${BREAKPOINTS.md}) {
		grid-template-columns: repeat(6, minmax(92px, 1fr));
	}

	@media (min-width: ${BREAKPOINTS.lg}) {
		width: auto;
		grid-template-columns: repeat(4, minmax(92px, 116px));
	}

	@media (min-width: ${BREAKPOINTS.xl}) {
		grid-template-columns: repeat(6, minmax(92px, 116px));
	}
`

export const SectionTitle = styled(HomePageSectionTitle)`
	max-width: 400px;
`

export const SectionDescription = styled(HomePageSectionDescription)`
	max-width: 600px;

	@media (min-width: ${BREAKPOINTS.lg}) {
		max-width: 450px;
	}
`

export const TopLevelCategoryTitle = styled.div`
	text-align: center;
	${NotinoLabelRegular400};
	color: ${theme.color.text.primary} !important; // fix color for iphone
	${multiLineClamp(2)}
`

export const TopLevelCategoryButton = styled.a`
	text-decoration: none;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	text-align: center;
`

export const TopLevelCategoryImgWrapper = styled.div`
	width: 56px;
	height: 56px;
	border-radius: 999px;
	position: relative;
	overflow: hidden;
	margin-bottom: 10px;

	@media (min-width: ${BREAKPOINTS.sm}) {
		width: 80px;
		height: 80px;
	}
`

export const TopLevelCategoryImg = styled.img`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
`
