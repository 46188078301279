import React, { useEffect, useState } from 'react'

// types
import { ContextProps, RequiredSnapshotData } from '../../types/types'

// utils
import { SCREEN_STATE_KEY } from '../../utils/enums'

// hooks
import useLocalStorage from '../../hooks/useLocalStorage'
import useMessages from '../../hooks/useMessages'
import useCountdown from '../../hooks/useCountdown'

// styles
import * as SC from './AuthCompletePageStyles'

const PROCESSING_TIME_IN_SECONDS = 2

/**
 * The AuthCompletePage component is used to handle the authorization flow in the WSK (Web Single Key) scenario.
 * After the user has successfully authorized the app, the app will redirect to this page.
 * The page will then try to read a value from localStorage that matches any value from the `STORAGE_KEYS` enum.
 * If it finds a matching value, it will redirect to the corresponding URL.
 * If not, it will redirect to the index page.
 *
 * The page also contains a countdown hook that help to properly read value from localStorage.
 */
const AuthCompletePage: React.FC<React.PropsWithChildren<ContextProps>> = () => {
	const [storageKey, setStorageKey] = useState<null | string>(null)
	const [canRedirect, setCanRedirect] = useState(false)

	const { messages } = useMessages()

	const { storedValue, setValue } = useLocalStorage<RequiredSnapshotData>(storageKey || '')

	/**
	 * This hook is used to gain time to read value from localStorage. Without this hook, the page will redirect to index immediately.
	 */
	useCountdown(() => {
		setCanRedirect(true)
	}, PROCESSING_TIME_IN_SECONDS)

	if (canRedirect) {
		const { url, authorized } = storedValue || {}

		if (url) {
			if (!authorized) {
				setValue({ authorized: true })
			}

			window.location.href = url
		} else {
			window.location.href = '/'
		}
	}

	/**
	 * On initial render, check if there is any key in localStorage that matches any value from the STORAGE_KEYS enum.
	 * If so, it indicates that the auth flow should continue in WSK.
	 */
	useEffect(() => {
		let foundKey = ''

		for (let i = 0; i < window.localStorage.length; i += 1) {
			const key = window.localStorage.key(i)

			// Check if the key matches any value from the STORAGE_KEYS enum
			if (key && Object.keys(SCREEN_STATE_KEY).includes(key)) {
				foundKey = key
				break
			}
		}

		setStorageKey(foundKey)
	}, [])

	return (
		<SC.Container>
			<SC.Title>
				{messages['Redirect in progress']}
				<SC.Dot>.</SC.Dot>
				<SC.Dot>.</SC.Dot>
				<SC.Dot>.</SC.Dot>
			</SC.Title>
		</SC.Container>
	)
}

export default AuthCompletePage
