import React from 'react'

// components
import FormItem from '../../atoms/FormItem/FormItem'
import SalonPartialRatings from '../../components/SalonPartialRatings/SalonPartialRatings'

// types
import { SalonPartialRatingsFormFieldProps } from './types'

// component
const SalonPartialRatingsFormField = (props: SalonPartialRatingsFormFieldProps) => {
	const {
		input: { value, onChange },
		meta: { error, invalid },
		label,
		htmlFor,
		required,
		...restProps
	} = props

	const invalidStatus = !!(error || invalid)

	return (
		<FormItem htmlFor={htmlFor} label={label} required={required} error={invalidStatus && error ? error : undefined}>
			<SalonPartialRatings value={value} onChange={onChange} {...restProps} />
		</FormItem>
	)
}

export default SalonPartialRatingsFormField
