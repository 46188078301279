import React, { useContext, useMemo } from 'react'
import { useInitialData } from '@notino/react-toolkit/renderer/fragment/useInitialData'

// components
import HomepageHead from './components/HomepageHead/HomepageHead'
import HeroSection from './components/HeroSection/HeroSection'
import SalonsBookingForm from './components/SalonsBookingForm/SalonsBookingForm'
import RecommendedSalonsSection from './components/RecommendedSalonsSection/RecommendedSalonsSection'
import AppDownloadSection from './components/AppDownloadSection/AppDownloadSection'
import DiscoverSalonsSection from './components/DiscoverSalonsSection/DiscoverSalonsSection'
import ServicesSection from './components/ServicesSection/ServicesSection'
import SelectedCategorySalonsSection from './components/SelectedCategorySalonsSection/SelectedCategorySalonsSection'
import AppBenefitsSection from './components/AppBenefitsSection/AppBenefitsSection'
import ReviewsSection from './components/ReviewsSection/ReviewsSection'
import StatisticsSection from './components/StatisticsSection/StatisticsSection'
import FooterSection from './components/FooterSection/FooterSection'

// types
import {
	GetSalonsDataParams,
	CategoriesResponse,
	ConfigResponse,
	ContextProps,
	IGetInitialDataProps,
	SalonsResponse,
	HomePageServerQueryType,
	HomePageQueryType,
	CmsHomepageData
} from '../../types/types'

// utils
import { RECOMMENDED_SALONS_PARAMS, SELECTED_CATEGORY_ID, SELECTED_CATEGORY_SALONS_PARAMS } from './utils'
import { getHomepageHeroSectionData } from '../../utils/helper'
import { AppContext } from '../../utils/appProvider'

// styles
import * as SC from './HomePageStyles'

// types
type ExtendedContextProps = ContextProps & {
	query: HomePageServerQueryType
}

type InitialData = {
	configData: ConfigResponse
	categoriesData: CategoriesResponse
	recommendedSalonsData: SalonsResponse | undefined
	selectedCategorySalonsData: SalonsResponse | undefined
	selectedCategoryData: CategoriesResponse['categories'][number] | undefined
	homepageData: CmsHomepageData | undefined
}

type HomePageProps = InitialData & { query: HomePageQueryType }

// page
const HomePage = (props: HomePageProps) => {
	const { configData, categoriesData, recommendedSalonsData, selectedCategorySalonsData, selectedCategoryData, homepageData } = props
	const { assetsPath } = useContext(AppContext)

	const heroSectionData = getHomepageHeroSectionData(assetsPath, homepageData?.heroData)

	return (
		<>
			<HomepageHead heroSectionData={heroSectionData} />
			<HeroSection heroSectionData={heroSectionData} categoriesData={categoriesData} />
			<SalonsBookingForm categoriesData={categoriesData} configData={configData} />
			<SC.HomePageWrapper>
				<RecommendedSalonsSection recommendedSalonsData={recommendedSalonsData?.salons} />
				<AppDownloadSection cmsData={homepageData?.appPromoData} />
				<DiscoverSalonsSection cmsData={homepageData?.discoverSalonData} />
				<ServicesSection cmsData={homepageData?.discoverServiceData} categoriesData={categoriesData} />
				{/**
				 * Display recommended salons for the selected category only if the category exists.
				 * (The ID of the selected category is hardcoded on the frontend, so although it is not expected, the category might be deleted. Therefore, this case needs to be handled.)
				 * If the selected category is not found, display the Recommended Salons section again as a fallback.
				 */}
				{selectedCategoryData ? (
					<SelectedCategorySalonsSection
						selectedCategorySalonsData={selectedCategorySalonsData?.salons}
						selectedCategoryData={selectedCategoryData}
					/>
				) : (
					<RecommendedSalonsSection recommendedSalonsData={recommendedSalonsData?.salons} />
				)}
				<AppBenefitsSection cmsData={homepageData?.appFeatureData} />
				<ReviewsSection cmsData={homepageData?.npTestimonialsData} />
				<StatisticsSection cmsData={homepageData?.npStatisticsData} />
				<FooterSection cmsData={homepageData?.npForBusinessData} />
			</SC.HomePageWrapper>
		</>
	)
}

// page container
const HomePageContainer: IGetInitialDataProps<InitialData | null, ExtendedContextProps> = (props) => {
	const data = useInitialData(HomePageContainer, props)

	// When working with the user's location, always use values from `MyLocationContext`, not from the query.
	// While the query does include `latMy` and `lonMy`, this is only to ensure the server can fetch the correct data when the user's detected location is passed via query parameters in the URL.
	// Therefore, throughout the application, this typed `query` should be used, where user-related location parameters are omitted to avoid unintentional usage.
	const query: HomePageQueryType = useMemo(() => {
		const resolvedQuery = { ...props.query }
		delete resolvedQuery.latMy
		delete resolvedQuery.latMy

		return {
			...resolvedQuery
		}
	}, [props.query])

	// NOTE: needed for react-toolkit SSR
	if (!data) {
		return null
	}

	return <HomePage {...props} {...data} query={query} />
}

// ssr setup
HomePageContainer.initDefaultData = async ({ api, query }) => {
	try {
		const [categoriesData, configData, homepageData] = await Promise.all([api.b2c.getCategoriesData(), api.b2c.getConfigData(), api.cms.getHomepageData()])

		const selectedCategoryData = categoriesData.categories.find((category) => SELECTED_CATEGORY_ID === category.id)
		let recommendedSalonsData: SalonsResponse | undefined
		let selectedCategorySalonsData: SalonsResponse | undefined

		try {
			const getSalonsDataParams: GetSalonsDataParams = {
				...RECOMMENDED_SALONS_PARAMS,
				lonMy: query.lonMy,
				latMy: query.latMy
			}

			const recommendedSalonsPromise = api.b2c.getSalonsData(getSalonsDataParams)

			let selectedCategorySalonsPromise: Promise<SalonsResponse> | undefined

			if (selectedCategoryData) {
				const getSelectedCategorySalonsDataParams: GetSalonsDataParams = {
					...SELECTED_CATEGORY_SALONS_PARAMS,
					lonMy: query.lonMy,
					latMy: query.latMy
				}

				selectedCategorySalonsPromise = api.b2c.getSalonsData(getSelectedCategorySalonsDataParams)
			}

			;[recommendedSalonsData, selectedCategorySalonsData] = await Promise.all([recommendedSalonsPromise, selectedCategorySalonsPromise])
		} catch {
			/* empty */
		}

		return { recommendedSalonsData, selectedCategorySalonsData, selectedCategoryData, categoriesData, configData, homepageData }
	} catch {
		return null
	}
}

HomePageContainer.identifier = 'HomePage'

export default HomePageContainer
