import { styled } from '@notino/react-styleguide'
import { NotinoLabelLarge } from '../../../../styles/helpers'

export const ServicesComponentContainer = styled.div`
	margin-bottom: 10px;

	.rc-collapse {
		margin-top: 16px;
	}

	.rc-collapse-item {
		&:not(:last-child) {
			margin-bottom: 16px;
		}
	}

	.rc-collapse-header {
		display: flex;
		align-items: center;
		cursor: pointer;
		justify-content: space-between;
		padding: 10px 0;
		.rc-collapse-expand-icon {
			display: none;
		}

		.rc-collapse-extra {
			margin-left: 10px;
		}

		span.rc-collapse-header-text {
			font-size: 1.125rem;
			line-height: 1.25rem;
			font-weight: 500;
		}
	}

	.rc-collapse-content-hidden {
		display: none;
	}
`

export const ServicesTitle = styled.div`
	${NotinoLabelLarge};
	display: flex;
	align-items: center;
	gap: 8px;
`

export const IndustryWrapper = styled.div`
	padding: 16px 0 10px 0;
`
