/* eslint-disable global-require */
import React, { forwardRef, useContext, useImperativeHandle, useState } from 'react'
import { useApiIsLoaded, Map, MapProps, MapMouseEvent, AdvancedMarker, AdvancedMarkerProps, useAdvancedMarkerRef } from '@vis.gl/react-google-maps'
import { Spinner } from '@notino/react-styleguide'

// utils
import { MAP } from '../../utils/enums'
import { AppContext } from '../../utils/appProvider'

// styled
import * as SC from './GoogleMapsStyles'

// types
export type MapMarker<ExtraType extends Record<string, any> = {}> = {
	id: string
	position: AdvancedMarkerProps['position']
	extra: ExtraType
}

export type OnMarkerClickFunc<MarkerExtraType extends Record<string, any> = {}> = (
	markerElement: google.maps.marker.AdvancedMarkerElement,
	data: MapMarker<MarkerExtraType>
) => void

export type GoogleMapPosition = google.maps.LatLng | google.maps.LatLngLiteral

type Props<MarkerExtraType extends Record<string, any> = {}> = MapProps & {
	markers?: MapMarker<MarkerExtraType>[]
	defaultSelectedMarkerID?: string
	onMarkerClick?: OnMarkerClickFunc<MarkerExtraType>
	markersLoading?: boolean
}

type Handle = {
	resetCurrentMarker: () => void
}

export const AdvancedMarkerWithRef = (
	props: AdvancedMarkerProps & {
		onMarkerClick: (marker: google.maps.marker.AdvancedMarkerElement) => void
	}
) => {
	const { children, onMarkerClick, ...advancedMarkerProps } = props
	const [markerRef, marker] = useAdvancedMarkerRef()

	return (
		<AdvancedMarker
			onClick={() => {
				if (marker) {
					onMarkerClick(marker)
				}
			}}
			ref={markerRef}
			{...advancedMarkerProps}
		>
			{children}
		</AdvancedMarker>
	)
}

// component
const GoogleMaps = forwardRef(<MarkerExtraType extends Record<string, any> = {}>(props: Props<MarkerExtraType>, ref: React.Ref<Handle>) => {
	const { markersLoading, children, markers, onMarkerClick, onClick, onCameraChanged, defaultSelectedMarkerID, ...restMapProps } = props
	const { assetsPath } = useContext(AppContext)

	const isMapLoaded = useApiIsLoaded()

	const isLoading = !isMapLoaded || markersLoading

	const [currentMarkerID, setCurrentMarkerID] = useState<string | null>(defaultSelectedMarkerID ?? null)

	const handleClick = (e: MapMouseEvent) => {
		if (currentMarkerID !== defaultSelectedMarkerID) {
			setCurrentMarkerID(null)
		}

		if (onClick) {
			onClick(e)
		}
	}

	useImperativeHandle(ref, () => ({
		resetCurrentMarker() {
			setCurrentMarkerID(null)
		}
	}))

	return (
		<SC.MapContainer>
			{isMapLoaded && (
				<Map
					defaultZoom={MAP.defaultZoom}
					maxZoom={MAP.maxZoom}
					minZoom={MAP.minZoom}
					zoomControl={false}
					streetViewControl={false}
					fullscreenControl={false}
					mapTypeControl={false}
					clickableIcons={false} // don't allow click on google maps default pins (e.g. pins for restaurants, places, ...)
					mapId={'DEMO_MAP_ID'}
					onClick={handleClick}
					onCameraChanged={onCameraChanged}
					{...restMapProps}
				>
					{markers?.map((markerData, index) => {
						const icon =
							markerData.id === currentMarkerID
								? `${assetsPath}/${require('../../assets/icons/location-pin-icon-pink.png')}`
								: `${assetsPath}/${require('../../assets/icons/location-pin-icon-black.png')}`
						return (
							<AdvancedMarkerWithRef
								key={index}
								position={markerData.position}
								onMarkerClick={(markerElement: google.maps.marker.AdvancedMarkerElement) => {
									if (onMarkerClick) {
										onMarkerClick(markerElement, markerData)
									}
									setCurrentMarkerID(markerData.id)
								}}
							>
								<img width={36} height={44} src={icon} alt={''} />
							</AdvancedMarkerWithRef>
						)
					})}
					{/* additional map elements */}
					{children}
				</Map>
			)}
			{isLoading && (
				<SC.SpinnerContainer>
					<Spinner color={'icon.highlight'} size={24} />
				</SC.SpinnerContainer>
			)}
		</SC.MapContainer>
	)
}) as <MarkerExtraType extends Record<string, any> = {}>(props: Props<MarkerExtraType> & { ref?: React.Ref<Handle> }) => JSX.Element

export default GoogleMaps
