export const BREAKPOINTS_INTS = {
	xs: 400,
	sm: 576,
	md: 768,
	lg: 960,
	xl: 1280,
	'2xl': 1536,
	'3xl': 1700
}

export const BREAKPOINTS = {
	xs: `${BREAKPOINTS_INTS.xs}px`,
	sm: `${BREAKPOINTS_INTS.sm}px`,
	md: `${BREAKPOINTS_INTS.md}px`,
	lg: `${BREAKPOINTS_INTS.lg}px`,
	xl: `${BREAKPOINTS_INTS.xl}px`,
	'2xl': `${BREAKPOINTS_INTS['2xl']}px`,
	'3xl': `${BREAKPOINTS_INTS['3xl']}px`
}
export type Breakpoint = keyof typeof BREAKPOINTS

// TODO: is this object still relevant, since all the pages have same widths?
export const MAX_WIDTHS = {
	PAGE_DEFAULT: 1352 // in px
}

export const SALONS_PAGE_MOBILE_BREAKPOINT_KEY = 'lg'
export const SALONS_PAGE_MOBILE_BREAKPOINT_INT = BREAKPOINTS_INTS[SALONS_PAGE_MOBILE_BREAKPOINT_KEY]
export const SALONS_PAGE_MOBILE_BREAKPOINT = BREAKPOINTS[SALONS_PAGE_MOBILE_BREAKPOINT_KEY]

export const SALON_PAGE_MOBILE_BREAKPOINT_KEY = 'lg'

export const SALON_PAGE_MOBILE_BREAKPOINT_INT = BREAKPOINTS_INTS[SALON_PAGE_MOBILE_BREAKPOINT_KEY]
export const SALON_PAGE_MOBILE_BREAKPOINT = BREAKPOINTS[SALON_PAGE_MOBILE_BREAKPOINT_KEY]
export const RESERVATION_BOOKING_MODAL_MOBILE_BREAKPOINT_KEY = 'sm'
export const RESERVATION_BOOKING_MODAL_MOBILE_BREAKPOINT_INT = BREAKPOINTS_INTS[RESERVATION_BOOKING_MODAL_MOBILE_BREAKPOINT_KEY]
export const RESERVATION_BOOKING_MODAL_MOBILE_BREAKPOINT = BREAKPOINTS[RESERVATION_BOOKING_MODAL_MOBILE_BREAKPOINT_KEY]

export const MY_RESERVATION_PAGE_MOBILE_BREAKPOINT_KEY = 'lg'
export const MY_RESERVATION_PAGE_MOBILE_BREAKPOINT_INT = BREAKPOINTS_INTS[MY_RESERVATION_PAGE_MOBILE_BREAKPOINT_KEY]
export const MY_RESERVATION_PAGE_MOBILE_BREAKPOINT = BREAKPOINTS[MY_RESERVATION_PAGE_MOBILE_BREAKPOINT_KEY]

export const CONTAINER_X_PADDING = 16
export const PAGE_TOP_PADDING = 16
export const PAGE_BOTTOM_PADDING = 80

export const SALON_DETAIL_RIGHT_CONTACT_BAR = 319

export const RC_DRAWER_IS_OPEN_CLASS_NAME = 'filter-sidebar-is-open'

export const SALON_MOBILE_PROMO_BANNER_CLASSNAME = 'salon-mobile-promo-banner-image'

export const SALON_DETAIL = {
	IMAGE_WIDTH: 420, // in px
	IMAGE_HEIGHT: 210, // in px
	IMAGE_ASPECT_RATIO: 1.8, // width / height
	TEXT_CONTENT_HEIGHT: 94, // in px
	GAP: 8 // in px
}
