import React from 'react'

// types
import { DatePickerFormFieldProps } from './types'

// components
import DatePicker from '../../atoms/DatePicker/DatePicker'
import FormItem from '../../atoms/FormItem/FormItem'

const DatePickerFormField = (props: DatePickerFormFieldProps) => {
	const {
		input: { value, onChange },
		meta: { invalid, error },
		label,
		htmlFor,
		required,
		...restProps
	} = props

	const invalidStatus = !!(error || invalid)

	return (
		<FormItem htmlFor={htmlFor} label={label} required={required} error={invalidStatus && error ? error : undefined}>
			<DatePicker value={value} onChange={onChange} {...restProps} />
		</FormItem>
	)
}

export default DatePickerFormField
