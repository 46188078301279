import React, { ComponentProps, useState } from 'react'
import Collapse from 'rc-collapse'
import useMessages from '../../../../hooks/useMessages'

// types
import { SalonServicesResponse, ServiceCardCallbackData, ServiceDetailData } from '../../../../types/types'

// assets
import ChevronUpIcon from '../../../../assets/icons/ChevronUpIcon'
import ChevronDownIcon from '../../../../assets/icons/ChevronDownIcon'
import ScissorsIcon from '../../../../assets/icons/ScissorsIcon'

// styles
import * as SC from './SalonServicesCollapseStyles'

// components
import SalonServicesCards from '../SalonServicesCards/SalonServicesCards'

type Props = {
	services?: SalonServicesResponse
	onServiceCardButtonClick: (data: ServiceCardCallbackData) => void
	setServiceDetail: (data: ServiceDetailData) => void
	phoneNumber: string
}

const SalonServicesCollapse = (props: Props) => {
	const { services, onServiceCardButtonClick, setServiceDetail, phoneNumber } = props
	const [expandedRowId, setExpandedRowId] = useState<React.Key[]>([])

	const { messages } = useMessages()

	return (
		<SC.ServicesComponentContainer>
			<SC.ServicesTitle>
				<ScissorsIcon />
				{messages.Services}
			</SC.ServicesTitle>
			<Collapse
				onChange={(key) => {
					setExpandedRowId(key as React.Key[])
				}}
				activeKey={expandedRowId}
				items={services?.groupedServicesByCategory?.reduce<NonNullable<ComponentProps<typeof Collapse>['items']>>((acc, industry, index) => {
					if (!industry.category) {
						return acc
					}

					const key = industry.category.id

					return [
						...acc,
						{
							label: industry.category.name,
							key: `${index}`,
							extra: expandedRowId?.includes(key) ? <ChevronUpIcon /> : <ChevronDownIcon />,
							forceRender: true,
							children: (
								<SC.IndustryWrapper>
									<SalonServicesCards
										phoneNumber={phoneNumber}
										industryData={industry.category}
										onServiceCardButtonClick={onServiceCardButtonClick}
										setServiceDetail={setServiceDetail}
									/>
								</SC.IndustryWrapper>
							)
						}
					]
				}, [])}
			/>
		</SC.ServicesComponentContainer>
	)
}

export default SalonServicesCollapse
