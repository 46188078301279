import styled from 'styled-components'
import { BREAKPOINTS } from '../../../../styles/constants'
import { HomePageSectionDescription, HomePageSectionTitle } from '../../HomePageStyles'

export const Layout = styled.div`
	display: grid;
	gap: 24px;

	@media (min-width: ${BREAKPOINTS.sm}) {
		gap: 40px;
	}

	@media (min-width: ${BREAKPOINTS.lg}) {
		gap: 0;
		grid-template-columns: repeat(2, 1fr);
	}
`

export const InfoCol = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	order: 1;

	@media (min-width: ${BREAKPOINTS.lg}) {
		order: 2;
		padding: 40px 0;
		margin-left: 80px;
	}
`

export const ImageCol = styled.div`
	order: 2;
	display: flex;
	flex-direction: column;
	justify-content: center;

	@media (min-width: ${BREAKPOINTS.lg}) {
		order: 1;
	}
`

export const ImageLink = styled.a`
	display: block;
	text-decoration: none;
`

export const SectionTitle = styled(HomePageSectionTitle)`
	max-width: 400px;
`

export const SectionDescription = styled(HomePageSectionDescription)`
	max-width: 600px;

	@media (min-width: ${BREAKPOINTS.lg}) {
		max-width: 450px;
	}
`

export const Picture = styled.picture`
	display: block;
	width: 100%;
	aspect-ratio: 640 / 400;

	img {
		display: block;
		object-fit: cover;
		object-position: center;
		width: 100%;
		height: auto;
	}
`
