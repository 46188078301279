import React, { useEffect, useMemo } from 'react'

// utils
import { useForm } from 'react-hook-form'
import { FORM, SALONS_FILTER_TYPE } from '../../../../utils/enums'
import { areValuesInArrayEqual, SALONS_FILTER_ITEMS_CONFIG } from '../../../../utils/helper'

// hooks
import useMessages from '../../../../hooks/useMessages'

// components
import Drawer from '../../../../components/Drawer/Drawer'
import SidebarFooter from '../FilterSidebarFooter/FilterSidebarFooter'
import Tag from '../../../../atoms/Tag/Tag'
import HookFormField from '../../../../formFields/HookFormField'
import CheckboxGroupFormField from '../../../../formFields/CheckboxGroupFormField/CheckboxGroupFormField'
import FetchResult from '../../../../components/FetchResult/FetchResult'

// assets
import ChevronDownLeft from '../../../../assets/icons/ChevronLeftIcon'

// types
import { FilterLanguagesValuesType, FilterValuesType, GetSalonsFilterCountResponse, Language, LoadSalonsFromFiltersParams } from '../../../../types/types'
import { CheckboxGroupOption } from '../../../../formFields/CheckboxGroupFormField/types'

// styles
import * as SC from './FilterSidebarStyles'

// types
type FilterSidebarLanguagesProps = {
	filterSidebarDetailOpen: boolean
	setFilterSidebarDetailOpen: (open: boolean) => void
	filterDetailOpenedDirectly: boolean
	loadSalonsFromFilters: (params: LoadSalonsFromFiltersParams) => void
	submittedFilterValues: FilterValuesType
	setFilterValues: (newFilterValues: FilterLanguagesValuesType) => void
	languages: Language[]
	filterItemsConfig: ReturnType<typeof SALONS_FILTER_ITEMS_CONFIG>
	languagesCounts: GetSalonsFilterCountResponse['languages'] | undefined
	initialClientDataLoading?: boolean
}

// component
const FilterSidebarLanguages = (props: FilterSidebarLanguagesProps) => {
	const {
		filterSidebarDetailOpen,
		setFilterSidebarDetailOpen,
		filterDetailOpenedDirectly,
		loadSalonsFromFilters,
		submittedFilterValues,
		setFilterValues,
		languages,
		filterItemsConfig,
		initialClientDataLoading,
		languagesCounts
	} = props
	const { messages } = useMessages()

	const languagesDefaultValue = filterItemsConfig[SALONS_FILTER_TYPE.LANGUAGES].defaultValue

	// submitted filter values
	const submittedLanguageIDs = useMemo(
		() => submittedFilterValues.languageIDs || languagesDefaultValue,
		[submittedFilterValues.languageIDs, languagesDefaultValue]
	)

	// internal filter values - no submitted - just for components state
	const {
		control,
		handleSubmit,
		reset,
		watch,
		setValue,
		formState: { isDirty }
	} = useForm<FilterLanguagesValuesType>({
		defaultValues: {
			languageIDs: submittedFilterValues.languageIDs
		}
	})

	const languageIDs = watch('languageIDs')

	// check if current internal filter values are equal to default values
	// we will show "reset" buttons based on the check
	const isLanguagesFilterEqualToDefault = areValuesInArrayEqual(languageIDs, languagesDefaultValue)

	useEffect(() => {
		// reinitialize internal filter values when submitted values changes to keep them in sync
		// be careful with dependencies, non primitive values must be memoized, otherwise internal filter values would reset even when the submitted values haven't changed
		reset({ languageIDs: submittedLanguageIDs })
	}, [submittedLanguageIDs, reset])

	const handleSubmitFilterDetail = (formSubmitValues: FilterLanguagesValuesType) => {
		if (filterDetailOpenedDirectly) {
			loadSalonsFromFilters({ languageIDs: formSubmitValues.languageIDs })
		} else {
			setFilterValues({ languageIDs: formSubmitValues.languageIDs })
		}

		setFilterSidebarDetailOpen(false)
	}

	const resetLanguagesFilterToDefault = () => {
		setValue('languageIDs', languagesDefaultValue, { shouldDirty: true })
	}

	const closeSidebarDetail = () => {
		setFilterSidebarDetailOpen(false)
		reset()
	}

	const getFooterSliderItems = () => {
		const languagesFilterTags = languageIDs
			.map((selectedLanguageID) => {
				const languageName = languages.find((language) => language.id === selectedLanguageID)?.name
				return {
					name: languageName || '',
					id: selectedLanguageID
				}
			})
			.sort((a, b) => {
				if (a.name < b.name) {
					return -1
				}
				if (a.name > b.name) {
					return 1
				}
				return 0
			})
			.map((selectedLanguage) => (
				<Tag
					key={selectedLanguage.id}
					label={selectedLanguage.name}
					onCloseBtnClick={() =>
						setValue(
							'languageIDs',
							languageIDs.filter((languageID) => languageID !== selectedLanguage.id),
							{ shouldDirty: true }
						)
					}
				/>
			))
		return languagesFilterTags.length ? languagesFilterTags : null
	}

	const options: CheckboxGroupOption[] = languages.reduce((acc, language) => {
		// hide languages with 0 count (except selected)
		// if languagesCounts is undefined => show all languages, but without counts
		if (languagesCounts && !languagesCounts[language.id] && !languageIDs.includes(language.id)) {
			return acc
		}

		return [
			...acc,
			{
				value: language.id,
				label: language.name,
				icon: <SC.CheckboxFlag $backgroundImage={language.image?.resizedImages.thumbnail} />,
				description: languagesCounts ? languagesCounts[language.id] || 0 : undefined
			}
		]
	}, [] as CheckboxGroupOption[])

	return (
		<Drawer
			open={filterSidebarDetailOpen}
			mask
			maskClosable
			// if the detail is open from the main filter sidebar, it already has a mask, so we need to set opacity of detail sidebar mask to zero, so they don't overlap visually
			maskStyle={!filterDetailOpenedDirectly ? { opacity: 0 } : undefined}
			closeIcon={!filterDetailOpenedDirectly ? <ChevronDownLeft /> : undefined}
			title={filterItemsConfig[SALONS_FILTER_TYPE.LANGUAGES].title}
			onClose={closeSidebarDetail}
			headerExtra={
				!isLanguagesFilterEqualToDefault ? (
					<SC.HeaderResetButton onClick={resetLanguagesFilterToDefault}>{messages.Reset}</SC.HeaderResetButton>
				) : undefined
			}
			footer={<SidebarFooter formId={FORM.FILTER_SIDEBAR_LANGUAGES} isVisible={isDirty} extraContent={getFooterSliderItems()} />}
			destroyOnClose
		>
			<SC.SidebarDetailContent>
				<FetchResult
					initialLoading={initialClientDataLoading}
					empty={!languages.length}
					emptyTitle={messages['Unfortunately, no languages were found']}
				>
					<SC.SidebarForm id={FORM.FILTER_SIDEBAR_LANGUAGES} onSubmit={handleSubmit(handleSubmitFilterDetail)}>
						<HookFormField control={control} name={'languageIDs'} component={CheckboxGroupFormField} options={options} />
					</SC.SidebarForm>
				</FetchResult>
			</SC.SidebarDetailContent>
		</Drawer>
	)
}

export default FilterSidebarLanguages
