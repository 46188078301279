/* eslint-disable global-require */
import React, { useContext } from 'react'

// types
import { MapPointsResponse } from '../../types/types'

// utils
import { AppContext } from '../../utils/appProvider'

type Props = {
	salon: MapPointsResponse['mapPoints'][number]['salon']
	width?: number
	height?: number
	className?: string
	loading?: React.ImgHTMLAttributes<HTMLImageElement>['loading']
}

const SalonImage = ({ salon, className, loading, width, height }: Props) => {
	const { assetsPath } = useContext(AppContext)
	const imageSmallSrc = salon.images[0]?.resizedImages.small
	const imageSrc = imageSmallSrc || `${assetsPath}/${require('../../assets/images/basic-salon-placeholder-small.jpg')}`

	return <img src={imageSrc} alt={salon.name} loading={loading} className={className} width={width} height={height} />
}

export default SalonImage
