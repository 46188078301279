import styled from 'styled-components'

export const MapContainer = styled.div`
	width: 100%;
	height: 100%;
	position: relative;
`

export const SpinnerContainer = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: grid;
	place-items: center;
	z-index: 100;
	pointer-events: none;
`
