import React from 'react'

// components
import Input from '../../atoms/Input/Input'
import FormItem from '../../atoms/FormItem/FormItem'

// types
import { InputFormFieldProps } from './types'

// component
const InputFormField = (props: InputFormFieldProps) => {
	const {
		input: { value, onChange, onBlur, onFocus },
		meta: { invalid, error },
		label,
		htmlFor,
		required,
		...restProps
	} = props

	const invalidStatus = !!(error || invalid)

	return (
		<FormItem htmlFor={htmlFor} label={label} required={required} error={invalidStatus && error ? error : undefined}>
			<Input value={value} onChange={(e) => onChange(e.target.value)} onBlur={onBlur} onFocus={onFocus} invalid={invalidStatus} {...restProps} />
		</FormItem>
	)
}

export default InputFormField
