import React from 'react'

// types
import { InputErrorProps } from './types'

// styles
import * as SC from './InputErrorStyles'

const InputError = (props: InputErrorProps) => {
	const { children, htmlFor } = props

	return <SC.InputError htmlFor={htmlFor}>{children}</SC.InputError>
}

export default InputError
