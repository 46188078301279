import React, { ComponentProps, useContext, useEffect, useRef, useState } from 'react'

// components
import SalonsAndCitiesSearch, { GET_MY_LOCATION_OPTION } from '../../../../components/SalonsAndCitiesSearch/SalonsAndCitiesSearch'

// types
import { SalonsAndCitiesSearchValue, LoadSalonsFromFiltersParams, SalonsCategoryPageQueryType } from '../../../../types/types'

// styles
import * as SC from './SalonsAndCitiesSearchWrapStyles'
import { SALONS_PAGE_MOBILE_BREAKPOINT_INT } from '../../../../styles/constants'

// utils
import { SEARCH_FIELD_OPTION_TYPE } from '../../../../utils/enums'
import { AppContext } from '../../../../utils/appProvider'
import { MyLocationContext } from '../../../../utils/myLocationProvider'
import { getPlaceSelectedEvent, getSalonSelectedFromSearchEvent, getSearchFocusedEvent } from '../../../../utils/dataLayerEvents'
import { pushToDataLayer } from '../../../../utils/dataLayer'

// hooks
import useMessages from '../../../../hooks/useMessages'
import useIsMobile from '../../../../hooks/useIsMobile'

type Props = {
	query: SalonsCategoryPageQueryType
	loadSalonsFromFilters: (params: LoadSalonsFromFiltersParams) => void
	currentCategoryID: string | undefined
}

// search for both salons and cities
const SalonsAndCitiesSearchWrap = (props: Props) => {
	const { query, loadSalonsFromFilters, currentCategoryID } = props
	const { messages } = useMessages()

	const { apiBrowser } = useContext(AppContext)
	const { myLocation, isLoadingMyLocation } = useContext(MyLocationContext)

	const [searchValue, setSearchValue] = useState<SalonsAndCitiesSearchValue>(null)
	const [isLoadingSearchValue, setIsLoadingSearchValue] = useState(true)
	const searchValueType = searchValue?.extra?.type

	const isLocationInitializedRef = useRef(false)

	const isMobile = useIsMobile(SALONS_PAGE_MOBILE_BREAKPOINT_INT)

	useEffect(() => {
		// Initialize search value
		;(async () => {
			if ((searchValueType && searchValueType === SEARCH_FIELD_OPTION_TYPE.SALON) || isLoadingMyLocation || isLocationInitializedRef.current) {
				setIsLoadingSearchValue(false)
				return
			}

			setIsLoadingSearchValue(true)
			let newSearchValue: SalonsAndCitiesSearchValue = null

			if (query.googlePlaceID && query.lat && query.lon) {
				let cityName = searchValue?.label
				if (!cityName) {
					const city = await apiBrowser.b2c.getCityNameByPlaceId(query.googlePlaceID)
					cityName = city?.name || query.googlePlaceID
				}

				newSearchValue = {
					key: query.googlePlaceID,
					value: query.googlePlaceID,
					label: cityName,
					extra: { type: SEARCH_FIELD_OPTION_TYPE.CITY, longitude: query.lon, latitude: query.lat }
				}
			} else if (myLocation?.latMy && myLocation.lonMy) {
				newSearchValue = GET_MY_LOCATION_OPTION({ latitude: myLocation.latMy, longitude: myLocation.lonMy })
			}
			isLocationInitializedRef.current = true
			setSearchValue(newSearchValue)
			setIsLoadingSearchValue(false)
		})()
	}, [isLoadingMyLocation, apiBrowser, searchValue?.label, searchValueType, query.googlePlaceID, query.lat, query.lon, myLocation?.latMy, myLocation?.lonMy])

	const handleChange: ComponentProps<typeof SalonsAndCitiesSearch>['onChange'] = (value) => {
		if (value?.extra?.type === SEARCH_FIELD_OPTION_TYPE.SALON) {
			const event = getSalonSelectedFromSearchEvent({ inputValue: value?.value ?? '' })
			pushToDataLayer(event)
		} else if (value?.extra?.type === SEARCH_FIELD_OPTION_TYPE.CITY) {
			const event = getPlaceSelectedEvent({ inputValue: value?.value ?? '' })
			pushToDataLayer(event)
		}

		loadSalonsFromFilters({
			city: value
				? {
						name: value.label,
						placeID: value.value,
						latitude: value.extra?.latitude,
						longitude: value.extra?.longitude
					}
				: null
		})
		setSearchValue(value)
	}

	const onDropdownVisibleChange: ComponentProps<typeof SalonsAndCitiesSearch>['onDropdownVisibleChange'] = (open: boolean) => {
		if (open) {
			const event = getSearchFocusedEvent({ isMobile })
			pushToDataLayer(event)
		}
	}

	return (
		<SC.SalonsSearchWrapper>
			<SalonsAndCitiesSearch
				value={searchValue}
				emptyContent={{
					emptyTitle: messages['Search salon or city'],
					emptyLabel: messages['Enter the name of the salon or city you are looking for.']
				}}
				showMyLocationOption
				onChange={handleChange}
				onDropdownVisibleChange={onDropdownVisibleChange}
				redirectQueryData={{
					currentCategoryID,
					avResTimeSlotDayPart: query.avResTimeSlotDayPart,
					avResTimeSlotDateFrom: query.avResTimeSlotDateFrom
				}}
				isLoadingValue={isLoadingSearchValue}
			/>
		</SC.SalonsSearchWrapper>
	)
}

export default SalonsAndCitiesSearchWrap
