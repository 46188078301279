import styled, { css } from 'styled-components'
import { theme } from '@notino/react-styleguide'

// styles
import { NotinoLabelRegular400 } from '../../styles/helpers'

// types
import { CheckboxAlignment, CheckboxGap } from './types'

const CHECKBOX_SIZE = 16 // in px

export const Checker = styled.span<{ $alignment: CheckboxAlignment; $isInvalid?: boolean }>`
	position: absolute;
	left: 0;
	width: ${CHECKBOX_SIZE}px;
	height: ${CHECKBOX_SIZE}px;
	outline: 1px solid transparent;
	background-color: ${theme.color.background.primary};
	border: 1px solid ${({ $isInvalid }) => ($isInvalid ? theme.color.border.negative : theme.color.border.default)};
	border-radius: 2px;
	transition: all 0.3s ease 0s;

	${({ $alignment }) => {
		if ($alignment === 'start') {
			return css`
				top: 0.125rem;
			`
		}

		return css`
			top: 50%;
			transform: translateY(-50%);
		`
	}}
`

export const CheckboxInput = styled.input<{ $isInvalid?: boolean }>`
	position: absolute;
	opacity: 0;
	top: 0;
	left: 0;

	&:checked {
		& ~ ${Checker} {
			background-color: ${theme.color.background.inverse};
			border-color: ${({ $isInvalid }) => ($isInvalid ? theme.color.border.negative : theme.color.common.black)};

			&::after {
				content: '';
				position: absolute;
				display: block;
				left: 5px;
				top: 2px;
				width: 4px;
				height: 8px;
				border-width: 0 2px 2px 0;
				transform: rotate(45deg);
				border-color: ${theme.color.common.white};
				border-style: solid;
				transition: border 0.3s ease 0s;
			}
		}

		&:disabled {
			& ~ ${Checker} {
				background-color: ${theme.color.common.gray90};
				border-color: ${({ $isInvalid }) => ($isInvalid ? theme.color.border.negative : theme.color.border.divider)};
			}
		}
	}

	&:not(:disabled) {
		&:focus-visible ~ ${Checker} {
			outline-color: ${theme.color.text.primary};
		}
	}

	&:disabled {
		&:not(:checked) ~ ${Checker} {
			background-color: ${theme.color.background.secondary};
			border-color: ${({ $isInvalid }) => ($isInvalid ? theme.color.border.negative : theme.color.border.divider)};
		}
	}
`

export const CheckboxLabel = styled.label<{ $disabled?: boolean; $alignment: CheckboxAlignment; $isInvalid?: boolean; $checkboxGap: CheckboxGap }>`
	padding-left: ${({ $checkboxGap }) => CHECKBOX_SIZE + $checkboxGap}px;
	display: flex;
	align-items: ${({ $alignment }) => $alignment};
	position: relative;
	color: ${theme.color.text.primary};
	cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
	outline: none;
	user-select: none;
	${NotinoLabelRegular400};

	${({ $disabled, $isInvalid }) =>
		$disabled &&
		css`
			color: ${theme.color.text.disabled};
			&:hover {
				${Checker} {
					border-color: ${$isInvalid ? theme.color.border.negative : theme.color.common.black};
				}
			}
		`}
`
