import { IconSolidStar } from '@notino/react-styleguide'
import React from 'react'
import { useIntl } from 'react-intl'

// components
import SalonDetailPlaceholder from '../SalonDetailPlaceholder/SalonDetailPlaceholder'
import SalonImage from '../SalonImage/SalonImage'

// types
import { Salon, SalonsCategoryPageQueryType, SalonsListPageQueryType } from '../../types/types'

// assets
import ClockIcon from '../../assets/icons/ClockIcon'

// utils
import CalendarIcon2 from '../../assets/icons/CalendarIcon2'
import PhoneIcon from '../../assets/icons/PhoneIcon'
import { pushToDataLayer } from '../../utils/dataLayer'
import { getSelectItemEvent } from '../../utils/dataLayerEvents'
import { AVAILABLE_RESERVATION_TYPE } from '../../utils/enums'
import { PAGE_LINKS } from '../../utils/helper'

// hooks
import useMessages from '../../hooks/useMessages'

// styles
import * as SC from './SalonDetailStyles'
import { SALON_DETAIL } from '../../styles/constants'

// types
type SalonDetailProps = {
	salon: Salon
	isLoading?: boolean
	listPosition?: number
	currentCategoryID?: string
	query: SalonsCategoryPageQueryType | SalonsListPageQueryType
	imageLoading?: React.ImgHTMLAttributes<HTMLImageElement>['loading']
	logoLoading?: React.ImgHTMLAttributes<HTMLImageElement>['loading']
}

// component
const SalonDetail: React.FC<React.PropsWithChildren<SalonDetailProps>> = (props) => {
	const { salon, isLoading = false, listPosition, currentCategoryID, query, imageLoading, logoLoading } = props
	const { locale } = useIntl()
	const { messages } = useMessages()

	const isSalonsCategoryPageQuery = 'avResTimeSlotDayPart' in query

	const itemHref = PAGE_LINKS['/salons/:salonSlug'](locale, salon.seoSlugName, {
		currentCategoryID,
		avResTimeSlotDayPart: isSalonsCategoryPageQuery ? query.avResTimeSlotDayPart : undefined,
		avResTimeSlotDateFrom: isSalonsCategoryPageQuery ? query.avResTimeSlotDateFrom : undefined
	})

	if (isLoading) {
		return <SalonDetailPlaceholder />
	}

	const handleItemClick = () => {
		// push to dataLayer
		const event = getSelectItemEvent({ listPosition, salon })
		pushToDataLayer(event)
	}

	return (
		<SC.SalonDetailItem href={itemHref} onClick={handleItemClick}>
			<SC.ImageWrapper>
				{salon.availableReservationType === AVAILABLE_RESERVATION_TYPE.ONLINE_RESERVATION && (
					<SC.AvailableRSBadge>
						<CalendarIcon2 />
						{messages['Online reservation']}
					</SC.AvailableRSBadge>
				)}
				{salon.availableReservationType === AVAILABLE_RESERVATION_TYPE.PHONE_RESERVATION && (
					<SC.AvailableRSBadge>
						<PhoneIcon />
						{messages['Phone reservation only']}
					</SC.AvailableRSBadge>
				)}
				<SalonImage salon={salon} loading={imageLoading} width={SALON_DETAIL.IMAGE_WIDTH} height={SALON_DETAIL.IMAGE_HEIGHT} />
			</SC.ImageWrapper>

			<SC.TextContentWrapper>
				{salon.logo?.resizedImages.small && (
					<SC.LogoWrapper>
						<img
							loading={logoLoading}
							src={salon.logo.resizedImages.small}
							srcSet={`${salon.logo.resizedImages.thumbnail} 2x`} // Retina variant
							alt='salon logo'
							width={40}
							height={40}
						/>
					</SC.LogoWrapper>
				)}

				{salon.rating && (
					<SC.Rating>
						<IconSolidStar width='12' height='12' style={{ marginBottom: '2px' }} color={'icon.highlight'} />
						<span>{salon.rating.toFixed(1)}</span>
					</SC.Rating>
				)}

				{salon.name && <SC.Title>{salon.name}</SC.Title>}

				<SC.Description>
					{salon.formattedAddress && <SC.Address>{salon.formattedAddress}</SC.Address>}

					{salon.openingHoursData && (
						<SC.OpeningHours $isOpen={salon.openingHoursData?.isOpen}>
							<ClockIcon />
							<SC.OpeningHoursText>
								<SC.OpeningHoursStatus $isOpen={salon.openingHoursData?.isOpen}>
									{salon.openingHoursData.isOpen ? messages.Open : messages.Close}
								</SC.OpeningHoursStatus>
								{salon.openingHoursData.message && ` • ${salon.openingHoursData.message}`}
							</SC.OpeningHoursText>
						</SC.OpeningHours>
					)}
				</SC.Description>
			</SC.TextContentWrapper>
		</SC.SalonDetailItem>
	)
}

export default SalonDetail
