/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components'
import { theme } from '@notino/react-styleguide'

// styles
import { NotinoLabelRegular500, truncate } from '../../styles/helpers'

export const InputLabel = styled.label<{ $required?: boolean }>`
	${NotinoLabelRegular500};
	color: ${theme.color.text.primary};
	position: relative;
	${truncate};

	${({ $required }) =>
		$required &&
		css`
			&::after {
				display: inline;
				${NotinoLabelRegular500};
				content: '*';
				color: ${theme.color.text.primary};
				margin-left: 2px;
			}
		`}
`
