import { SkeletonElement } from '@notino/react-styleguide'
import styled from 'styled-components'

export const TimeSlotOptionsSkeletonWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
`

export const DayPeriodSkeletonTitle = styled(SkeletonElement)`
	margin-bottom: 8px;
`

export const DayPeriodSkeletonsWrapper = styled.div`
	display: flex;
	gap: 8px;
	flex-wrap: wrap;
`

export const TimeSlotOptionsSkeleton = styled(SkeletonElement)`
	border-radius: 999px;
`
