import React from 'react'
import { Button, ButtonModel, Modal } from '@notino/react-styleguide'
import * as SC from './MyLocationProviderModalStyles'
import useMessages from '../../hooks/useMessages'

type InfoModalProps = {
	show: boolean
	onOk: () => void
}

const MyLocationProviderModal = ({ show, onOk }: InfoModalProps) => {
	const { messages } = useMessages()

	return (
		<Modal show={show} onClose={onOk}>
			<SC.ModalText>{messages['Please allow location access in your browser to use this feature']}</SC.ModalText>
			<Button onClick={onOk} buttonStyle={ButtonModel.Styles.primary} type='button' fullWidth>
				{messages['I understand']}
			</Button>
		</Modal>
	)
}

export default MyLocationProviderModal
