/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import React, { useContext } from 'react'
import { useIntl } from 'react-intl'
import { IconRegularClose, Modal, ModalModel } from '@notino/react-styleguide'
import useMessages from '../../hooks/useMessages'
import * as SC from './ReservationBookingPromoModalStyles'
import StoreDownloadButtons from '../StoreDownloadButtons/StoreDownloadButtons'
import CalendarIcon from '../../assets/icons/CalendarIcon'
import AlarmClockIcon from '../../assets/icons/AlarmClockIcon'
import CheckCircleIcon from '../../assets/icons/CheckCircleIcon'
import { AppContext } from '../../utils/appProvider'

type Props = {
	isOpen: boolean
	onClose: () => void
	availableReservation: boolean
}

const ReservationBookingPromoModal = ({ isOpen, onClose, availableReservation }: Props) => {
	const { messages } = useMessages()
	const { locale } = useIntl()
	const { assetsPath } = useContext(AppContext)

	const imgSrc = `${assetsPath}/${require(`../../assets/images/booking-promo-modal/modal-image-${locale}-desktop.jpg`)}`
	const imgSrcMobile = `${assetsPath}/${require(`../../assets/images/booking-promo-modal/modal-image-${locale}-mobile.jpg`)}`
	const qrCode = `${assetsPath}/${require(`../../assets/images/app-qr-code.jpg`)}`

	const headingText = availableReservation
		? messages?.['Easy way to salon bookings anytime, anywhere via the Notino app']
		: messages?.['To make an appointment at our salon, download our app']

	return (
		<SC.ModalWrapper>
			<Modal withFocusTrap={isOpen} show={isOpen} closeIconColor={'icon.highlight'} onClose={onClose} size={ModalModel.Sizes.s700}>
				{/* close button */}
				<SC.CloseButton tabIndex={0} aria-expanded={isOpen ? 'true' : undefined} aria-keyshortcuts='Escape' type='button' onClick={onClose}>
					<IconRegularClose color={'icon.primary'} />
				</SC.CloseButton>
				<SC.Content>
					{/* image */}
					<SC.MobileImageWrapper>
						<SC.Image src={imgSrcMobile} alt='' />
					</SC.MobileImageWrapper>
					<SC.DesktopImageWrapper>
						<SC.Image src={imgSrc} alt='' />
					</SC.DesktopImageWrapper>
					{/* text content */}
					<SC.TextContent>
						<SC.Heading>{headingText}</SC.Heading>
						<SC.Text>
							{
								messages?.[
									'Looking to book a hair, nail, or massage appointment? Skip the phone call — download the Notino app now and book your next visit with just a few taps.'
								]
							}
						</SC.Text>
						<SC.List>
							<SC.ListItem>
								<SC.IconWrapper>
									<CalendarIcon />
								</SC.IconWrapper>
								{messages?.['Simple and convenient booking management within the app']}
							</SC.ListItem>
							<SC.ListItem>
								<SC.IconWrapper>
									<AlarmClockIcon />
								</SC.IconWrapper>
								{messages?.['Reminders for your bookings and complete salon info in one place']}
							</SC.ListItem>
							<SC.ListItem>
								<SC.IconWrapper>
									<CheckCircleIcon />
								</SC.IconWrapper>
								{messages?.['Automatic data pre-filling']}
							</SC.ListItem>
						</SC.List>

						<SC.DownloadsSectionWrapper>
							<SC.StoreDownloadWrapper>
								<SC.DownloadText>{messages?.['Get the Notino App for Free - Download Now:']}</SC.DownloadText>
								<StoreDownloadButtons />
							</SC.StoreDownloadWrapper>
							<SC.QRCodeImageWrapper>
								<img src={qrCode} width={92} height={92} alt={messages?.['Download Notino app via QR code']} />
							</SC.QRCodeImageWrapper>
						</SC.DownloadsSectionWrapper>
					</SC.TextContent>
				</SC.Content>
			</Modal>
		</SC.ModalWrapper>
	)
}

export default ReservationBookingPromoModal
