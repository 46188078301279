import React from 'react'

const EmptyStateIcon = () => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='72' height='72' fill='none' viewBox='0 0 72 72'>
			<path
				fill='currentColor'
				fillRule='evenodd'
				d='M34.066 15.5a.97.97 0 011.174-.712 16.737 16.737 0 0111.51 9.923.97.97 0 11-1.796.734 14.797 14.797 0 00-10.176-8.773.97.97 0 01-.712-1.173zM48.764 49.923l.686-.686.686-.686 14.58 14.58a.97.97 0 01-1.372 1.372l-14.58-14.58z'
				clipRule='evenodd'
			/>
			<path
				fill='currentColor'
				d='M52.361 31.044c0 11.656-9.448 21.104-21.104 21.104-.648 0-1.289-.03-1.922-.086-.375.637-.799 1.241-1.267 1.808a23.25 23.25 0 003.19.219c12.726 0 23.044-10.318 23.044-23.045C54.302 18.317 43.984 8 31.257 8 18.53 8 8.213 18.317 8.213 31.044c0 1.083.074 2.147.219 3.19a13.988 13.988 0 011.808-1.268 21.346 21.346 0 01-.087-1.922c0-11.655 9.449-21.103 21.104-21.103s21.104 9.448 21.104 21.103z'
			/>
			<path
				fill='currentColor'
				d='M24.466 37.707c-3.959-4-10.438-4.036-14.44-.08-4.004 3.96-4.039 10.438-.08 14.44 3.96 4 10.439 4.036 14.44.077 4.001-3.96 4.037-10.438.08-14.44v.003zM17.46 49.49c-.177.17-.412.258-.702.258s-.525-.084-.702-.258a.862.862 0 01-.268-.647c0-.261.09-.487.27-.658a.966.966 0 01.697-.26c.283 0 .515.086.696.26.18.171.27.393.27.658 0 .264-.09.476-.264.647h.003zm1.856-5.329c-.152.3-.416.635-.796 1.002l-.483.461a1.649 1.649 0 00-.519 1.021l-.022.364h-1.53c0-.557.067-1.002.202-1.333.136-.332.384-.658.745-.98.36-.322.599-.583.718-.783.12-.2.18-.415.18-.637 0-.674-.312-1.012-.93-1.012-.294 0-.532.09-.71.27-.177.18-.27.432-.276.751H14.16c.007-.76.255-1.356.738-1.788.483-.432 1.144-.648 1.981-.648.838 0 1.502.203 1.966.613.467.409.699.986.699 1.73 0 .338-.074.657-.226.96l-.003.01z'
			/>
		</svg>
	)
}

export default EmptyStateIcon
