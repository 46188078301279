import styled, { css, keyframes } from 'styled-components'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { Button, theme } from '@notino/react-styleguide'
import { NotinoLabelSmall400, NotinoTitleMedium, resetButtonStyles } from '../../styles/helpers'
import { BREAKPOINTS } from '../../styles/constants'

const detailAppearAnimation = keyframes`
	from {
		transform: translateY(100%);
		opacity: 0;
	}
	to {
		transform: translateY(0);
		opacity: 1;
	}
`

export const ReservationModalScreenWrapper = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;
`

export const ReservationModalScreen = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	background: white;
	z-index: 2;
	transform: translateY(100%);
	opacity: 0;
	animation: ${detailAppearAnimation} 0.3s forwards;
	overflow: hidden;
`

export const ScrollContainer = styled(OverlayScrollbarsComponent)<{ $isReservationBookingModal: boolean }>`
	overflow-y: auto;
	height: 100%;

	${({ $isReservationBookingModal }) =>
		!$isReservationBookingModal &&
		css`
			@media (min-width: ${BREAKPOINTS.sm}) {
				height: auto;
			}
		`}
`

export const CloseButton = styled.button`
	${resetButtonStyles};
	position: absolute;
	right: 20px;
	top: 20px;
	z-index: 2;
`

export const ContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	padding: 20px;

	@media (min-width: ${BREAKPOINTS.sm}) {
		padding: 24px;
	}
`

export const ServiceCategoryTitle = styled.div`
	${NotinoTitleMedium};
	margin-bottom: 4px;
	margin-right: 30px;
`

export const ServicePriceAndDurationWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	gap: 8px;
	@media (min-width: ${BREAKPOINTS.sm}) {
		align-items: center;
		flex-direction: row;
	}
`

export const PriceAndDurationContainer = styled.div`
	display: flex;
	gap: 4px;
	align-items: center;
	margin-bottom: 20px;
`

export const ServicePrice = styled.div`
	display: flex;
	align-items: center;
	${NotinoLabelSmall400};
	white-space: nowrap;
`

export const ServiceDuration = styled.div`
	display: flex;
	align-items: center;
	position: relative;
	${NotinoLabelSmall400};

	@media (min-width: ${BREAKPOINTS.sm}) {
		&:not(:last-child) {
			&::after {
				width: 12px;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				text-align: center;
				content: '•';
				color: ${theme.color.text.disabled};
				margin: 0 4px;
			}
		}
	}
`

export const DurationIcon = styled.div`
	margin-right: 5px;
	flex-shrink: 0;

	& > svg {
		width: 12px;
		height: 12px;
	}
`

export const DurationText = styled.div`
	${NotinoLabelSmall400};
	white-space: nowrap;
`

export const BookOnlineButton = styled(Button)`
	margin-left: auto;
`

export const ServiceDescription = styled.div`
	color: ${theme.color.text.secondary};
	${NotinoLabelSmall400};
	margin-bottom: 20px;
`
export const ButtonsWrapper = styled.div`
	margin-top: auto;
	position: sticky;
	bottom: 0;
	margin: auto -20px -20px -20px;
	padding: 20px;
	background: ${theme.color.background.primary};

	@media (min-width: ${BREAKPOINTS.sm}) {
		margin: auto -24px -24px -24px;
		padding: 24px;
	}
`

export const ModalWrapper = styled.div<{ $isReservationBookingModal?: boolean }>`
	position: relative;

	> div > div {
		overflow-y: auto;
	}

	& div[role='dialog'] {
		position: relative;

		& > button {
			display: none;
		}

		> div:first-of-type {
			height: 100%;
			max-height: initial;

			> div:first-of-type {
				height: 100%;

				> div:first-of-type {
					height: 100%;
				}
			}
		}
	}

	@media (min-width: ${BREAKPOINTS.sm}) {
		& div[role='dialog'] {
			position: relative;

			& > button {
				display: none;
			}

			> div:first-of-type {
				height: auto;
				max-height: initial;

				> div:first-of-type {
					height: auto;

					> div:first-of-type {
						height: auto;
					}
				}
			}
		}
	}
`
