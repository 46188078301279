import styled, { css } from 'styled-components'
import { theme } from '@notino/react-styleguide'

// styles
import { NotinoLabelLarge, resetButtonStyles } from '../../../styles/helpers'

export const LayoutWrapper = styled.div<{ $isServiceDetailVisible: boolean }>`
	width: 100%;
	height: 100%;
	z-index: 1;

	${({ $isServiceDetailVisible }) =>
		$isServiceDetailVisible &&
		css`
			overflow: hidden;
			pointer-events: none;
			position: absolute;
			inset: 0;
		`}
`

export const ScrollButton = styled.button`
	${resetButtonStyles};
	position: absolute;
	top: 8px;
	width: 24px;
	height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	display: none;

	/* styles for devices with precise input (like mouse) */
	@media (pointer: fine) {
		display: block;
	}
`

export const ScrollLeftButton = styled(ScrollButton)`
	left: 16px;

	&::after {
		left: 100%;
		background: linear-gradient(to right, white, transparent);
	}
`

export const ScrollRightButton = styled(ScrollButton)`
	right: 16px;

	&::after {
		right: 100%;
		background: linear-gradient(to left, white, transparent);
	}
`

export const TabsContainer = styled.div`
	position: relative;
	isolation: isolate;
	margin: 0 -20px -20px -20px;

	/* styles for devices with precise input (like mouse) */
	@media (pointer: fine) {
		padding: 0 20px 20px 20px;
	}
`

export const Tabs = styled.ul<{ $isScrollLeft?: boolean; $isScrollRight?: boolean }>`
	display: flex;
	scroll-behavior: smooth;
	z-index: 0;
	gap: 8px;
	overflow-x: auto;
	padding: 0 20px 20px 20px;

	/* styles for devices with precise input (like mouse) */
	@media (pointer: fine) {
		padding: 0;
		/* hide scrollbar */
		&::-webkit-scrollbar {
			display: none; // for Chrome, Safari and Opera
		}
		& {
			-ms-overflow-style: none; // for IE and Edge
			scrollbar-width: none; // for for Firefox
		}

		${({ $isScrollLeft, $isScrollRight }) => {
			if ($isScrollLeft && $isScrollRight) {
				return css`
					mask-image: linear-gradient(to right, transparent, black 20%, black 80%, transparent);
				`
			}

			if ($isScrollLeft) {
				return css`
					mask-image: linear-gradient(to left, black 80%, transparent);
				`
			}

			if ($isScrollRight) {
				return css`
					mask-image: linear-gradient(to right, black 80%, transparent);
				`
			}

			return null
		}}
	}
`

export const Tab = styled.li`
	list-style: none;
	padding: 0;
	margin: 0;

	&::before {
		display: none; // override Notino main app styles
	}
`

export const TabButton = styled.div<{ $isActive?: boolean }>`
	cursor: pointer;
	padding: 8px;
	white-space: nowrap;
	color: ${theme.color.text.inverse};
	border-bottom: 2px solid transparent;

	&[aria-selected='true'] {
		position: relative;
		${NotinoLabelLarge};
		border-color: ${theme.color.common.white};
	}
`

export const TabsContent = styled.div`
	position: relative;
`

export const TabContent = styled.div<{ $isActive: boolean }>`
	position: relative;
	left: 0;
	top: 0;

	${({ $isActive }) =>
		!$isActive &&
		css`
			width: 0;
			height: 0;
			overflow: hidden;
		`}
`
