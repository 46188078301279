/* eslint-disable no-console */
import { useState, useEffect } from 'react'

const unavailableLocalStorageWarning = () => console.warn('Local storage is not available. Value cannot be persistently stored.')

const dummyLocalStorage = {
	setItem: (_key: string, _value: string) => {
		unavailableLocalStorageWarning()
	},
	getItem: (_key: string): string | null => {
		unavailableLocalStorageWarning()
		return null
	},
	length: 0,
	removeItem: (_key: string) => {
		unavailableLocalStorageWarning()
	}
}

/**
 * Returns the local storage object, if available. If not (i.e. the code is running on the server),
 * a dummy object with the same interface is returned. The dummy object will log a warning if any of
 * its methods is called.
 *
 * @returns {Storage} The local storage object, or a dummy object if it is not available.
 */
const localStorage = () => {
	if (typeof window === 'undefined' || typeof window.localStorage === 'undefined') {
		console.warn('Local storage is not available. Make sure to access it on the client side.')
		return dummyLocalStorage
	}

	return window.localStorage
}

/**
 * Hook to store and retrieve data from local storage.
 *
 * The data is stored as JSON string in local storage. When the component mounts,
 * it will try to load the data from local storage and set it to the state. If the
 * data is not available in local storage, it will set the state to `null`.
 *
 * Keep in mind the limitation of the serialized JSON object in localStorage. Following types are only supported:
 * - string
 * - number
 * - boolean
 * Types like Date, regex, and functions are not supported due backward compatibility (deserialization)
 *
 * The hook returns an object with the following properties:
 *
 * - `storedValue`: The data stored in local storage, or `null` if it is not available.
 * - `setValue`: A function to set the data in local storage. It will update the state
 *   with the new value.
 * - `deleteStoredValue`: A function to delete the data from local storage.
 * - `hasValue`: A function to check if the data is available in local storage.
 * - `loadingPersistentData`: A boolean indicating whether the data is being loaded from
 *   local storage.
 *
 * @param {string} key The key to store the data under in local storage.
 * @returns {Object} An object with the properties described above.
 */
function useLocalStorage<T>(key: string) {
	const [storedValue, setStoredValue] = useState<T | null>(null)
	const [loadingPersistentData, setLoadingPersistentData] = useState(true)

	useEffect(() => {
		if (!key) {
			return
		}

		const item = localStorage().getItem(key)

		if (item) {
			setStoredValue(JSON.parse(item))
		}
		setLoadingPersistentData(false)
	}, [key])

	const setValue = (newValue: Partial<T>) => {
		const value = {
			...storedValue,
			...newValue
		} as T

		localStorage().setItem(key, JSON.stringify(value))
		setStoredValue(value)
	}

	const deleteStoredValue = () => {
		localStorage().removeItem(key)
		setStoredValue(null)
	}

	const hasValue = () => {
		return !!localStorage().getItem(key) || !!storedValue
	}

	return { storedValue, setValue, deleteStoredValue, hasValue, loadingPersistentData }
}

export default useLocalStorage
