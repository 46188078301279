import React from 'react'

// styles
import * as SC from './CheckboxStyles'

// types
import { CheckboxProps } from './types'

const Checkbox = (props: CheckboxProps) => {
	const { checked, onChange, disabled, invalid, className, alignment = 'center', checkboxGap = 12, children } = props

	return (
		<SC.CheckboxLabel $isInvalid={invalid} $checkboxGap={checkboxGap} $alignment={alignment} $disabled={disabled} className={className}>
			<SC.CheckboxInput $isInvalid={invalid} type={'checkbox'} checked={checked} onChange={onChange} disabled={disabled} />
			<SC.Checker $isInvalid={invalid} $alignment={alignment} />
			{children}
		</SC.CheckboxLabel>
	)
}

export default Checkbox
