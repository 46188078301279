import React from 'react'
import { useParams } from 'react-router-dom'
import { StringParam, useQueryParams } from 'use-query-params'

// components
import SalonPage from '../pages/SalonPage/SalonPage'

// types
import { ContextProps, SalonPageQueryType, SalonPageSetQueryType } from '../types/types'

// helpers
import { getValidSalonPageQuery } from '../utils/helper'

type SalonRouteParams = {
	salonSlug: string
}

const SalonRoute: React.FC<React.PropsWithChildren<ContextProps>> = (props) => {
	const { salonSlug } = useParams<SalonRouteParams>()

	// NOTE: when adding new parameter, also add it to SalonPageQueryType and SalonPageSetQueryType in interfaces
	const [query, setQuery] = useQueryParams({
		t: StringParam,
		calendarEventID: StringParam,
		selectedIndustryID: StringParam,
		selectedAvResTimeSlotDayPart: StringParam,
		selectedAvResTimeSlotDateFrom: StringParam
	})
	const validQuery = getValidSalonPageQuery(query as SalonPageQueryType)

	return <SalonPage {...props} salonSlug={salonSlug as string} query={validQuery} setQuery={setQuery as SalonPageSetQueryType} />
}

export default SalonRoute
